import { put, takeLatest } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

export const actionTypes = {
  FilledFormFirstStep: '[First Step] Fill form',
  LoadingPageFirstStep: '[First Step] Loading Page',
  NextPageFirstStep: '[First Step] Next Page',
  NextFirstStep: '[First Step] Next',
  PrevFirstStep: '[First Step] Prev',
  PrevPageFirstStep: '[First Step] Prev Page',
  ResetFirstStep: '[First Step] Reset',
  SetActiveFirstStep: '[First Step] Active Step',
  SetCustomerStep: '[First Step] Customer Step',
  ToggleHiddenNavFirstStep: '[First Step] Toggle nav',
};

const initialState = {
  active: 0,
  clickedOrientation: 'left',
  loading: false,
  hidden: false,
  disabledSwipe: false,
  disabled: false,
  selected: {
    nextPage: 'addressToForm',
    prevPage: '',
    validate: true,
    canGoForward: false,
    formType: 'addressFromForm',
    completed: false,
    categoryType: 1,
    categoryLabel: 'category.addresses',
  },
  items: [
    {
      nextPage: 'addressToForm',
      prevPage: '',
      validate: true,
      canGoForward: false,
      formType: 'addressFromForm',
      completed: false,
      showDot: false,
      categoryType: 1,
      categoryLabel: 'category.addresses',
    },
    {
      nextPage: 'confirmAddressForm',
      prevPage: 'addressFromForm',
      validate: true,
      canGoForward: false,
      formType: 'addressToForm',
      completed: false,
      showDot: false,
      categoryType: 1,
      categoryLabel: 'category.addresses',
    },
    {
      nextPage: 'servicesQuestionForm',
      prevPage: 'addressToForm',
      validate: false,
      canGoForward: true,
      formType: 'confirmAddressForm',
      completed: false,
      showDot: true,
      categoryType: 1,
      categoryLabel: 'category.addresses',
    },
    {
      nextPage: 'roomsForm',
      prevPage: 'addressToForm',
      validate: false,
      canGoForward: true,
      formType: 'servicesQuestionForm',
      completed: false,
      showDot: true,
      categoryType: 2,
      categoryLabel: 'category.question',
    },
    {
      nextPage: 'roomsSelectionPage',
      prevPage: 'servicesQuestionForm',
      validate: true,
      canGoForward: false,
      formType: 'roomsForm',
      completed: false,
      showDot: false,
      categoryType: 3,
      categoryLabel: 'category.rooms',
    },
    {
      nextPage: 'customerForm',
      prevPage: 'roomsForm',
      formType: 'roomsSelectionPage',
      validate: true,
      canGoForward: false,
      completed: false,
      showDot: true,
      categoryType: 3,
      categoryLabel: 'category.rooms',
    },
    {
      nextPage: 'notesForm',
      prevPage: 'roomsSelectionPage',
      formType: 'customerForm',
      validate: true,
      canGoForward: false,
      completed: false,
      showDot: false,
      categoryType: 5,
      categoryLabel: 'category.customer',
    },
    {
      nextPage: 'confirmCustomerPage',
      prevPage: 'customerForm',
      formType: 'notesForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: true,
      categoryType: 5,
      categoryLabel: 'category.notesForm',
    },
    {
      formType: 'confirmCustomerPage',
      nextPage: 'secondaryAddressPage',
      prevPage: 'notesForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: false,
      categoryType: 5,
      categoryLabel: 'category.customerData',
    },
    {
      prevPage: 'confirmCustomerPage',
      formType: 'secondaryAddressPage',
      nextPage: 'quotePricing',
      validate: false,
      canGoForward: true,
      completed: true,
      showDot: false,
      categoryType: 5,
      categoryLabel: 'category.customerData',
    },
    {
      formType: 'quotePricing',
      nextPage: 'quoteResult',
      prevPage: 'secondaryAddressPage',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: true,
      categoryType: 6,
    },
    {
      formType: 'quoteResult',
      nextPage: 'sendQuotePage',
      prevPage: 'quotePricing',
      validate: false,
      canGoForward: true,
      showDot: false,
      categoryType: 6,
    },
    {
      formType: 'sendQuotePage',
      nextPage: '',
      prevPage: 'quoteResult',
      validate: false,
      completed: true,
      showDot: false,
      categoryType: 6,
    },
  ],
};

export const reducer = persistReducer({ storage: localforage, key: 'first-step' }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetActiveFirstStep: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.NextFirstStep: {
      return {
        ...state,
      };
    }
    case actionTypes.PrevFirstStep: {
      return {
        ...state,
      };
    }
    case actionTypes.NextPageFirstStep: {
      const page = action.payload.active >= state.items.length - 1 ? action.payload.active : action.payload.active + 1;
      const nextItem = state.items.find(item => item.formType === action.payload.selected.nextPage);
      if (!nextItem.validate) {
        nextItem.completed = true;
      }
      return {
        ...state,
        active: page,
        clickedOrientation: 'left',
        loading: false,
        selected: nextItem,
      };
    }
    case actionTypes.PrevPageFirstStep: {
      const prevItem = state.items.find(item => item.formType === action.payload.selected.prevPage);
      return {
        ...state,
        ...action.payload,
        clickedOrientation: 'right',
        loading: false,
        selected: prevItem,
      };
    }
    case actionTypes.LoadingPageFirstStep: {
      return {
        ...state,
        loading: false,
        disabled: action.payload,
      };
    }
    case actionTypes.ToggleHiddenNavFirstStep: {
      return {
        ...state,
        hidden: action.payload,
      };
    }
    case actionTypes.FilledFormFirstStep: {
      const itemSelectedinArray = state.items.find(item => item.formType === state.selected.formType);
      itemSelectedinArray.canGoForward = action.payload;
      itemSelectedinArray.disabled = !action.payload;
      itemSelectedinArray.completed = action.payload;
      return {
        ...state,
        selected: {
          ...state.selected,
          canGoForward: action.payload,
          completed: action.payload,
        },
        items: [...state.items],
      };
    }
    case actionTypes.ResetFirstStep: {
      return {
        ...initialState,
        items: [...initialState.items].map(item => ({ ...item, completed: false })),
      };
    }
    case actionTypes.SetCustomerStep: {
      return {
        ...state,
        hidden: false,
        selected: {
          nextPage: 'confirmCustomerPage',
          prevPage: 'dateForm',
          formType: 'customerForm',
          validate: true,
          canGoForward: false,
          completed: false,
          showDot: false,
          categoryType: 5,
          categoryLabel: 'category.customerData',
        },
      };
    }
    default:
      return { ...state };
  }
});

export const actions = {
  toggleNav: data => ({
    type: actionTypes.ToggleHiddenNavFirstStep,
    payload: data,
  }),
  setNav: data => ({ type: actionTypes.SetActiveFirstStep, payload: data }),
  setLoading: data => ({ type: actionTypes.LoadingPageFirstStep, payload: data }),
  nextPage: (data, selected) => ({
    type: actionTypes.NextPageFirstStep,
    payload: { active: data, selected },
  }),
  prevPage: (data, selected) => ({
    type: actionTypes.PrevPageFirstStep,
    payload: { active: data.selected, selected },
  }),
  filledForm: data => ({ type: actionTypes.FilledFormFirstStep, payload: data }),
  reset: () => ({ type: actionTypes.ResetFirstStep }),
  setCustomerStep: () => ({ type: actionTypes.SetCustomerStep }),
  next: (data, selected) => ({ type: actionTypes.NextFirstStep, payload: { data, selected } }),
  prev: (data, selected) => ({ type: actionTypes.PrevFirstStep, payload: { data, selected } }),
};

export function* saga() {
  yield takeLatest(actionTypes.NextFirstStep, function* nextPageSaga(action) {
    yield put(actions.nextPage(action.payload.data, action.payload.selected));
  });
  yield takeLatest(actionTypes.PrevFirstStep, function* prevPageSaga(action) {
    const page = action.payload.data === 0 ? action.payload.data : action.payload.data - 1;
    yield put(actions.prevPage(page, action.payload.selected));
  });
}
