import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { getRelationCommunications } from '../Services/CommunicationService';

export const actionTypes = {
  RequestData: '[Customer Communications Tab] Request Invoice',
  FulfilledTable: '[Customer Communications Tab] FulfilledCustomers communications',
  SearchTable: '[Customer Communications Tab] Search customers communications',
  ChangePage: '[Customer Communications Tab] change page',
  SetPageSize: '[Customer Communications Tab] set page size',
  SortTable: '[Customer Communications Tab] Sort customers communications',
  ApplyFilter: '[Customer Communications Tab] Apply filter',
  RemoveFilter: '[Customer Communications Tab] Remove filter',
  ResetFilters: '[Customer Communications Tab] Reset filter',
};

const initialState = {
  ...tableInitialState,
  pageSize: 5,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);
  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    const customerId = yield select(state => state.CustomerCommunicationsReducer.customerId);

    yield put(actions.requestData({ customerId }));
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestSaga({ data: { customerId } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.CustomerCommunicationsReducer);

    const response = yield getRelationCommunications(
      currentTenantId,
      customerId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
    );
    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
