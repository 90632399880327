import PropTypes from 'prop-types';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  label: {
    marginBottom: '7px',
  },
  whiteLabel: {
    color: '#ffffff',
    marginBottom: '7px',
  },
  whiteHelperText: {
    background: 'transparent',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  inputWhite: {
    color: '#ffffff',
  },
  whiteUnderline: {
    '&:before': {
      borderBottom: '1px solid #ffffff',
    },
  },
}));

const SelectComponent = props => {
  const { label, field, whiteLabel, ...form } = props;
  const { form: insideForm } = form;
  const classes = useStyles();
  const inError =
    insideForm &&
    Object.keys(insideForm.errors).length > 0 &&
    insideForm.errors[field.name] &&
    insideForm.errors[field.name].props.id;

  return (
    <>
      <FormControl
        label={label}
        className={classes.formControl}
        error={
          insideForm &&
          Object.keys(insideForm.errors).length > 0 &&
          insideForm.errors[field.name] &&
          insideForm.errors[field.name].props.id
        }
      >
        <InputLabel required className={clsx(classes.root, whiteLabel ? classes.whiteHelperText : classes.helperText)}>
          {label}
        </InputLabel>
        <Select
          label={label}
          {...field}
          {...form}
          className={clsx(classes.root, whiteLabel ? classes.inputWhite : classes.helperText)}
        />
        {inError && (
          <FormHelperText className={whiteLabel ? classes.whiteHelperText : classes.helperText}>
            <FormattedMessage id={inError} />
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SelectComponent.defaultProps = {
  label: '',
};

export default SelectComponent;
