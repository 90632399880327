import axios from 'axios';

const upload = async (tenantId, authToken, file, progress) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}api/${tenantId}/media-objects`, file, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      if (typeof progress === 'function') {
        progress(progressEvent);
      }
    },
  });

  return data;
};

export default { upload };
