import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';

const LanguageField = props => (
  <TextField {...props} select>
    <MenuItem value="nl">
      <FormattedMessage id="languages.nl" />
    </MenuItem>
    <MenuItem value="en">
      <FormattedMessage id="languages.en" />
    </MenuItem>
  </TextField>
);

export default LanguageField;
