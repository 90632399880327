import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { InvoiceService } from '../../../services';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[Invoices] RequestInvoices',
  FulfilledTable: '[Invoices] FulfilledInvoices',
  SearchTable: '[Invoices] Search invoices',
  ChangePage: '[Invoices] change page',
  SetPageSize: '[Invoices] set page size',
  SortTable: '[Invoices] Sort invoices',
  ApplyFilter: '[Invoices] Apply filter',
  RemoveFilter: '[Invoices] Remove filter',
  ResetFilters: '[Invoices] Reset filter',
  FulFilledStatistics: '[Invoices] FulFilledStatistics',
};

const initialState = {
  ...tableInitialState,
  sortBy: { invoiceNumber: 'desc' },
  filters: { invoiceStatus: [] },
  statistics: {
    openInvoicesValue: {
      amount: 0,
      currency: 'EUR',
    },
    expiredInvoicesValue: {
      amount: 0,
      currency: 'EUR',
    },
    conceptInvoicesValue: {
      amount: 0,
      currency: 'EUR',
    },
    averageDaysThatAInvoiceIsOpenForThisMonth: 0,
    averageDaysThatAInvoiceIsOpenForLastMonth: 0,
  },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.FulFilledStatistics: {
      return {
        ...state,
        statistics: action.payload,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  fulFilledStatistics: statistics => ({
    type: actionTypes.FulFilledStatistics,
    payload: statistics,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestInvoicesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.InvoicesReducer);

    const response = yield InvoiceService.getInvoices(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    if (!response.cancelPrevQuery) {
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    }
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Invoice') {
      yield put(actions.requestData());
    }
  });

  yield takeLatest(actionTypes.RequestData, function* invoke() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const today = dayjs();
    const { data } = yield InvoiceService.getInvoiceStatistics(currentTenantId, today.year(), today.format('M'));

    yield put(actions.fulFilledStatistics(data));
  });
}
