import axios from 'axios';
import Qs from 'qs';

let tokenSource;
const mapField = field => {
  switch (field) {
    case 'customerNumber':
      return ['customerInformation.customerNumber'];
    case 'city':
      return ['address.city'];
    case 'zipCode':
      return ['address.zipCode'];
    default:
      return [field];
  }
};

export function getCustomers(tenantId, page, perPage, order = {}, filters = {}, globalFilter) {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }
    tokenSource = axios.CancelToken.source();

    const appliedFilters = {};
    Object.keys(filters).forEach(filter => {
      mapField(filter).forEach(field => {
        appliedFilters[field] = filters[filter];
      });
    });

    const appliedOrder = {};
    Object.keys(order).forEach(key => {
      mapField(key).forEach(field => {
        appliedOrder[field] = order[key];
      });
    });

    return axios.get(`api/${tenantId}/customers`, {
      params: {
        ...appliedFilters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: tokenSource.token,
    });
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
}

export function searchCustomers(tenantId, page, perPage, globalFilter) {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }
    tokenSource = axios.CancelToken.source();

    return axios.get(`api/${tenantId}/customers/search/${globalFilter}`, {
      params: {
        page,
        perPage,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: tokenSource.token,
    });
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
}

export function getCustomer(tenantId, customerId) {
  return axios.get(`api/${tenantId}/customers/${customerId}`);
}

export function createCustomer(tenantId, values) {
  return axios.post(`api/${tenantId}/customers`, { ...values, tenantId });
}

export default {
  getCustomer,
  getCustomers,
  searchCustomers,
  createCustomer,
};
