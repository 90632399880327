import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from './SingleObject.duck';
import { getPackage } from '../../../services/TenantService';
import { newMercureMessage } from './App.duck';

export const actionTypes = {
  ResetData: '[Package] Reset Package',
  RequestData: '[Package] Request Package',
  FulfilledData: '[Package] Fulfilled Package',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestPackageSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getPackage(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Package') {
      const { item } = yield select(state => state.PackageReducer);
      if (item && action && action.data.packageId === item.packageId) {
        yield put(actions.fulfilledData(action.data));
      }
    }
  });
}
