import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { TenantService } from '../../../services';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[Tenants] RequestTenants',
  FulfilledTable: '[Tenants] FulfilledTenants',
  SearchTable: '[Tenants] Search tenants',
  ChangePage: '[Tenants] change page',
  SetPageSize: '[Tenants] set page size',
  SortTable: '[Tenants] Sort tenants',
  ApplyFilter: '[Tenants] Apply filter',
  RemoveFilter: '[Tenants] Remove filter',
  ResetFilters: '[Tenants] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestTenantsSaga() {
    const currentState = yield select(state => state.TenantsReducer);

    const response = yield TenantService.getTenants(
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Tenant') {
      yield put(actions.requestData());
    }
  });
}
