import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { actions as AppActions } from 'Domain/App/Ducks/App.duck';
import { injectIntl } from 'react-intl';

let displayed = [];

const Notifier = ({ intl }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(store => store.AppReducer.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(notificationId => id !== notificationId)];
  };

  useEffect(() => {
    notifications.forEach(({ notificationId, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(notificationId);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(notificationId)) return;

      // display snackbar using notistack
      enqueueSnackbar(typeof message === 'object' ? intl.formatMessage(message) : message, {
        key: notificationId,
        ...options,
        onClose: (event, reason, closeNotificationId) => {
          if (options.onClose) {
            options.onClose(event, reason, closeNotificationId);
          }
        },
        onExited: (event, exitNotificationId) => {
          // remove this snackbar from redux store
          dispatch(AppActions.dismissNotification(exitNotificationId));
          removeDisplayed(exitNotificationId);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(notificationId);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, intl]);

  return null;
};

export default injectIntl(Notifier);
