import { put, select, takeLatest } from 'redux-saga/effects';
import { RoomService, TenantService } from 'services';
import { persistReducer } from 'redux-persist';
import moment from 'moment';
import localforage from 'localforage';
import { actions as FirstStepActions } from '../../FirstStep/Ducks/FirstStep.duck';
import { actions as SecondStepActions } from '../../SecondStep/Ducks/SecondStep.duck';
import { getPublicMovingBoxes } from '../../Inventory/Services/InventoryService';

export const actionTypes = {
  SetProject: '[MovingJobEstimate] Set MovingJobEstimate',
  RemoveQuoteSecondaryAddressesFrom: '[MovingJobEstimate] Remove secondary address from',
  RemoveQuoteSecondaryAddressesTo: '[MovingJobEstimate] Remove secondary address to',
  ResetError: '[MovingJobEstimate] Reset Error',
  ResetState: '[MovingJobEstimate] Reset State',
  SetQuote: '[MovingJobEstimate] Set MovingJobEstimate Quote',
  SetQuoteSecondaryAddressesFrom: '[MovingJobEstimate] Set secondary address from',
  SetQuoteSecondaryAddressesTo: '[MovingJobEstimate] Set secondary address to',
  SetRooms: '[MovingJobEstimate] Set Rooms',
  DeleteQuote: '[MovingJobEstimate] Delete MovingJobEstimate',
  ResetApp: '[MovingJobEstimate] Reset All',
  SaveForm: '[MovingJobEstimate] Save Forms',
  SetError: '[MovingJobEstimate] Set Error',
  LoadProject: '[MovingJobEstimate] Load Project',
  SetMovingBoxesTypes: '[MovingJobEstimate] SetMovingBoxesTypes',
  SetLoadedInformation: '[MovingJobEstimate] SetLoadedInformation',
  LoadInformation: '[MovingJobEstimate] LoadInformation',
};

const initialState = {
  projectId: null,
  identityId: null,
  eventId: null,
  startedAt: null,
  loadedInformation: {
    roomTypes: [],
    furnitureTypes: [],
    serviceTypes: [],
    movingBoxTypes: [],
  },
  addressTo: {},
  pricingType: 'fixed-price',
  secondaryAddressTo: [],
  addressFrom: {},
  secondaryAddressFrom: [],
  customerForm: {
    salutation: '',
    gender: '',
    companyName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
  },
  notes: '',
  storageNotes: '',
  surchargeOrDiscount: {
    percentage: 0,
    reason: null,
    type: null,
  },
  needDisassemble: null,
  servicesByRoom: {},
  inventoryItems: {},
  images: {},
  rooms: [],
  furnitureByRoom: [],
  movingDate: '',
  services: [],
  quoteResult: null,
  error: null,
  movingBoxTypes: [],
};

export const reducer = persistReducer(
  { storage: localforage, key: 'moving-job-estimate' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetQuote: {
        return {
          ...state,
          ...action.payload,
          addressFrom: { ...state.addressFrom, ...action.payload.addressFrom },
          addressTo: { ...state.addressTo, ...action.payload.addressTo },
          furnitureByRoom: { ...state.furnitureByRoom, ...action.payload.furnitureByRoom },
          disassembledItems: { ...state.disassembledItems, ...action.payload.disassembledItems },
          inventoryItems: { ...state.inventoryItems, ...action.payload.inventoryItems },
          images: { ...state.images, ...action.payload.images },
          services: { ...state.services, ...action.payload.services },
        };
      }
      case actionTypes.SetProject: {
        return {
          ...state,
          ...action.payload,
        };
      }
      case actionTypes.SetMovingBoxesTypes: {
        return {
          ...state,
          movingBoxTypes: [...action.payload],
        };
      }
      case actionTypes.SetRooms: {
        return {
          ...state,
          rooms: action.payload,
        };
      }
      case actionTypes.SetLoadedInformation: {
        return {
          ...state,
          loadedInformation: action.payload,
        };
      }
      case actionTypes.SetQuoteSecondaryAddressesFrom: {
        const copy = [...state.secondaryAddressFrom];
        if (typeof copy[action.index] === 'undefined') {
          copy.push(action.payload);
        } else {
          copy[action.index] = action.payload;
        }
        return {
          ...state,
          secondaryAddressFrom: [...copy],
        };
      }
      case actionTypes.SetQuoteSecondaryAddressesTo: {
        const copy = [...state.secondaryAddressTo];
        if (typeof copy[action.index] === 'undefined') {
          copy.push(action.payload);
        } else {
          copy[action.index] = action.payload;
        }
        return {
          ...state,
          secondaryAddressTo: [...copy],
        };
      }
      case actionTypes.RemoveQuoteSecondaryAddressesFrom: {
        const copy = [...state.secondaryAddressFrom];
        copy.splice(action.index, 1);

        return {
          ...state,
          secondaryAddressFrom: [...copy],
        };
      }
      case actionTypes.RemoveQuoteSecondaryAddressesTo: {
        const copy = [...state.secondaryAddressTo];
        copy.splice(action.index, 1);

        return {
          ...state,
          secondaryAddressTo: [...copy],
        };
      }
      case actionTypes.ResetState: {
        return {
          ...initialState,
        };
      }
      case actionTypes.ResetError: {
        if (
          action.payload &&
          state.error &&
          state.error.props &&
          state.error.props.data &&
          state.error.props.data.violations
        ) {
          let newViolations = [];
          const customerErrors = state.error.props.data.violations.filter(item =>
            item.propertyPath.includes('customer'),
          );
          customerErrors.forEach(item => {
            const query = item.propertyPath.replace('customer.', '');
            if (state.customerForm[query] !== action.payload.customerForm[query]) {
              newViolations = [...state.error.props.data.violations];
              const index = newViolations.findIndex(nx => nx.propertyPath.includes(`customer.${query}`));
              newViolations.splice(index, 1);
            }
          });
          return {
            ...state,
            error: {
              ...state.error,
              props: {
                ...state.error.props,
                data: {
                  ...state.error.props.data,
                  violations: newViolations,
                },
              },
            },
          };
        }
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  },
);

export const actions = {
  save: data => ({ type: actionTypes.SetQuote, payload: data }),
  loadInformation: () => ({ type: actionTypes.LoadInformation }),
  setLoadedInformation: data => ({ type: actionTypes.SetLoadedInformation, payload: data }),
  saveSecondaryAddressesFrom: data => ({
    type: actionTypes.SetQuoteSecondaryAddressesFrom,
    payload: data.value,
    index: data.index,
  }),
  saveSecondaryAddressesTo: data => ({
    type: actionTypes.SetQuoteSecondaryAddressesTo,
    payload: data.value,
    index: data.index,
  }),
  removeSecondaryAddressesFrom: data => ({
    type: actionTypes.RemoveQuoteSecondaryAddressesFrom,
    index: data.index,
  }),
  removeSecondaryAddressesTo: data => ({
    type: actionTypes.RemoveQuoteSecondaryAddressesTo,
    index: data.index,
  }),
  resetState: () => ({
    type: actionTypes.ResetState,
  }),
  resetError: data => ({
    type: actionTypes.ResetError,
    payload: data,
  }),
  setProject: project => ({
    type: actionTypes.SetProject,
    payload: project,
  }),
  erase: data => ({
    type: actionTypes.DeleteQuote,
    payload: data,
  }),
  saveRooms: data => ({
    type: actionTypes.SetRooms,
    payload: data,
  }),
  reset: () => ({
    type: actionTypes.ResetApp,
  }),
  saveForm: data => ({
    type: actionTypes.SaveForm,
    payload: data,
  }),
  setError: data => ({
    type: actionTypes.SetError,
    payload: data,
  }),
  loadProject: (project, eventId, startedAt) => ({
    type: actionTypes.LoadProject,
    payload: project,
    eventId,
    startedAt,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ResetApp, function* resetAppSaga() {
    yield put(FirstStepActions.reset());
    yield put(FirstStepActions.reset());
    yield put(actions.resetState());
  });

  yield takeLatest(actionTypes.LoadInformation, function* resetAppSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const page = 1;
    const perPage = 999999;
    const { items: roomTypes } = yield RoomService.getRooms(currentTenantId, page, perPage);
    const { items: furnitureTypes } = yield RoomService.getFurnitureList(page, perPage);
    const { items: serviceTypes } = yield TenantService.getServices(currentTenantId, page, perPage);
    const {
      data: { 'hydra:member': movingBoxTypes },
    } = yield getPublicMovingBoxes(currentTenantId, moment().format('YYYY-MM-DD HH:mm:ss'));
    yield put(actions.setLoadedInformation({ roomTypes, furnitureTypes, serviceTypes, movingBoxTypes }));
  });

  yield takeLatest(actionTypes.SaveForm, function* saveFormSaga(action) {
    yield put(actions.save(action.payload));
    yield put(actions.resetError(action.payload));
  });

  yield takeLatest(actionTypes.LoadProject, function* loadProjectSaga(action) {
    const info = yield select(state => state.MovingJobEstimateReducer.loadedInformation);
    if (
      !info?.roomTypes?.length > 0 ||
      !info?.furnitureTypes?.length > 0 ||
      !info?.serviceTypes?.length > 0 ||
      !info?.movingBoxTypes?.length > 0
    ) {
      yield put(actions.loadInformation());
    }

    const project = action.payload;
    const roomTypes = yield RoomService.getRooms(project.tenantId, 1, 250);
    const response = yield getPublicMovingBoxes(project.tenantId, moment().format('YYYY-MM-DD HH:mm:ss'));
    const movingBoxTypes = response.data['hydra:member'];
    const addressFrom = yield project.addresses.find(
      address => address.primary === true && address.addressType === 'from',
    );
    const addressTo = yield project.addresses.find(address => address.primary === true && address.addressType === 'to');

    const mapAddress = address => ({
      fromBackend: true,
      unknownAddress: false,
      addressId: address.addressId ?? '',
      addition: address.houseNumberAddition ?? '',
      city: address.city,
      country: address.country,
      distanceFromCar: address.distanceFromCar ?? '',
      distanceToApartment: address.distanceToApartment ?? '',
      hasElevator: address.hasElevator,
      floor: address.floor ?? '',
      houseNumber: address.houseNumber,
      houseNumberAddition: address.houseNumberAddition,
      municipality: address.municipality,
      province: address.province,
      streetName: address.streetName,
      type: address.buildingType,
      zipCode: address.zipCode,
      trafficSigns: address.trafficSigns,
      notes: address.notes,
    });

    const personName =
      project.customer['@type'] === 'BusinessRelation'
        ? project.customer.contactPersons[0]?.personalInformation.personName
        : project.customer.personalInformation.personName;
    const personalInformation =
      project.customer['@type'] === 'BusinessRelation'
        ? project.customer.contactPersons[0]?.personalInformation
        : project.customer.personalInformation;
    const contactInformation =
      project.customer['@type'] === 'BusinessRelation'
        ? project.customer.contactPersons[0]?.primaryContactInformation
        : project.customer.primaryContactInformation;

    const rooms = [...roomTypes.items];
    const projectRoomsDefault = project.rooms || [];
    const inventoryItems = {};
    const furnitureByRoom = {};
    const servicesByRoom = {};
    const services = {};
    const images = {};

    yield roomTypes.items.forEach((room, index) => {
      const projectRooms = projectRoomsDefault.filter(pr => pr.roomType.roomTypeId === room.roomTypeId);
      if (projectRooms.length >= 1) {
        projectRooms.forEach((projectRoom, projectIndex) => {
          const quoteRoom = {
            ...room,
            selected: true,
            completed: true,
            roomId: projectRoom.roomId,
          };

          let currentIndex = index;
          if (projectIndex !== 0) {
            currentIndex = rooms.length;
          }

          inventoryItems[`${room.roomTypeId}:${currentIndex}`] = {};
          images[`${room.roomTypeId}:${currentIndex}`] = projectRoom.photos;

          projectRoom.inventoryItems.forEach(row => {
            inventoryItems[`${room.roomTypeId}:${currentIndex}`][row.inventoryItemId] = row;
          });

          const roomFurniture = [...room.furnitureType];
          const roomServices = {};
          projectRoom.furniture.forEach(selectedFurnitureItem => {
            const existingFurniture = roomFurniture.find(
              rf =>
                selectedFurnitureItem.furnitureType &&
                selectedFurnitureItem.furnitureType.furnitureTypeId === rf.furnitureTypeId &&
                rf.selected !== true,
            );

            let furnitureIndex = roomFurniture.length;
            let furnitureIdentifier = '';

            if (existingFurniture) {
              furnitureIdentifier =
                selectedFurnitureItem?.furnitureType?.furnitureTypeId || selectedFurnitureItem.furnitureId;
              furnitureIndex = roomFurniture.indexOf(existingFurniture);
              roomFurniture[furnitureIndex] = {
                ...existingFurniture,
                selected: true,
                furnitureId: selectedFurnitureItem.furnitureId,
                selectedVariant: selectedFurnitureItem.furnitureVariant,
              };
            } else if (!selectedFurnitureItem.furnitureType) {
              furnitureIdentifier = selectedFurnitureItem.furnitureId;
              roomFurniture[roomFurniture.length] = {
                selected: true,
                furnitureId: selectedFurnitureItem.furnitureId,
                cubicMeter: selectedFurnitureItem.cubicMeter,
                name: selectedFurnitureItem.label,
                icon: 'question-circle',
                value: selectedFurnitureItem.furnitureId,
                label: selectedFurnitureItem.label,
                servicesPossible: selectedFurnitureItem.servicesPossible,
                variants: [],
              };
            } else {
              furnitureIdentifier = selectedFurnitureItem.furnitureType.furnitureTypeId;
              roomFurniture[roomFurniture.length] = {
                selected: true,
                furnitureId: selectedFurnitureItem.furnitureId,
                selectedVariant: selectedFurnitureItem.furnitureVariant,
                furnitureTypeId: selectedFurnitureItem.furnitureType.furnitureTypeId,
                cubicMeter: selectedFurnitureItem.furnitureType.cubicMeter,
                name: selectedFurnitureItem.furnitureType.furnitureTypeName,
                icon: selectedFurnitureItem.furnitureType.icon,
                value: selectedFurnitureItem.furnitureType.furnitureTypeId,
                label: selectedFurnitureItem.furnitureType.furnitureTypeName,
                servicesPossible: selectedFurnitureItem.furnitureType.servicesPossible,
                variants: selectedFurnitureItem.furnitureType.variants || [],
              };
            }

            selectedFurnitureItem.services.forEach(service => {
              if (typeof roomServices[`${furnitureIdentifier}:${furnitureIndex}`] === 'undefined') {
                roomServices[`${furnitureIdentifier}:${furnitureIndex}`] = {};
              }
              roomServices[`${furnitureIdentifier}:${furnitureIndex}`][service.serviceTypeId] = true;
            });
          });

          servicesByRoom[`${room.roomTypeId}:${currentIndex}`] = roomServices;
          furnitureByRoom[`${room.roomTypeId}:${currentIndex}`] = roomFurniture;
          if (projectIndex !== 0) {
            rooms.push(quoteRoom);
          } else {
            rooms[index] = quoteRoom;
          }
        });
      }
    });

    const projectServices = project.services || [];

    const skipSteps = { assembleForm: true, disassembleForm: true, storageForm: true, storageBoxesForm: true };
    yield projectServices.forEach(service => {
      services[service.serviceTypeId] = true;
      if (service.internalType === 'assemble') {
        skipSteps.assembleForm = false;
      }

      if (service.internalType === 'disassemble') {
        skipSteps.disassembleForm = false;
      }

      if (service.internalType === 'storage') {
        skipSteps.storageForm = false;
        skipSteps.storageBoxesForm = false;
      }
    });

    yield put(SecondStepActions.setSkip('assembleForm', skipSteps.assembleForm));
    yield put(SecondStepActions.setSkip('disassembleForm', skipSteps.disassembleForm));
    yield put(SecondStepActions.setSkip('storageForm', skipSteps.storageForm));
    yield put(SecondStepActions.setSkip('storageBoxesForm', skipSteps.storageBoxesForm));

    yield put(
      actions.setProject({
        customerId: project.customer.relationId,
        projectId: project.projectId,
        tenantId: project.tenantId,
        packageId: project.packageId || project?.package?.packageId,
        billingMoments: project.billingMoments,
        identityId: project.identityId,
        eventId: action.eventId,
        startedAt: action.startedAt,
        surchargeOrDiscount: project.surchargeOrDiscount,
        addressFrom: mapAddress(addressFrom),
        addressTo: addressTo
          ? mapAddress(addressTo)
          : {
              unknownAddress: true,
            },
        customerForm: {
          salutation: personalInformation?.salutation,
          gender: personalInformation?.gender ? personalInformation?.gender : '',
          relationType: project.customer.relationType,
          language: project.customer.language,
          companyName: project.customer.companyInformation?.companyName ?? '',
          email: contactInformation?.emailAddress,
          phoneNumber: contactInformation?.phoneNumber,
          mobileNumber: contactInformation?.mobileNumber,
          firstName: personName?.firstName,
          middleName: personName?.middleName,
          lastName: personName?.lastName,
        },
        notes: project.notes,
        storageNotes: project.storageNotes,
        secondaryAddressFrom: project.addresses
          .filter(address => address.primary === false && address.addressType === 'from')
          .map(mapAddress),
        secondaryAddressTo: project.addresses
          .filter(address => address.primary === false && address.addressType === 'to')
          .map(mapAddress),
        movingDate: project.preferredMovingDate,
        rooms,
        inventoryItems,
        images,
        furnitureByRoom,
        servicesByRoom,
        services,
        pricingType: project.pricingType,
        movingBoxTypes,
      }),
    );
  });

  yield takeLatest(actionTypes.SetError, function* setErrorSaga(action) {
    const { error } = action.payload;
    if (error.response && error.response.data && error.response.data.violations) {
      const customerErrors = error.response.data.violations.filter(item => item.propertyPath.includes('customer'));
      if (customerErrors.length > 0) {
        yield put(actions.save({ error: { type: 'CUSTOMER_VALIDATION_ERROR', props: error.response } }));

        yield put(FirstStepActions.setCustomerStep());
      } else {
        yield put(actions.save({ error: { props: error.response } }));
      }
    } else {
      yield put(actions.save({ error: { props: error.response } }));
    }
  });
}
