import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const CustomDialog = withStyles(() => ({
  paper: {
    padding: '47px',
  },
}))(Dialog);

const Title = withStyles(() => ({
  root: {
    color: '#3A78F2',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '31px',
  },
}))(DialogTitle);

const ConfirmDialog = props => {
  const {
    closeMiddleware,
    btnsDisabled,
    title,
    maxWidth,
    children,
    open,
    setOpen,
    onConfirm,
    labels,
    avoidCloseOnConfirm,
  } = props;
  const classes = useStyles();
  return (
    <CustomDialog
      maxWidth={maxWidth ?? 'sm'}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <Title disableTypography id="confirm-dialog">
        {title}
      </Title>
      <DialogContent>
        {children}
        <Grid item xs={12} className={classes.actions}>
          <DialogActions>
            <Button
              disabled={btnsDisabled}
              variant="outlined"
              onClick={() => {
                if (closeMiddleware) {
                  closeMiddleware();
                }
                setOpen(false);
              }}
              color="secondary"
            >
              <FormattedMessage id={labels.no || 'button.cancel'} />
            </Button>
            <Button
              disabled={btnsDisabled}
              variant="contained"
              onClick={async () => {
                await onConfirm();
                if (!avoidCloseOnConfirm) {
                  setOpen(false);
                }
              }}
              color="default"
            >
              <FormattedMessage id={labels.yes || 'button.yes'} />
            </Button>
          </DialogActions>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

ConfirmDialog.propTypes = {
  labels: PropTypes.object,
};

ConfirmDialog.defaultProps = {
  labels: {},
};

export default ConfirmDialog;
