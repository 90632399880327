import { useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import LanguageService from '@cospired/i18n-iso-languages';

const LanguageSelectComponent = props => {
  const {
    form: { setFieldValue },
    field: { name, value },
  } = props;

  const onChange = useCallback(
    (event, newValue) => {
      setFieldValue(name, newValue || '');
    },
    [setFieldValue, name],
  );

  return (
    <Autocomplete
      {...props}
      style={{ width: '100%' }}
      onChange={onChange}
      defaultValue={value}
      value={value}
      getOptionLabel={option => LanguageService.getName(option, option) ?? ''}
      options={['en', 'nl']}
      renderInput={params => <TextField {...fieldToTextField(props)} {...params} onChange={() => {}} fullWidth />}
    />
  );
};

export default LanguageSelectComponent;
