import { FormattedMessage } from 'react-intl';

const EstimateItemTitleComponent = ({ estimateItem, services }) => {
  if (
    (estimateItem.itemType === 'boxes' || estimateItem.itemType === 'inventory-item') &&
    estimateItem.extraData?.inventoryItemName
  ) {
    return <span>{estimateItem.extraData?.inventoryItemName}</span>;
  }

  if (estimateItem.itemType === 'cubic-meter' && estimateItem.groupType !== 'storage') {
    return <span> &#13221;</span>;
  }

  if (estimateItem.itemType === 'service' && estimateItem.groupType === 'storage') {
    return (
      <span>
        <FormattedMessage
          id="quote.result.group.storage.item.service"
          values={{
            storageType: <FormattedMessage id={`storage.storageType.${estimateItem.extraData.storageType}`} />,
          }}
        />
      </span>
    );
  }

  if (estimateItem.itemType === 'cubic-meter' && estimateItem.groupType === 'storage') {
    return <span>&#13221;</span>;
  }

  if (estimateItem.itemType === 'service') {
    const service = services.find(({ internalType }) => internalType === estimateItem.extraData.serviceType);
    return <span>{service ? service.serviceTypeName : estimateItem.extraData.serviceType}</span>;
  }

  if (estimateItem.itemType === 'building-type') {
    return <FormattedMessage id={`address.property_type.${estimateItem.extraData.buildingType}`} />;
  }

  if (['assemble', 'disassemble'].includes(estimateItem.extraData?.serviceType)) {
    return (
      <FormattedMessage
        id={`quote.result.group.${estimateItem.extraData?.serviceType}.item.${estimateItem.itemType}`}
      />
    );
  }

  return <FormattedMessage id={`quote.result.group.${estimateItem.groupType}.item.${estimateItem.itemType}`} />;
};

export default EstimateItemTitleComponent;
