import { useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CountryService from 'i18n-iso-countries';

const useStyles = makeStyles(() => ({
  listItem: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    padding: '18px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  head: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0,
  },
  subHead: {
    margin: 0,
    fontSize: '16px !important',
    color: '#464457',
  },
  subHeadDate: {
    margin: 0,
    fontSize: '18px !important',
    color: '#464457',
  },
  margin9: {
    margin: '9px',
  },
  gray: {
    fontSize: '16px !important',
    marginTop: '9px',
    color: '#A2A1AE',
    fontWeight: 'bold',
  },
}));

const ProjectItemComponent = ({ project, handleClick }) => {
  const classes = useStyles();
  const language = useSelector(state => state.AppReducer.language);

  // @TODO
  const intakeDate = new Date(project.intakeDate);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(intakeDate);
  const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(intakeDate);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(intakeDate);
  const hr = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: false }).format(intakeDate);

  const primaryAddresses = project.addresses
    .filter(address => address.primary)
    .sort(a => (a.addressType === 'from' ? -1 : 1))
    .map(
      address =>
        `${address.streetName} ${address.houseNumberIncludingAddition}, ${address.city}, ${CountryService.getName(
          address.country,
          language,
        )}`,
    );

  return (
    <ListItem onClick={handleClick}>
      <ListItemText
        className={classes.listItem}
        primary={
          <>
            <Typography variant="body2" component="span" className={classes.head}>
              {project.customer['@type'] === 'BusinessRelation'
                ? project.customer.companyInformation.companyName
                : project.customer.personalInformation?.personName?.fullName}
            </Typography>
            <Typography variant="body2" component="span" className={classes.subHeadDate}>
              {` ${da} ${mo} ${ye}`}
            </Typography>
          </>
        }
        secondary={
          <>
            <Typography variant="body2" component="p" className={classes.gray}>
              {`${hr}`}
            </Typography>

            <Typography variant="body2" component="span" className={classes.subHead}>
              {primaryAddresses[0]}
              &nbsp;
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default ProjectItemComponent;
