import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dragContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: '10px',
    paddingBottom: '15px',
    width: '100%',
    height: '8px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1000,
    background: '#464457',
  },
  dragBox: {
    background: '#DADCE4',
    borderRadius: '20%',
    width: '20%',
    height: '4px',
  },
}));

const DragToCloseModalComponent = ({ closeFunction }) => {
  const classes = useStyles();
  const [direction, setDirection] = useState(0);
  const [directionToGo, setDirectionToGo] = useState(0);

  const handleTouchStart = e => {
    setDirection(e.targetTouches[0].clientY);
    setDirectionToGo(e.targetTouches[0].clientY);
  };

  const handleMove = e => {
    setDirectionToGo(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (directionToGo > direction) closeFunction();
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={classes.dragContainer}
        onTouchStart={e => handleTouchStart(e)}
        onTouchMove={e => handleMove(e)}
        onTouchEnd={e => handleTouchEnd(e)}
      >
        <div className={classes.dragBox} />
      </Grid>
    </Grid>
  );
};

export default DragToCloseModalComponent;
