import { Field, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { InputComponent } from '../../../../components';

const FinancialDocumentFreeTextFieldsForm = () => {
  const { values } = useFormikContext();

  if (!values) {
    return '';
  }

  return (
    <Grid container spacing={3}>
      {Object.keys(values.freeText).map(key => (
        <Grid item xs={12} key={key}>
          <Field
            type="text"
            color="secondary"
            name={`freeText[${key}]`}
            multiline
            component={InputComponent}
            label={<FormattedMessage id={`financial.quote.freeText[${key}]`} />}
            fullWidth
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FinancialDocumentFreeTextFieldsForm;
