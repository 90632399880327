import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    fontWeight: 'bold',
    width: '100%',
    paddingTop: '50%',
    paddingBottom: '50%',
    height: 0,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: '2px dashed #C6C6C6',
    background: 'transparent',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '30px',
  },
  whiteText: {
    color: '#ffffff',
  },
  blackText: {
    color: '#000000',
  },
}));

const AddMoreCardComponent = props => {
  const { label, clickFunction, inverted } = props;
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card} onClick={clickFunction}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={12}>
              <Typography align="center" className={inverted ? classes.whiteText : classes.blackText}>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

AddMoreCardComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inverted: PropTypes.bool,
  clickFunction: PropTypes.func,
};

AddMoreCardComponent.defaultProps = {
  label: '',
  inverted: false,
  clickFunction: null,
};

export default AddMoreCardComponent;
