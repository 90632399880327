import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getEmployeesByUserId, getUser } from '../services/userService';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  ResetData: '[User] Reset User',
  RequestData: '[User] Request User',
  FulfilledData: '[User] Fulfilled User',
  FulfilledEmployees: '[User] Fulfilled employees',
};

const initialState = {
  ...singleObjectInitialState,
  employees: [],
  employeesLoading: true,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...newState,
        employeesLoading: true,
      };
    }

    case actionTypes.FulfilledEmployees: {
      return {
        ...newState,
        employeesLoading: false,
        employees: action.employees,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulFilledEmployees: employees => ({
    type: actionTypes.FulfilledEmployees,
    employees,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestUserSaga(action) {
    const response = yield getUser(action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeLatest(actionTypes.RequestData, function* invoke(action) {
    const response = yield getEmployeesByUserId(action.itemId);

    yield put(actions.fulFilledEmployees(response.data['hydra:member']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'User') {
      yield put(actions.requestData());
    }
  });
}
