import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faMobile, faPhone, faUser, faUserTie } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#A3A1AE',
  },
  icon: {
    fontSize: '18px',
  },
  card: {
    background: 'white',
    padding: '15px',
    border: '1px solid #F2F3F8',
  },
}));

const ConfirmCustomerPage = () => {
  const { customerForm } = useSelector(state => state.MovingJobEstimateReducer);
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="moving.customer_data" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {customerForm.companyName && (
          <Grid container className={classes.card}>
            <Grid item xs={2} className={classes.flex}>
              <FontAwesomeIcon icon={faUserTie} className={classes.icon} />
            </Grid>
            <Grid item xs={10}>
              <Typography component="div" variant="body1">
                <Box>{customerForm.companyName}</Box>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.card}>
          <Grid item xs={2} className={classes.flex}>
            <FontAwesomeIcon icon={faUser} className={classes.icon} />
          </Grid>
          <Grid item xs={10}>
            <Typography component="div" variant="body1">
              <Box>
                {[customerForm.firstName, customerForm.middleName, customerForm.lastName]
                  .filter(name => name !== '')
                  .join(' ')}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.card}>
          <Grid item xs={2} className={classes.flex}>
            <FontAwesomeIcon icon={faAt} className={classes.icon} />
          </Grid>
          <Grid item xs={10}>
            <Typography component="div" variant="body1">
              <Box>{customerForm.email}</Box>
            </Typography>
          </Grid>
        </Grid>
        {customerForm.phoneNumber && (
          <Grid container className={classes.card}>
            <Grid item xs={2} className={classes.flex}>
              <FontAwesomeIcon icon={faPhone} className={classes.icon} />
            </Grid>
            <Grid item xs={10}>
              <Typography component="div" variant="body1">
                <Box>{customerForm.phoneNumber}</Box>
              </Typography>
            </Grid>
          </Grid>
        )}
        {customerForm.mobileNumber && (
          <Grid container className={classes.card}>
            <Grid item xs={2} className={classes.flex}>
              <FontAwesomeIcon icon={faMobile} className={classes.icon} />
            </Grid>
            <Grid item xs={10}>
              <Typography component="div" variant="body1">
                <Box>{customerForm.mobileNumber}</Box>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ConfirmCustomerPage;
