import Skeleton from '@material-ui/lab/Skeleton';

const RandomWidthSkeleton = ({ className, height, variant = 'text', animation = 'wave', factor = 35, style = {} }) => (
  <Skeleton
    className={className}
    variant={variant}
    animation={animation}
    height={height}
    style={style}
    width={`${Math.floor(Math.random() * 51) + factor}%`}
  />
);

export default RandomWidthSkeleton;
