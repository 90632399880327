import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from 'store';

import { AppProvider } from '../components';
import { setupAxios } from '../utils';

setupAxios(axios, store);

library.add(fal);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppProvider />
      </PersistGate>
    </Provider>
  );
}

export default Sentry.withProfiler(App);
