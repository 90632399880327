import { put, select, takeLatest } from 'redux-saga/effects';
import { TenantService } from '../../../services';

export const actionTypes = {
  RequestData: '[ServiceTypes] RequestServiceTypes',
  FulfilledTable: '[ServiceTypes] FulfilledServiceTypes',
};

const initialState = {
  items: [],
  loading: true,
  totalCount: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FulfilledTable: {
      return {
        ...state,
        loading: false,
        items: action.items,
        totalCount: action.totalCount,
      };
    }

    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return { ...state };
  }
};

export const actions = {
  requestData: () => ({ type: actionTypes.RequestData }),
  fulfilled: (items, totalCount) => ({
    type: actionTypes.FulfilledTable,
    items,
    totalCount,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestServiceTypes() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield TenantService.getServiceTypes(currentTenantId);

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
