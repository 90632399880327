import axios from 'axios';
import Qs from 'qs';
import { getEmployee } from 'services/EmployeeService';
import { getCustomer } from 'Domain/Customer/Services/CustomerService';
import { getMovingJob } from '../../../Project/Services/MovingJobService';

let tokenSource;
const mapField = (field, type) => {
  if (type === 'sort' && field === 'cubicMeter') {
    return [field];
  }

  switch (field) {
    case 'contentOwner':
      return ['contentOwner.name'];
    case 'contentOwnerId':
      return ['contentOwner'];
    case 'storageStatus':
      return ['storageStatus.value'];
    case 'storageType':
      return ['storageType.value'];
    case 'cubicMeter':
      return ['cubicMeter[gte]'];
    default:
      return [field];
  }
};

const fetchAsyncOwner = async (currentTenantId, { contentOwner: owner }) => {
  if (owner) {
    const customer = owner.split('/');
    return getCustomer(currentTenantId, customer[customer.length - 1]).then(response => response.data);
  }

  return null;
};

const getOwners = async (currentTenantId, list) =>
  Promise.all(list.map(item => fetchAsyncOwner(currentTenantId, item)));

const getStoragesForProject = async (tenantId, storedForProject) => {
  const response = await axios.get(`api/${tenantId}/storages`, {
    params: {
      storedForProject,
      perPage: 100,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

  return { items: response.data['hydra:member'], total: response.data['hydra:totalItems'] };
};

const getStorages = async (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }
    tokenSource = axios.CancelToken.source();

    const appliedFilters = {};
    Object.keys(filters).forEach(filter => {
      mapField(filter, 'filter').forEach(field => {
        appliedFilters[field] = filters[filter];
      });
    });
    const appliedOrder = {};
    Object.keys(order).forEach(key => {
      mapField(key).forEach(field => {
        appliedOrder[field] = order[key];
      });
    });

    const response = await axios.get(`api/${tenantId}/storages`, {
      params: {
        ...appliedFilters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: tokenSource.token,
    });

    const data = await getOwners(tenantId, response.data['hydra:member']);

    const dataMapped = response.data['hydra:member'].map((item, index) => ({
      ...item,
      contentOwner: data[index] || item.contentOwner,
    }));

    return { items: dataMapped, total: response.data['hydra:totalItems'] };
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const getData = async (currentTenantId, list) => {
  const employees = [];
  const employeeIds = list.map(({ handledById }) => handledById);

  await Promise.all(
    employeeIds
      .filter((item, index) => employeeIds.indexOf(item) === index)
      .map(async employeeId => {
        const employee = await getEmployee(currentTenantId, employeeId).then(response => response.data);
        employees.push(employee);
      }),
  );

  return list.map(item => ({
    ...item,
    handledBy: employees.find(({ employeeId }) => employeeId === item.handledById),
  }));
};

const getStorage = async (tenantId, storageId) => {
  const response = await axios.get(`api/${tenantId}/storages/${storageId}`);
  let data = {
    ...response.data,
    storageMovements: await getData(tenantId, response.data.storageMovements),
  };

  if (response.data.contentOwner) {
    const customer = response.data.contentOwner.split('/');
    const { data: contentOwner } = await getCustomer(tenantId, customer[customer.length - 1]);
    data = {
      ...data,
      contentOwner,
    };
  }

  if (response.data.storedForProject) {
    const storage = response.data.storedForProject.split('/');
    const { data: storedForProject } = await getMovingJob(tenantId, storage[storage.length - 1]);
    data = {
      ...data,
      storedForProject,
    };
  }

  return data;
};

const create = async (tenantId, data) => axios.post(`api/${tenantId}/storages`, data);
const removeStorage = async (tenantId, storageId) =>
  axios.put(`api/${tenantId}/storages/${storageId}/remove-storage`, { storageId });

const csvUpload = async (tenantId, file) => {
  const { data } = await axios.post(`api/${tenantId}/storages/batch-import`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

const reserve = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/reserve`, {
    ...data,
    storageId,
    tenantId,
  });
  return response.data;
};

const logMovement = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/log-movement`, data);
  return response.data;
};

const emptyContent = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/empty`, { ...data, tenantId, storageId });
  return response.data;
};

const emptyAllContentFromCustomer = async (tenantId, data) => {
  const response = await axios.post(`api/${tenantId}/storages/empty-all-for-customer`, data);
  return response.data;
};

const updateNotes = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/update-notes`, data);
  return response.data;
};

const renameStorage = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/rename-storage`, {
    ...data,
    storageId,
    tenantId,
  });
  return response.data;
};

const updateInvoicing = async (tenantId, storageId, data) => {
  const response = await axios.put(`api/${tenantId}/storages/${storageId}/update-invoicing`, data);
  return response.data;
};

export default {
  create,
  getStorages,
  getStorage,
  emptyAllContentFromCustomer,
  emptyContent,
  getStoragesForProject,
  csvUpload,
  logMovement,
  removeStorage,
  reserve,
  updateNotes,
  renameStorage,
  updateInvoicing,
};
