import { put, select, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from './SingleObject.duck';
import CommunicationService from '../Services/CommunicationService';

export const actionTypes = {
  ResetData: '[AutomatedEmail] Reset AutomatedEmail',
  RequestData: '[AutomatedEmail] Request AutomatedEmail',
  FulfilledData: '[AutomatedEmail] Fulfilled AutomatedEmail',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestAutomatedEmailSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield CommunicationService.getAutomatedEmail(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });
}
