import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import CommunicationService from 'Domain/App/Services/CommunicationService';
import { InvoiceService, TenantService } from '../../../services';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  ResetData: '[Invoice] Reset Invoice',
  RequestData: '[Invoice] Request Invoice',
  FulfilledData: '[Invoice] Fulfilled Invoice',
  FulfilledEmailsData: '[Invoice] Fulfilled Emails Invoice',
  FulfilledTemplateData: '[Invoice] Fulfilled Template',
};

const initialState = {
  ...singleObjectInitialState,
  loadingEmails: true,
  emails: [],
  loadingTemplate: true,
  template: null,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...newState,
        loadingEmails: true,
        loadingTemplate: true,
        emails: [],
        template: null,
      };
    }

    case actionTypes.FulfilledEmailsData: {
      return {
        ...newState,
        loadingEmails: false,
        emails: action.emails,
      };
    }

    case actionTypes.FulfilledTemplateData: {
      return {
        ...newState,
        loadingTemplate: false,
        template: action.template,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulfilledEmailsData: emails => ({
    type: actionTypes.FulfilledEmailsData,
    emails,
  }),
  fulfilledTemplateData: template => ({
    type: actionTypes.FulfilledTemplateData,
    template,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestInvoiceSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield InvoiceService.getInvoice(currentTenantId, action.itemId);

    const invoice = response.data;
    yield put(actions.fulfilledData(invoice));

    const templateResponse = yield TenantService.getFinancialDocumentTemplate(currentTenantId, invoice.templateId);

    yield put(actions.fulfilledTemplateData(templateResponse.data));
  });

  yield takeLatest(actionTypes.RequestData, function* requestInvoiceSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield CommunicationService.getEmails(currentTenantId, 1, 2000, {}, { invoiceId: action.itemId });

    yield put(actions.fulfilledEmailsData(response.data['hydra:member']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Invoice') {
      const { item } = yield select(state => state.InvoiceReducer);
      if (item && action && action.data.invoiceId === item.invoiceId) {
        yield put(actions.fulfilledData({ ...action.data, relation: item.relation }));
      }
    }
  });
}
