import { useContext, useEffect, useState } from 'react';
import { Grid, TextField as MuiText, Typography, Switch } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import useFormatMessage from 'utils/useFormatMessage';
import WizardContext from 'Domain/EmployeeApp/Utils/WizardContext';
import { useDispatch, useSelector } from 'react-redux';
import { FormatCurrencyComponent, InputComponent } from '../../../../components';
import formatHours from '../../../../utils/formatHours';
import EstimateItemTitleComponent from '../EstimateItemTitleComponent';
import { actions as ServiceTypesActions } from '../../../App/Ducks/ServiceTypes.duck';

const useStyles = makeStyles(theme => ({
  typo: {
    borderBottom: '1px solid #DADCE4',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  firstBlock: {
    // marginTop: '20px',
    marginBottom: '20px',
  },
  specifications: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  mb20: {
    marginBottom: '20px',
  },
  mb10: {
    marginBottom: '10px',
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  title: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    paddingBottom: '10px',
    color: '#2C2E30',
  },
  toggleRoot: {
    width: '100%',
    '& .MuiToggleButton-root.Mui-selected': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.1), -2px 2px 8px rgba(0, 0, 0, 0.15)',
      '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        borderColor: 'transparent',
      },
    },
  },
  percentageField: {
    width: '100%',
    // background: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '5px',
    boxSizing: 'border-box',
    height: '48px',
  },
  textCenter: {
    textAlign: 'center',
    padding: '14px 0',
  },
  whiteButton: {
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderColor: 'transparent',
    fontWeight: 'bold',
    minHeight: '48px',
    borderRadius: '5px',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      borderColor: 'transparent',
    },
  },
  textArea: {
    marginTop: '0',
    background: 'white',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3px',
    marginTop: '3px',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  default: {
    color: '#A2A1AE',
  },
}));

const MovingJobEstimateComponent = ({ quote, finish }) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const hourTypes = ['hours', 'travel_time', 'service'];
  const ignoreItemsInList = ['staff', 'hours', 'boxes'];
  const [quoteState, setQuoteState] = useState(quote);
  const { setFinishStepFunction } = useContext(WizardContext);

  const persons = quoteState.items.find(item => item.itemType === 'staff')?.quantity ?? 0;
  const hours = formatHours(quoteState.items.find(item => item.itemType === 'hours')?.quantity ?? 0);
  const numberOfBoxes = quoteState.items.find(item => item.itemType === 'boxes')?.quantity ?? 0;
  const { items: services } = useSelector(state => state.ServiceTypesReducer);

  useEffect(() => {
    if (!services || services.length === 0) {
      dispatch(ServiceTypesActions.requestData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const rest = [...quoteState.items];
    const indexToRemove = rest.findIndex(item => item.surcharge);
    if (indexToRemove > -1) {
      rest.splice(indexToRemove, 1);
      setQuoteState({ ...quoteState, items: rest, total: { ...quote.total } });
    }

    if (!values.selectedItem) {
      return;
    }

    const surchargePrice = +quote.total.amount * (values.percentage / 100);
    let totalAmount = +quote.total.amount - surchargePrice;
    if (values.selectedItem === 'surcharge') {
      totalAmount = +quote.total.amount + surchargePrice;
    }

    const line = {
      description: <FormattedMessage id={`quote.result.${values.selectedItem}`} />,
      calculated: {
        itemTotalPrice: {
          amount: surchargePrice,
          currency: 'EUR',
        },
        quantity: `${values.percentage}%`,
      },
      pricePerUnit: { amount: '', currency: 'EUR' },
      quantity: `${values.percentage}%`,
      surcharge: true,
    };

    const newItems = [line, ...quote.items];
    setQuoteState({
      ...quoteState,
      items: newItems,
      total: {
        ...quoteState.total,
        amount: totalAmount,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedItem, values.percentage]);

  useEffect(() => {
    setFinishStepFunction(() => finish);
  }, [values, setFinishStepFunction, finish]);

  return (
    <Form>
      <Grid container spacing={4} className={classes.firstBlock}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h3" align="center" className={classes.headerTitle}>
            <FormattedMessage id="quote.result.offerte" />
          </Typography>
          <Typography variant="h3" component="h3" align="center">
            <FormattedMessage id="quote.result.title" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.mb20} spacing={2}>
        <Grid item xs={8}>
          <Typography component="span" variant="subtitle2" className={classes.default}>
            <FormattedMessage id="quote.result.discount" />
          </Typography>
          <Switch
            onChange={event => {
              if (!event.target.checked) {
                setFieldValue('selectedItem', 'discount');
              } else {
                setFieldValue('selectedItem', 'surcharge');
              }
            }}
          />
          <Typography
            component="span"
            variant="subtitle2"
            className={values.selectedItem === 'surcharge' ? classes.active : classes.default}
          >
            <FormattedMessage id="quote.result.surcharge" />
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <MuiText
            error={errors.percentage && touched.percentage}
            value={values.percentage}
            className={classes.percentageField}
            inputProps={{ className: classes.textCenter }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              min: 0,
              max: 100,
              type: 'number',
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={event => {
              setFieldValue('percentage', event.target.value ? Number.parseFloat(event.target.value) : null, true);
              setFieldTouched('percentage', true, false);
            }}
          />
          {errors.percentage && touched.percentage && <FormHelperText>{errors.percentage}</FormHelperText>}
        </Grid>
      </Grid>
      <Grid container className={classes.mb20}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h6" component="h6">
            <FormattedMessage id="quote.result.total" />
            <FormatCurrencyComponent amount={quoteState.total.amount} currency={quoteState.total.currency} />
          </Typography>
          {quoteState.items
            .slice()
            .filter(item => !ignoreItemsInList.includes(item.itemType))
            .map((item, index) => {
              const showHours =
                hourTypes.includes(item.itemType) && !['provide_boxes', 'waste-disposal'].includes(item.serviceType);
              if (!Object.keys(item).includes('surcharge')) {
                return (
                  <Grid container className={classes.typo} key={index}>
                    <Grid item xs={6}>
                      <EstimateItemTitleComponent services={services} estimateItem={item} />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="div" align="left">
                        {!showHours && item.calculated.quantity}
                        {showHours && formatHours(item.calculated.quantity)}
                        {item.itemType === 'moving-job' && <span> &#13221;</span>}
                        {item.itemType === 'distance' && <span> km</span>}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {parseInt(item.calculated.itemTotalPrice.amount, 10) !== 0 && (
                        <Typography component="div" align="right">
                          <FormatCurrencyComponent
                            amount={item.calculated.itemTotalPrice.amount}
                            currency={item.calculated.itemTotalPrice.currency}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              }
              if (Object.keys(item).includes('surcharge') && parseInt(item.quantity, 10) > 0) {
                return (
                  <Grid container className={classes.typo} key={index}>
                    <Grid item xs={6}>
                      {item.itemType !== 'building-type' && item.description}
                      {item.itemType === 'building-type' && (
                        <FormattedMessage id={`address.property_type.${item.description}`} />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="div" align="left">
                        {!showHours && item.calculated.quantity}
                        {showHours && formatHours(item.calculated.quantity)}
                        {item.itemType === 'moving-job' && <span> &#13221;</span>}
                        {item.itemType === 'distance' && <span> km</span>}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {parseInt(item.calculated.itemTotalPrice.amount, 10) !== 0 && (
                        <Typography component="div" align="right">
                          <FormatCurrencyComponent
                            amount={item.calculated.itemTotalPrice.amount}
                            currency={item.calculated.itemTotalPrice.currency}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              }

              return null;
            })}
        </Grid>
      </Grid>
      <Grid container className={classes.specifications} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" align="left">
            <FormattedMessage id="quote.result.specifications" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.mb20} spacing={2}>
        <Grid item xs={4} sm={4}>
          <div className={classes.flex}>
            <FontAwesomeIcon size="2x" icon={['fal', 'people-carry']} />
          </div>
          <div className={classes.flex}>
            <Typography>
              <FormattedMessage id="quote.result.specification.persons" values={{ persons }} />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} sm={4}>
          <div className={classes.flex}>
            <FontAwesomeIcon size="2x" icon={['fal', 'clock']} />
          </div>
          <div className={classes.flex}>
            <Typography>
              <FormattedMessage id="quote.result.specification.hours" values={{ hours }} />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} sm={4}>
          <div className={classes.flex}>
            <FontAwesomeIcon size="2x" icon={['fal', 'box']} />
          </div>
          <div className={classes.flex}>
            <Typography>
              <FormattedMessage id="quote.result.specification.boxes" values={{ numberOfBoxes }} />
            </Typography>
          </div>
        </Grid>
      </Grid>

      {values && values.percentage > 0 && (
        <Grid container className={classes.mb10} spacing={2}>
          <Grid item xs={12}>
            <Field
              required
              color="secondary"
              component={InputComponent}
              InputProps={{ className: classes.textArea }}
              variant="outlined"
              name="discountReason"
              multiline
              rows={6}
              placeholder={formatMessage({ id: 'quote.result.discount.explain' })}
              fullWidth
              noLabel
            />
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

MovingJobEstimateComponent.propTypes = {
  quote: PropTypes.object,
  finish: PropTypes.func,
};

MovingJobEstimateComponent.defaultProps = {
  quote: {},
  finish: () => {},
};

export default MovingJobEstimateComponent;
