import { put, select, takeLatest } from 'redux-saga/effects';
import { listEventsByProject } from '../../Planning/Services/PlanningService';

export const actionTypes = {
  requestProjectsEvents: '[Project Events] Fetch events',
  storeEvents: '[Project Events] Store events',
};

const initialState = {
  loading: true,
  projectId: null,
  items: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.requestProjectsEvents: {
      return {
        ...state,
        items: [],
        projectId: action.projectId,
        loading: true,
      };
    }
    case actionTypes.storeEvents: {
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  requestProjectsEvents: projectId => ({
    type: actionTypes.requestProjectsEvents,
    projectId,
  }),
  storeEvents: payload => ({
    type: actionTypes.storeEvents,
    payload,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.requestProjectsEvents, function* getProjectEventsSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const response = yield listEventsByProject(currentTenantId, action.projectId);

    yield put(actions.storeEvents(response.result['hydra:member'] || []));
  });
}
