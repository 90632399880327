import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  error: {
    color: 'red',
  },
  label: {
    marginBottom: '7px',
  },
  whiteLabel: {
    color: '#ffffff',
    marginBottom: '7px',
  },
  hiddenButton: {
    marginLeft: '-105vw',
    visibility: 'hidden',
  },
  popButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    minHeight: '56px',
    borderRadius: '5px',
  },
  elevatorButton: {
    textTransform: 'initial',
    background: theme.palette.primary.main,
    minHeight: '56px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  activeElevatorButton: {
    textTransform: 'initial',
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    minHeight: '56px',
    borderRadius: '5px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
}));
