/* eslint-disable no-use-before-define */
import { useCallback } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { fieldToTextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';

const PhoneNumberField = props => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const tenant = useSelector(state => state.AppReducer.tenant);
  let defaultCountry = 'nl';
  if (tenant && tenant.address) {
    defaultCountry = tenant.address.country;
  }

  const onChange = useCallback(
    value => {
      setFieldValue(name, value || '');
    },
    [setFieldValue, name],
  );

  return (
    <MuiPhoneNumber
      {...fieldToTextField(props)}
      defaultCountry={defaultCountry.toLowerCase()}
      regions="europe"
      onChange={onChange}
    />
  );
};

export default PhoneNumberField;
