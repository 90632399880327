import { createTheme } from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';

// A custom theme for this app
const theme = createTheme(
  {
    props: {
      MuiTypography: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'h2',
        },
      },
    },
    spacing: [0, 8, 16, 24, 32, 48, 56, 64, 72, 96, 120],
    palette: {
      primary: {
        main: '#3A78F2',
      },
      secondary: {
        main: '#3A78F2',
        light: '#E6EEFF',
      },
      sidebar: {
        background: '#021B4E',
        color: '#ffffff',
        activeColor: '#3A78F2',
      },
      background: {
        default: '#ffffff',
        mobile: '#F2F3F8',
        secondary: '#021B4E',
      },
      border: {
        primary: '#DADCE4',
      },
    },
    typography: {
      fontFamily: ['DM Sans, sans-serif'].join(','),
    },
    bold: 700,
    overrides: {
      MuiToggleButtonGroup: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        },
        groupedHorizontal: {
          '&:not(:first-child)': {
            borderLeft: '1px solid #DADCE4',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            marginLeft: '6px',
          },
          '&:not(:last-child)': {
            borderRight: '1px solid #DADCE4',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            marginLeft: '6px',
          },
          '&:first-child': {
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
          },
        },
      },
      MuiToggleButton: {
        label: {
          fontSize: '14px',
          textTransform: 'none',
          lineHeight: '18px',
          textAlign: 'center',
          fontWeight: 'bold',
        },
        root: {
          flexGrow: 1,
          paddingTop: 13,
          paddingBottom: 13,
          marginLeft: '6px',
          border: '1px solid #DADCE4',
          boxSizing: 'border-box',
          borderRadius: '5px',
          '&$selected': {
            color: '#3A78F2',
            backgroundColor: '#F6F9FF',
            border: '1px solid #ACC6F9',
          },
        },
      },
      MuiPaper: {
        outlined: {
          padding: '10%',
        },
      },
      MuiFormControl: {
        root: {
          margin: '4px',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#1249B7',
          borderRadius: '2px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '18px',
          color: '#ffffff',
        },
      },
      MuiTabs: {
        root: {
          borderBottom: '1px solid #DADCE4',
        },
      },
      MuiTab: {
        root: {
          fontSize: '16px',
          minWidth: 'initial !important',
          maxWidth: 'initial !important',
          marginRight: '32px',
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: 'initial',
          '&:hover': {
            color: '#3A78F2',
          },
        },
      },
      MuiTypography: {
        body2: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '18px',
          color: '#464457',
        },
        body1: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          color: '#464457',
          lineHeight: '24px',
        },
        caption: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '10px',
          lineHeight: '13px',
          color: '#464457',
        },
        button: {},
        h1: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '64px',
          lineHeight: '83px',
          color: '#464457',
        },
        h2: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '48px',
          lineHeight: '62px',
          color: '#464457',
        },
        h3: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '36px',
          lineHeight: '47px',
          color: '#464457',
        },
        h4: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '24px',
          lineHeight: '31px',
          color: '#464457',
        },
        h5: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '20px',
          lineHeight: '26px',
          color: '#464457',
        },
        h6: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '20px',
          lineHeight: '26px',
          color: '#464457',
        },
        subtitle1: {
          fontFamily: 'Work Sans',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '1.2px',
          textTransform: 'uppercase',
          color: '#464457',
        },
        subtitle2: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#A2A1AE',
        },
      },
      MuiCardContent: {
        root: {
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
      MuiPaginationItem: {
        root: {
          color: '#7A7887',
          fontWeight: 700,
          borderBottom: '2px solid transparent',
          '&$selected': {
            backgroundColor: '#fff !important',
            borderBottom: '2px solid #3A78F2',
            color: '#3A78F2',
          },
        },
        rounded: {
          borderRadius: 0,
        },
      },
      MuiTable: {
        root: {
          padding: '20px',
          marginLeft: '-10px',
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '0.5px solid #E6E7EE',
          paddingLeft: '10px',
          paddingRight: '10px',
        },
        head: {
          fontWeight: 'bold',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        root: {
          fontSize: '16px',
          textTransform: 'lowercase',
          border: '1px solid #fff',
          '&:focus': {
            border: '1px solid #FFBF47',
          },
          '&:hover': {
            color: '#1249B7',
          },
        },
        text: {
          border: 'none',
          fontWeight: 'normal',
        },
        textSecondary: {
          color: '#fff',
        },
        contained: {
          color: '#fff',
          border: '1px solid #3A78F2',
          backgroundColor: '#3A78F2',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            color: '#fff',
            backgroundColor: '#1249B7',
          },
          '&:focus': {
            boxShadow: 'none',
            color: '#fff',
            border: '1px solid #FFBF47',
          },
          '&:active': {
            boxShadow: 'none',
            color: '#fff',
          },
          '&:disabled': {
            boxShadow: 'none',
            color: '#fff',
            backgroundColor: '#DADCE4',
            border: 0,
          },
        },
        outlined: {
          border: '1px solid #3A78F2 !important',
          '&:focus': {
            border: '1px solid #FFBF47 !important',
          },
          '&:disabled': {
            border: '0 !important',
          },
        },
      },
      MuiCircularProgress: {
        colorSecondary: {
          color: 'white',
        },
      },
      MuiCheckbox: {
        root: {
          '&:focus': {
            border: '1px solid #FFBF47',
          },
          '&:hover': {
            backgroundColor: '#fff',
            color: 'black',
          },
        },
        colorSecondary: {
          color: 'black !important',
        },
      },
      MuiRadio: {
        root: {
          '&:focus': {
            border: '1px solid #FFBF47',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'black',
          },
        },
        colorSecondary: {
          color: 'black !important',
        },
      },
      MuiTableRow: {
        root: {
          '&:hover .MuiTableCell-body': {
            color: '#3A78F2',
          },
          '&:hover .MuiTypography-root': {
            color: '#3A78F2',
          },
        },
      },
      MuiListItem: {
        button: {
          backgroundColor: '#F2F3F8',
          '&$selected': {
            color: '#3A78F2',
            backgroundColor: 'transparent',
          },
        },
      },
      MuiMenu: {
        list: {
          backgroundColor: '#F2F3F8',
        },
      },
      MuiTablePagination: {
        selectRoot: {
          marginRight: '2px',
        },
      },
      MuiChip: {
        root: {
          padding: '14px',
          backgroundColor: '#F2F3F8',
          color: '#7A7887',
          fontWeight: 'normal !important',
          margin: '5px',
        },
        colorSecondary: {
          backgroundColor: '#FFFFFF',
          color: '#7A7887',
          '&:focus': {
            backgroundColor: '#FFFFFF !important',
            color: '#7A7887',
          },
          '&:hover': {
            backgroundColor: '#FFFFFF !important',
          },
        },
        clickableColorSecondary: {
          '&:focus': {
            backgroundColor: '#FFFFFF !important',
            color: '#7A7887',
          },
        },
      },
      MuiDialog: {
        paperFullScreen: {
          width: '89%',
          height: '90%',
        },
      },
      MuiDialogContent: {
        root: {
          minHeight: '250px',
        },
      },
    },
  },
  locales.nlNL,
);

export default theme;
