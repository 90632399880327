import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import { StorageService } from '../../../services';

export const actionTypes = {
  RequestData: '[Customers Storage Tab] Request Storages',
  FulfilledTable: '[Customers Storage Tab] FulfilledCustomers storages',
  SearchTable: '[Customers Storage Tab] Search customers storages',
  ChangePage: '[Customers Storage Tab] change page storages',
  SetPageSize: '[Customers Storage Tab] set page size',
  SortTable: '[Customers Storage Tab] Sort customers storages',
  ApplyFilter: '[Customers Storage Tab] Apply filter',
  RemoveFilter: '[Customers Storage Tab] Remove filter',
  ResetFilters: '[Customers Storage Tab] Reset filter',
};

const initialState = {
  ...tableInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);
  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    const customerId = yield select(state => state.CustomerStoragesReducer.customerId);

    yield put(actions.requestData({ customerId }));
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestData({ data: { customerId: contentOwnerId } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.CustomerStoragesReducer);

    const response = yield StorageService.getStorages(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      { ...currentState.filters, contentOwnerId },
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.items, response.total));
  });
}
