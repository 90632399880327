import { useEffect, useState } from 'react';

const useOnlineStatus = () => {
  const [online, setOnline] = useState(true);

  const handler = event => {
    if (event.type === 'online') {
      setOnline(true);
      // handle stuffs when browser resume online
    } else {
      setOnline(false);
      // handle stuffs when browser goes offline
    }
  };

  useEffect(() => {
    window.addEventListener('online', handler);
    window.addEventListener('offline', handler);

    // cleanup
    return () => {
      window.removeEventListener('online', handler);
      window.removeEventListener('offline', handler);
    };
  });

  return { online };
};

export default useOnlineStatus;
