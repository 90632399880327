import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { mercureSubscribe } from '../../utils/dataAccess';
import ModelTypeToQueryKey from '../../data/ModelTypeToQueryKey';

const ReactQueryMercureListener = () => {
  const {
    mercureToken,
    tenant: { tenantId },
  } = useSelector(state => state.AppReducer);
  const queryClient = useQueryClient();

  useEffect(() => {
    let eventSource = null;
    if (mercureToken) {
      eventSource = mercureSubscribe(mercureToken, tenantId, ['api/{tenantId}/{+anything}']);
      eventSource.onmessage = message => {
        const data = JSON.parse(message.data);
        const queryKey = ModelTypeToQueryKey[data['@type']];
        if (queryKey) {
          queryClient.invalidateQueries(queryKey);
        }
      };
    }
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [mercureToken, tenantId, queryClient]);

  return null;
};

export default ReactQueryMercureListener;
