import axios from 'axios';
import Qs from 'qs';

let messagesToken;

export const getMessages = (tenantId, conversationId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof messagesToken !== typeof undefined) {
    messagesToken.cancel('Operation canceled due to new request.');
  }
  messagesToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/chat/conversations/${conversationId}/messages`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: messagesToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let conversationsToken;
export const getConversations = (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof conversationsToken !== typeof undefined) {
    conversationsToken.cancel('Operation canceled due to new request.');
  }
  conversationsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/chat/conversations`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: conversationsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let conversationToken;
export const getConversation = async (tenantId, conversationId, cancel = false) => {
  if (cancel) {
    if (typeof conversationToken !== typeof undefined) {
      conversationToken.cancel('Operation canceled due to new request.');
    }
    conversationToken = axios.CancelToken.source();
  }

  try {
    return axios.get(`api/${tenantId}/chat/conversations/${conversationId}`, {
      cancelToken: cancel !== false ? conversationToken.token : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const sendMessageToCompany = (tenantId, employeeId, values) =>
  axios.post(`api/${tenantId}/chat/send-message-to-company`, {
    ...values,
    tenantId,
    sender: { employeeId },
  });

export const sendMessageToConversation = (tenantId, conversationId, data) =>
  axios.post(`api/${tenantId}/chat/conversations/${conversationId}/messages`, { ...data });

export const fetchConversations = tenantId => axios.get(`api/${tenantId}/chat/conversations`);

export const createConversation = (tenantId, employeeId) =>
  axios.post(`api/${tenantId}/chat/start-with-employee`, { employeeId, tenantId });
