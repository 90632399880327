import { put, takeLatest } from 'redux-saga/effects';
import { TenantService } from '../../../services';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';

export const actionTypes = {
  ResetData: '[Tenant] Reset Tenant',
  RequestData: '[Tenant] Request Tenant',
  FulfilledData: '[Tenant] Fulfilled Tenant',
  FulfilledIdentities: '[Tenant] Fulfilled Identities',
};

const initialState = {
  ...singleObjectInitialState,
  identities: [],
  identitiesLoading: true,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...newState,
        identitiesLoading: true,
      };
    }

    case actionTypes.FulfilledIdentities: {
      return {
        ...newState,
        identitiesLoading: false,
        identities: action.identities,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulFilledIdentities: identities => ({
    type: actionTypes.FulfilledIdentities,
    identities,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestTenantSaga(action) {
    const response = yield TenantService.getTenant(action.itemId);

    yield put(actions.fulfilledData(response));
  });

  yield takeLatest(actionTypes.RequestData, function* invoke(action) {
    const response = yield TenantService.getIdentitiesByTenantId(action.itemId);

    yield put(actions.fulFilledIdentities(response.data['hydra:member']));
  });
}
