import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import { actions as MovingJobEstimateDesktopActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobDesktopEstimate.duck';

import { RandomWidthSkeleton } from '../../components';

const useStyles = makeStyles(() => ({
  listItem: {
    height: '48px',
    boxSizing: 'border-box',
    marginTop: '8px',
    color: '#000000',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  listItemAll: {
    backgroundColor: '#3A78F2',
    '&:hover': {
      backgroundColor: '#3A78F2',
    },
  },
  whiteText: {
    color: '#ffffff',
  },
  title: {
    fontWeight: 'bold',
    fontHeight: '16px',
  },
  center: {
    textAlign: 'center',
  },
  icon: {
    marginRight: '19px',
  },
}));

const mapReducers = {
  mobile: {
    stepReducer: 'SecondStepReducer',
    actions: MovingJobEstimateActions,
    dataReducer: 'MovingJobEstimateReducer',
  },
  desktop: {
    stepReducer: 'ThirdStepReducer',
    actions: MovingJobEstimateDesktopActions,
    dataReducer: 'MovingJobEstimateDesktopReducer',
  },
};

const RoomServicesForm = ({ service, tag }) => {
  const dispatch = useDispatch();
  const selectedRoom = useSelector(state => state[mapReducers[tag].stepReducer].propSelected);
  const roomKey = `${selectedRoom.value}:${selectedRoom.index}`;
  const servicesList = useSelector(state => state[mapReducers[tag].dataReducer].loadedInformation.serviceTypes);
  const furnitureByRoom = useSelector(state => state[mapReducers[tag].dataReducer].furnitureByRoom || {});
  const servicesByRoom = useSelector(state => state[mapReducers[tag].dataReducer].servicesByRoom || {});
  const furnitureListSaved = furnitureByRoom[roomKey] ?? [];
  const servicesListForRoom = servicesByRoom[roomKey] ?? [];
  const [serviceTypeId, setServiceTypeId] = useState(false);
  const list = furnitureListSaved
    .map((item, index) => ({ ...item, index }))
    .filter(item => item.selected && (item.servicesPossible || service === 'storage'));

  const classes = useStyles();

  const handleToggle = (item, index) => () => {
    const itemKey = `${item.value}:${index}`;
    const itemServices = servicesListForRoom[itemKey] ?? {};
    dispatch(
      mapReducers[tag].actions.save({
        servicesByRoom: {
          ...servicesByRoom,
          [roomKey]: {
            ...servicesListForRoom,
            [itemKey]: { ...itemServices, [serviceTypeId]: !itemServices[serviceTypeId] },
          },
        },
      }),
    );
  };

  const handleToggleAll = () => {
    const newServicesListForRoom = { ...servicesListForRoom };
    list.forEach(item => {
      const itemKey = `${item.value}:${item.index}`;
      if (typeof newServicesListForRoom[itemKey] === 'undefined') {
        newServicesListForRoom[itemKey] = {};
      }

      newServicesListForRoom[itemKey][serviceTypeId] = true;
    });

    dispatch(
      mapReducers[tag].actions.save({
        servicesByRoom: {
          ...servicesByRoom,
          [roomKey]: newServicesListForRoom,
        },
      }),
    );
  };

  useEffect(() => {
    const selectedService = servicesList.find(serviceType => serviceType.internalType === service);
    setServiceTypeId(selectedService.serviceTypeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesList, service]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center" className={classes.whiteText}>
          <FormattedMessage id={`room.furniture.service.${service}`} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List className={classes.root}>
          {!serviceTypeId &&
            [1, 2, 3, 4, 5].map(index => (
              <ListItem key={index} className={classes.listItem} role={undefined} dense button>
                <ListItemText primary={<RandomWidthSkeleton />} primaryTypographyProps={{ className: classes.title }} />
              </ListItem>
            ))}
          {serviceTypeId && list.length > 0 && (
            <ListItem
              className={clsx(classes.listItem, classes.listItemAll)}
              role={undefined}
              dense
              button
              onClick={handleToggleAll}
            >
              {/* @TODO deselect all when everything is selected */}
              {/* {list.length === servicesListForRoom.length && ( */}
              {/*  <ListItemText */}
              {/*    primary={<FormattedMessage id="room.furniture.service.deselect_all" />} */}
              {/*    primaryTypographyProps={{ className: clsx(classes.whiteText, classes.title, classes.center) }} */}
              {/*  /> */}
              {/* )} */}
              {/* {list.length !== servicesListForRoom.length && ( */}
              <ListItemText
                primary={<FormattedMessage id="room.furniture.service.select_all" />}
                primaryTypographyProps={{ className: clsx(classes.whiteText, classes.title, classes.center) }}
              />
              {/* )} */}
            </ListItem>
          )}
          {serviceTypeId &&
            list.length > 0 &&
            list.map(item => {
              const labelId = `checkbox-list-label-${item.value}`;
              const itemKey = `${item.value}:${item.index}`;
              const itemServices = servicesListForRoom[itemKey] ?? {};

              return (
                <ListItem
                  key={`${item.value}:${item.index}`}
                  className={classes.listItem}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(item, item.index)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={itemServices[serviceTypeId] ?? false}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={item.label || item.furnitureTypeName}
                    primaryTypographyProps={{ className: classes.title }}
                  />
                </ListItem>
              );
            })}
          {list.length === 0 && (
            <Typography variant="p" component="div" align="center" className={classes.whiteText}>
              <FormattedMessage id="room.furniture.service.none" />
            </Typography>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default RoomServicesForm;
