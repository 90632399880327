import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from './Table.duck';
import CommunicationService from '../Services/CommunicationService';

export const actionTypes = {
  RequestData: '[AutomatedEmails] RequestEmails',
  FulfilledTable: '[AutomatedEmails] FulfilledEmails',
  SearchTable: '[AutomatedEmails] Search customers',
  ChangePage: '[AutomatedEmails] change page',
  SetPageSize: '[AutomatedEmails] set page size',
  SortTable: '[AutomatedEmails] Sort customers',
  ApplyFilter: '[AutomatedEmails] Apply filter',
  RemoveFilter: '[AutomatedEmails] Remove filter',
  ResetFilters: '[AutomatedEmails] Reset filter',
};

const initialState = {
  ...tableInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.AutomatedEmailsReducer);

    const response = yield CommunicationService.getAutomatedEmails(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
