import axios from 'axios';
import { getCustomer } from 'Domain/Customer/Services/CustomerService';
import Qs from 'qs';

const getInventoryItem = async (tenantId, inventoryId) => {
  const response = await axios.get(`api/${tenantId}/inventory/items/${inventoryId}`);
  return response.data;
};

const fetchAsyncName = async (currentTenantId, { inventoryItemId }) =>
  getInventoryItem(currentTenantId, inventoryItemId).then(response => response.name);

const getInfo = async (currentTenantId, list) => Promise.all(list.map(item => fetchAsyncName(currentTenantId, item)));

let messagesToken;
const getInventory = async (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof messagesToken !== typeof undefined) {
    messagesToken.cancel('Operation canceled due to new request.');
  }

  const mappedFilters = { ...filters };
  if (mappedFilters.currentStock === 'normal') {
    mappedFilters['currentStock[gte]'] = 50;
    delete mappedFilters.currentStock;
  } else if (mappedFilters.currentStock === 'attention') {
    mappedFilters['currentStock[lt]'] = 50;
    delete mappedFilters.currentStock;
  }

  messagesToken = axios.CancelToken.source();
  try {
    const response = await axios.get(`api/${tenantId}/inventory/item/stock`, {
      params: {
        ...mappedFilters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: messagesToken.token,
    });

    const data = await getInfo(tenantId, response.data['hydra:member']);

    const dataMapped = response.data['hydra:member'].map((item, index) => ({
      ...item,
      name: data[index] || item.name,
    }));
    return { items: dataMapped, total: response.data['hydra:totalItems'] };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let outStandingToken;

const fetchAsyncCustomer = async (currentTenantId, { customerId }) => {
  if (!customerId) {
    return null;
  }

  return getCustomer(currentTenantId, customerId).then(response => response.data);
};

const getCostumer = async (currentTenantId, list) =>
  Promise.all(list.map(item => fetchAsyncCustomer(currentTenantId, item)));

const getOutStandingList = async (tenantId, itemId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof outStandingToken !== typeof undefined) {
    outStandingToken.cancel('Operation canceled due to new request.');
  }
  outStandingToken = axios.CancelToken.source();
  try {
    const response = await axios.get(`api/${tenantId}/inventory/item/${itemId}/outstanding`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: outStandingToken.token,
    });

    const data = await getCostumer(tenantId, response.data['hydra:member']);
    const dataMapped = response.data['hydra:member'].map((item, index) => ({
      ...item,
      customer: data[index] || item.data,
    }));
    return { items: dataMapped, total: response.data['hydra:totalItems'] };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const getSupplier = async (tenantId, supplierId) => {
  const response = await axios.get(`api/${tenantId}/inventory/suppliers/${supplierId}`);
  return response.data;
};

const fetchAsyncSupplierName = async (currentTenantId, { supplierId }) =>
  getSupplier(currentTenantId, supplierId).then(response => response.name);

const getInfoSupplier = async (currentTenantId, list) =>
  Promise.all(list.map(item => fetchAsyncSupplierName(currentTenantId, item)));

let orderListToken;
const getOrderList = async (tenantId, itemId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof orderListToken !== typeof undefined) {
    orderListToken.cancel('Operation canceled due to new request.');
  }
  orderListToken = axios.CancelToken.source();
  try {
    const response = await axios.get(`api/${tenantId}/inventory/item/${itemId}/open-orders`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: orderListToken.token,
    });

    const data = await getInfoSupplier(tenantId, response.data['hydra:member']);

    const dataMapped = response.data['hydra:member'].map((item, index) => ({
      ...item,
      name: data[index] || item.name,
    }));
    return { items: dataMapped, total: response.data['hydra:totalItems'] };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const createInventoryItem = async (tenantId, data) =>
  axios.post(`api/${tenantId}/inventory/moving-boxes`, {
    tenantId,
    ...data,
    width: data.width / 100,
    height: data.height / 100,
    length: data.length / 100,
  });

const removeSupplierForItem = async (tenantId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${data.inventoryItemId}/remove-supplier`, { tenantId, ...data });

const loadSuppliers = tenantId => axios.get(`api/${tenantId}/inventory/suppliers`);

const addSupplierToInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/add-existing-supplier`, {
    inventoryItemId,
    tenantId,
    ...data,
  });

const createSupplierAndAddToInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/add-new-supplier`, {
    inventoryItemId,
    tenantId,
    ...data,
  });

const updateSupplier = (tenantId, supplierId, data) =>
  axios.put(`api/${tenantId}/inventory/supplier/${supplierId}`, {
    supplierId,
    tenantId,
    ...data,
    phoneNumber: data.mobileNumber,
  });

const orderInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/order`, {
    tenantId,
    inventoryItemId,
    ...data,
  });
const receivedInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/received-inventory`, {
    tenantId,
    inventoryItemId,
    ...data,
  });

const wornInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/report-worn-out`, {
    tenantId,
    inventoryItemId,
    ...data,
  });

const soldInventory = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/sold`, {
    tenantId,
    inventoryItemId,
    ...data,
  });

const inventoryOut = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/report-out`, {
    tenantId,
    inventoryItemId,
    ...data,
  });

const inventoryIn = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/report-in`, {
    tenantId,
    inventoryItemId,
    ...data,
  });
const retrieveInventoryFromStorage = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/retrieve-from-storage`, {
    tenantId,
    inventoryItemId,
    ...data,
  });

const putInventoryInStorage = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/put-in-storage`, {
    tenantId,
    inventoryItemId,
    ...data,
  });
const updateName = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/item/${inventoryItemId}/rename`, {
    inventoryItemId,
    tenantId,
    ...data,
  });

const getPublicMovingBoxes = (tenantId, expiresAfter) =>
  axios.get(`api/${tenantId}/inventory/moving-boxes/public`, {
    params: {
      'expiresAt[before]': expiresAfter,
      page: 1,
      perPage: 1000,
      order: {
        name: 'desc',
      },
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

const getOutstandingStockByProject = (tenantId, projectId) =>
  axios.get(`api/${tenantId}/inventory/item/outstanding-by-project/${projectId}`, {
    params: {
      page: 1,
      perPage: 1000,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

const getStorageOutstandingStockByProject = (tenantId, projectId) =>
  axios.get(`api/${tenantId}/inventory/item/storage-outstanding-by-project/${projectId}`, {
    params: {
      page: 1,
      perPage: 1000,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

const updateDimensions = (tenantId, inventoryItemId, data) =>
  axios.put(`api/${tenantId}/inventory/moving-boxes/${inventoryItemId}/change-dimensions`, {
    inventoryItemId,
    tenantId,
    ...data,
    width: data.width / 100,
    height: data.height / 100,
    length: data.length / 100,
  });

export {
  getOutstandingStockByProject,
  getPublicMovingBoxes,
  addSupplierToInventory,
  createSupplierAndAddToInventory,
  getInventory,
  getInventoryItem,
  getOutStandingList,
  getOrderList,
  createInventoryItem,
  removeSupplierForItem,
  retrieveInventoryFromStorage,
  putInventoryInStorage,
  getStorageOutstandingStockByProject,
  loadSuppliers,
  updateSupplier,
  orderInventory,
  receivedInventory,
  wornInventory,
  soldInventory,
  inventoryOut,
  inventoryIn,
  updateDimensions,
  updateName,
};
