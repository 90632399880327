import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getAutomatedEmail } from '../services/automatedEmailService';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  ResetData: '[AutomatedEmail] Reset AutomatedEmail',
  RequestData: '[AutomatedEmail] Request AutomatedEmail',
  FulfilledData: '[AutomatedEmail] Fulfilled AutomatedEmail',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestAutomatedEmailSaga(action) {
    const response = yield getAutomatedEmail(action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'AutomatedEmail') {
      yield put(actions.requestData());
    }
  });
}
