import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { QuoteService } from '../../../services';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[Quotes] RequestQuotes',
  FulfilledTable: '[Quotes] FulfilledQuotes',
  SearchTable: '[Quotes] Search quotes',
  ChangePage: '[Quotes] change page',
  SetPageSize: '[Quotes] set page size',
  SortTable: '[Quotes] Sort quotes',
  ApplyFilter: '[Quotes] Apply filter',
  RemoveFilter: '[Quotes] Remove filter',
  ResetFilters: '[Quotes] Reset filter',
  FulFilledStatistics: '[Quotes] FulFilledStatistics',
};

const initialState = {
  ...tableInitialState,
  sortBy: { quotationNumber: 'desc' },
  filters: { quoteStatus: [] },
  statistics: {
    openQuotesValue: {
      amount: 0,
      currency: 'EUR',
    },
    acceptedQuotesValue: {
      amount: 0,
      currency: 'EUR',
    },
    declinedQuotesValue: {
      amount: 0,
      currency: 'EUR',
    },
    averageDaysThatAQuoteIsOpenForThisMonth: 0,
    averageDaysThatAQuoteIsOpenForLastMonth: 0,
  },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.FulFilledStatistics: {
      return {
        ...state,
        statistics: action.payload,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  fulFilledStatistics: statistics => ({
    type: actionTypes.FulFilledStatistics,
    payload: statistics,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestQuotesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.QuotesReducer);

    const response = yield QuoteService.getQuotes(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    if (!response.cancelPrevQuery) {
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    }
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Quote') {
      yield put(actions.requestData());
    }
  });

  yield takeLatest(actionTypes.RequestData, function* invoke() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const today = dayjs();
    const { data } = yield QuoteService.getQuoteStatistics(currentTenantId, today.year(), today.format('M'));

    yield put(actions.fulFilledStatistics(data));
  });
}
