import axios from 'axios';

let getVehiclesToken;
export const getVehicles = async (tenantId, page, perPage, order, filters, globalFilter) => {
  try {
    if (typeof getVehiclesToken !== typeof undefined) {
      getVehiclesToken.cancel('Operation canceled due to new request.');
    }

    getVehiclesToken = axios.CancelToken.source();

    return axios.get(`api/${tenantId}/vehicles`, {
      cancelToken: getVehiclesToken.token,
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getVehicle = async (tenantId, materialId) => axios.get(`api/${tenantId}/vehicles/${materialId}`);

export const addVehicle = async (tenantId, values) => axios.post(`api/${tenantId}/vehicles`, { ...values, tenantId });

export const addMovingTruck = async (tenantId, values) =>
  axios.post(`api/${tenantId}/moving-trucks`, { ...values, tenantId });

export const addMovingLift = async (tenantId, values) =>
  axios.post(`api/${tenantId}/moving-lifts`, { ...values, tenantId });

export const changeMaintenance = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/vehicles/${materialId}/change-maintenance`, { ...values, tenantId, materialId });

export const changeFuelCard = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/vehicles/${materialId}/change-fuel-card`, { ...values, tenantId, materialId });

export const changePicture = async (tenantId, materialId, mediaObjectId) =>
  axios.put(`api/${tenantId}/vehicles/${materialId}/change-picture`, { mediaObjectId, tenantId, materialId });

export const renameMaterial = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/materials/${materialId}/rename`, { ...values, tenantId, materialId });

export const changeVehicleInformation = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/vehicles/${materialId}/change-information`, { ...values, tenantId, materialId });

export const changeMovingTruckInformation = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/moving-trucks/${materialId}/change-information`, {
    ...values,
    tenantId,
    materialId,
  });

export const changeMovingLiftInformation = async (tenantId, materialId, values) =>
  axios.put(`api/${tenantId}/moving-lifts/${materialId}/change-information`, {
    ...values,
    tenantId,
    materialId,
  });

export default { getVehicles, addVehicle, addMovingTruck, addMovingLift };
