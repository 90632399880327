import axios from 'axios';
import Qs from 'qs';
import { mapFields } from '../../../../utils/requestUtils';

export function getProjectEstimates(tenantId, projectId, page, perPage) {
  return axios.get(`api/${tenantId}/projects/${projectId}/project-estimates`, {
    params: {
      page,
      perPage,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export function previewProjectEstimate(tenantId, projectId, projectEstimateId) {
  return axios.get(`api/${tenantId}/projects/${projectId}/project-estimates/${projectEstimateId}/preview-pdf`, {
    responseType: 'blob',
  });
}

export const changeBillingMomentsOfProject = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/projects/${projectId}/change-billing-moments`, {
    ...values,
    projectId,
    tenantId,
  });

export const forceReceivedDeposit = (tenantId, projectId) =>
  axios.put(`api/${tenantId}/projects/${projectId}/received-deposit`, {
    projectId,
    tenantId,
  });

export const getProjectsToPlan = (tenantId, page, perPage) =>
  axios.get(`api/${tenantId}/projects/to-plan`, {
    params: {
      page,
      perPage,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

export const overwriteProjectEstimateValue = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/projects/${projectId}/project-estimates/overwrite-value`, {
    ...values,
    tenantId,
    projectId,
  });

const documentFieldMappings = {
  documentType: 'documentType.value',
};

export const fetchDocuments = ({ tenantId, relationId, projectId, page, perPage, sortBy }) =>
  axios.get(`api/${tenantId}/documents`, {
    params: {
      page,
      perPage,
      relationId,
      projectId,
      order: mapFields(sortBy, documentFieldMappings),
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
