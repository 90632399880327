import { actions as AppActions } from '../Domain/App/Ducks/App.duck';

const setupAxios = (axios, store) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers['Content-Type'] = 'application/ld+json';

  axios.interceptors.request.use(
    config => {
      const {
        AppReducer: { authToken },
      } = store.getState();

      if (authToken && !config.headers.Authorization && !config.headers.skipAuthorization) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      const { response } = error;
      const { dispatch } = store;
      if (response && response.status === 401 && response.data.message !== 'Invalid credentials.') {
        dispatch(AppActions.logout());

        return Promise.resolve();
      }

      return Promise.reject(error);
    },
  );
};

export default setupAxios;
