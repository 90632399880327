import { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { MobileLayout } from '../../../../layouts';
import { ErrorHandlerComponent, ProjectItemComponent, RandomWidthSkeleton } from '../../../../components';
import { TenantService } from '../../../../services';
import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';
import { actions as SecondStepActions } from '../../../SecondStep/Ducks/SecondStep.duck';
import { actions as MovingJobEstimateActions } from '../../../MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '0px',
    height: '100%',
  },
  paddingBottom: {
    paddingBottom: '100px',
  },
}));

const perPage = 100;

const ProjectsPage = () => {
  const classes = useStyles();
  const {
    authToken,
    tenant: { tenantId },
  } = useSelector(state => state.AppReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  /** @TODO allow infinite scrolling */
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ total: 0, projects: [] });

  const loadProjects = async () => {
    const result = await TenantService.getProjects(authToken, tenantId, page, perPage, ['intake', 'quotation'], {
      'order[nextActionDate]': 'desc',
    });

    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectId = useSelector(state => state.MovingJobEstimateReducer.projectId);

  useEffect(() => {
    if (selectedProjectId && projectId && selectedProjectId === projectId) {
      history.push('/employee/wizard');
    }
  }, [selectedProjectId, projectId, history]);

  const openProject = async project => {
    await dispatch(FirstStepActions.reset());
    await dispatch(SecondStepActions.reset());
    await dispatch(MovingJobEstimateActions.reset());
    await dispatch(MovingJobEstimateActions.loadInformation());
    await dispatch(MovingJobEstimateActions.loadProject(project));

    setSelectedProjectId(project.projectId);
  };

  return (
    <MobileLayout>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <ErrorHandlerComponent>
          <Grid container className={classes.paddingBottom}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Button className={classes.backButton} onClick={() => history.push('/employee')}>
                    <FontAwesomeIcon icon={faArrowLeft} color="#BCC1D9" size="lg" />
                  </Button>
                  <Typography variant="h3" component="h3" align="center">
                    <FormattedMessage id="projects.page.title" />
                  </Typography>
                  <Typography variant="h5" component="h5" align="center">
                    <FormattedMessage id="projects.page.results" values={{ count: data.total }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {loading && <RandomWidthSkeleton />}
                  <List>
                    {!loading &&
                      data.projects.map(project => (
                        <ProjectItemComponent
                          key={project.projectId}
                          project={project}
                          handleClick={() => openProject(project)}
                        />
                      ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ErrorHandlerComponent>
      </Container>
    </MobileLayout>
  );
};

export default ProjectsPage;
