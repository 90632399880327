import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { getConversations } from '../Services/ConversationService';

export const actionTypes = {
  RequestData: '[Conversations] RequestConversations',
  RequestMoreConversations: '[Conversations] RequestMoreConversations',
  FulfilledMoreData: '[Conversations] FulfilledMoreData',
  FulfilledTable: '[Conversations] FulfilledConversations',
  SearchTable: '[Conversations] Search',
  ChangePage: '[Conversations] change page',
  SetPageSize: '[Conversations] set page size',
  SortTable: '[Conversations] Sort',
  ApplyFilter: '[Conversations] Apply filter',
  RemoveFilter: '[Conversations] Remove filter',
  ResetFilters: '[Conversations] Reset filter',
};

const initialState = {
  ...tableInitialState,
  pageSize: 40,
  sortBy: { createdAt: 'DESC' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...state,
        page: 1,
        loading: true,
      };
    }

    case actionTypes.RequestMoreConversations: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case actionTypes.FulfilledMoreData: {
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...state.items, ...action.items],
        totalCount: action.totalCount,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  requestMoreConversations: () => ({ type: actionTypes.RequestMoreConversations }),
  fulfilledMoreData: (items, totalCount) => ({
    type: actionTypes.FulfilledMoreData,
    items,
    totalCount,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestConversationsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.ConversationsReducer);

    const response = yield getConversations(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    if (response.cancelPrevQuery) {
      return;
    }

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });

  yield takeLatest(actionTypes.RequestMoreConversations, function* requestConversationsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.ConversationsReducer);

    const response = yield getConversations(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    if (response.cancelPrevQuery) {
      return;
    }

    yield put(actions.fulfilledMoreData(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
