import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions as AppActions, newMercureMessage } from 'Domain/App/Ducks/App.duck';
import { StorageService } from '../../../services';

export const actionTypes = {
  requestProjectStorages: '[ProjectStorages] Fetch',
  storeProjectStorages: '[ProjectStorages] Store',
  reserveStorage: '[ProjectStorages] reserve storage',
  selectStorage: '[ProjectStorages] Select storage',
  removeStorage: '[ProjectStorages] remove storage',
  unselectStorage: '[ProjectStorages] unselect storage',
};

const initialState = {
  loading: true,
  projectId: null,
  identityId: null,
  items: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.requestProjectStorages: {
      return {
        ...state,
        items: [],
        projectId: action.projectId,
        identityId: action.identityId,
        loading: true,
      };
    }

    case actionTypes.selectStorage:
    case actionTypes.reserveStorage: {
      return {
        ...state,
        items: [...state.items, action.storage],
      };
    }

    case actionTypes.removeStorage:
    case actionTypes.unselectStorage: {
      return {
        ...state,
        items: [...state.items.filter(({ storageId }) => storageId !== action.storage.storageId)],
      };
    }

    case actionTypes.storeProjectStorages: {
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  requestProjectStorages: (projectId, identityId) => ({
    type: actionTypes.requestProjectStorages,
    projectId,
    identityId,
  }),
  reserveStorage: (storage, customerId, vatCodeId, price = null) => ({
    type: actionTypes.reserveStorage,
    storage,
    customerId,
    vatCodeId,
    price,
  }),
  removeStorage: storage => ({
    type: actionTypes.removeStorage,
    storage,
  }),
  selectStorage: (storage, customerId) => ({
    type: actionTypes.selectStorage,
    storage,
    customerId,
  }),
  unselectStorage: storage => ({
    type: actionTypes.unselectStorage,
    storage,
  }),
  storeProjectStorages: payload => ({
    type: actionTypes.storeProjectStorages,
    payload,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.requestProjectStorages, function* getSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const response = yield StorageService.getStoragesForProject(currentTenantId, action.projectId);

    yield put(actions.storeProjectStorages(response.items || []));
  });

  yield takeLatest(actionTypes.reserveStorage, function* execute(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const { projectId, identityId } = yield select(state => state.ProjectStoragesReducer);
    try {
      yield StorageService.reserve(currentTenantId, action.storage.storageId, {
        contentOwnerId: action.customerId,
        projectId,
        identityId,
        invoicingPeriod: 'monthly',
        price: action.price,
        vatCodeId: action.vatCodeId,
      });
    } catch (error) {
      yield put(actions.unselectStorage(action.storage));
      yield put(
        AppActions.notifyUser(
          { id: 'error.whileSaving' },
          {
            variant: 'error',
          },
        ),
      );
    }
  });

  yield takeLatest(actionTypes.removeStorage, function* execute(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    try {
      yield StorageService.emptyContent(currentTenantId, action.storage.storageId, {
        invoicingEndDate: null,
        createInvoice: false,
      });
    } catch (error) {
      yield put(actions.selectStorage(action.storage));

      yield put(
        AppActions.notifyUser(
          { id: 'error.whileSaving' },
          {
            variant: 'error',
          },
        ),
      );
    }
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Storage') {
      const { projectId, items } = yield select(state => state.ProjectStoragesReducer);
      const storageIds = items.map(({ storageId }) => storageId);
      const storage = action.data;
      if (
        storage.storedForProject &&
        storage.storedForProject.includes(projectId) &&
        !storageIds.includes(storage.storageId)
      ) {
        yield put(actions.selectStorage(storage));
      } else if (
        (!storage.storedForProject || !storage.storedForProject.includes(projectId)) &&
        storageIds.includes(storage.storageId)
      ) {
        yield put(actions.unselectStorage(storage));
      }
    }
  });
}
