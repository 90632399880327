import { useState } from 'react';
import { Button, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
  content: {
    padding: theme.spacing(6),
  },
  leftSide: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  rightSide: {
    borderLeft: `1px solid ${theme.palette.border.primary}`,
    maxHeight: '100vh',
    overflow: 'auto',
  },
  leftSideInside: {
    height: `calc(100vh - ${theme.spacing(6)}px - ${theme.spacing(5)}px)`,
    paddingTop: theme.spacing(3),
    paddingLeft: 0,
  },
  rightSideInside: {
    height: `calc(100vh - ${theme.spacing(6)}px - ${theme.spacing(5)}px)`,
    paddingTop: theme.spacing(3),
  },
  isBig: {
    marginLeft: theme.spacing(6),
    position: 'relative!important',
  },
  fullHeight: {
    paddingTop: theme.spacing(5),
    background: theme.palette.background.default,
    position: 'absolute',
    height: '100vh',
    width: '100%',
    right: 0,
    top: 0,
    bottom: 0,
  },
  growButton: {
    background: theme.palette.background.default,
    border: '1px solid #DADCE4',
    boxSizing: 'border-box',
    borderRadius: '50%',
    height: 44,
    width: 44,
    minWidth: 'initial',
    position: 'fixed',
    marginLeft: '-78px',
    marginTop: '10px',
    '&:hover': {
      background: theme.palette.background.default,
    },
  },
  backButton: {
    background: theme.palette.background.default,
    border: '1px solid #DADCE4',
    boxSizing: 'border-box',
    borderRadius: '50%',
    height: 44,
    width: 44,
    minWidth: 'initial',
    position: 'absolute',
    left: 16,
    top: 82,
    '&:hover': {
      background: theme.palette.background.default,
    },
  },
}));

const TwoSideLayout = ({
  leftSide,
  rightSide,
  invertedSizes,
  className,
  classes,
  insideLayout,
  rightSideFullHeight,
  rightSideClassName,
  growable,
  invertable,
  leftSideRoute,
}) => {
  const history = useHistory();
  const localClasses = useStyles();
  const { path } = useRouteMatch();
  const [isBig, setIsBig] = useState(false);
  const [isInverted, setIsInverted] = useState(invertedSizes);

  const theme = useTheme();

  const isTablet = useMediaQuery(t => t.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  let leftSidePath = leftSideRoute;
  if (!leftSideRoute) {
    const parts = path.split('/');
    if (parts[parts.length - 1].includes(':')) {
      parts.splice(parts.length - 1);
    }
    leftSidePath = parts.join('/');
  }
  const isLeftSide = leftSidePath === path;
  let rightSideSize = isInverted ? 7 : 5;
  if (isBig) {
    rightSideSize = 12;
  }

  return (
    <Grid container spacing={0} className={clsx(localClasses.container, { [className]: Boolean(className) })}>
      <Grid
        item
        xs={12}
        lg={isInverted ? 5 : 7}
        xl={isInverted ? 5 : 7}
        className={clsx(localClasses.content, isLarge ? localClasses.leftSide : '', {
          [localClasses.leftSideInside]: insideLayout,
        })}
        hidden={isTablet ? !isLeftSide : isBig}
      >
        {leftSide}
      </Grid>
      <Grid
        item
        xs={12}
        lg={rightSideSize}
        xl={rightSideSize}
        hidden={isTablet ? isLeftSide : false}
        className={clsx(
          localClasses.content,
          isLarge ? localClasses.rightSide : '',
          rightSideClassName,
          { [localClasses.rightSideInside]: insideLayout },
          { [localClasses.leftSideInside]: insideLayout && isTablet && !isLeftSide },
          { [localClasses.fullHeight]: !growable && !invertable && rightSideFullHeight },
          { [localClasses.isBig]: isBig },
        )}
      >
        {isTablet && (
          <Button
            onClick={() => {
              history.push(leftSidePath);
            }}
            className={clsx(localClasses.backButton)}
          >
            <FontAwesomeIcon icon={faChevronDoubleLeft} />
          </Button>
        )}
        {!isTablet && (growable || invertable) && (
          <Button
            onClick={() => {
              if (growable) {
                setIsBig(!isBig);
              } else if (invertable) {
                setIsInverted(!isInverted);
              }
              window.dispatchEvent(new Event('resize'));
            }}
            className={clsx(localClasses.growButton, classes.growableButton)}
          >
            <FontAwesomeIcon
              icon={(growable && isBig) || (invertable && isInverted) ? faChevronDoubleRight : faChevronDoubleLeft}
            />
          </Button>
        )}
        {rightSide}
      </Grid>
    </Grid>
  );
};

TwoSideLayout.propTypes = {
  leftSide: PropTypes.element.isRequired,
  rightSide: PropTypes.element,
  className: PropTypes.string,
  insideLayout: PropTypes.bool,
  growable: PropTypes.bool,
  invertable: PropTypes.bool,
  leftSideRoute: PropTypes.string,
  invertedSizes: PropTypes.bool,
  classes: PropTypes.object,
  rightSideFullHeight: PropTypes.bool,
  rightSideClassName: PropTypes.string,
};

TwoSideLayout.defaultProps = {
  className: '',
  insideLayout: false,
  growable: false,
  invertable: false,
  invertedSizes: false,
  rightSideFullHeight: false,
  rightSide: null,
  rightSideClassName: '',
  leftSideRoute: null,
  classes: {},
};

export default TwoSideLayout;
