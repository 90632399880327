import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ShowAddressComponent } from '../../../../components';
import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';

const ConfirmAddressPage = () => {
  const dispatch = useDispatch();
  const { addressTo, addressFrom } = useSelector(state => state.MovingJobEstimateReducer);
  const { active, selected } = useSelector(state => state.FirstStepReducer);

  if ((!addressTo.type && !addressTo.unknownAddress) || !addressFrom.type) {
    dispatch(FirstStepActions.prev(active, selected));

    return '';
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="address.confirm" />
        </Typography>
      </Grid>

      <ShowAddressComponent
        id="addressFrom"
        address={addressFrom}
        title={<FormattedMessage id="address.confirm.from" />}
      />

      <ShowAddressComponent id="addressTo" address={addressTo} title={<FormattedMessage id="address.confirm.to" />} />
    </Grid>
  );
};

export default ConfirmAddressPage;
