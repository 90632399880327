import axios from 'axios';
import Qs from 'qs';

let tokenSource;
const mapField = field => {
  switch (field) {
    case 'customerName':
    case 'customer':
      return ['customer.name'];
    default:
      return [field];
  }
};

const getMovingJobs = (tenantId, page, perPage, order, appliedFilters, globalFilter, cancel = true, listed = false) => {
  if (cancel) {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }
    tokenSource = axios.CancelToken.source();
  }
  const filters = { ...appliedFilters };
  if (filters.zipCode && filters.addressType !== 'both') {
    filters[`${filters.addressType}ZipCode`] = filters.zipCode;
    delete filters.zipCode;
  }

  if (filters.city && filters.addressType !== 'both') {
    filters[`${filters.addressType}City`] = filters.city;
    delete filters.city;
  }

  delete filters.addressType;

  const appliedOrder = {};
  Object.keys(order).forEach(key => {
    mapField(key).forEach(field => {
      appliedOrder[field] = order[key];
    });
  });

  try {
    return axios.get(`api/${tenantId}/${listed ? 'listed-moving-jobs' : 'moving-jobs'}`, {
      params: {
        ...filters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: cancel !== false ? tokenSource.token : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const getDashboardMovingJobs = (tenantId, page, perPage, order, filters) => {
  const appliedOrder = {};
  if (order) {
    Object.keys(order).forEach(key => {
      mapField(key).forEach(field => {
        appliedOrder[field] = order[key];
      });
    });
  }

  return axios.get(`api/${tenantId}/dashboard-moving-jobs`, {
    params: {
      ...filters,
      page,
      perPage,
      order: appliedOrder,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

const getMovingJob = (tenantId, projectId) => axios.get(`api/${tenantId}/moving-jobs/${projectId}`);

const createMovingJob = (tenantId, values) => axios.post(`api/${tenantId}/moving-jobs`, { ...values, tenantId });

const addAddress = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/add-address`, {
    ...values,
    projectId,
    tenantId,
    primary: values.primary || values.addressType === 'primary',
    addressType: values.addressType === 'primary' ? 'to' : values.addressType,
    distanceFromCar: !values.distanceFromCar ? null : values.distanceFromCar,
    distanceToApartment: !values.distanceToApartment ? null : values.distanceToApartment,
    floor: !values.floor ? null : values.floor,
  });

const updateAddresses = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-addresses`, {
    ...values,
    projectId,
    tenantId,
  });

const updateNotes = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-notes`, {
    ...values,
    projectId,
    tenantId,
  });

const updateNotesEmployee = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-notes-for-employees`, {
    ...values,
    projectId,
    tenantId,
  });

const updateHourlyRate = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-hourly-rate`, {
    ...values,
    projectId,
    tenantId,
  });

const updateDates = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-dates`, {
    ...values,
    projectId,
    tenantId,
  });

const addMaterialToMovingJob = (tenantId, movingJobId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/add-material`, values);

const updateUsedMaterialForMovingJob = (tenantId, movingJobId, materialId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/update-material/${materialId}`, values);

const removeUsedMaterialFromMovingJob = (tenantId, movingJobId, materialId) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/remove-material/${materialId}`, {
    projectId: movingJobId,
    materialId,
  });

const addFurniture = (tenantId, movingJobId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/add-furniture`, values);

const removeFurniture = (tenantId, movingJobId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/remove-furniture`, values);

const finishFurnitureService = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/furniture/finish-service`, {
    ...values,
    projectId,
    tenantId,
  });

const putFurnitureInStorage = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/furniture/put-in-storage`, {
    ...values,
    projectId,
    tenantId,
  });

const unloadFurnitureFromStorage = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/furniture/unload-from-storage`, {
    ...values,
    projectId,
    tenantId,
  });

const resetFurnitureService = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/furniture/reset-service`, {
    ...values,
    projectId,
    tenantId,
  });

const addRoom = (tenantId, movingJobId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/add-room`, values);

const updateRoom = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/update-room`, {
    ...values,
    projectId,
    tenantId,
  });

const removeRoom = (tenantId, movingJobId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${movingJobId}/remove-room`, values);

const planIntake = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/plan-intake`, {
    ...values,
    projectId,
    tenantId,
  });

const planMovingJob = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/plan`, {
    ...values,
    projectId,
    tenantId,
  });

const reopenMovingJob = (tenantId, projectId) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/reopen`, {
    projectId,
    tenantId,
  });

const planPickingUpBoxes = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/plan-picking-up-of-boxes`, {
    ...values,
    projectId,
    tenantId,
  });

const deliverBoxes = (tenantId, projectId, eventId = null) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/deliver-boxes`, {
    projectId,
    tenantId,
    eventId,
  });

const pickUpBoxes = (tenantId, projectId, eventId = null) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/pick-up-boxes`, {
    projectId,
    tenantId,
    eventId,
  });

const finishMoving = (tenantId, projectId, eventId = null, finishedAt = null) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/finish-moving`, {
    projectId,
    tenantId,
    eventId,
    finishedAt,
  });

const finish = (tenantId, projectId) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/finish`, {
    projectId,
    tenantId,
  });

const clearStorage = (tenantId, projectId, invoicingEndDate) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/clear-storage`, {
    projectId,
    tenantId,
    invoicingEndDate,
  });

const skipQuote = (tenantId, projectId) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/skip-quote`, {
    projectId,
    tenantId,
  });

const sendQuote = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/projects/${projectId}/send-to-customer`, {
    ...values,
    tenantId,
    projectId,
  });

const createQuote = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/projects/${projectId}/create-quote`, {
    ...values,
    tenantId,
    projectId,
  });

const generateQuote = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/projects/${projectId}/generate-quote`, {
    ...values,
    tenantId,
    projectId,
  });

const cancelMovingJob = (tenantId, projectId) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/cancel`, {
    tenantId,
    projectId,
  });

const rescheduleEvents = (tenantId, projectId, values) =>
  axios.put(`api/${tenantId}/moving-jobs/${projectId}/reschedule-events`, {
    tenantId,
    projectId,
    ...values,
  });

export {
  getMovingJobs,
  getMovingJob,
  createMovingJob,
  addAddress,
  updateAddresses,
  updateNotes,
  updateNotesEmployee,
  updateDates,
  updateHourlyRate,
  addMaterialToMovingJob,
  updateUsedMaterialForMovingJob,
  removeUsedMaterialFromMovingJob,
  putFurnitureInStorage,
  unloadFurnitureFromStorage,
  addFurniture,
  removeFurniture,
  finishFurnitureService,
  resetFurnitureService,
  reopenMovingJob,
  addRoom,
  updateRoom,
  finishMoving,
  removeRoom,
  planIntake,
  planMovingJob,
  generateQuote,
  createQuote,
  rescheduleEvents,
  sendQuote,
  skipQuote,
  deliverBoxes,
  pickUpBoxes,
  planPickingUpBoxes,
  finish,
  cancelMovingJob,
  clearStorage,
};
