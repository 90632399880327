import printValue from 'yup/lib/util/printValue';

export const mixed = {
  default: 'Deze waarde is ongeldig',
  required: 'Deze waarde mag niet leeg zijn.',
  // eslint-disable-next-line no-template-curly-in-string
  oneOf: 'De geselecteerde waarde is geen geldige optie. De volgende waarden zijn geldig: ${values}',
  // eslint-disable-next-line no-template-curly-in-string
  notOneOf: 'De geselecteerde waarde is mag niet één van de volgende waarden zijn: ${values}',
  notType: ({ path, type, value, originalValue }) => {
    const isCast = originalValue != null && originalValue !== value;
    // eslint-disable-next-line no-useless-concat
    let msg = `${`${path} must be a \`${type}\` type, ` + `but the final value was: \`${printValue(value, true)}\``}${
      isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : '.'
    }`;

    if (value === null) {
      msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
    }

    return msg;
  },
  defined: 'Deze waarde moet gedefinieerd zijn.',
};

export const string = {
  // eslint-disable-next-line no-template-curly-in-string
  min: 'Deze waarde is te kort. Hij moet tenminste ${min} tekens bevatten.',
  // eslint-disable-next-line no-template-curly-in-string
  max: 'Deze waarde is te lang. Hij mag maximaal ${max} tekens bevatten.',
  // eslint-disable-next-line no-template-curly-in-string
  matches: '${path} must match the following: "${regex}"',
  email: 'Deze waarde is geen geldig e-mailadres.',
  url: 'Deze waarde is geen geldige URL.',
  uuid: 'Dit is geen geldige UUID.',
  // eslint-disable-next-line no-template-curly-in-string
  length: '${path} must be exactly ${length} characters',
  // eslint-disable-next-line no-template-curly-in-string
  trim: '${path} must be a trimmed string',
  // eslint-disable-next-line no-template-curly-in-string
  lowercase: '${path} must be a lowercase string',
  // eslint-disable-next-line no-template-curly-in-string
  uppercase: '${path} must be a upper case string',
};

export const number = {
  // eslint-disable-next-line no-template-curly-in-string
  min: 'Deze waarde moet groter dan of gelijk aan ${min} zijn.',
  // eslint-disable-next-line no-template-curly-in-string
  max: 'Deze waarde moet minder dan of gelijk aan ${max} zijn.',
  // eslint-disable-next-line no-template-curly-in-string
  lessThan: 'Deze waarde moet minder zijn dan ${less}.',
  // eslint-disable-next-line no-template-curly-in-string
  moreThan: 'Deze waarde moet groter zijn dan ${more}.',
  // eslint-disable-next-line no-template-curly-in-string
  notEqual: 'Deze waarde mag niet gelijk zijn aan ${notEqual}.',
  positive: 'Deze waarde moet positief zijn.',
  negative: 'Deze waarde moet negatief zijn.',
  integer: 'Deze waarde moet een getal zijn',
};

export const date = {
  // eslint-disable-next-line no-template-curly-in-string
  min: 'Deze waarde moet later zijn dan ${min}.',
  // eslint-disable-next-line no-template-curly-in-string
  max: 'Deze waarde moet eerder zijn dan ${max}.',
};

export const boolean = {};

export const object = {
  // eslint-disable-next-line no-template-curly-in-string
  noUnknown: '${path} field has unspecified keys: ${unknown}',
};

export const array = {
  // eslint-disable-next-line no-template-curly-in-string
  min: 'Deze waarde moet minstens ${min} items bevatten.',
  // eslint-disable-next-line no-template-curly-in-string
  max: 'Deze waarde mag maximaal ${max} items bevatten.',
};

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
});
