import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { getNews } from '../Services/NewsService';

export const actionTypes = {
  RequestData: '[News] RequestNews',
  FulfilledTable: '[News] FulfilledNews',
  SearchTable: '[News] Search',
  ChangePage: '[News] change page',
  SetPageSize: '[News] set page size',
  SortTable: '[News] Sort',
  ApplyFilter: '[News] Apply filter',
  RemoveFilter: '[News] Remove filter',
  ResetFilters: '[News] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestNewsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.NewsReducer);

    const response = yield getNews(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
