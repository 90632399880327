import axios from 'axios';

const rename = async (tenantId, relationId, values) =>
  axios.put(`api/${tenantId}/relations/${relationId}/rename`, {
    ...values,
    relationId,
    tenantId,
  });
const updateContactInformation = async (tenantId, relationId, values) =>
  axios.put(`api/${tenantId}/relations/${relationId}/update-contact-information`, {
    ...values,
    relationId,
    tenantId,
  });

const updateBusinessInformation = async (tenantId, relationId, values) =>
  axios.put(`api/${tenantId}/relations/${relationId}/update-business-information`, {
    ...values,
    relationId,
    tenantId,
  });

const addContactPerson = async (tenantId, relationId, contactPerson) =>
  axios.put(`api/${tenantId}/relations/${relationId}/contact-persons`, {
    ...contactPerson,
    relationId,
    tenantId,
  });

const updateContactPerson = async (tenantId, relationId, contactPersonId, contactPerson) =>
  axios.put(`api/${tenantId}/relations/${relationId}/contact-persons/${contactPersonId}`, {
    ...contactPerson,
    relationId,
    tenantId,
  });

const removeContactPerson = async (tenantId, relationId, contactPersonId) =>
  axios.put(`api/${tenantId}/relations/${relationId}/contact-persons/${contactPersonId}/delete`, {
    relationId,
    tenantId,
    contactPersonId,
  });

const addAddress = async (tenantId, relationId, address) =>
  axios.put(`api/${tenantId}/relations/${relationId}/addresses`, {
    ...address,
    relationId,
    tenantId,
  });

const updateAddress = async (tenantId, relationId, addressId, address) =>
  axios.put(`api/${tenantId}/relations/${relationId}/addresses/${addressId}`, {
    ...address,
    relationId,
    tenantId,
  });

const removeAddress = async (tenantId, relationId, addressId) =>
  axios.put(`api/${tenantId}/relations/${relationId}/addresses/${addressId}/delete`, {
    relationId,
    tenantId,
    addressId,
  });

const fetchAllQuotes = async (tenantId, relationId, page, perPage, order) =>
  axios.get(`api/${tenantId}/relations/${relationId}/quotes`, {
    params: {
      page,
      perPage,
      order,
    },
  });

const fetchAllInvoices = async (tenantId, relationId, page, perPage, order) =>
  axios.get(`api/${tenantId}/relations/${relationId}/invoices`, {
    params: {
      page,
      perPage,
      order,
    },
  });

export default {
  addContactPerson,
  updateContactPerson,
  removeContactPerson,
  addAddress,
  updateAddress,
  removeAddress,
  rename,
  updateContactInformation,
  updateBusinessInformation,
  fetchAllQuotes,
  fetchAllInvoices,
};
