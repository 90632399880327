import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { AddMoreCardComponent, DialogComponent, IconCardComponent, DragToCloseModalComponent } from 'components';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { actions as FirstStepActions } from '../../Domain/FirstStep/Ducks/FirstStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(() => ({
  paddingBottom: {
    paddingBottom: '100px',
  },
}));

const RoomsForm = () => {
  const dispatch = useDispatch();
  const {
    rooms: roomsListSaved,
    loadedInformation: { roomTypes },
  } = useSelector(state => state.MovingJobEstimateReducer);
  const [roomsList, setRoomsList] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles();

  const select = check => {
    const indexSelected = roomsList.items.indexOf(check);
    const roomItems = [...roomsList.items];

    const optionsSelected = roomsList.items.filter(item => item.selected === true && item.name === check.name);

    roomItems[indexSelected] = {
      ...check,
      selected: !check.selected,
      index: check.selected === true ? null : optionsSelected.length,
    };

    setRoomsList({ ...roomsList, items: roomItems });
  };

  const addItem = addedItem => {
    const optionsSelected = roomsList.items.filter(item => item.selected === true && item.name === addedItem.name);
    const newList = [...roomsList.items, { ...addedItem, selected: true, index: optionsSelected.length }];
    setRoomsList({ ...roomsList, items: newList });
    setOpenDialog(false);
  };

  useEffect(() => {
    if (roomsListSaved.length > 0) {
      setRoomsList({ total: roomsListSaved.length, items: roomsListSaved });
    } else {
      setRoomsList({ total: roomTypes.length, items: roomTypes });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roomsList) {
      const selectedRooms = roomsList.items.filter(item => item.selected);
      dispatch(MovingJobEstimateActions.saveRooms(roomsList.items));

      dispatch(FirstStepActions.filledForm(selectedRooms.length > 0));
    }
  }, [roomsList, dispatch]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" align="center">
            <FormattedMessage id="rooms.what_rooms" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paddingBottom}>
          <Grid container spacing={2}>
            {roomsList &&
              roomsList.items.length > 0 &&
              roomsList.items.map((item, index) => (
                <Grid item xs={6} sm={4} key={`${item.id}${index}`}>
                  <IconCardComponent item={item} selectable clickFunction={insItem => select(insItem)} />
                </Grid>
              ))}
            {
              <Grid item xs={6} sm={4}>
                <AddMoreCardComponent
                  label={<FormattedMessage id="room.add_room" />}
                  clickFunction={() => {
                    setOpenDialog(true);
                  }}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <DialogComponent
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={<FormattedMessage id="room.choose_the_room_to_add" />}
      >
        <DragToCloseModalComponent closeFunction={() => setOpenDialog(false)} />
        <Grid container spacing={2}>
          {roomTypes.length > 0 &&
            roomTypes.map(item => (
              <Grid item xs={6} sm={4} key={item.id} onClick={() => addItem(item)}>
                <IconCardComponent item={item} clickFunction={insItem => addItem(insItem)} />
              </Grid>
            ))}
        </Grid>
      </DialogComponent>
    </>
  );
};

export default RoomsForm;
