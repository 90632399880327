export default obj => {
  const copy = { ...obj };
  Object.entries(copy).forEach(([key, value]) => {
    if (!value) {
      delete copy[key];
    }
  });
  return copy;
};

export const getProp = (path, obj) => path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);
