import { put, takeLatest } from 'redux-saga/effects';
import { RoomService } from 'services';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { actions as FirstStepActions } from '../../FirstStep/Ducks/FirstStep.duck';
import { actions as SecondStepActions } from '../../SecondStep/Ducks/SecondStep.duck';

export const actionTypes = {
  SetProject: '[MovingJobDesktopEstimate] Set MovingJobEstimate',
  RemoveQuoteSecondaryAddressesFrom: '[MovingJobDesktopEstimate] Remove secondary address from',
  RemoveQuoteSecondaryAddressesTo: '[MovingJobDesktopEstimate] Remove secondary address to',
  ResetError: '[MovingJobDesktopEstimate] Reset Error',
  ResetState: '[MovingJobDesktopEstimate] Reset State',
  SetQuote: '[MovingJobDesktopEstimate] Set MovingJobEstimate Quote',
  SetQuoteSecondaryAddressesFrom: '[MovingJobDesktopEstimate] Set secondary address from',
  SetQuoteSecondaryAddressesTo: '[MovingJobDesktopEstimate] Set secondary address to',
  SetRooms: '[MovingJobDesktopEstimate] Set Rooms',
  DeleteQuote: '[MovingJobDesktopEstimate] Delete MovingJobEstimate',
  ResetApp: '[MovingJobDesktopEstimate] Reset All',
  SaveForm: '[MovingJobDesktopEstimate] Save Forms',
  SetError: '[MovingJobDesktopEstimate] Set Error',
  LoadProject: '[MovingJobDesktopEstimate] Load Project',
};

const initialState = {
  projectId: null,
  addressTo: {},
  secondaryAddressTo: [],
  addressFrom: {},
  secondaryAddressFrom: [],
  customerForm: {
    companyName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  surchargeOrDiscount: {
    percentage: 0,
    reason: null,
    type: null,
  },
  needDisassemble: null,
  servicesByRoom: {},
  inventoryItems: {},
  images: {},
  rooms: [],
  furnitureByRoom: [],
  movingDate: '',
  note: '',
  services: [],
  quoteResult: null,
  error: null,
};

export const reducer = persistReducer(
  { storage: localforage, key: 'moving-job-desktop-estimate' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetQuote: {
        return {
          ...state,
          ...action.payload,
          addressFrom: { ...state.addressFrom, ...action.payload.addressFrom },
          addressTo: { ...state.addressTo, ...action.payload.addressTo },
          furnitureByRoom: { ...state.furnitureByRoom, ...action.payload.furnitureByRoom },
          disassembledItems: { ...state.disassembledItems, ...action.payload.disassembledItems },
          inventoryItems: { ...state.inventoryItems, ...action.payload.inventoryItems },
          images: { ...state.images, ...action.payload.images },
          services: { ...state.services, ...action.payload.services },
        };
      }
      case actionTypes.SetProject: {
        return {
          ...state,
          ...action.payload,
        };
      }
      case actionTypes.SetRooms: {
        return {
          ...state,
          rooms: action.payload,
        };
      }
      case actionTypes.SetQuoteSecondaryAddressesFrom: {
        const copy = [...state.secondaryAddressFrom];
        copy[action.index] = action.payload;
        return {
          ...state,
          secondaryAddressFrom: [...copy],
        };
      }
      case actionTypes.SetQuoteSecondaryAddressesTo: {
        const copy = [...state.secondaryAddressTo];
        copy[action.index] = action.payload;
        return {
          ...state,
          secondaryAddressTo: [...copy],
        };
      }
      case actionTypes.RemoveQuoteSecondaryAddressesFrom: {
        const copy = [...state.secondaryAddressFrom];
        copy.splice(action.index, 1);

        return {
          ...state,
          secondaryAddressFrom: [...copy],
        };
      }
      case actionTypes.RemoveQuoteSecondaryAddressesTo: {
        const copy = [...state.secondaryAddressTo];
        copy.splice(action.index, 1);

        return {
          ...state,
          secondaryAddressTo: [...copy],
        };
      }
      case actionTypes.ResetState: {
        return {
          ...initialState,
        };
      }
      case actionTypes.ResetError: {
        if (state.error && state.error.props && state.error.props.data) {
          let newViolations = [];
          const customerErrors = state.error.props.data.violations.filter(item =>
            item.propertyPath.includes('customer'),
          );
          customerErrors.forEach(item => {
            const query = item.propertyPath.replace('customer.', '');
            if (state.customerForm[query] !== action.payload.customerForm[query]) {
              newViolations = [...state.error.props.data.violations];
              const index = newViolations.findIndex(nx => nx.propertyPath.includes(`customer.${query}`));
              newViolations.splice(index, 1);
            }
          });
          return {
            ...state,
            error: {
              ...state.error,
              props: {
                ...state.error.props,
                data: {
                  ...state.error.props.data,
                  violations: newViolations,
                },
              },
            },
          };
        }
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  },
);

export const actions = {
  save: data => ({ type: actionTypes.SetQuote, payload: data }),
  saveSecondaryAddressesFrom: data => ({
    type: actionTypes.SetQuoteSecondaryAddressesFrom,
    payload: data.value,
    index: data.index,
  }),
  saveSecondaryAddressesTo: data => ({
    type: actionTypes.SetQuoteSecondaryAddressesTo,
    payload: data.value,
    index: data.index,
  }),
  removeSecondaryAddressesFrom: data => ({
    type: actionTypes.RemoveQuoteSecondaryAddressesFrom,
    index: data.index,
  }),
  removeSecondaryAddressesTo: data => ({
    type: actionTypes.RemoveQuoteSecondaryAddressesTo,
    index: data.index,
  }),
  resetState: () => ({
    type: actionTypes.ResetState,
  }),
  resetError: data => ({
    type: actionTypes.ResetError,
    payload: data,
  }),
  setProject: project => ({
    type: actionTypes.SetProject,
    payload: project,
  }),
  erase: data => ({
    type: actionTypes.DeleteQuote,
    payload: data,
  }),
  saveRooms: data => ({
    type: actionTypes.SetRooms,
    payload: data,
  }),
  reset: () => ({
    type: actionTypes.ResetApp,
  }),
  saveForm: data => ({
    type: actionTypes.SaveForm,
    payload: data,
  }),
  setError: data => ({
    type: actionTypes.SetError,
    payload: data,
  }),
  loadProject: project => ({
    type: actionTypes.LoadProject,
    payload: project,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ResetApp, function* resetAppSaga() {
    yield put(FirstStepActions.reset());
    yield put(FirstStepActions.reset());
    yield put(actions.resetState());
  });

  yield takeLatest(actionTypes.SaveForm, function* saveFormSaga(action) {
    yield put(actions.resetError());
    yield put(actions.save(action.payload));
  });

  yield takeLatest(actionTypes.LoadProject, function* loadProjectSaga(action) {
    const project = action.payload;
    const roomTypes = yield RoomService.getRooms(project.tenantId, 1, 250);
    const addressFrom = yield project.addresses.find(
      address => address.primary === true && address.addressType === 'from',
    );
    const addressTo = yield project.addresses.find(address => address.primary === true && address.addressType === 'to');
    const mapAddress = address => ({
      fromBackend: true,
      addressId: address.addressId ?? '',
      addition: address.houseNumberAddition ?? '',
      city: address.city,
      country: address.country,
      distanceFromCar: address.distanceFromCar ?? '',
      distanceToApartment: address.distanceToApartment ?? '',
      hasElevator: address.hasElevator,
      floor: address.floor ?? '',
      houseNumber: address.houseNumber,
      houseNumberAddition: address.houseNumberAddition,
      municipality: address.municipality,
      province: address.province,
      streetName: address.streetName,
      type: address.buildingType,
      zipCode: address.zipCode,
      notes: address.notes,
    });

    const personName =
      project.customer['@type'] === 'BusinessRelation'
        ? project.customer.contactPersons[0]?.personalInformation.personName
        : project.customer.personalInformation.personName;
    const contactInformation =
      project.customer['@type'] === 'BusinessRelation'
        ? project.customer.contactPersons[0]?.primaryContactInformation
        : project.customer.primaryContactInformation;

    const rooms = [...roomTypes.items];
    const projectRoomsDefault = project.rooms || [];
    const inventoryItems = {};
    const furnitureByRoom = {};
    const servicesByRoom = {};
    const services = {};
    const images = {};

    yield roomTypes.items.forEach((room, index) => {
      const projectRooms = projectRoomsDefault.filter(pr => pr.roomType.roomTypeId === room.roomTypeId);
      if (projectRooms.length >= 1) {
        projectRooms.forEach((projectRoom, projectIndex) => {
          const quoteRoom = {
            ...room,
            selected: true,
            completed: true,
            roomId: projectRoom.roomId,
          };
          let currentIndex = index;
          if (projectIndex !== 0) {
            currentIndex = rooms.length + projectIndex - 1;
          }

          inventoryItems[`${room.roomTypeId}:${currentIndex}`] = projectRoom.inventoryItems;
          images[`${room.roomTypeId}:${currentIndex}`] = projectRoom.photos;

          const roomFurniture = [...room.furnitureType];
          const roomServices = {};
          projectRoom.furniture.forEach(selectedFurnitureItem => {
            const existingFurniture = roomFurniture.find(
              rf => selectedFurnitureItem.furnitureType.furnitureTypeId === rf.furnitureTypeId && rf.selected !== true,
            );

            let furnitureIndex = roomFurniture.length;

            if (existingFurniture) {
              furnitureIndex = roomFurniture.indexOf(existingFurniture);
              roomFurniture[furnitureIndex] = {
                ...existingFurniture,
                selected: true,
                furnitureId: selectedFurnitureItem.furnitureId,
                selectedVariant: selectedFurnitureItem.furnitureVariant,
              };
            } else {
              roomFurniture[roomFurniture.length] = {
                selected: true,
                furnitureId: selectedFurnitureItem.furnitureId,
                selectedVariant: selectedFurnitureItem.furnitureVariant,
                furnitureTypeId: selectedFurnitureItem.furnitureType.furnitureTypeId,
                cubicMeter: selectedFurnitureItem.furnitureType.cubicMeter,
                name: selectedFurnitureItem.furnitureType.furnitureTypeName,
                icon: selectedFurnitureItem.furnitureType.icon,
                value: selectedFurnitureItem.furnitureType.furnitureTypeId,
                label: selectedFurnitureItem.furnitureType.furnitureTypeName,
                servicesPossible: selectedFurnitureItem.furnitureType.servicesPossible,
                variants: selectedFurnitureItem.furnitureType.variants || [],
              };
            }

            selectedFurnitureItem.services.forEach(service => {
              if (
                typeof roomServices[`${selectedFurnitureItem.furnitureType.furnitureTypeId}:${furnitureIndex}`] ===
                'undefined'
              ) {
                roomServices[`${selectedFurnitureItem.furnitureType.furnitureTypeId}:${furnitureIndex}`] = {};
              }
              roomServices[`${selectedFurnitureItem.furnitureType.furnitureTypeId}:${furnitureIndex}`][
                service.serviceTypeId
              ] = true;
            });
          });

          servicesByRoom[`${room.roomTypeId}:${currentIndex}`] = roomServices;
          furnitureByRoom[`${room.roomTypeId}:${currentIndex}`] = roomFurniture;
          if (projectIndex !== 0) {
            rooms.push(quoteRoom);
          } else {
            rooms[index] = quoteRoom;
          }
        });
      }
    });

    const projectServices = project.services || [];

    const skipSteps = { assembleForm: true, disassembleForm: true };
    yield projectServices.forEach(service => {
      services[service.serviceTypeId] = true;
      if (service.internalType === 'assemble') {
        skipSteps.assembleForm = false;
      }

      if (service.internalType === 'disassemble') {
        skipSteps.disassembleForm = false;
      }
    });

    yield put(SecondStepActions.setSkip('assembleForm', skipSteps.assembleForm));
    yield put(SecondStepActions.setSkip('disassembleForm', skipSteps.disassembleForm));

    yield put(
      actions.setProject({
        customerId: project.customer.relationId,
        projectId: project.projectId,
        identityId: project.identityId,
        packageId: project.packageId,
        billingMoments: project.billingMoments,
        surchargeOrDiscount: project.surchargeOrDiscount,
        addressFrom: mapAddress(addressFrom),
        addressTo: mapAddress(addressTo),
        customerForm: {
          companyName: project.customer.companyInformation?.companyName ?? '',
          email: contactInformation?.emailAddress,
          phoneNumber: contactInformation?.phoneNumber,
          firstName: personName?.firstName,
          middleName: personName?.middleName,
          lastName: personName?.lastName,
        },
        note: project.notes,
        secondaryAddressFrom: project.addresses
          .filter(address => address.primary === false && address.addressType === 'from')
          .map(mapAddress),
        secondaryAddressTo: project.addresses
          .filter(address => address.primary === false && address.addressType === 'to')
          .map(mapAddress),
        movingDate: project.preferredMovingDate,
        rooms,
        inventoryItems,
        images,
        furnitureByRoom,
        servicesByRoom,
        services,
      }),
    );
  });

  yield takeLatest(actionTypes.SetError, function* setErrorSaga(action) {
    const { error } = action.payload;
    if (error.response && error.response.data && error.response.data.violations) {
      const customerErrors = error.response.data.violations.filter(item => item.propertyPath.includes('customer'));
      if (customerErrors.length > 0) {
        yield put(actions.save({ error: { type: 'CUSTOMER_VALIDATION_ERROR', props: error.response } }));

        yield put(FirstStepActions.setCustomerStep());
      } else {
        yield put(actions.save({ error: { props: error.response } }));
      }
    } else {
      yield put(actions.save({ error: { props: error.response } }));
    }
  });
}
