import { put, select, takeLatest } from 'redux-saga/effects';
import { CustomerService } from '../../../services';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';

export const actionTypes = {
  RequestData: '[Customers] RequestCustomers',
  FulfilledTable: '[Customers] FulfilledCustomers',
  SearchTable: '[Customers] Search customers',
  ChangePage: '[Customers] change page',
  SetPageSize: '[Customers] set page size',
  SortTable: '[Customers] Sort customers',
  ApplyFilter: '[Customers] Apply filter',
  RemoveFilter: '[Customers] Remove filter',
  ResetFilters: '[Customers] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestCustomersSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.CustomersReducer);

    const response = yield CustomerService.getCustomers(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
