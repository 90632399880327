import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import DumbTable from '../DumbTable';

const SmartReduxTable = ({ selectorFunction, ...props }) => {
  const state = useSelector(selectorFunction);
  let data = [];
  let loading = true;
  let totalCount = 0;
  let page = 1;
  let pageSize = 10;
  let sortBy = {};
  let searchQuery = '';
  let filters = {};

  if (state) {
    ({ items: data, loading, totalCount, page, pageSize, sortBy, searchQuery, filters } = state);
  }

  return (
    <DumbTable
      data={data}
      loading={loading}
      totalCount={totalCount}
      page={page}
      pageSize={pageSize}
      sortBy={sortBy}
      searchQuery={searchQuery}
      filters={filters}
      {...props}
    />
  );
};

SmartReduxTable.propTypes = {
  allChecked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checkedItems: PropTypes.array,
  hideColumns: PropTypes.array,
  defaultColumns: PropTypes.array,
  disableSortColumns: PropTypes.array,
  translationPrefix: PropTypes.string.isRequired,
};

SmartReduxTable.defaultProps = {
  allChecked: false,
  showCheckbox: false,
  checkedItems: [],
  hideColumns: [],
  defaultColumns: [],
  disableSortColumns: [],
};

export default SmartReduxTable;
