import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Field } from 'formik';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '30px',
  },
  cardActive: {
    width: '100%',
    border: '1px solid #3A78F2',
    color: '#3A78F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  icon: {
    color: '#000000',
    background: 'transparent',
    width: '100%',
  },
  iconActive: {
    color: '#3A78F2',
    background: 'transparent',
    width: '100%',
  },
  checkedCircle: {
    position: 'absolute',
    top: '5%',
    left: '5%',
  },
  counter: {
    position: 'absolute',
    top: '5%',
    right: '5%',
  },
  subMenu: {
    width: '100%',
    border: '1px solid black',
    padding: '10px',
    cursor: 'pointer',
  },
}));

const CheckBoxComponent = props => {
  const { name, value, icon, label, nameform, counter, variants } = props;
  const classes = useStyles();
  const [showOption, setShowOptions] = useState(false);

  const selectOption = (form, field) => {
    if (field.value.includes(value)) {
      const nextValue = field.value.filter(nxtV => nxtV !== value);
      form.setFieldValue(nameform, nextValue);
    } else {
      const nextValue = field.value.concat(value);
      form.setFieldValue(nameform, nextValue);
    }
  };

  const handleClick = (form, field, opts) => {
    if (opts.length > 0) {
      setShowOptions(true);
      return;
    }

    selectOption(form, field);
  };

  const handleSubOptionClick = (item, field, form) => {
    const a = field.value.find(nt => Object.keys(nt).indexOf(value) > -1);
    if (a) {
      const idx = field.value.indexOf(a);
      field.value.splice(idx, 1);
    }

    const customField = {
      ...field,
      value: [...field.value, { [value]: item.variantTypeId }, value],
    };
    selectOption(form, customField, value);
    setShowOptions(false);
  };

  const isActive = (val, field) => {
    let checkFlag = false;
    field.value.forEach(item => {
      if (Object.keys(item).indexOf(val) > -1) {
        checkFlag = true;
      }
      return checkFlag;
    });

    return !checkFlag ? field.value.includes(val) : checkFlag;
  };

  return (
    <Field name={nameform}>
      {({ field, form }) => (
        <Grid container>
          <Grid item xs={12}>
            <Card className={isActive(value, field) ? classes.cardActive : classes.card}>
              {isActive(value, field) && <CheckCircle className={classes.checkedCircle} />}
              {showOption && (
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={2}>
                    {variants.map(item => (
                      <Grid
                        className={classes.subMenu}
                        key={item.variantTypeId}
                        item
                        xs={6}
                        onClick={() => handleSubOptionClick(item, field, form)}
                      >
                        <Typography align="center">{item.variantTypeName}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              )}
              {!showOption && (
                <CardContent className={classes.cardContent} onClick={() => handleClick(form, field, variants)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Avatar
                        align="center"
                        className={isActive(value, field) ? classes.iconActive : classes.icon}
                        variant="rounded"
                      >
                        {icon}
                      </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="center">{label}</Typography>
                    </Grid>
                    {counter > 0 && <Typography className={classes.counter}>{counter}</Typography>}
                  </Grid>
                </CardContent>
              )}
            </Card>
            <input
              type="checkbox"
              style={{ visibility: 'hidden' }}
              {...props}
              value={nameform}
              name={nameform}
              checked={isActive(value, field)}
              onChange={() => {
                if (isActive(value, field)) {
                  const nextValue = field.value.filter(nxtV => nxtV !== value);
                  form.setFieldValue(name, nextValue);
                } else {
                  const nextValue = field.value.concat(value);
                  form.setFieldValue(name, nextValue);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </Field>
  );
};

CheckBoxComponent.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cubicMeter: PropTypes.number,
};

CheckBoxComponent.defaultProps = {
  label: '',
  icon: '',
  cubicMeter: 0,
};

export default CheckBoxComponent;
