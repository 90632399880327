import { Grid, makeStyles } from '@material-ui/core';

const mapStyle = {
  quote: 'quoteBox',
  filter: 'filterBox',
};

const useStyles = makeStyles(() => ({
  filterBox: {
    background: '#F2F3F8',
    borderRadius: '5px',
    padding: '30px',
    marginTop: '18px',
    marginBottom: '18px',
  },
  quoteBox: {
    background: '#FAFAFB',
    borderRadius: '5px',
    paddingBottom: '60px',
    paddingRight: '32px',
    paddingLeft: '32px',
    marginTop: '18px',
    marginBottom: '18px',
  },
}));

const BoxStyleComponent = ({ children, type }) => {
  const classes = useStyles();
  return (
    <Grid container item className={classes[mapStyle[type]]}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default BoxStyleComponent;
