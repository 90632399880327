import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getMovingJob } from '../Services/MovingJobService';
import { tableInitialState } from '../../App/Ducks/Table.duck';
import { getProjectCommunications } from '../../Customer/Services/CommunicationService';
import { newMercureMessage } from '../../App/Ducks/App.duck';
import CommunicationService from '../../App/Services/CommunicationService';
import { getHourEntriesByProjectId } from '../../MobileApp/Services/HoursEntryService';
import { InvoiceService } from '../../../services';

export const actionTypes = {
  ResetData: '[Project] Reset Project',
  RequestData: '[Project] Request Project',
  UnlockItem: '[Project] Unlock',
  LockItem: '[Project] Lock',
  FulfilledData: '[Project] Fulfilled Project',
  ChangePage: '[Project] ChangePage',
  SetPageSize: '[Project] SetPageSize',
  FulfilledCommunication: '[Project] FulfilledCommunication',
  NewCommunication: '[Project] NewCommunication',
  FulfilledEmailsData: '[Project] FulfilledEmailsData',
  FulfilledInvoicesData: '[Project] FulfilledInvoicesData',
  RequestInvoices: '[Project] RequestInvoices',
  FulfilledHourEntriesData: '[Project] FulfilledHourEntriesData',
};

const initialState = {
  ...singleObjectInitialState,
  communication: {
    ...tableInitialState,
    pageSize: 5,
  },
  loadingEmails: true,
  emails: [],
  loadingHourEntries: true,
  hourEntries: [],
  invoices: [],
  loadingInvoices: true,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.FulfilledCommunication: {
      return {
        ...state,
        communication: {
          ...state.communication,
          loading: false,
          loaded: true,
          items: action.items,
          totalCount: action.totalCount,
        },
      };
    }

    case actionTypes.FulfilledEmailsData: {
      return {
        ...state,
        loadingEmails: false,
        emails: action.emails,
      };
    }

    case actionTypes.FulfilledInvoicesData: {
      return {
        ...state,
        loadingInvoices: false,
        invoices: action.invoices,
      };
    }

    case actionTypes.FulfilledHourEntriesData: {
      return {
        ...state,
        loadingHourEntries: false,
        hourEntries: action.hourEntries,
      };
    }

    case actionTypes.NewCommunication: {
      const newItems = [action.payload.message, ...state.communication.items];
      if (newItems.length > 5) {
        newItems.pop();
      }

      return {
        ...state,
        communication: {
          ...state.communication,
          items: newItems,
          totalCount: state.communication.totalCount + 1,
        },
      };
    }

    case actionTypes.ChangePage: {
      return {
        ...state,
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          page: action.payload.page,
          loading: true,
        },
      };
    }

    case actionTypes.SetPageSize: {
      return {
        ...state,
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          pageSize: action.payload.pageSize,
          loading: true,
        },
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulfilledCommunication: (items, totalCount) => ({
    type: actionTypes.FulfilledCommunication,
    items,
    totalCount,
  }),
  newCommunication: message => ({
    type: actionTypes.NewCommunication,
    payload: { message },
  }),
  changePage: (dataKey, page) => ({
    type: actionTypes.ChangePage,
    payload: { dataKey, page },
  }),
  setPageSize: (dataKey, pageSize) => ({
    type: actionTypes.SetPageSize,
    payload: { dataKey, pageSize },
  }),
  fulfilledEmailsData: emails => ({
    type: actionTypes.FulfilledEmailsData,
    emails,
  }),
  fulfilledInvoicesData: invoices => ({
    type: actionTypes.FulfilledInvoicesData,
    invoices,
  }),
  requestInvoices: projectId => ({
    type: actionTypes.RequestInvoices,
    projectId,
  }),
  fulfilledHourEntriesData: hourEntries => ({
    type: actionTypes.FulfilledHourEntriesData,
    hourEntries,
  }),
};

export function* saga() {
  function* requestCommunication(action) {
    if (!action.itemId && action.payload.dataKey !== 'communication') {
      return;
    }

    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.ProjectReducer);

    const response = yield getProjectCommunications(
      currentTenantId,
      action.itemId ? action.itemId : currentState.item.projectId,
      currentState.communication?.page,
      currentState.communication?.pageSize,
      currentState.communication?.sortBy,
      currentState.communication?.filters,
    );

    yield put(actions.fulfilledCommunication(response.data['hydra:member'], response.data['hydra:totalItems']));
  }
  yield takeLatest(actionTypes.RequestData, function* requestProjectSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getMovingJob(currentTenantId, action.itemId);

    yield put(
      actions.fulfilledData(
        response.data,
        !['first-contact', 'intake', 'tuning', 'quote-declined', 'quotation'].includes(response.data.projectStatus),
      ),
    );
  });

  yield takeLatest(actionTypes.RequestData, requestCommunication);
  yield takeLatest(actionTypes.ChangePage, requestCommunication);
  yield takeLatest(actionTypes.SetPageSize, requestCommunication);

  yield takeLatest(actionTypes.RequestData, function* triggerLoadingInvoices(action) {
    const currentState = yield select(state => state.ProjectReducer);
    yield put(actions.requestInvoices(action.itemId ? action.itemId : currentState.item.projectId));
  });

  yield takeLatest(actionTypes.RequestData, function* requestEmails(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.ProjectReducer);

    const response = yield CommunicationService.getEmails(
      currentTenantId,
      1,
      2000,
      {},
      { projectId: action.itemId ? action.itemId : currentState.item.projectId },
    );

    yield put(actions.fulfilledEmailsData(response.data['hydra:member']));
  });

  yield takeLatest(actionTypes.RequestInvoices, function* requestInvoices(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield InvoiceService.getInvoices(
      currentTenantId,
      1,
      2000,
      {},
      { projectId: action.projectId },
      undefined,
      false,
    );

    yield put(actions.fulfilledInvoicesData(response.data['hydra:member']));
  });

  yield takeLatest(actionTypes.RequestData, function* requestHourEntries(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.ProjectReducer);

    const response = yield getHourEntriesByProjectId(
      currentTenantId,
      action.itemId ? action.itemId : currentState.item.projectId,
    );

    yield put(actions.fulfilledHourEntriesData(response.data['hydra:member']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Communication') {
      const { projectId } = yield select(state => state.ProjectReducer.item);
      if (action.data.identifiers.projectId === projectId) {
        yield put(actions.newCommunication(action.data));
      }
    }

    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Invoice') {
      const project = yield select(state => state.ProjectReducer.item);
      if (action.data.projectId === project?.projectId) {
        yield put(actions.requestInvoices(project?.projectId));
      }
    }
  });
}
