import { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  InputAdornment,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ResultTypoComponent } from 'components';
import Checkbox from '@material-ui/core/Checkbox';
import { RandomWidthSkeleton } from '../index';

const useStyles = makeStyles(theme => ({
  activeRow: {
    '& .MuiTableCell-body': {
      color: theme.palette.primary.main,
      // fontWeight: 'bold',
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      // fontWeight: 'bold',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  results: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}));

const DumbTable = ({
  showCheckbox,
  checkedItems,
  checkItem,
  checkAll,
  allChecked,
  translationPrefix,
  title,
  indexKey,
  selectedRow,
  hideColumns,
  defaultColumns,
  setSelectedRow,
  headerExtraOption,
  setPageSize,
  setPage,
  mapFunction,
  searchFunction,
  sortFunction,
  filterComponents,
  alignColumns,
  disableSortColumns,
  hideHeaders,
  styles,
  intl,
  hideResult,
  resetFilters,
  data,
  loading,
  totalCount,
  page,
  pageSize,
  sortBy,
  searchQuery,
  filters,
  fullWidthHeaderExtraOption,
  filterValueTransformer,
}) => {
  const classes = useStyles();
  const [openHeader, setOpenHeader] = useState(Boolean(searchQuery));
  const [openFilter, setOpenFilter] = useState(false);

  const items = data.map(mapFunction);

  const renderLoadingItems = () => {
    let numberOfLoadingItems = pageSize;
    if (totalCount > 0) {
      const itemsOnPage = totalCount - (page - 1) * pageSize;
      if (itemsOnPage <= 0) {
        numberOfLoadingItems = totalCount;
      } else if (itemsOnPage <= pageSize) {
        numberOfLoadingItems = itemsOnPage;
      }
    }

    const loadingCells = new Array(defaultColumns.length).fill(null).map((value, index) => (
      <TableCell key={index}>
        <RandomWidthSkeleton />
      </TableCell>
    ));

    return new Array(numberOfLoadingItems)
      .fill(null)
      .map((value, index) => <TableRow key={index}>{loadingCells}</TableRow>);
  };

  const renderColumns = () => {
    const columns = items && items.length > 0 ? Object.keys(items[0]) : defaultColumns;
    return columns.map(key => {
      if (hideColumns.includes(key)) {
        return null;
      }

      if (key === indexKey) {
        return (
          showCheckbox &&
          !loading && (
            <TableCell key={key}>
              <Checkbox
                checked={allChecked}
                onChange={event => checkAll(event.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </TableCell>
          )
        );
      }

      return (
        <TableCell key={key} style={styles?.column || { cursor: 'normal' }} sortDirection={sortBy[key] || false}>
          <TableSortLabel
            active={Boolean(sortBy[key])}
            direction={sortBy[key]}
            disabled={disableSortColumns.includes(key) || !sortFunction}
            onClick={() => {
              if (!disableSortColumns.includes(key)) {
                sortFunction(key, sortBy[key] === 'asc' ? 'desc' : 'asc');
              }
            }}
          >
            <FormattedMessage id={`${translationPrefix}.${key}`} />
          </TableSortLabel>
        </TableCell>
      );
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {title && (
          <h1>
            <FormattedMessage id={title} />
          </h1>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={fullWidthHeaderExtraOption ? 12 : 3}>
            {headerExtraOption}
          </Grid>
          <Grid item xs={fullWidthHeaderExtraOption ? 12 : 9}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid item xs={6} style={{ marginRight: '10px' }}>
                {searchFunction && (
                  <Slide direction="left" in={openHeader} mountOnEnter unmountOnExit>
                    <TextField
                      size="small"
                      onChange={event => searchFunction(event.target.value)}
                      placeholder={intl.formatMessage({ id: 'table.search' })}
                      fullWidth
                      value={searchQuery}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={['fal', 'search']} />
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                      style={{ display: 'inline-block' }}
                    />
                  </Slide>
                )}
              </Grid>
              <Grid item>
                {searchFunction && (
                  <Button className={classes.lowercase} onClick={() => setOpenHeader(!openHeader)}>
                    <FontAwesomeIcon size="1x" icon={['fal', 'search']} />
                    &nbsp;
                    <FormattedMessage id="table.search" />
                  </Button>
                )}
                {filterComponents && (
                  <Button className={classes.lowercase} onClick={() => setOpenFilter(!openFilter)}>
                    <FontAwesomeIcon size="1x" icon={['fal', 'cog']} />
                    &nbsp;
                    <FormattedMessage id="table.filter" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openFilter && filterComponents}
      {!hideResult && (
        <Grid container className={classes.results}>
          <Grid item xs={12}>
            <ResultTypoComponent
              total={totalCount}
              searchField={searchQuery}
              filters={filters}
              variant="h5"
              filterValueTransformer={filterValueTransformer}
              resetFilters={resetFilters}
            />
          </Grid>
        </Grid>
      )}
      <Table>
        {!hideHeaders && (
          <TableHead>
            <TableRow style={styles?.row || {}}>{renderColumns()}</TableRow>
          </TableHead>
        )}
        <TableBody>
          {loading && renderLoadingItems()}
          {!loading &&
            items &&
            items.map(item => (
              <TableRow
                key={item[indexKey]}
                className={clsx(
                  { [classes.activeRow]: selectedRow === item[indexKey] },
                  { [classes.clickable]: setSelectedRow },
                )}
                style={styles?.row || {}}
              >
                {showCheckbox && (
                  <TableCell>
                    <Checkbox
                      checked={allChecked || checkedItems.findIndex(({ dataId }) => dataId === item.dataId) !== -1}
                      onChange={event => checkItem(item, event.target.checked)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                )}
                {Object.keys(item).map(key => {
                  if (key === indexKey || hideColumns.includes(key)) {
                    return null;
                  }

                  return (
                    <TableCell
                      align={alignColumns && typeof alignColumns[key] !== 'undefined' ? alignColumns[key] : 'left'}
                      style={styles?.column || {}}
                      key={key}
                      onClick={setSelectedRow ? () => setSelectedRow(item) : undefined}
                    >
                      {item[key] ? item[key] : ''}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          {!loading && items.length === 0 && (
            <TableRow style={styles?.row || {}}>
              <TableCell colSpan={defaultColumns.length}>
                <FormattedMessage id="table.noResultsFound" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {setPage && setPageSize && (
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Pagination
                size="small"
                count={Math.ceil(totalCount / pageSize)}
                shape="rounded"
                showFirstButton
                showLastButton
                page={page}
                onChange={(e, pageIndex) => setPage(pageIndex)}
              />
            </Grid>
            <Grid item>
              <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={page - 1}
                labelRowsPerPage=""
                labelDisplayedRows={({ from, to, count }) => `resultaten per pagina, ${from} - ${to} van ${count}`}
                onChangePage={(e, pageIndex) => setPage(pageIndex + 1)}
                onChangeRowsPerPage={e => setPageSize(e.target.value)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                backIconButtonProps={{ style: { display: 'none' } }}
                nextIconButtonProps={{ style: { display: 'none' } }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DumbTable.propTypes = {
  allChecked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checkedItems: PropTypes.array,
  data: PropTypes.array,
  sortBy: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  searchQuery: PropTypes.string,
  filters: PropTypes.object,
  hideColumns: PropTypes.array,
  defaultColumns: PropTypes.array,
  disableSortColumns: PropTypes.array,
  translationPrefix: PropTypes.string.isRequired,
};

DumbTable.defaultProps = {
  allChecked: false,
  showCheckbox: false,
  loading: false,
  page: 1,
  pageSize: 1,
  sortBy: [],
  searchQuery: '',
  filters: {},
  data: [],
  checkedItems: [],
  hideColumns: [],
  defaultColumns: [],
  disableSortColumns: [],
};

export default injectIntl(DumbTable);
