import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { EmployeeService } from '../../../services';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getHourEntriesByEmployeeIdAndPeriod } from '../../MobileApp/Services/HoursEntryService';
import { newMercureMessage } from '../../App/Ducks/App.duck';

export const actionTypes = {
  ResetData: '[Employee] Reset Employee',
  RequestData: '[Employee] Request Employee',
  RequestHourEntries: '[Employee] RequestHourEntries',
  FulfilledHourEntries: '[Employee] FulfilledHourEntries',
  RetrievedHourEntry: '[Employee] RetrievedHourEntry',
  FulfilledData: '[Employee] Fulfilled Employee',
};

const initialState = {
  ...singleObjectInitialState,
  hourEntries: [],
  loadingHourEntries: true,
  loadedHourEntries: false,
  hourEntriesStartDate: null,
  hourEntriesEndDate: null,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestHourEntries: {
      return {
        ...state,
        hourEntries: [],
        loadingHourEntries: true,
        hourEntriesStartDate: action.payload.startDate,
        hourEntriesEndDate: action.payload.endDate,
      };
    }

    case actionTypes.FulfilledHourEntries: {
      return {
        ...state,
        hourEntries: action.payload,
        loadingHourEntries: false,
        loadHourEntries: true,
      };
    }

    case actionTypes.RetrievedHourEntry: {
      const hourEntries = [...state.hourEntries.filter(({ entryId }) => entryId !== action.payload.entryId)];
      hourEntries.push(action.payload);

      return {
        ...state,
        hourEntries,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  requestHourEntries: (employeeId, startDate, endDate) => ({
    type: actionTypes.RequestHourEntries,
    payload: {
      employeeId,
      startDate,
      endDate,
    },
  }),
  fulfilledHourEntries: hourEntries => ({
    type: actionTypes.FulfilledHourEntries,
    payload: hourEntries,
  }),
  retrievedHourEntry: hourEntry => ({
    type: actionTypes.RetrievedHourEntry,
    payload: hourEntry,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestEmployeeSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield EmployeeService.getEmployee(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeLatest(actionTypes.RequestHourEntries, function* invoke({ payload: { employeeId, startDate, endDate } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getHourEntriesByEmployeeIdAndPeriod(
      currentTenantId,
      employeeId,
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
    );

    yield put(actions.fulfilledHourEntries(response.data['hydra:member']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (
      typeof action.data['@type'] === 'string' &&
      [
        'ApprovedHoursEntry',
        'HolidayHoursEntry',
        'HoursEntry',
        'OfficeWorkHoursEntry',
        'ProjectHoursEntry',
        'SickHoursEntry',
        'WarehouseWorkHoursEntry',
      ].includes(action.data['@type'])
    ) {
      const { hourEntriesStartDate, hourEntriesEndDate } = yield select(state => state.EmployeeReducer);

      if (
        dayjs(action.data.startDate).isBetween(
          dayjs(hourEntriesStartDate).subtract(1, 'day'),
          dayjs(hourEntriesEndDate).add(1, 'day'),
          'day',
        )
      ) {
        yield put(actions.retrievedHourEntry(action.data));
      }
    }
  });
}
