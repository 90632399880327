import axios from 'axios';
import Qs from 'qs';

export function getMaterialTypes(tenantId) {
  return axios.get(`api/${tenantId}/settings/material-types`);
}

export function getRooms() {
  return axios.get(`api/settings/room-types`);
}

export function getRoom(roomTypeId) {
  return axios.get(`api/settings/room-types/${roomTypeId}`);
}

export function getAdminRooms(page = 1, perPage = 9999, order, filters = {}, globalFilter) {
  return axios.get(`api/admin/settings/room-types`, {
    params: {
      ...filters,
      page,
      perPage,
      order,
      globalFilter: globalFilter !== '' ? globalFilter : undefined,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const addNewRoom = values => axios.post(`api/settings/room-types`, { ...values });
export const updateRoom = (roomTypeId, values) =>
  axios.put(`api/settings/room-types/${roomTypeId}/update`, { ...values, roomTypeId });

export const addNewFurniture = values => axios.post(`api/settings/furniture-types`, { ...values });
export const updateFurniture = (furnitureTypeId, values) =>
  axios.put(`api/settings/furniture-types/${furnitureTypeId}/update`, { ...values, furnitureTypeId });

export function getAdminRoom(roomTypeId) {
  return axios.get(`api/admin/settings/room-types/${roomTypeId}`);
}

export function getAdminFurniture(page = 1, perPage = 9999, order, filters = {}, globalFilter) {
  return axios.get(`api/admin/settings/furniture-types`, {
    params: {
      ...filters,
      page,
      perPage,
      order,
      globalFilter: globalFilter !== '' ? globalFilter : undefined,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export function getAdminFurnitureType(furnitureTypeId) {
  return axios.get(`api/admin/settings/furniture-types/${furnitureTypeId}`);
}

export function getFurniture() {
  return axios.get(`api/settings/furniture-types`, {
    params: {
      perPage: 99999,
    },
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
  });
}
