import axios from 'axios';

const validVatNumbers = [];

const validateVatNumber = async vatNumber => {
  if (validVatNumbers.includes(vatNumber)) {
    return true;
  }

  try {
    await axios.post(`api/validate-vat-number`, {
      vatNumber,
    });
    validVatNumbers.push(vatNumber);

    return true;
  } catch (error) {
    return false;
  }
};

export default validateVatNumber;
