import { put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { addFurniture, addRoom, getMovingJobs, removeFurniture, removeRoom } from '../Services/MovingJobService';

export const actionTypes = {
  ResetMovingJobs: '[MovingJob] ResetMovingJobs',
  RequestMovingJobs: '[MovingJob] RequestMovingJobs',
  FulfilledMovingJobs: '[MovingJob] FulfilledMovingJobs',
  SearchMovingJobs: '[MovingJob] Search projects',
  ChangePage: '[MovingJob] change page',
  SetPageSize: '[MovingJob] set page size',
  SortMovingJobs: '[MovingJob] Sort projects',
  AddFurniture: '[MovingJob] Add Furniture',
  RemoveFurniture: '[MovingJob] Remove Furniture',
  AddRoom: '[MovingJob] Add Room',
  RemoveRoom: '[MovingJob] Remove Room',
  ApplyFilter: '[MovingJob] Apply filter',
  RemoveFilter: '[MovingJob] Remove filter',
  UpdateMovingJob: '[MovingJob] Update Moving Job',
  ResetFilters: '[MovingJob] Reset filter',
};

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  page: 1,
  pageSize: 10,
  totalCount: 0,
  sortBy: {
    nextActionDate: 'asc',
  },
  filters: {
    zipCodeType: 'both',
    projectStatus: [
      'first-contact',
      'intake',
      'quotation',
      'quote-sent',
      'quote-declined',
      'tuning',
      'quote-accepted',
      'awaiting-deposit',
      'received-deposit',
      'planned',
      'deliver-boxes',
      'in-storage',
      'carry-out',
      'aftercare',
      'pick-up-boxes',
    ],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ResetMovingJobs: {
      return initialState;
    }

    case actionTypes.FulfilledMovingJobs: {
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.projects,
        totalCount: action.totalCount,
      };
    }

    case actionTypes.RequestMovingJobs: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.SearchMovingJobs: {
      return {
        ...state,
        loading: true,
        searchQuery: action.searchQuery,
      };
    }

    case actionTypes.ChangePage: {
      return {
        ...state,
        loading: true,
        page: action.page,
      };
    }

    case actionTypes.SetPageSize: {
      return {
        ...state,
        loading: true,
        pageSize: action.pageSize,
      };
    }

    case actionTypes.SortMovingJobs: {
      return {
        ...state,
        loading: true,
        sortBy: action.sortBy,
      };
    }

    case actionTypes.AddFurniture: {
      return {
        ...state,
      };
    }

    case actionTypes.RemoveFurniture: {
      return {
        ...state,
      };
    }

    case actionTypes.AddRoom: {
      return {
        ...state,
      };
    }

    case actionTypes.RemoveRoom: {
      return {
        ...state,
      };
    }

    case actionTypes.UpdateMovingJob: {
      if (!action.index) {
        return {
          ...state,
        };
      }
      const newItems = [...state.items];
      newItems[action.index] = action.project;

      return {
        ...state,
        items: newItems,
      };
    }

    case actionTypes.ApplyFilter: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    }

    case actionTypes.RemoveFilter: {
      const { filters } = state;
      delete filters[action.key];

      return {
        ...state,
        filters,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  resetMovingJobs: () => ({ type: actionTypes.ResetMovingJobs }),
  setPageSize: pageSize => ({ type: actionTypes.SetPageSize, pageSize }),
  sortMovingJobs: sortBy => ({ type: actionTypes.SortMovingJobs, sortBy }),
  changePage: page => ({ type: actionTypes.ChangePage, page }),
  applyFilter: (key, value) => ({ type: actionTypes.ApplyFilter, key, value }),
  removeFilter: key => ({ type: actionTypes.RemoveFilter, key }),
  searchMovingJobs: searchQuery => ({
    type: actionTypes.SearchMovingJobs,
    searchQuery,
  }),
  requestMovingJobs: () => ({ type: actionTypes.RequestMovingJobs }),
  updateMovingJob: (project, index) => ({
    type: actionTypes.UpdateMovingJob,
    project,
    index,
  }),
  fulfilledMovingJobs: (projects, totalCount) => ({
    type: actionTypes.FulfilledMovingJobs,
    projects,
    totalCount,
  }),
  sort: (sort, order) => ({ type: actionTypes.RequestMovingJobs, sort, order }),
  addFurniture: (projectId, furniture) => ({
    type: actionTypes.AddFurniture,
    furniture,
    projectId,
  }),
  removeFurniture: (projectId, furniture) => ({
    type: actionTypes.RemoveFurniture,
    furniture,
    projectId,
  }),
  addRoom: (projectId, room) => ({
    type: actionTypes.AddRoom,
    projectId,
    room,
  }),
  removeRoom: (projectId, roomId) => ({
    type: actionTypes.RemoveRoom,
    projectId,
    roomId,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.SearchMovingJobs, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });
  yield takeLatest(actionTypes.ChangePage, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });
  yield takeLatest(actionTypes.SetPageSize, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });
  yield takeLatest(actionTypes.SortMovingJobs, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });
  yield takeLatest(actionTypes.ApplyFilter, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });
  yield takeLatest(actionTypes.RemoveFilter, function* reloadMovingJobs() {
    yield put(actions.requestMovingJobs());
  });

  yield takeLatest(actionTypes.UpdateMovingJob, function* reloadMovingJobs(action) {
    if (!action.index) {
      const items = yield select(state => state.MovingJobReducer.items);

      const index = yield items.findIndex(project => project.projectId === action.project.projectId);

      if (index) {
        yield put(actions.updateMovingJob(action.project, index));
      } else {
        yield put(actions.requestMovingJobs());
      }
    }
  });

  yield takeLatest(actionTypes.AddFurniture, function* reloadMovingJobs(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const { type, ...content } = action;

    yield addFurniture(currentTenantId, content.projectId, {
      ...content.furniture,
      furnitureId: uuidv4(),
      projectId: content.projectId,
    });
  });
  yield takeLatest(actionTypes.RemoveFurniture, function* reloadMovingJobs(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const { type, ...content } = action;

    yield removeFurniture(currentTenantId, content.projectId, {
      ...content.furniture,
    });
  });

  yield takeLatest(actionTypes.AddRoom, function* reloadRooms(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    yield addRoom(currentTenantId, action.projectId, {
      roomTypeId: action.room.roomTypeId,
      projectId: action.projectId,
      furnitureTypeId: null,
      furnitureVariantId: null,
      services: null,
      furnitureId: null,
      inventoryItems: {},
      notes: null,
      photos: [],
      furniture: [],
    });
  });

  yield takeLatest(actionTypes.RemoveRoom, function* reloadRooms(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    yield removeRoom(currentTenantId, action.projectId, {
      roomId: action.roomId,
      projectId: action.projectId,
      furnitureTypeId: null,
      furnitureVariantId: null,
      services: null,
      furnitureId: null,
    });
  });

  yield takeLatest(actionTypes.RequestMovingJobs, function* requestMovingJobsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.MovingJobReducer);

    const response = yield getMovingJobs(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    const projects = response.data['hydra:member'];

    yield put(actions.fulfilledMovingJobs(projects, response.data['hydra:totalItems']));
  });
}
