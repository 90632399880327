import { put, select, takeLatest } from 'redux-saga/effects';
import { CustomerService, InvoiceService, QuoteService } from '../../../services';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';

export const actionTypes = {
  ResetData: '[Customer] Reset Customer',
  RequestData: '[Customer] Request Customer',
  FulfilledData: '[Customer] Fulfilled Customer',
  FulfilledQuotes: '[Customer] Fulfilled Quotes',
  FulfilledInvoices: '[Customer] Fulfilled Invoices',
};

const initialState = {
  ...singleObjectInitialState,
  quotes: [],
  quotesLoading: true,
  invoices: [],
  invoicesLoading: true,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...newState,
        quotesLoading: true,
        invoicesLoading: true,
      };
    }

    case actionTypes.FulfilledQuotes: {
      return {
        ...newState,
        quotesLoading: false,
        quotes: action.quotes,
      };
    }

    case actionTypes.FulfilledInvoices: {
      return {
        ...newState,
        invoicesLoading: false,
        invoices: action.invoices,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulFilledQuotes: quotes => ({
    type: actionTypes.FulfilledQuotes,
    quotes,
  }),
  fulFilledInvoices: invoices => ({
    type: actionTypes.FulfilledInvoices,
    invoices,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestCustomerSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield CustomerService.getCustomer(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeLatest(actionTypes.RequestData, function* requestQuotesSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield QuoteService.getRelationQuotes(currentTenantId, action.itemId);

    yield put(actions.fulFilledQuotes(response.result['hydra:member']));
  });

  yield takeLatest(actionTypes.RequestData, function* requestInvoiceSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield InvoiceService.getRelationInvoices(currentTenantId, action.itemId);

    yield put(actions.fulFilledInvoices(response.result['hydra:member']));
  });
}
