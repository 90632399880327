import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { ButtonComponent } from '../index';

const HandleLoadingButton = ({ buttonProps, forceLoading, onClick, hideChildrenOnLoading, children, disabled }) => {
  const [loading, setLoading] = useState(false);
  const showChildren = !loading || !hideChildrenOnLoading;

  return (
    <ButtonComponent
      {...buttonProps}
      loading={forceLoading || loading}
      disabled={forceLoading || disabled}
      onClick={async event => {
        if (!loading) {
          setLoading(true);
          await onClick(event);
          setLoading(false);
        }
      }}
    >
      {showChildren && children}
    </ButtonComponent>
  );
};

HandleLoadingButton.propTypes = {
  hideChildrenOnLoading: PropTypes.bool,
  forceLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  buttonProps: PropTypes.object.isRequired,
};

HandleLoadingButton.defaultProps = {
  hideChildrenOnLoading: false,
  forceLoading: false,
};

export default HandleLoadingButton;
