import axios from 'axios';
import Qs from 'qs';

let userToken;
export const getUsers = (page, perPage, order, filters = {}, globalFilter) => {
  if (typeof userToken !== typeof undefined) {
    userToken.cancel('Operation canceled due to new request.');
  }
  userToken = axios.CancelToken.source();
  try {
    return axios.get(`api/users`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: userToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const getUser = userId => axios.get(`api/users/${userId}`);
export const getEmployeesByUserId = userId => axios.get(`api/admin/${userId}/hrm/employees`);
export const adminChangeUserPassword = (userId, values) =>
  axios.put(`api/users/${userId}/admin-change-password`, {
    ...values,
    userId,
  });
