import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { AddFurnitureComponent, AddMoreCardComponent, IconCardComponent } from 'components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import AddCustomFurnitureComponent from '../../components/AddCustomFurnitureComponent';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: '#ffffff',
  },
  searchInput: {
    background: '#ffffff',
    color: '#495057',
    borderRadius: '5px',
  },
  paddingBottom: {
    paddingBottom: '100px',
  },
}));

const FurnitureForm = ({ intl }) => {
  const dispatch = useDispatch();
  const furnitureTypes = useSelector(state => state.MovingJobEstimateReducer.loadedInformation.furnitureTypes);
  const selectedRoom = useSelector(state => state.SecondStepReducer.propSelected);
  const roomKey = `${selectedRoom.value}:${selectedRoom.index}`;
  const roomFurnitureList = useSelector(state => state.SecondStepReducer.roomFurnitureList);
  const furnitureByRoom = useSelector(state => state.MovingJobEstimateReducer.furnitureByRoom);
  const furnitureListSaved = furnitureByRoom[roomKey] ?? [];

  const [furnitureList, setFurnitureList] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const classes = useStyles();

  const select = (check, changes = {}) => {
    const indexSelected = furnitureList.items.indexOf(check);
    const furnitureItems = [...furnitureList.items];

    furnitureItems[indexSelected] = {
      ...check,
      selected: !check.selected,
      ...changes,
    };

    setFurnitureList({ ...furnitureList, items: furnitureItems });
  };

  const addItem = (addedItem, selectedVariant = null, index) => {
    const furnitureItems = [...furnitureList.items];
    if (index >= 0) {
      furnitureItems[index] = { ...addedItem, selected: true, selectedVariant };
    } else {
      furnitureItems.push({ ...addedItem, selected: true, selectedVariant });
    }

    setFurnitureList({ ...furnitureList, items: furnitureItems });
    setOpenDialog(false);
  };

  const addCustomItem = values => {
    const furnitureItems = [...furnitureList.items];
    furnitureItems.push({ ...values, icon: 'question-circle', selected: true });

    setFurnitureList({ ...furnitureList, items: furnitureItems });
    setOpenCustomDialog(false);
  };

  useEffect(() => {
    if (furnitureListSaved.length > 0) {
      setFurnitureList({
        total: furnitureListSaved.length,
        items: [...furnitureListSaved],
      });
    } else {
      setFurnitureList({
        total: roomFurnitureList.length,
        items: [...roomFurnitureList],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (furnitureList) {
      dispatch(
        MovingJobEstimateActions.save({
          furnitureByRoom: { ...furnitureByRoom, [roomKey]: furnitureList.items },
        }),
      );

      // const selectedFurniture = furnitureList.items.filter(item => item.selected);
      // if (selectedFurniture.length > 0) {
      //   dispatch(SecondStepActions.filledForm(true));
      // } else {
      //   dispatch(SecondStepActions.filledForm(false));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [furnitureList]);

  return (
    <>
      <Grid container className={classes.paddingBottom} spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" align="center" className={classes.whiteText}>
            <FormattedMessage id="room.furniture.which_furniture_is_in_the_room" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paddingBottom}>
          <Grid container spacing={2}>
            {furnitureList &&
              furnitureList.items.length > 0 &&
              furnitureList.items.map((item, index) => (
                <Grid item xs={6} sm={4} key={`${item.value}${index}`}>
                  <IconCardComponent
                    item={item}
                    selectable
                    clickFunction={insItem => select(insItem)}
                    variantsFunction={(insItem, variant) => addItem(insItem, variant, index)}
                  />
                </Grid>
              ))}
            <Grid item xs={6} sm={4}>
              <IconCardComponent
                item={{ label: intl.formatMessage({ id: 'room.furniture.custom' }), icon: 'question-circle' }}
                selectable
                clickFunction={() => setOpenCustomDialog(true)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <AddMoreCardComponent
                inverted
                label={<FormattedMessage id="room.furniture.add_furniture" />}
                clickFunction={() => {
                  setOpenDialog(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddFurnitureComponent
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        furniture={furnitureTypes}
        handleAddItem={addItem}
      />
      <AddCustomFurnitureComponent
        handleClose={() => setOpenCustomDialog(false)}
        open={openCustomDialog}
        handleAddItem={addCustomItem}
      />
    </>
  );
};

export default injectIntl(FurnitureForm);
