import { useState } from 'react';
import {
  AppBar,
  Avatar,
  Backdrop,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Gravatar from 'react-gravatar';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { NavLink, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import isAdminApplication from '../../utils/isAdminApplication';

const menuItems = [
  {
    icon: ['fal', 'tachometer-alt-fastest'],
    page: '/',
    translate: 'menu.item.dashboard',
    exact: true,
  },
  {
    translate: 'menu.item.projects',
    icon: ['fal', 'truck-couch'],
    page: '/projects',
  },
  {
    translate: 'menu.item.customers',
    icon: ['fal', 'users-crown'],
    page: '/customers',
  },
  {
    translate: 'menu.item.resources',
    icon: ['fal', 'people-carry'],
    page: '/resources',
  },
  {
    translate: 'menu.item.planning',
    icon: ['fal', 'calendar'],
    page: '/planning',
  },
  {
    translate: 'menu.item.finance',
    icon: ['fal', 'coins'],
    page: '/financial',
  },
  {
    translate: 'menu.item.chat',
    // roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    page: '/communication/conversations',
    icon: ['fal', 'comment-alt'],
  },
  {
    translate: 'menu.item.settings',
    // roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    roles: ['ROLE_NOPE'],
    page: '/settings',
    icon: ['fal', 'cog'],
  },
  {
    translate: 'menu.item.admin',
    // roles: ['ROLE_SUPER_ADMIN'],
    roles: ['ROLE_NOPE'],
    page: '/admin',
    icon: ['fal', 'user-ninja'],
  },
];

const adminMenuItems = [
  {
    icon: ['fal', 'tachometer-alt-fastest'],
    roles: ['ROLE_SUPER_ADMIN'],
    page: '/',
    translate: 'menu.item.dashboard',
    exact: true,
  },
  {
    translate: 'menu.item.tenants',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: ['fal', 'book-user'],
    page: '/tenants',
  },
  {
    translate: 'menu.item.automatedEmails',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: ['fal', 'mail-bulk'],
    page: '/automated-emails',
  },
  {
    translate: 'menu.item.users',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: ['fal', 'users'],
    page: '/users',
  },
  {
    translate: 'menu.item.furniture',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: ['fal', 'alicorn'],
    page: '/furniture',
  },
  {
    translate: 'menu.item.rooms',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: ['fal', 'atlas'],
    page: '/rooms',
  },
];

const drawerWidth = 88;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    background: theme.palette.sidebar.background,
    width: drawerWidth,
    overflowX: 'hidden',
  },
  appBar: {
    background: theme.palette.sidebar.background,
    color: theme.palette.sidebar.color,
  },
  menuButton: {
    color: theme.palette.sidebar.color,
  },
  appButton: {
    color: theme.palette.sidebar.color,
  },
  brandLogo: {
    maxWidth: 80,
    maxHeight: 65,
  },
  brand: {
    color: theme.palette.sidebar.color,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  brandQuery: {
    color: theme.palette.sidebar.color,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    paddingBottom: '39.75px',
  },
  item: {
    paddingBottom: '10px',
  },
  menuItem: {
    textAlign: 'center',
    color: theme.palette.sidebar.color,
    textDecoration: 'none',
    fontSize: '0.8rem',
    width: '100%',
  },
  activeMenuItem: {
    textAlign: 'center',
    color: theme.palette.sidebar.activeColor,
  },
  menuIcon: {
    minWidth: '0',
    fontSize: '1.8rem',
    marginBottom: '10px',
  },
  smallerIcon: {
    minWidth: '0',
    fontSize: '1.4rem',
    marginBottom: '10px',
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: '28px',
  },
  avatar: {
    margin: '0 auto',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  offset: {
    marginTop: 64,
    maxWidth: '100vw',
    width: '100vw',
  },
  fullWidth: {
    width: '100%',
  },
  maxHeight: {
    maxHeight: 'calc(100vh - 64px)',
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SidebarLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(state => state.AppReducer.user);
  const query = useMediaQuery('(min-height:1087px)');
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isSmallerMenu = useMediaQuery('(max-height:768px)');
  const tooSmallMenu = useMediaQuery('(max-height:700px)');

  const [menuOpen, setMenuOpen] = useState(false);

  const filterOnRoles = menuItem => {
    if (menuItem.roles) {
      let hide = true;
      menuItem.roles.some(role => {
        if (user.roles.includes(role)) {
          hide = false;

          return true;
        }
        return false;
      });

      return !hide;
    }

    return true;
  };

  const menu = isAdminApplication ? adminMenuItems : menuItems;
  const sidebarMenu = (
    <>
      <Typography variant="h3" className={query ? classes.brand : classes.brandQuery}>
        Bas.
      </Typography>
      <Divider />
      <List>
        {menu
          .slice()
          .filter(filterOnRoles)
          .map(menuItem => (
            <ListItem key={menuItem.translate} className={query ? classes.listItem : classes.item} disableGutters>
              <NavLink
                exact={menuItem.exact}
                to={menuItem.page}
                activeClassName={classes.activeMenuItem}
                className={classes.menuItem}
              >
                <Grid container alignContent="center">
                  <Grid xs={12} item>
                    <FontAwesomeIcon
                      icon={menuItem.icon}
                      className={clsx(classes.menuIcon, { [classes.smallerIcon]: isSmallerMenu })}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <FormattedMessage id={menuItem.translate} />
                  </Grid>
                </Grid>
              </NavLink>
            </ListItem>
          ))}
        {isSmallerMenu && (
          <ListItem className={query ? classes.listItem : classes.item} disableGutters>
            <NavLink to="/settings" activeClassName={classes.activeMenuItem} className={classes.menuItem}>
              <Grid container alignContent="center">
                <Grid xs={12} item>
                  <FontAwesomeIcon
                    icon={['fal', 'cog']}
                    className={clsx(classes.menuIcon, { [classes.smallerIcon]: isSmallerMenu })}
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormattedMessage id="menu.item.settings" />
                </Grid>
              </Grid>
            </NavLink>
          </ListItem>
        )}
        {tooSmallMenu && (
          <ListItem className={query ? classes.listItem : classes.item} disableGutters>
            <NavLink to="/user" activeClassName={classes.activeMenuItem} className={classes.menuItem}>
              {user.profilePictureUrl && (
                <Avatar alt={user.personName.fullName} src={user.profilePictureUrl} className={classes.avatar} />
              )}
              {!user.profilePictureUrl && (
                <Avatar className={classes.avatar}>
                  <Gravatar email={user.emailAddress} rating="pg" default="monsterid" size={56} />
                </Avatar>
              )}
            </NavLink>
          </ListItem>
        )}
      </List>
      <Divider />
      <div className={classes.footer} hidden={tooSmallMenu}>
        <Grid container alignContent="center" spacing={5}>
          <Grid item xs={12} hidden={isSmallerMenu}>
            {!isAdminApplication && (
              <NavLink to="/settings" activeClassName={classes.activeMenuItem} className={classes.menuItem}>
                <Grid container alignContent="center">
                  <Grid xs={12} item>
                    <FontAwesomeIcon
                      icon={['fal', 'cog']}
                      className={clsx(classes.menuIcon, { [classes.smallerIcon]: isSmallerMenu })}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <FormattedMessage id="menu.item.settings" />
                  </Grid>
                </Grid>
              </NavLink>
            )}
          </Grid>
          <Grid item xs={12}>
            <NavLink to="/user" activeClassName={classes.activeMenuItem} className={classes.menuItem}>
              {user.profilePictureUrl && (
                <Avatar alt={user.personName.fullName} src={user.profilePictureUrl} className={classes.avatar} />
              )}
              {!user.profilePictureUrl && (
                <Avatar className={classes.avatar}>
                  <Gravatar email={user.emailAddress} rating="pg" default="monsterid" size={56} />
                </Avatar>
              )}
            </NavLink>
          </Grid>
        </Grid>
      </div>
    </>
  );

  return (
    <div className={classes.root}>
      {isTablet && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <IconButton
              edge="start"
              aria-label="menu"
              className={classes.menuButton}
              onClick={() => setMenuOpen(c => !c)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h3" className={query ? classes.brand : classes.brandQuery}>
              Bas
            </Typography>
            <IconButton className={classes.appButton} onClick={() => history.push('/app')}>
              <FontAwesomeIcon icon={['fal', 'mobile']} />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        hidden={isTablet}
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        {sidebarMenu}
      </Drawer>
      <Backdrop className={classes.backdrop} open={isTablet && menuOpen} onClick={() => setMenuOpen(false)}>
        <Drawer
          hidden={isTablet ? !menuOpen : false}
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          {sidebarMenu}
        </Drawer>
      </Backdrop>
      <div
        className={clsx(
          { [classes.fullWidth]: !isTablet },
          { [classes.offset]: isTablet },
          { [classes.maxHeight]: isTablet && menuOpen },
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SidebarLayout;
