import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { InvoiceService, TenantService } from '../../../services';
import { tableInitialState } from './Table.duck';
import { getDashboardMovingJobs } from '../../Project/Services/MovingJobService';
import { getQuotes } from '../../Financial/Services/QuotesService';
import { newMercureMessage } from './App.duck';
import { getBatchData, getIntegrations } from '../Services/IntegrationService';

dayjs.extend(isBetween);

export const actionTypes = {
  SetMovingJobTab: '[Dashboard] SetMovingJobTab',
  SetQuoteTab: '[Dashboard] SetQuoteTab',
  SetInvoiceTab: '[Dashboard] SetInvoiceTab',
  RequestStatistics: '[Dashboard] RequestStatistics',
  SetPeriodStatistics: '[Dashboard] SetPeriodStatistics',
  SetDayStatistics: '[Dashboard] SetDayStatistics',
  RequestCertificateIntegration: '[Dashboard] RequestCertificateIntegration',
  SetCertificateIntegration: '[Dashboard] SetCertificateIntegration',
  ChangePage: '[Dashboard] ChangePage',
  SetPageSize: '[Dashboard] SetPageSize',
  RequestTabsData: '[Dashboard] RequestTabsData',
  RequestProjectsWithAcceptedQuotes: '[Dashboard] RequestProjectsWithAcceptedQuotes',
  SetProjectsWithAcceptedQuotes: '[Dashboard] SetProjectsWithAcceptedQuotes',
  RequestProjectsAwaitingBoxesPickup: '[Dashboard] RequestProjectsAwaitingBoxesPickup',
  SetProjectsAwaitingBoxesPickup: '[Dashboard] SetProjectsAwaitingBoxesPickup',
  RequestProjectsAwaitingMovingCertificate: '[Dashboard] RequestProjectsAwaitingMovingCertificate',
  SetProjectsAwaitingMovingCertificate: '[Dashboard] SetProjectsAwaitingMovingCertificate',
  RequestProjectsAwaitingAftercare: '[Dashboard] RequestProjectsAwaitingAftercare',
  SetProjectsAwaitingAftercare: '[Dashboard] SetProjectsAwaitingAftercare',
  RequestProjectsAwaitingTaxationEvent: '[Dashboard] RequestProjectsAwaitingTaxationEvent',
  SetProjectsAwaitingTaxationEvent: '[Dashboard] SetProjectsAwaitingTaxationEvent',
  RequestQuotesWaitingForAcceptance: '[Dashboard] RequestQuotesWaitingForAcceptance',
  SetQuotesWaitingForAcceptance: '[Dashboard] SetQuotesWaitingForAcceptance',
  RequestQuotesToMake: '[Dashboard] RequestQuotesToMake',
  SetQuotesToMake: '[Dashboard] SetQuotesToMake',
  RequestQuotesToSend: '[Dashboard] RequestQuotesToSend',
  SetQuotesToSend: '[Dashboard] SetQuotesToSend',
  RequestDeclinedQuotes: '[Dashboard] RequestDeclinedQuotes',
  SetDeclinedQuotes: '[Dashboard] SetDeclinedQuotes',
  RequestRemindedQuotes: '[Dashboard] RequestRemindedQuotes',
  SetRemindedQuotes: '[Dashboard] SetRemindedQuotes',
  RequestInvoicesToSend: '[Dashboard] RequestInvoicesToSend',
  SetInvoicesToSend: '[Dashboard] SetInvoicesToSend',
  RequestOpenInvoices: '[Dashboard] RequestOpenInvoices',
  SetOpenInvoices: '[Dashboard] SetOpenInvoices',
  RequestOverdueInvoices: '[Dashboard] RequestOverdueInvoices',
  SetOverdueInvoices: '[Dashboard] SetOverdueInvoices',
};

export const movingJobTabs = {
  toPlan: {
    name: 'toPlan',
    requestActionType: actionTypes.RequestProjectsWithAcceptedQuotes,
    setActionType: actionTypes.SetProjectsWithAcceptedQuotes,
    filters: { projectStatus: ['quote-accepted', 'received-deposit'] },
    dataKey: 'projectsWithAcceptedQuotes',
    dataType: 'project',
  },
  boxesToPickup: {
    name: 'boxesToPickup',
    requestActionType: actionTypes.RequestProjectsAwaitingBoxesPickup,
    setActionType: actionTypes.SetProjectsAwaitingBoxesPickup,
    filters: { projectStatus: ['aftercare'], shouldPlanPickupBoxes: true },
    dataKey: 'projectsAwaitingBoxesPickup',
    dataType: 'project',
  },
  movingCertificate: {
    name: 'movingCertificateRequests',
    requestActionType: actionTypes.RequestProjectsAwaitingMovingCertificate,
    setActionType: actionTypes.SetProjectsAwaitingMovingCertificate,
    filters: {
      'syncStatus.value': ['awaiting-approval', 'pending', 'in-progress', 'failed', 'success'],
      finished: false,
    },
    dataKey: 'projectsNeedingCertificates',
    dataType: 'certificate',
  },
  toFinish: {
    name: 'toFinish',
    requestActionType: actionTypes.RequestProjectsAwaitingAftercare,
    setActionType: actionTypes.SetProjectsAwaitingAftercare,
    filters: { projectStatus: ['aftercare'] },
    dataKey: 'projectsAwaitingAftercare',
    dataType: 'project',
  },
  firstContact: {
    name: 'firstContact',
    requestActionType: actionTypes.RequestProjectsAwaitingTaxationEvent,
    setActionType: actionTypes.SetProjectsAwaitingTaxationEvent,
    filters: { projectStatus: ['first-contact'], shouldPlanPickupBoxes: false },
    dataKey: 'projectsAwaitingTaxationEvent',
    dataType: 'project',
  },
};

export const quoteTabs = {
  quotesWaitingForAcceptance: {
    name: 'quotesWaitingForAcceptance',
    requestActionType: actionTypes.RequestQuotesWaitingForAcceptance,
    setActionType: actionTypes.SetQuotesWaitingForAcceptance,
    filters: { quoteStatus: ['sent', 'expired', 'reminded'] },
    dataKey: 'quotesWaitingForAcceptance',
    dataType: 'quote',
  },
  quotesToMake: {
    name: 'quotesToMake',
    requestActionType: actionTypes.RequestQuotesToMake,
    setActionType: actionTypes.SetQuotesToMake,
    filters: { projectStatus: ['quotation', 'tuning'] },
    dataKey: 'quotesToMake',
    dataType: 'project',
  },
  quotesToSend: {
    name: 'quotesToSend',
    requestActionType: actionTypes.RequestQuotesToSend,
    setActionType: actionTypes.SetQuotesToSend,
    filters: { quoteStatus: ['concept'] },
    dataKey: 'quotesToSend',
    dataType: 'quote',
  },
  declinedQuotes: {
    name: 'declinedQuotes',
    requestActionType: actionTypes.RequestDeclinedQuotes,
    setActionType: actionTypes.SetDeclinedQuotes,
    filters: { quoteStatus: ['declined'] },
    dataKey: 'declinedQuotes',
    dataType: 'quote',
  },
  remindedQuotes: {
    name: 'remindedQuotes',
    requestActionType: actionTypes.RequestRemindedQuotes,
    setActionType: actionTypes.SetRemindedQuotes,
    filters: { quoteStatus: ['reminded'] },
    dataKey: 'remindedQuotes',
    dataType: 'quote',
  },
};

export const invoiceTabs = {
  invoicesToSend: {
    name: 'invoicesToSend',
    requestActionType: actionTypes.RequestInvoicesToSend,
    setActionType: actionTypes.SetInvoicesToSend,
    filters: { invoiceStatus: ['concept', 'scheduled'] },
    dataKey: 'invoicesToSend',
    dataType: 'invoice',
  },
  openInvoices: {
    name: 'openInvoices',
    requestActionType: actionTypes.RequestOpenInvoices,
    setActionType: actionTypes.SetOpenInvoices,
    filters: { invoiceStatus: ['open', 'expired', 'reminded'] },
    dataKey: 'openInvoices',
    dataType: 'invoice',
  },
  overdueInvoices: {
    name: 'overdueInvoices',
    requestActionType: actionTypes.RequestOverdueInvoices,
    setActionType: actionTypes.SetOverdueInvoices,
    filters: { invoiceStatus: ['reminded'] },
    dataKey: 'overdueInvoices',
    dataType: 'invoice',
  },
};

const tabs = [...Object.values(movingJobTabs), ...Object.values(quoteTabs), ...Object.values(invoiceTabs)];

const initialState = {
  movingJobTab: movingJobTabs.toPlan.name,
  quoteTab: quoteTabs.quotesWaitingForAcceptance.name,
  invoiceTab: invoiceTabs.invoicesToSend.name,
  certificateIntegration: null,
  dayStatistics: {
    loading: true,
    numberOfMovingJobs: 0,
    numberOfDeclinedQuotes: 0,
    numberOfAcceptedQuotes: 0,
    numberOfSendQuotes: 0,
  },
  periodStatistics: {
    loading: true,
    numberOfMovingJobs: 0,
    numberOfDeclinedQuotes: 0,
    numberOfAcceptedQuotes: 0,
    numberOfSendQuotes: 0,
  },
  projectsWithAcceptedQuotes: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  projectsAwaitingBoxesPickup: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  projectsNeedingCertificates: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  projectsAwaitingWarrantyCertificates: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  projectsAwaitingAftercare: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  projectsAwaitingTaxationEvent: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  quotesWaitingForAcceptance: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  quotesToMake: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  quotesToSend: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  declinedQuotes: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  remindedQuotes: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  invoicesToSend: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  openInvoices: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
  overdueInvoices: {
    ...tableInitialState,
    sortBy: { name: 'asc' },
    pageSize: 5,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetMovingJobTab: {
      return {
        ...state,
        movingJobTab: action.payload,
      };
    }

    case actionTypes.SetQuoteTab: {
      return {
        ...state,
        quoteTab: action.payload,
      };
    }

    case actionTypes.SetInvoiceTab: {
      return {
        ...state,
        invoiceTab: action.payload,
      };
    }

    case actionTypes.SetDayStatistics: {
      return {
        ...state,
        loading: false,
        dayStatistics: action.payload,
      };
    }

    case actionTypes.SetPeriodStatistics: {
      return {
        ...state,
        loading: false,
        periodStatistics: action.payload,
      };
    }

    case actionTypes.SetCertificateIntegration: {
      return {
        ...state,
        loading: false,
        certificateIntegration: action.payload,
      };
    }

    case actionTypes.ChangePage: {
      return {
        ...state,
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          page: action.payload.page,
          loading: true,
        },
      };
    }

    case actionTypes.SetPageSize: {
      return {
        ...state,
        [action.payload.dataKey]: {
          ...state[action.payload.dataKey],
          pageSize: action.payload.pageSize,
          loading: true,
        },
      };
    }

    default: {
      const requestTab = tabs.find(({ requestActionType }) => requestActionType === action.type);

      if (requestTab) {
        return {
          ...state,
          [requestTab.dataKey]: {
            ...state[requestTab.dataKey],
            loading: true,
          },
        };
      }

      const setTab = tabs.find(({ setActionType }) => setActionType === action.type);

      if (setTab) {
        return {
          ...state,
          [setTab.dataKey]: {
            ...state[setTab.dataKey],
            ...action.payload,
            loading: false,
            loaded: true,
          },
        };
      }

      return state;
    }
  }
};

export const actions = {
  setMovingJobTab: tab => ({
    type: actionTypes.SetMovingJobTab,
    payload: tab,
  }),
  setQuoteTab: tab => ({
    type: actionTypes.SetQuoteTab,
    payload: tab,
  }),
  setInvoiceTab: tab => ({
    type: actionTypes.SetInvoiceTab,
    payload: tab,
  }),
  requestCertificateIntegration: () => ({
    type: actionTypes.RequestCertificateIntegration,
  }),
  setCertificateIntegration: result => ({
    type: actionTypes.SetCertificateIntegration,
    payload: result,
  }),
  requestStatistics: () => ({
    type: actionTypes.RequestStatistics,
  }),
  requestTabsData: () => ({
    type: actionTypes.RequestTabsData,
  }),
  setDayStatistics: result => ({
    type: actionTypes.SetDayStatistics,
    payload: result,
  }),
  setPeriodStatistics: result => ({
    type: actionTypes.SetPeriodStatistics,
    payload: result,
  }),
  changePage: (dataKey, page) => ({
    type: actionTypes.ChangePage,
    payload: { dataKey, page },
  }),
  setPageSize: (dataKey, pageSize) => ({
    type: actionTypes.SetPageSize,
    payload: { dataKey, pageSize },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.SetMovingJobTab, function* invoke({ payload }) {
    const tab = Object.values(movingJobTabs).find(({ name }) => name === payload);

    if (tab) {
      yield put({
        type: tab.requestActionType,
      });
    }
  });

  yield takeLatest(actionTypes.SetQuoteTab, function* invoke({ payload }) {
    const tab = Object.values(quoteTabs).find(({ name }) => name === payload);

    if (tab) {
      yield put({
        type: tab.requestActionType,
      });
    }
  });

  yield takeLatest(actionTypes.SetInvoiceTab, function* invoke({ payload }) {
    const tab = Object.values(invoiceTabs).find(({ name }) => name === payload);

    if (tab) {
      yield put({
        type: tab.requestActionType,
      });
    }
  });

  yield takeLatest(actionTypes.RequestCertificateIntegration, function* invoke() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const {
      data: { 'hydra:member': integrations, 'hydra:totalItems': count },
    } = yield getIntegrations(currentTenantId, 1, 10, {}, { 'provider.value': 'schouten-zekerheid' });

    if (count !== 1) {
      yield put(actions.setCertificateIntegration(null));
      return;
    }

    yield put(actions.setCertificateIntegration(integrations[0]));
  });

  function* handleRequestDashboardData({ dataKey, filters, setActionType, dataType }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.DashboardReducer);
    let page = 1;
    let pageSize = 10;
    let sortBy = {};
    if (currentState[dataKey]) {
      ({ page, pageSize, sortBy } = currentState[dataKey]);
    }

    let items = [];
    let totalCount = 0;

    if (dataType === 'project') {
      ({
        data: { 'hydra:member': items, 'hydra:totalItems': totalCount },
      } = yield getDashboardMovingJobs(currentTenantId, page, pageSize, sortBy, filters));
    } else if (dataType === 'quote') {
      ({
        data: { 'hydra:member': items, 'hydra:totalItems': totalCount },
      } = yield getQuotes(currentTenantId, page, pageSize, sortBy, filters));
    } else if (dataType === 'invoice') {
      ({
        data: { 'hydra:member': items, 'hydra:totalItems': totalCount },
      } = yield InvoiceService.getInvoices(currentTenantId, page, pageSize, sortBy, filters, undefined, false));
    } else if (dataType === 'certificate' && currentState.certificateIntegration) {
      ({
        data: { 'hydra:member': items, 'hydra:totalItems': totalCount },
      } = yield getBatchData(
        currentTenantId,
        currentState.certificateIntegration.integrationId,
        page,
        pageSize,
        sortBy,
        filters,
      ));
    }

    yield put({
      type: setActionType,
      payload: { items, totalCount },
    });
  }

  function* reloadTable({ payload: { dataKey } }) {
    const tab = tabs.find(t => t.dataKey === dataKey);
    yield handleRequestDashboardData(tab);
  }

  yield takeLatest(actionTypes.ChangePage, reloadTable);
  yield takeLatest(actionTypes.SetPageSize, reloadTable);
  yield takeLatest(actionTypes.RequestTabsData, function* invoke() {
    const { movingJobTab, quoteTab, invoiceTab } = yield select(state => state.DashboardReducer);
    let tab = tabs.find(t => t.name === movingJobTab);
    if (tab) {
      yield handleRequestDashboardData(tab);
    }
    tab = tabs.find(t => t.name === quoteTab);
    if (tab) {
      yield handleRequestDashboardData(tab);
    }
    tab = tabs.find(t => t.name === invoiceTab);
    if (tab) {
      yield handleRequestDashboardData(tab);
    }
  });

  yield takeLatest(actionTypes.RequestProjectsWithAcceptedQuotes, function* invoke() {
    yield handleRequestDashboardData(movingJobTabs.toPlan);
  });

  yield takeLatest(actionTypes.RequestProjectsAwaitingBoxesPickup, function* invoke() {
    yield handleRequestDashboardData(movingJobTabs.boxesToPickup);
  });

  yield takeLatest(actionTypes.RequestProjectsAwaitingMovingCertificate, function* invoke() {
    yield handleRequestDashboardData(movingJobTabs.movingCertificate);
  });

  yield takeLatest(actionTypes.RequestProjectsAwaitingAftercare, function* invoke() {
    yield handleRequestDashboardData(movingJobTabs.toFinish);
  });

  yield takeLatest(actionTypes.RequestProjectsAwaitingTaxationEvent, function* invoke() {
    yield handleRequestDashboardData(movingJobTabs.firstContact);
  });

  yield takeLatest(actionTypes.RequestQuotesWaitingForAcceptance, function* invoke() {
    yield handleRequestDashboardData(quoteTabs.quotesWaitingForAcceptance);
  });

  yield takeLatest(actionTypes.RequestQuotesToSend, function* invoke() {
    yield handleRequestDashboardData(quoteTabs.quotesToSend);
  });

  yield takeLatest(actionTypes.RequestDeclinedQuotes, function* invoke() {
    yield handleRequestDashboardData(quoteTabs.declinedQuotes);
  });

  yield takeLatest(actionTypes.RequestRemindedQuotes, function* invoke() {
    yield handleRequestDashboardData(quoteTabs.remindedQuotes);
  });

  yield takeLatest(actionTypes.RequestQuotesToMake, function* invoke() {
    yield handleRequestDashboardData(quoteTabs.quotesToMake);
  });

  yield takeLatest(actionTypes.RequestInvoicesToSend, function* invoke() {
    yield handleRequestDashboardData(invoiceTabs.invoicesToSend);
  });

  yield takeLatest(actionTypes.RequestOpenInvoices, function* invoke() {
    yield handleRequestDashboardData(invoiceTabs.openInvoices);
  });

  yield takeLatest(actionTypes.RequestOverdueInvoices, function* invoke() {
    yield handleRequestDashboardData(invoiceTabs.overdueInvoices);
  });

  yield takeLatest(actionTypes.RequestStatistics, function* invoke() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const {
      data: { 'hydra:member': statistics },
    } = yield TenantService.getStatistics(currentTenantId);

    yield put(actions.setDayStatistics(statistics.find(({ startDate, endDate }) => startDate === endDate)));
    yield put(
      actions.setPeriodStatistics(
        statistics.find(
          ({ startDate, endDate }) =>
            !dayjs(startDate).isSame(endDate, 'day') && dayjs().isBetween(startDate, endDate, 'day', '[]'),
        ),
      ),
    );
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string') {
      const { movingJobTab, quoteTab, invoiceTab, ...currentState } = yield select(state => state.DashboardReducer);
      let messageDataType = '';
      let identifier = '';
      if (action.data['@type'] === 'Invoice') {
        messageDataType = 'invoice';
        identifier = 'invoiceId';
      } else if (action.data['@type'] === 'Quote') {
        messageDataType = 'quote';
        identifier = 'quoteId';
      } else if (action.data['@type'] === 'MovingJob') {
        messageDataType = 'project';
        identifier = 'projectId';
      } else if (action.data['@type'] === 'CertificateData') {
        messageDataType = 'certificate';
        identifier = 'dataId';
      }

      if (!messageDataType || !identifier) {
        return;
      }

      const dataTabs = tabs.filter(({ dataType }) => dataType === messageDataType);
      const tabsToProcess = dataTabs
        .filter(({ name }) => [movingJobTab, quoteTab, invoiceTab].includes(name))
        .filter(tab => currentState[tab.dataKey].items.find(item => item[identifier] === action.data[identifier]));

      for (let i = 0; i <= tabsToProcess.length - 1; ++i) {
        yield handleRequestDashboardData(tabsToProcess[i]);
      }

      // yield tabsToProcess.map(async tab => handleRequestDashboardData(tab));
    }
  });
}
