import { useState } from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    // minHeight: '158px',
    paddingTop: '50%',
    paddingBottom: '50%',
    height: 0,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: '#E6E7EE',
  },
  cardWithVariants: {
    width: '100%',
    paddingTop: '50%',
    paddingBottom: '50%',
    height: 0,
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '0px 10px',
  },
  cardActive: {
    width: '100%',
    paddingTop: '50%',
    paddingBottom: '50%',
    height: 0,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#3A78F2',
  },
  icon: {
    color: '#000',
    background: 'transparent',
    width: '100%',
    height: '55px',
  },
  iconActive: {
    color: '#FFF',
    background: 'transparent',
    width: '100%',
    height: '55px',
  },
  checkedCircle: {
    color: '#FFF',
    position: 'absolute',
    top: '5%',
    left: '5%',
  },
  bgWhite: {
    background: '#fff',
  },
  blackText: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    color: '#000',
  },
  blueText: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    color: '#FFF',
  },
  furnitureTitle: {
    position: 'absolute',
    top: '8%',
    height: '20%',
    left: '0',
    right: '0',
  },
  variantsGrid: {
    height: '100%',
    borderTop: '1px solid #B0B0B0',
  },
  variantsList: {
    position: 'absolute',
    top: '28%',
    bottom: '0',
    left: '0',
    right: '0',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  variant: {
    borderRight: '1px solid #B0B0B0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:nth-child(2)': {
      borderRight: 'none',
    },
    '&:nth-child(3)': {
      borderTop: '1px solid #B0B0B0',
    },
    '&:nth-child(4)': {
      borderTop: '1px solid #B0B0B0',
    },
  },
}));

const IconCardComponent = props => {
  const { item, selectable, clickFunction, variantsFunction, style } = props;
  const [showVariants, setShowVariants] = useState(false);
  const classes = useStyles();

  const checkSelection = () => {
    if (!selectable) {
      return false;
    }

    return item.selected;
  };

  const clickMiddleware = () => {
    if (showVariants) {
      return null;
    }

    if (!item.selectedVariant && item.variants && item.variants.length > 0) {
      return setShowVariants(true);
    }

    return clickFunction(item);
  };

  let cardClassName = classes.card;
  if (checkSelection()) {
    cardClassName = classes.cardActive;
  } else if (showVariants) {
    cardClassName = classes.cardWithVariants;
  }
  return (
    <Card id={item.label} className={cardClassName} style={style} onClick={() => clickMiddleware(item)}>
      {checkSelection() && <Check className={classes.checkedCircle} />}
      <CardContent className={classes.cardContent}>
        {!showVariants && (
          <Grid container>
            <Grid item xs={12}>
              <Avatar align="center" className={checkSelection() ? classes.iconActive : classes.icon} variant="rounded">
                {item.icon && !item.icon.includes('.svg') && (
                  <FontAwesomeIcon size="2x" icon={['fal', item.icon.replace('fa-', '')]} />
                )}
                {item.icon && item.icon.includes('.svg') && <ReactSVG src={`/icons/${item.icon}`} />}
              </Avatar>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={checkSelection() ? classes.blueText : classes.blackText}>
                {item.label || item.furnitureTypeName}
              </Typography>
              {item.selectedVariant && (
                <Typography align="center" className={checkSelection() ? classes.blueText : classes.blackText}>
                  {item.selectedVariant.variantTypeName}
                  <span className={classes.lowercase}>
                    {' '}
                    <FormattedMessage id={`furniture.variant.${item.selectedVariant.valueType}`} />
                  </span>
                </Typography>
              )}
              {item.smallText && (
                <Typography align="center" className={checkSelection() ? classes.blueText : classes.blackText}>
                  &euro;
                  {item.smallText}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {showVariants && (
          <Grid container>
            <Grid item xs={12} className={classes.furnitureTitle}>
              <Typography align="center" className={classes.blackText}>
                <FormattedMessage id={`furniture.variant.${item.variants[0].valueType}`} />
              </Typography>
            </Grid>
            <div className={classes.variantsList}>
              <Grid container className={classes.variantsGrid}>
                {!item.selectedVariant &&
                  item.variants.map(variant => (
                    <Grid
                      key={variant.variantTypeId}
                      item
                      xs={6}
                      className={classes.variant}
                      onClick={() => {
                        setShowVariants(false);
                        if (!checkSelection()) {
                          variantsFunction(item, variant);
                        } else {
                          clickFunction(item);
                        }
                      }}
                    >
                      <Typography align="center" className={classes.blackText}>
                        {variant.variantTypeName}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

IconCardComponent.propTypes = {
  item: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  clickFunction: PropTypes.func,
  variantsFunction: PropTypes.func,
};

IconCardComponent.defaultProps = {
  selectable: false,
  clickFunction: () => {},
  variantsFunction: () => {},
};

export default IconCardComponent;
