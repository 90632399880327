import { Button, FormControlLabel, Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import DialogComponent from 'components/DialogComponent';
import { FormattedMessage } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Checkbox } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import InputComponent from '../InputComponent';

const useStyles = makeStyles(() => ({
  label: {
    color: '#fff',
  },
}));

const AddCustomFurnitureComponent = ({ open, handleClose, handleAddItem }) => {
  const classes = useStyles();

  return (
    <DialogComponent open={open} handleClose={handleClose} title={<FormattedMessage id="room.furniture.addCustom" />}>
      <Formik
        onSubmit={handleAddItem}
        initialValues={{ label: '', cubicMeter: '', pricePerVariant: false, servicesPossible: true }}
        validationSchema={Yup.object({
          label: Yup.string().required(),
          cubicMeter: Yup.number().required(),
        })}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={InputComponent}
                name="label"
                whiteLabel
                fullWidth
                label={<FormattedMessage id="room.furniture.label" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                required
                inputProps={{ min: '0', step: 'any' }}
                endAdornment={<InputAdornment position="end">&#13221;</InputAdornment>}
                helperText={<span>&#13221;</span>}
                type="number"
                component={InputComponent}
                whiteLabel
                name="cubicMeter"
                label={<FormattedMessage id="room.furniture.cubicMeter" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                classes={{ label: classes.label }}
                label={<FormattedMessage id="room.furniture.servicesPossible" />}
                control={<Field type="checkbox" component={Checkbox} name="servicesPossible" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth color="primary" variant="contained">
                <FormattedMessage id="room.furniture.addButton" />
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </DialogComponent>
  );
};

export default AddCustomFurnitureComponent;
