import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import { ButtonComponent } from '../../../../components';

const useStyles = makeStyles(() => ({
  white: {
    '& .MuiFormLabel-root': {
      color: '#ffffff',
    },
    '& .MuiTextField-root': {
      color: '#ffffff',
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInput-underline:before': {
      borderColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#ffffff',
    },
  },
}));

const TwoFactorForm = ({ handleSubmit }) => {
  const classes = useStyles();

  const validate = values => {
    const errors = {};

    if (!values.code) {
      errors.code = <FormattedMessage id="error.required" />;
    }

    return errors;
  };

  return (
    <Grid item xs={12}>
      <Formik initialValues={{ code: '' }} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  required
                  type="text"
                  component={TextField}
                  className={classes.white}
                  name="code"
                  label={<FormattedMessage id="login.label.code" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonComponent type="submit" fullWidth variant="contained" color="primary" loading={isSubmitting}>
                  <FormattedMessage id="login.submit_button" />
                </ButtonComponent>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default TwoFactorForm;
