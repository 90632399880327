const formatHours = (hours, dontShowEmptyMinutes = false) => {
  if (hours === 0) {
    if (dontShowEmptyMinutes) {
      return '0';
    }

    return '0:00';
  }

  const rhours = Math.floor(+hours);
  const minutes = +(+hours - +rhours) * 60;
  const rminutes = Math.round(minutes).toString();
  if (dontShowEmptyMinutes && Math.round(minutes) === 0) {
    return rhours;
  }

  return `${rhours}:${rminutes.padStart(2, '0')}`;
};

export default formatHours;
