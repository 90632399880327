import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getMovingJobs } from 'Domain/Project/Services/MovingJobService';
import { tableAction, tableActions, tableInitialState } from './Table.duck';
import { newMercureMessage } from './App.duck';
import { listEventsByProject } from '../../Planning/Services/PlanningService';

export const actionTypes = {
  RequestData: '[DashboardProjects] RequestDashboardProjects',
  FulfilledTable: '[DashboardProjects] FulfilledDashboardProjects',
  SearchTable: '[DashboardProjects] Search DashboardProjects',
  ChangePage: '[DashboardProjects] change page',
  SetPageSize: '[DashboardProjects] set page size',
  SortTable: '[DashboardProjects] Sort DashboardProjects',
  ApplyFilter: '[DashboardProjects] Apply filter',
  RemoveFilter: '[DashboardProjects] Remove filter',
  ResetFilters: '[DashboardProjects] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestDashboardProjectsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.DashboardProjectsReducer);

    const response = yield getMovingJobs(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      {
        'order[nextActionDate]': 'desc',
      },
      {
        projectStatus: [
          'first-contact',
          'quotation',
          'quote-declined',
          'quote-accepted',
          'deliver-boxes',
          'pick-up-boxes',
          'carry-out',
          'aftercare',
        ],
      },
      null,
      false,
    );
    const projects = [];
    yield Promise.all(
      response.data['hydra:member'].map(async project => {
        const eventsResponse = await listEventsByProject(currentTenantId, project.projectId, false);

        projects.push({ ...project, events: eventsResponse.result['hydra:member'] });
      }),
    );

    yield put(actions.fulfilled(projects, response.data['hydra:totalItems']));
  });
  yield takeEvery(newMercureMessage, function* execute(action) {
    if (
      typeof action.data['@type'] === 'number' &&
      [
        'Project',
        'MovingJob',
        'ProjectEvent',
        'IntakeEvent',
        'DeliverBoxesEvent',
        'PickupBoxesEvent',
        'DisassembleEvent',
        'AssembleEvent',
        'PostRoadSignsEvent',
        'RemoveRoadSignsEvent',
        'UnloadStorageEvent',
        'LoadStorageEvent',
        'UnpackingEvent',
        'MovingLiftEvent',
        'PackingEvent',
        'MovingEvent',
      ].includes(action.data['@type'])
    ) {
      yield put(actions.requestData());
    }
  });
}
