import axios from 'axios';
import Qs from 'qs';
import { getMovingJob } from '../../../Project/Services/MovingJobService';
import { getVehicle } from '../../../../services/VehicleService';
import { getEmployee } from '../../../../services/EmployeeService';

export const loadEventsData = async (tenantId, data) => {
  const events = data['hydra:member'];
  const projects = [];
  const materials = [];
  const materialIds = [];
  const projectIds = [];
  events.forEach(event => {
    event.materials.forEach(material => materialIds.push(material.split('/')[4]));
    if (event.project) {
      projectIds.push(event.project.split('/')[4]);
    }
  });
  await Promise.all([
    Promise.all(
      projectIds
        .filter((item, index) => projectIds.indexOf(item) === index)
        .map(async pjId => {
          const response = await getMovingJob(tenantId, pjId);

          projects.push(response.data);
        }),
    ),
    Promise.all(
      materialIds
        .filter((item, index) => materialIds.indexOf(item) === index)
        .map(async materialId => {
          const response = await getVehicle(tenantId, materialId);

          materials.push(response.data);
        }),
    ),
  ]);

  return {
    result: {
      'hydra:member': events.map(item => {
        let project;
        if (item.project) {
          project = projects.find(pr => pr.projectId === item.project.split('/')[4]);
        }
        const newMaterials = item.materials.map(materialIri =>
          materials.find(material => material.materialId === materialIri.split('/')[4]),
        );

        return { ...item, project, materials: newMaterials, allDay: item.fullDay };
      }),
    },
  };
};

export const getEventById = async (tenantId, eventId) => axios.get(`/api/${tenantId}/events/${eventId}`);

let availableHoursToken;
export const listAvailableHoursByRoleForPeriod = async (tenantId, role, fromDate, untilDate) => {
  try {
    if (typeof availableHoursToken !== typeof undefined) {
      availableHoursToken.cancel('Operation canceled due to new request.');
    }

    availableHoursToken = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/planning/available-hours/${role}/${fromDate}/${untilDate}`, {
      cancelToken: availableHoursToken.token,
    });

    return { result: data };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let holidaysToken;
export const listHolidaysByCountryAndPeriod = async (tenantId, country, fromDate, untilDate) => {
  try {
    if (typeof holidaysToken !== typeof undefined) {
      holidaysToken.cancel('Operation canceled due to new request.');
    }

    holidaysToken = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/planning/holidays/${country}/${fromDate}/${untilDate}`, {
      cancelToken: holidaysToken.token,
    });

    return { result: data };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let eventsToken;
export const listEventsByPeriod = async (tenantId, fromDate, untilDate, eventTypes = undefined) => {
  try {
    if (typeof eventsToken !== typeof undefined) {
      eventsToken.cancel('Operation canceled due to new request.');
    }

    eventsToken = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/events`, {
      params: {
        fromDate,
        untilDate,
        perPage: 5000,
        eventTypes,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: eventsToken.token,
    });

    return loadEventsData(tenantId, data);
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let eventsByUserToken;
export const listEventsByUserIdAndPeriod = async (tenantId, userId, fromDate, untilDate) => {
  try {
    if (typeof eventsByUserToken !== typeof undefined) {
      eventsByUserToken.cancel('Operation canceled due to new request.');
    }

    eventsByUserToken = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/events/by-user/${userId}`, {
      params: {
        fromDate,
        untilDate,
        perPage: 5000,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: eventsByUserToken.token,
    });

    return loadEventsData(tenantId, data);
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const listEventsByProject = async (tenantId, projectId, loadDetails = true) => {
  const { data } = await axios.get(`api/${tenantId}/events/by-project/${projectId}`, {
    params: {
      perPage: 1000,
    },
  });

  if (!loadDetails) {
    return { result: data };
  }

  return loadEventsData(tenantId, data);
};

let attentionsToken;
export const getAttentions = async (tenantId, page, perPage) => {
  try {
    if (typeof attentionsToken !== typeof undefined) {
      attentionsToken.cancel('Operation canceled due to new request.');
    }

    attentionsToken = axios.CancelToken.source();

    const response = await axios.get(`api/${tenantId}/attentions`, {
      params: {
        finished: false,
        page,
        perPage,
      },
      cancelToken: attentionsToken.token,
    });

    const employeeIds = [];
    const materialIds = [];
    const projectIds = [];
    const eventIds = [];
    const employees = [];
    const materials = [];
    const projects = [];
    const events = [];
    response.data['hydra:member'].forEach(attention => {
      if (attention.employee) {
        employeeIds.push(attention.employee.split('/')[5]);
      }
      if (attention.material) {
        materialIds.push(attention.material.split('/')[4]);
      }
      if (attention.project) {
        projectIds.push(attention.project.split('/')[4]);
      }
      if (attention.event) {
        eventIds.push(attention.event.split('/')[4]);
      }
    });

    await Promise.all([
      Promise.all(
        projectIds
          .filter((item, index) => projectIds.indexOf(item) === index)
          .map(async pjId => {
            const projectResponse = await getMovingJob(tenantId, pjId);

            projects.push(projectResponse.data);
          }),
      ),
      Promise.all(
        materialIds
          .filter((item, index) => materialIds.indexOf(item) === index)
          .map(async materialId => {
            const materialResponse = await getVehicle(tenantId, materialId);

            materials.push(materialResponse.data);
          }),
      ),
      Promise.all(
        employeeIds
          .filter((item, index) => employeeIds.indexOf(item) === index)
          .map(async employeeId => {
            const employeeResponse = await getEmployee(tenantId, employeeId);

            employees.push(employeeResponse.data);
          }),
      ),
      Promise.all(
        eventIds.map(async eventId => {
          const eventResponse = await getEventById(tenantId, eventId);

          events.push(eventResponse.data);
        }),
      ),
    ]);

    return {
      ...response,
      data: {
        ...response.data,
        'hydra:member': response.data['hydra:member'].map(attention => ({
          ...attention,
          employee: attention.employee
            ? employees.find(({ employeeId }) => employeeId === attention.employee.split('/')[5])
            : null,
          material: attention.material
            ? materials.find(({ materialId }) => materialId === attention.material.split('/')[4])
            : null,
          project: attention.project
            ? projects.find(({ projectId }) => projectId === attention.project.split('/')[4])
            : null,
          event: attention.event ? events.find(({ eventId }) => eventId === attention.event.split('/')[4]) : null,
        })),
      },
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const snoozeAttention = async (tenantId, attentionId, snoozeUntil) =>
  axios.put(`/api/${tenantId}/attentions/${attentionId}/snooze`, {
    tenantId,
    attentionId,
    snoozeUntil,
  });
export const finishAttention = async (tenantId, attentionId) =>
  axios.put(`/api/${tenantId}/attentions/${attentionId}/finish`, {
    tenantId,
    attentionId,
  });

export const planEmployeeAbsence = async (tenantId, employeeId, values) =>
  axios.post(`/api/${tenantId}/events/plan-absence-for-employee`, {
    ...values,
    tenantId,
    employeeId,
  });

export const planMaterialAbsence = async (tenantId, materialId, values) =>
  axios.post(`/api/${tenantId}/events/plan-absence-for-material`, {
    ...values,
    tenantId,
    materialId,
  });

export const planEmployeeOnEvent = async (tenantId, eventId, employeeId, projectRole) =>
  axios.put(`/api/${tenantId}/events/${eventId}/plan-employee`, {
    tenantId,
    eventId,
    employeeId,
    projectRole,
  });

export const removeEmployeeFromEvent = async (tenantId, eventId, employeeId) =>
  axios.put(`/api/${tenantId}/events/${eventId}/remove-employee`, {
    tenantId,
    eventId,
    employeeId,
  });

export const planMaterialOnEvent = async (tenantId, eventId, materialId, projectRole) =>
  axios.put(`/api/${tenantId}/events/${eventId}/plan-material`, {
    tenantId,
    eventId,
    materialId,
    projectRole,
  });

export const removeMaterialFromEvent = async (tenantId, eventId, materialId) =>
  axios.put(`/api/${tenantId}/events/${eventId}/remove-material`, {
    tenantId,
    eventId,
    materialId,
  });

export const updateNeededRoles = async (tenantId, eventId, neededRoles) =>
  axios.put(`/api/${tenantId}/events/${eventId}/update-needed-roles`, {
    tenantId,
    eventId,
    neededRoles,
  });

export const planEvent = async (tenantId, values) =>
  axios.post(`/api/${tenantId}/events/plan`, {
    tenantId,
    ...values,
  });

export const rescheduleEvent = async (tenantId, eventId, values) =>
  axios.put(`/api/${tenantId}/events/${eventId}/reschedule`, {
    tenantId,
    eventId,
    ...values,
  });

export const cancelEvent = async (tenantId, eventId) =>
  axios.put(`/api/${tenantId}/events/${eventId}/cancel`, {
    tenantId,
    eventId,
  });

export const finishEvent = async (tenantId, eventId, finishedAt) =>
  axios.put(`/api/${tenantId}/events/${eventId}/finish`, {
    tenantId,
    eventId,
    finishedAt,
  });
