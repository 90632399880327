import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';

const useStyles = makeStyles(() => ({
  typo: {
    marginBottom: '10px',
  },
}));

const LoaderScreenPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { active, selected } = useSelector(state => state.FirstStepReducer);
  const quoteData = useSelector(state => state.MovingJobEstimateReducer);

  useEffect(() => {
    /** @TODO after fixing the steps finish this */
    dispatch(FirstStepActions.toggleNav(true));
    dispatch(FirstStepActions.next(active, selected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData.projectId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography className={classes.typo} variant="h5" component="h5" align="center">
          Retrieving data...
        </Typography>
        <LinearProgress color="secondary" />
      </Grid>
    </Grid>
  );
};

export default LoaderScreenPage;
