import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { newMercureMessage } from '../../App/Ducks/App.duck';
import { getAdminRoom } from '../../App/Services/SettingsService';

export const actionTypes = {
  ResetData: '[Room] Reset Room',
  RequestData: '[Room] Request Room',
  FulfilledData: '[Room] Fulfilled Room',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestRoomSaga(action) {
    const response = yield getAdminRoom(action.itemId);

    yield put(actions.fulfilledData(response.data));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'RoomType') {
      yield put(actions.requestData());
    }
  });
}
