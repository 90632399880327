import axios from 'axios';

const mapFurniture = item => ({
  id: item['@id'],
  furnitureTypeId: item.furnitureTypeId,
  cubicMeter: item.cubicMeter,
  name: item.furnitureTypeName,
  icon: item.icon,
  value: item.furnitureTypeId,
  label: item.furnitureTypeName,
  servicesPossible: item.servicesPossible,
  variants: item.variants || [],
});

let tokenSource;
const getRooms = async (tenantId, page, perPage) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/public/settings/room-types`,
        { params: { page, perPage } },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => ({
        items: result.data['hydra:member'].map(item => ({
          id: item['@id'],
          roomTypeId: item.roomTypeId,
          name: item.roomTypeName,
          icon: item.icon,
          value: item.roomTypeId,
          label: item.roomTypeName,
          furnitureType: item.furnitureType.map(mapFurniture) || [],
        })),
        total: result.data['hydra:totalItems'],
      }));

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

let furnitureToken;
const getFurnitureList = async (page, perPage) => {
  try {
    if (typeof furnitureToken !== typeof undefined) {
      furnitureToken.cancel('Operation canceled due to new request.');
    }

    furnitureToken = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/public/settings/furniture-types`,
        { params: { page, perPage } },
        {
          cancelToken: furnitureToken.token,
        },
      )
      .then(result => ({
        items: result.data['hydra:member'].map(mapFurniture).sort((a, b) => a.name.localeCompare(b.name)),
        total: result.data['hydra:totalItems'],
      }));

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

export default {
  getRooms,
  getFurnitureList,
};
