import axios from 'axios';
import Qs from 'qs';

export const getNewsItemById = async (tenantId, newId) => axios.get(`api/${tenantId}/news/${newId}`);

let newsToken;
export const getNews = (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof newsToken !== typeof undefined) {
    newsToken.cancel('Operation canceled due to new request.');
  }
  newsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/news`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: newsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const updateNews = (tenantId, newsId, data) =>
  axios.put(`api/${tenantId}/news/${newsId}/update`, {
    tenantId,
    newsId,
    ...data,
  });

export const publishNews = (tenantId, newsId, data) =>
  axios.put(`api/${tenantId}/news/${newsId}/publish`, {
    tenantId,
    newsId,
    ...data,
  });

export const removeNews = (tenantId, newsId) =>
  axios.put(`api/${tenantId}/news/${newsId}/remove`, {
    tenantId,
    newsId,
  });

export const postNews = (tenantId, values) =>
  axios.post(`api/${tenantId}/news`, {
    ...values,
    tenantId,
  });
