import axios from 'axios';
import Qs from 'qs';
import { getCustomer } from '../../Domain/Customer/Services/CustomerService';

let relationTokenSource;
const getRelationInvoices = async (tenantId, relationId) => {
  try {
    if (typeof relationTokenSource !== typeof undefined) {
      relationTokenSource.cancel('Operation canceled due to new request.');
    }

    relationTokenSource = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/relations/${relationId}/invoices`, {
      cancelToken: relationTokenSource.token,
    });

    return { result: data };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let invoicesTokenSource;
const mapField = field => {
  switch (field) {
    case 'customer':
      return ['relation.name'];
    case 'totalExcludingVat':
      return ['totalExcludingVat.amount'];
    case 'totalIncludingVat':
      return ['totalIncludingVat.amount'];
    default:
      return [field];
  }
};
const getInvoices = async (tenantId, page, perPage, order, appliedFilters, globalFilter, cancel = true) => {
  if (cancel && typeof invoicesTokenSource !== typeof undefined) {
    invoicesTokenSource.cancel('Operation canceled due to new request.');
  }
  invoicesTokenSource = axios.CancelToken.source();
  const appliedOrder = {};
  if (order) {
    Object.keys(order).forEach(key => {
      mapField(key).forEach(field => {
        appliedOrder[field] = order[key];
      });
    });
  }

  try {
    const result = await axios.get(`api/${tenantId}/invoices`, {
      params: {
        ...appliedFilters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: cancel ? invoicesTokenSource.token : undefined,
    });

    await Promise.all(
      result.data['hydra:member'].map(async item => {
        const contents = await getCustomer(tenantId, item.relation.split('/')[4]);
        item.customer = contents.data;
      }),
    );

    return result;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const getInvoice = async (tenantId, invoiceId) => {
  const result = await axios.get(`api/${tenantId}/invoices/${invoiceId}`);
  const customerResponse = await getCustomer(tenantId, result.data.relation.split('/')[4]);

  return { data: { ...result.data, relation: customerResponse.data } };
};

const createInvoice = (tenantId, invoiceId, values) =>
  axios.post(`api/${tenantId}/invoices`, { ...values, tenantId, invoiceId });

const updateInvoice = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}`, { ...values, tenantId, invoiceId });

const deleteInvoice = (tenantId, invoiceId) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/delete`, { tenantId, invoiceId });

const previewInvoice = (tenantId, invoiceId) =>
  axios.get(`api/${tenantId}/invoices/${invoiceId}/preview-pdf`, {
    responseType: 'blob',
  });

const downloadInvoice = (tenantId, invoiceId) =>
  axios.get(`api/${tenantId}/invoices/${invoiceId}/download-pdf`, {
    responseType: 'blob',
  });

const scheduleInvoice = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/schedule`, { tenantId, invoiceId, ...values });

const finalizeInvoice = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/finalize`, { tenantId, invoiceId, ...values });

const sendInvoice = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/send`, { tenantId, invoiceId, ...values });

const sendReminder = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/send-reminder`, { tenantId, invoiceId, ...values });

const markInvoiceAsIrrecoverable = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/mark-as-irrecoverable`, { tenantId, invoiceId, ...values });

const calculateExpiresAt = (tenantId, invoiceId) =>
  axios.get(`api/${tenantId}/invoices/${invoiceId}/calculate-expires-at`);

const markAsPaid = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/mark-as-paid`, { tenantId, invoiceId, ...values });
const addManualPayment = (tenantId, invoiceId, values) =>
  axios.put(`api/${tenantId}/invoices/${invoiceId}/add-manual-payment`, { tenantId, invoiceId, ...values });

const addAttachmentToInvoice = async (tenantId, invoiceId, values) =>
  axios.put(`/api/${tenantId}/invoices/${invoiceId}/add-attachment`, {
    ...values,
    invoiceId,
    tenantId,
  });

const removeAttachmentFromInvoice = async (tenantId, invoiceId, values) =>
  axios.put(`/api/${tenantId}/invoices/${invoiceId}/remove-attachment`, {
    ...values,
    invoiceId,
    tenantId,
  });

const getInvoiceStatistics = async (tenantId, year, month) =>
  axios.get(`/api/${tenantId}/invoices/invoice-statistics/${year}/${month}`);

export default {
  createInvoice,
  updateInvoice,
  deleteInvoice,
  getRelationInvoices,
  getInvoices,
  getInvoice,
  previewInvoice,
  downloadInvoice,
  sendInvoice,
  sendReminder,
  scheduleInvoice,
  finalizeInvoice,
  addManualPayment,
  markInvoiceAsIrrecoverable,
  markAsPaid,
  calculateExpiresAt,
  addAttachmentToInvoice,
  removeAttachmentFromInvoice,
  getInvoiceStatistics,
};
