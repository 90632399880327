import { useCallback } from 'react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { fieldToTextField } from 'formik-material-ui';

const CurrencyTextFieldComponent = props => {
  const {
    form: { setFieldValue },
    field: { name, value },
  } = props;
  const onChange = useCallback(
    (event, newValue) => {
      setFieldValue(name, Math.round(newValue * 100).toString() || 0);
    },
    [setFieldValue, name],
  );

  return (
    <CurrencyTextField
      {...fieldToTextField(props)}
      value={(value || 0) / 100}
      currencySymbol="€"
      decimalCharacter=","
      digitGroupSeparator="."
      onChange={onChange}
    />
  );
};

export default CurrencyTextFieldComponent;
