import { persistReducer } from 'redux-persist';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import localforage from 'localforage';
import { v4 } from 'uuid';
import { TenantService } from '../../../services';

export const actionTypes = {
  SetTenant: '[App] Set Tenant',
  SetIdentities: '[App] Set identities',
  SetIdentity: '[App] Set identity',
  LoginUser: '[App] Login',
  LogoutUser: '[App] Logout',
  SetUser: '[App] Set User',
  SetLanguage: '[App] Set Language',
  SetInitialPath: '[App] Set initial path',
  RequestVatCodes: '[App] request vat codes',
  FulfilledVatCodes: '[App] fulfilled vat codes',
  NotifyUser: '[App] Notify user',
  DismissNotification: '[App] dismiss notification',
  RemoveNotification: '[App] remove notification',
  NewMercureMessage: '[App] new mercure message',
};

export const newMercureMessage = actionTypes.NewMercureMessage;

const initialState = {
  initialPath: '/',
  tenant: {},
  identities: [],
  loggedIn: false,
  user: {},
  employee: {},
  authToken: null,
  mercureToken: null,
  intercomHash: null,
  language: 'nl',
  vatCodes: [],
  notifications: [],
};

export const reducer = persistReducer({ storage: localforage, key: 'auth' }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetTenant: {
      return {
        ...state,
        tenant: action.payload,
      };
    }

    case actionTypes.SetIdentity: {
      return {
        ...state,
        identities: [
          ...state.identities.filter(({ identityId }) => action.payload.identityId !== identityId),
          action.payload,
        ],
      };
    }

    case actionTypes.SetIdentities: {
      return {
        ...state,
        identities: action.payload,
      };
    }

    case actionTypes.LoginUser: {
      return {
        ...state,
        authToken: action.payload.authToken,
        mercureToken: action.payload.mercureToken,
        intercomHash: action.payload.intercomHash,
        user: action.payload.user,
        employee: action.payload.employee,
        loggedIn: true,
      };
    }

    case actionTypes.SetLanguage: {
      return {
        ...state,
        language: action.payload.language,
      };
    }

    case actionTypes.SetUser: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case actionTypes.SetInitialPath: {
      return {
        ...state,
        initialPath: action.payload.initialPath,
      };
    }

    case actionTypes.FulfilledVatCodes: {
      return {
        ...state,
        vatCodes: action.payload,
      };
    }

    case actionTypes.NotifyUser: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    }

    case actionTypes.DismissNotification: {
      return {
        ...state,
        notifications: [...state.notifications].filter(
          ({ notificationId }) => notificationId === action.payload.notificationId,
        ),
      };
    }

    case actionTypes.LogoutUser: {
      return {
        ...state,
        authToken: null,
        user: {},
        loggedIn: false,
      };
    }

    default:
      return state;
  }
});

export const actions = {
  setTenant: tenant => ({
    type: actionTypes.SetTenant,
    payload: tenant,
  }),
  setIdentities: identities => ({
    type: actionTypes.SetIdentities,
    payload: identities,
  }),
  newMercureMessage: (message, data) => ({
    type: actionTypes.NewMercureMessage,
    message,
    data,
  }),
  requestVatCodes: () => ({
    type: actionTypes.RequestVatCodes,
  }),
  fulfilledVatCodes: vatCodes => ({
    type: actionTypes.FulfilledVatCodes,
    payload: vatCodes,
  }),
  login: (authToken, mercureToken, user, employee, intercomHash) => ({
    type: actionTypes.LoginUser,
    payload: {
      authToken,
      mercureToken,
      intercomHash,
      user,
      employee,
    },
  }),
  setUser: user => ({
    type: actionTypes.SetUser,
    payload: {
      user,
    },
  }),
  setIdentity: identity => ({
    type: actionTypes.SetIdentity,
    payload: identity,
  }),
  setInitialPath: initialPath => ({
    type: actionTypes.SetInitialPath,
    payload: {
      initialPath,
    },
  }),
  setLanguage: language => ({
    type: actionTypes.SetLanguage,
    payload: {
      language,
    },
  }),
  notifyUser: (message, options) => ({
    type: actionTypes.NotifyUser,
    payload: {
      notificationId: v4(),
      message,
      options: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...options,
      },
    },
  }),
  dismissNotification: notification => ({
    type: actionTypes.DismissNotification,
    payload: notification,
  }),
  logout: () => ({
    type: actionTypes.LogoutUser,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestVatCodes, function* requestVatCodesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield TenantService.getVatCodes(currentTenantId);

    yield put(actions.fulfilledVatCodes(response.data['hydra:member']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Identity') {
      yield put(actions.setIdentity(action.data));
    }
  });
}
