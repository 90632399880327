import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { EstimateService } from '../../../../services';
import { actions as MovingJobEstimateActions } from '../../Ducks/MovingJobEstimate.duck';
import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';
import WizardContext from '../../../EmployeeApp/Utils/WizardContext';
import PackageSelectField from '../../../../components/PackageSelectField';

const MovingJobPricingPage = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { active, selected } = useSelector(state => state.FirstStepReducer);
  const { setNextStepFunction } = useContext(WizardContext);

  const {
    authToken,
    tenant: { tenantId, useIdentities },
    identities,
  } = useSelector(state => state.AppReducer);
  const quoteData = useSelector(state => state.MovingJobEstimateReducer);
  const saveQuote = async values => {
    try {
      await dispatch(
        MovingJobEstimateActions.save({
          pricingType: values.pricingType,
          identityId: values.identityId,
          packageId: values.packageId,
          billingMoments: values.billingMoments,
        }),
      );
      const quoteResult = await EstimateService.sendQuoteRequest(authToken, tenantId, quoteData.projectId, {
        ...quoteData,
        ...values,
      });
      if (!quoteData.projectId && quoteResult.projectId) {
        await dispatch(MovingJobEstimateActions.save({ projectId: quoteResult.projectId }));
      }
      if (!quoteData.customerId && quoteResult.customerId) {
        await dispatch(MovingJobEstimateActions.save({ customerId: quoteResult.customerId }));
      }
      await dispatch(MovingJobEstimateActions.save({ quoteResult }));
      return true;
    } catch (error) {
      dispatch(MovingJobEstimateActions.setError({ error: { ...error, active, selected } }));
      return false;
    }
  };

  useEffect(() => {
    setNextStepFunction(() => formRef.current.submitForm);

    return () => {
      setNextStepFunction(null);
    };
  }, [setNextStepFunction]);

  let validationSchema = Yup.object({
    pricingType: Yup.string().oneOf(['', 'fixed-price', 'hourly']).nullable().required(),
    packageId: Yup.string().nullable().required(),
  });

  if (useIdentities) {
    validationSchema = validationSchema.concat(
      Yup.object({
        identityId: Yup.string().nullable().required(),
      }),
    );
  }

  useEffect(() => {
    dispatch(FirstStepActions.filledForm(true));
  }, []);

  return (
    <Formik
      innerRef={formRef}
      onSubmit={saveQuote}
      initialValues={{
        pricingType: quoteData.pricingType,
        identityId: quoteData.identityId || null,
        packageId: quoteData.packageId || null,
        billingMoments: quoteData.billingMoments || [],
      }}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize={false}
    >
      {({ values: { identityId, pricingType }, setFieldValue }) => (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3" align="center">
              <FormattedMessage id="moving.pricing_type" />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Form>
              <Grid container spacing={3}>
                {useIdentities && identities.length > 0 && (
                  <Grid item xs={12}>
                    <Field
                      color="secondary"
                      component={TextField}
                      name="identityId"
                      label={<FormattedMessage id="movingJob.identity" />}
                      fullWidth
                      select
                    >
                      {identities.map(identity => (
                        <MenuItem value={identity.identityId} key={identity.identityId}>
                          {identity.companyInformation.companyName}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    select
                    name="pricingType"
                    label={<FormattedMessage id="movingJob.quote.pricingType" />}
                    fullWidth
                  >
                    <MenuItem value="fixed-price">
                      <FormattedMessage id="project.pricingType.fixed-price" />
                    </MenuItem>
                    <MenuItem value="hourly">
                      <FormattedMessage id="project.pricingType.hourly" />
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled={!identityId && useIdentities}
                    component={PackageSelectField}
                    tenantId={tenantId}
                    identityId={identityId}
                    pricingType={pricingType}
                    projectType="moving-job"
                    onSelectPackage={p => {
                      setFieldValue('billingMoments', p.billingMoments);
                    }}
                    select
                    name="packageId"
                    label={<FormattedMessage id="movingJob.quote.package" />}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default MovingJobPricingPage;
