import { put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { finishAttention, getAttentions as getPlanningAttentions, snoozeAttention } from '../Services/PlanningService';

export const actionTypes = {
  SetPlanningAttentions: '[PlanningAttentions] Set attentions',
  RequestPlanningAttentions: '[PlanningAttentions] request data',
  SnoozeAttention: '[PlanningAttentions] SnoozeAttention',
  FinishAttention: '[PlanningAttentions] FinishAttention',
  SnoozedAttention: '[PlanningAttentions] SnoozedAttention',
  FinishedAttention: '[PlanningAttentions] FinishedAttention',
};

const initialState = {
  attentions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPlanningAttentions: {
      return {
        ...state,
        attentions: action.payload,
      };
    }

    case actionTypes.SnoozedAttention: {
      const newAttentions = [...state.attentions];
      const index = newAttentions.findIndex(({ attentionId }) => attentionId === action.payload.attentionId);
      if (index !== -1) {
        newAttentions[index] = { ...newAttentions[index], snoozedUntil: action.payload.snoozedUntil };
      }

      return {
        ...state,
        attentions: newAttentions,
      };
    }

    case actionTypes.FinishedAttention: {
      const newAttentions = [...state.attentions];

      const index = newAttentions.findIndex(({ attentionId }) => attentionId === action.payload.attentionId);
      if (index !== -1) {
        newAttentions.splice(index, 1);
      }

      return {
        ...state,
        attentions: newAttentions,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setPlanningAttentions: attentions => ({
    type: actionTypes.SetPlanningAttentions,
    payload: attentions,
  }),
  requestPlanningAttentions: () => ({
    type: actionTypes.RequestPlanningAttentions,
  }),
  snoozeAttention: (attentionId, snoozedUntil) => ({
    type: actionTypes.SnoozeAttention,
    payload: {
      attentionId,
      snoozedUntil: moment(snoozedUntil).utc(),
    },
  }),
  finishAttention: attentionId => ({
    type: actionTypes.FinishAttention,
    payload: {
      attentionId,
    },
  }),
  snoozedAttention: (attentionId, snoozedUntil) => ({
    type: actionTypes.SnoozedAttention,
    payload: {
      attentionId,
      snoozedUntil,
    },
  }),
  finishedAttention: attentionId => ({
    type: actionTypes.FinishedAttention,
    payload: {
      attentionId,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestPlanningAttentions, function* invoke() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getPlanningAttentions(currentTenantId, 1, 50000);

    yield put(actions.setPlanningAttentions(response.data['hydra:member']));
  });

  yield takeLatest(actionTypes.SnoozeAttention, function* invoke({ payload }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    yield snoozeAttention(currentTenantId, payload.attentionId, payload.snoozedUntil.format('YYYY-MM-DD HH:mm:ss'));

    yield put(actions.snoozedAttention(payload.attentionId, payload.snoozedUntil));
  });

  yield takeLatest(actionTypes.FinishAttention, function* invoke({ payload }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    yield finishAttention(currentTenantId, payload.attentionId);

    yield put(actions.finishedAttention(payload.attentionId));
  });
}
