import { put, select, takeLatest } from 'redux-saga/effects';
import { getEventsWithoutHoursEntryByEmployeeId } from '../Services/HoursEntryService';

export const actionTypes = {
  RequestForEmployee: '[EventsWithoutHoursEntry] Request for employee',
  EnteredHours: '[EventsWithoutHoursEntry] EnteredHours',
  FulfilledData: '[EventsWithoutHoursEntry] Fulfilled',
};

const initialState = {
  items: [],
  employeeId: null,
  loading: true,
  totalCount: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RequestForEmployee: {
      return {
        ...state,
        employeeId: action.employeeId,
        loading: true,
      };
    }

    case actionTypes.FulfilledData: {
      return {
        ...state,
        items: action.items,
        totalCount: action.totalCount,
        loading: false,
      };
    }

    case actionTypes.EnteredHours: {
      const { items } = state;
      const itemIndex = items.findIndex(({ eventId }) => eventId === action.eventId);
      items[itemIndex].finished = true;

      return {
        ...state,
        items,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  requestForEmployee: employeeId => ({
    type: actionTypes.RequestForEmployee,
    employeeId,
  }),
  enteredHour: eventId => ({
    type: actionTypes.EnteredHours,
    eventId,
  }),
  fulfilled: (items, totalCount) => ({
    type: actionTypes.FulfilledData,
    items,
    totalCount,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestForEmployee, function* invoke({ employeeId }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const response = yield getEventsWithoutHoursEntryByEmployeeId(currentTenantId, employeeId);

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
