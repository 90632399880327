import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { EstimateService } from 'services';
import { actions as FailedEstimatesActions } from '../../Domain/FailedEstimates/Ducks/FailedEstimates.duck';

const useStyles = makeStyles(() => ({
  listItem: {
    cursor: 'pointer',
    height: '64px',
    boxSizing: 'border-box',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}));

const QuoteItemComponent = ({ quote, handleClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [quoteItem, setQuoteItem] = useState(quote);
  const tenantId = useSelector(state => state.AppReducer.tenant.tenantId);
  const { authToken } = useSelector(state => state.AppReducer);

  const renderStatus = status => {
    switch (status) {
      case 'retrying':
        return <Typography align="right">Retrying...</Typography>;
      default:
        return (
          <Typography align="right" color="error">
            Failed
          </Typography>
        );
    }
  };

  const itemClick = () => {
    setQuoteItem({ ...quote, status: 'retrying' });
    (async () => {
      try {
        const quoteResult = await EstimateService.sendQuoteRequest(authToken, tenantId, quote.projectId, quote);
        if (quoteResult.projectEstimate) {
          dispatch(FailedEstimatesActions.remove(quote.projectId));
        }
      } catch (error) {
        setQuoteItem({ ...quote, status: 'failed' });
      }
    })();
  };

  return (
    <ListItem className={classes.listItem} onClick={handleClick}>
      <ListItemText
        onClick={() => itemClick()}
        primary={
          <Grid container>
            <Grid item xs={6}>
              {quoteItem.customerForm?.companyName}
            </Grid>
            <Grid item xs={6}>
              {renderStatus(quoteItem.status)}
            </Grid>
          </Grid>
        }
        secondary={
          <>
            {`${quoteItem.addressFrom?.zipCode} - ${quoteItem.addressFrom?.city}`}
            &nbsp;
            <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
            &nbsp;
            {`${quoteItem.addressTo?.zipCode} - ${quoteItem.addressTo?.city}`}
          </>
        }
      />
    </ListItem>
  );
};

export default QuoteItemComponent;
