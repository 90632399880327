import { useDispatch, useSelector } from 'react-redux';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { IntlContext } from 'utils/useFormatMessage';
import { Button, Grid, LinearProgress, Snackbar, Typography } from '@material-ui/core';
import nlTranslations from 'translations/nl.json';
import enTranslations from 'translations/en.json';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from 'app/theme';
import { SecureRoute } from 'components';
import moment from 'moment';
import 'moment/locale/nl';
import { ApolloProvider } from '@apollo/react-hooks';
import LanguageService from '@cospired/i18n-iso-languages';
import CountryService from 'i18n-iso-countries';
import nlValidations from 'translations/validations/nl';
import nlLanguages from '@cospired/i18n-iso-languages/langs/nl.json';
import enLanguages from '@cospired/i18n-iso-languages/langs/en.json';
import nlCountries from 'i18n-iso-countries/langs/nl.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
// import { setLocale } from 'yup';
import { setLocale } from 'yup';
import { Alert } from '@material-ui/lab';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import isAdminApplication from 'utils/isAdminApplication';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import * as serviceWorker from 'serviceWorkerRegistration';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TenantService } from '../../services';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';
import BasClient from '../../services/BasClient';
import registerCustomYupTypes from '../../utils/registerCustomYupTypes';
import Notifier from '../Notifier';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import ErrorPage from '../../Domain/App/Pages/ErrorPage';
import { appHistory } from '../../index';
import ReactQueryMercureListener from '../ReactQueryMercureListener';

require('dayjs/locale/nl');

const translations = {
  en: enTranslations,
  nl: nlTranslations,
};

LanguageService.registerLocale(nlLanguages);
LanguageService.registerLocale(enLanguages);
CountryService.registerLocale(nlCountries);
CountryService.registerLocale(enCountries);

registerCustomYupTypes();

const EmployeeRoutes = lazy(() => import('app/EmployeeRoutes'));
const BackOfficeRoutes = lazy(() => import('app/BackOfficeRoutes'));
const LoginPage = lazy(() => import('Domain/App/Pages/LoginPage'));
const ResetPasswordPage = lazy(() => import('Domain/App/Pages/ResetPasswordPage'));
const CreatePasswordPage = lazy(() => import('Domain/App/Pages/CreatePasswordPage'));
const ForgotPasswordPage = lazy(() => import('Domain/App/Pages/ForgotPasswordPage'));
const LogoutPage = lazy(() => import('Domain/App/Pages/LogoutPage'));
const AcceptQuotePage = lazy(() => import('Domain/Financial/Pages/Public/Quote/AcceptQuotePage'));
const FinishedPaymentPage = lazy(() => import('Domain/Financial/Pages/Public/Invoice/FinishedPaymentPage'));
const MobileApp = lazy(() => import('Domain/MobileApp/Pages'));
const AdminRoutes = lazy(() => import('Domain/Admin/Pages'));
const queryClient = new QueryClient();

dayjs.extend(isBetween);

const useStyles = makeStyles(() => ({
  typo: {
    marginBottom: '10px',
  },
}));

export const InjectIntlContext = injectIntl(({ intl, children }) => (
  <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
));

const AppProvider = () => {
  const lang = useSelector(state => state.AppReducer.language);
  dayjs.locale(lang);
  moment.locale(lang);
  if (lang === 'nl') {
    setLocale(nlValidations);
  }

  // if (typeof validationTranslations[lang] !== 'undefined') {
  //   setLocale(validationTranslations[lang]);
  // }

  const dispatch = useDispatch();
  const { online } = useOnlineStatus();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const firstStep = useSelector(state => state.FirstStepReducer);
  const { tenantId, frontendVersion } = useSelector(state => state.AppReducer.tenant);
  const { authToken } = useSelector(state => state.AppReducer);
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = registration => {
    console.log('Found new service worker update');
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onUpdateApp = () => {
    console.log('Updating service worker');
    if (waitingWorker) {
      waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
    }
    window.location.reload(true);
  };

  useEffect(() => {
    (async () => {
      serviceWorker.register({ onUpdate: onSWUpdate });
      navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        if (serviceWorkerRegistration.waiting) {
          onSWUpdate(serviceWorkerRegistration);
        }
      });
    })();
  }, []);

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const tenant = await TenantService.getTenantIdByHostname(window.location.hostname);
        dispatch(AppActions.setTenant(tenant));
        if (authToken && tenant.useIdentities) {
          const response = await TenantService.getIdentitiesByTenantId(tenant.tenantId);
          dispatch(AppActions.setIdentities(response.data['hydra:member']));
        }
        if (authToken) {
          dispatch(AppActions.requestVatCodes());
        }
        setLoading(false);
      } catch (error) {
        const { active, selected } = firstStep;
        dispatch(MovingJobEstimateActions.setError({ error: { ...error, active, selected } }));
      }
    };

    if (!isAdminApplication) {
      makeRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  if (loading && !isAdminApplication) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography className={classes.typo} variant="h5" component="h5" align="center">
            Loading
          </Typography>
          <LinearProgress color="secondary" />
        </Grid>
      </Grid>
    );
  }

  const client = BasClient(tenantId, authToken);
  moment.locale(lang);

  let content = '';
  if (isAdminApplication) {
    content = (
      <>
        <SecureRoute path="" component={AdminRoutes} />
      </>
    );
  } else {
    content = (
      <Switch>
        <Route path="/financial/quotes/accept/:code" component={AcceptQuotePage} />
        <Route path="/financial/quotes/accepted/:code" component={AcceptQuotePage} />
        <Route path="/financial/invoices/was-already-paid/:code" component={FinishedPaymentPage} />
        <Route path="/financial/invoices/payment-finished/:code" component={FinishedPaymentPage} />
        <Route path="/financial/invoices/payment-failed/:code" component={FinishedPaymentPage} />
        <SecureRoute path="/employee" component={EmployeeRoutes} />
        <SecureRoute path="/app" component={MobileApp} />
        <SecureRoute path="" component={BackOfficeRoutes} />
      </Switch>
    );
  }

  if (frontendVersion) {
    console.log(`Version ${frontendVersion.version} expected version ${process.env.REACT_APP_RELEASE}`);
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={lang} messages={translations[lang]}>
          <InjectIntlContext>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ReactQueryMercureListener />
              <MuiPickersUtilsProvider locale={lang} libInstance={moment} utils={MomentUtils}>
                <SnackbarProvider maxSnack={3}>
                  <Snackbar open={!online} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="error">
                      <FormattedMessage id="app.offline" />
                    </Alert>
                  </Snackbar>
                  <Snackbar open={showReload} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                      color="warning"
                      action={
                        <Button color="inherit" size="small" onClick={onUpdateApp}>
                          <FormattedMessage id="button.reload" />
                        </Button>
                      }
                    >
                      <FormattedMessage id="app.usingOldVersion" />
                    </Alert>
                  </Snackbar>
                  <Notifier />
                  <Router history={appHistory}>
                    <Sentry.ErrorBoundary
                      fallback={({ error, componentStack, resetError, eventId }) => (
                        <ErrorPage
                          error={error}
                          componentStack={componentStack}
                          eventId={eventId}
                          resetError={resetError}
                        />
                      )}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                          <Route path="/login" component={LoginPage} />
                          <Route path="/create-password/:code" component={CreatePasswordPage} />
                          <Route path="/reset-password/:code" component={ResetPasswordPage} />
                          <Route path="/forgot-password" component={ForgotPasswordPage} />
                          <Route path="/logout" component={LogoutPage} />
                          {content}
                        </Switch>
                      </Suspense>
                    </Sentry.ErrorBoundary>
                  </Router>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </QueryClientProvider>
          </InjectIntlContext>
        </IntlProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default AppProvider;
