import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';

const GenderField = props => (
  <TextField {...props} select>
    <MenuItem value="">
      <FormattedMessage id="customers.gender.unknown" />
    </MenuItem>
    <MenuItem value="male">
      <FormattedMessage id="customers.gender.male" />
    </MenuItem>
    <MenuItem value="female">
      <FormattedMessage id="customers.gender.female" />
    </MenuItem>
  </TextField>
);

export default GenderField;
