import { put, select, takeLatest } from 'redux-saga/effects';
import { getProjectsToPlan } from '../../Project/Services/ProjectService';
import { getAttentions } from '../Services/PlanningService';

export const actionTypes = {
  SetProjectsToBePlanned: '[PlanningOverview] Set projects needed to be planned',
  SetAttentions: '[PlanningOverview] Set attentions',
  RequestPlanningOverviewData: '[PlanningOverview] request PlanningOverview data',
};

const initialState = {
  projectsToBePlanned: [],
  attentions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetProjectsToBePlanned: {
      return {
        ...state,
        projectsToBePlanned: action.payload,
      };
    }

    case actionTypes.SetAttentions: {
      return {
        ...state,
        attentions: action.payload,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setProjectsToBePlanned: projectsToBePlanned => ({
    type: actionTypes.SetProjectsToBePlanned,
    payload: projectsToBePlanned,
  }),
  setAttentions: attentions => ({
    type: actionTypes.SetAttentions,
    payload: attentions,
  }),
  requestPlanningOverviewData: () => ({
    type: actionTypes.RequestPlanningOverviewData,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestPlanningOverviewData, function* requestAttentions() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getAttentions(currentTenantId, 1, 50000);

    yield put(actions.setAttentions(response.data['hydra:member']));
  });

  yield takeLatest(actionTypes.RequestPlanningOverviewData, function* requestProjects() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getProjectsToPlan(currentTenantId, 1, 50000);

    yield put(actions.setProjectsToBePlanned(response.data['hydra:member']));
  });
}
