import axios from 'axios';
import Qs from 'qs';
import { getCustomer } from '../../Domain/Customer/Services/CustomerService';

let relationTokenSource;
const getRelationQuotes = async (tenantId, relationId) => {
  try {
    if (typeof relationTokenSource !== typeof undefined) {
      relationTokenSource.cancel('Operation canceled due to new request.');
    }

    relationTokenSource = axios.CancelToken.source();

    const { data } = await axios.get(`api/${tenantId}/relations/${relationId}/quotes`, {
      cancelToken: relationTokenSource.token,
    });

    return { result: data };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const mapField = field => {
  switch (field) {
    case 'customer':
      return ['relation.name'];
    case 'totalExcludingVat':
      return ['totalExcludingVat.amount'];
    case 'totalIncludingVat':
      return ['totalIncludingVat.amount'];
    default:
      return [field];
  }
};
const getQuotes = async (tenantId, page, perPage, order, appliedFilters, globalFilter) => {
  const appliedOrder = {};
  Object.keys(order).forEach(key => {
    mapField(key).forEach(field => {
      appliedOrder[field] = order[key];
    });
  });

  try {
    const result = await axios.get(`api/${tenantId}/quotes`, {
      params: {
        ...appliedFilters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });

    await Promise.all(
      result.data['hydra:member'].map(async item => {
        const contents = await getCustomer(tenantId, item.relation.split('/')[4]);
        item.customer = contents.data;
      }),
    );

    return result;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const getQuote = async (tenantId, quoteId) => {
  const result = await axios.get(`api/${tenantId}/quotes/${quoteId}`);
  const customerResponse = await getCustomer(tenantId, result.data.relation.split('/')[4]);

  return { data: { ...result.data, relation: customerResponse.data } };
};

const previewQuote = (tenantId, quoteId) =>
  axios.get(`api/${tenantId}/quotes/${quoteId}/preview-pdf`, {
    responseType: 'blob',
  });

const downloadQuote = (tenantId, quoteId) =>
  axios.get(`api/${tenantId}/quotes/${quoteId}/download-pdf`, {
    responseType: 'blob',
  });

const downloadAcceptedQuote = (tenantId, quoteId) =>
  axios.get(`api/${tenantId}/quotes/${quoteId}/download-accepted-pdf`, {
    responseType: 'blob',
  });

const createQuote = (tenantId, quoteId, values) =>
  axios.post(`api/${tenantId}/quotes`, { ...values, tenantId, quoteId });

const updateQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}`, { ...values, tenantId, quoteId });

const deleteQuote = (tenantId, quoteId) => axios.put(`api/${tenantId}/quotes/${quoteId}/delete`, { tenantId, quoteId });

const calculateExpiresAt = (tenantId, quoteId) => axios.get(`api/${tenantId}/quotes/${quoteId}/calculate-expires-at`);

const sendQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}/send`, { ...values, quoteId, tenantId });

const acceptQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}/accept`, { ...values, quoteId, tenantId });

const cancelQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}/cancel`, { ...values, quoteId, tenantId });

const declineQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}/decline`, { ...values, quoteId, tenantId });

const invoiceQuote = (tenantId, quoteId, values) =>
  axios.put(`api/${tenantId}/quotes/${quoteId}/invoice`, { ...values, quoteId, tenantId });

const addAttachmentToQuote = async (tenantId, quoteId, values) =>
  axios.put(`/api/${tenantId}/quotes/${quoteId}/add-attachment`, {
    ...values,
    quoteId,
    tenantId,
  });

const removeAttachmentFromQuote = async (tenantId, quoteId, values) =>
  axios.put(`/api/${tenantId}/quotes/${quoteId}/remove-attachment`, {
    ...values,
    quoteId,
    tenantId,
  });

const getQuoteStatistics = async (tenantId, year, month) =>
  axios.get(`/api/${tenantId}/quotes/quote-statistics/${year}/${month}`);

export default {
  createQuote,
  updateQuote,
  deleteQuote,
  addAttachmentToQuote,
  removeAttachmentFromQuote,
  getRelationQuotes,
  getQuotes,
  getQuote,
  previewQuote,
  calculateExpiresAt,
  downloadQuote,
  downloadAcceptedQuote,
  sendQuote,
  acceptQuote,
  cancelQuote,
  declineQuote,
  invoiceQuote,
  getQuoteStatistics,
};
