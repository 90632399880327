import axios from 'axios';
import Qs from 'qs';

export const addNewCommunication = async (tenantId, relationId, values) =>
  axios.post(`api/${tenantId}/relations/${relationId}/communications`, {
    ...values,
    relationId,
    tenantId,
  });

let communicationsToken;
export const getRelationCommunications = (tenantId, relationId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof communicationsToken !== typeof undefined) {
    communicationsToken.cancel('Operation canceled due to new request.');
  }
  communicationsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/relations/${relationId}/communications`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: communicationsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let projectCommunicationsToken;
export const getProjectCommunications = (tenantId, projectId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof projectCommunicationsToken !== typeof undefined) {
    projectCommunicationsToken.cancel('Operation canceled due to new request.');
  }
  projectCommunicationsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/projects/${projectId}/communications`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: projectCommunicationsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};
