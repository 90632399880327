import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { rootReducer, rootSaga } from './root.duck';
import mercureMiddleware from './mercureMiddleware';
import isAdminApplication from '../utils/isAdminApplication';
import { adminReducer, adminSaga } from './admin.duck';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

// Create persisted reducers using redux-persist
const persistedReducers = isAdminApplication ? adminReducer : rootReducer;

export const store = createStore(
  persistedReducers,
  composeEnhancers(applyMiddleware(mercureMiddleware, sagaMiddleware), sentryReduxEnhancer),
);

export const persistor = persistStore(store);

sagaMiddleware.run(isAdminApplication ? adminSaga : rootSaga);
