import { put, select, takeLatest } from 'redux-saga/effects';
import { StorageService } from 'services';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { listEventsByProject } from '../../Planning/Services/PlanningService';

export const actionTypes = {
  ResetData: '[Storage] Reset Storage',
  RequestData: '[Storage] Request Storage',
  FulfilledData: '[Storage] Fulfilled Storage',
  FulfilledEvents: '[Storage] Fulfilled events',
};

const initialState = {
  ...singleObjectInitialState,
  events: [],
  loadingEvents: false,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...state,
        loadingEvents: true,
      };
    }
    case actionTypes.FulfilledEvents: {
      return {
        ...state,
        loadingEvents: false,
        events: action.events,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulfilledEvents: events => ({
    type: actionTypes.FulfilledEvents,
    events,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestStorageSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    if (action.itemId) {
      const response = yield StorageService.getStorage(currentTenantId, action.itemId);
      yield put(actions.fulfilledData(response));
      if (response.storedForProject) {
        const eventsResponse = yield listEventsByProject(currentTenantId, response.storedForProject.projectId);
        yield put(
          actions.fulfilledEvents(
            eventsResponse.result['hydra:member'].filter(({ eventType }) =>
              ['moving-job-load-storage', 'moving-job-moving'].includes(eventType),
            ) || [],
          ),
        );
      } else {
        yield put(actions.fulfilledEvents([]));
      }
    }
  });
}
