import axios from 'axios';

let tokenSource;

const authenticate = async (tenantId, username, password) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const response = await axios.post(
      `api/auth/login`,
      { email: username, password },
      {
        params: {
          tenantId,
        },
      },
      {
        cancelToken: tokenSource.token,
      },
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const verifyToken = async (tenantId, token, code) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const response = await axios.post(
      `api/auth/two-factor-check`,
      {
        code,
        tenantId,
      },
      {
        params: {
          code,
          tenantId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {
        cancelToken: tokenSource.token,
      },
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const getUser = async (token, userId) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const response = await axios.get(
      `api/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {
        cancelToken: tokenSource.token,
      },
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const forgotPassword = async (tenantId, emailAddress) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const response = await axios.post(
      `api/users/forgot-password`,
      { emailAddress, tenantId },
      {},
      {
        cancelToken: tokenSource.token,
      },
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const resetPassword = async (resetRequestId, code, password) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const response = await axios.post(
      `api/users/reset-password`,
      { resetRequestId, code, password },
      {},
      {
        cancelToken: tokenSource.token,
      },
    );

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

export default {
  authenticate,
  verifyToken,
  getUser,
  forgotPassword,
  resetPassword,
};
