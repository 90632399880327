import { put, select, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getVehicle } from '../../../services/VehicleService';

export const actionTypes = {
  ResetData: '[Vehicle] Reset Vehicle',
  RequestData: '[Vehicle] Request Vehicle',
  FulfilledData: '[Vehicle] Fulfilled Vehicle',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestVehicleSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getVehicle(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });
}
