export const singleObjectInitialState = {
  item: undefined,
  loading: true,
  loaded: false,
  locked: false,
};

export const singleObjectAction = (actionTypes, state, action) => {
  switch (action.type) {
    case actionTypes.FulfilledData: {
      return {
        ...state,
        loading: false,
        loaded: true,
        locked: action.locked,
        item: action.item,
      };
    }

    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.UnlockItem: {
      return {
        ...state,
        locked: false,
      };
    }
    case actionTypes.LockItem: {
      return {
        ...state,
        locked: true,
      };
    }

    default:
      return state;
  }
};

export const singleObjectActions = actionTypes => ({
  resetData: () => ({ type: actionTypes.ResetData }),
  requestData: itemId => ({
    type: actionTypes.RequestData,
    itemId,
  }),
  unlockItem: () => ({
    type: actionTypes.UnlockItem,
  }),
  lockItem: () => ({
    type: actionTypes.LockItem,
  }),
  fulfilledData: (item, locked = false) => ({
    type: actionTypes.FulfilledData,
    item,
    locked,
  }),
});
