import { put, takeLatest } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

export const actionTypes = {
  FilledFormSecondStep: '[Second Step] Fill form',
  LoadingPageSecondStep: '[Second Step] Loading Page',
  NextPageSecondStep: '[Second Step] Next Page',
  NextSecondStep: '[Second Step] Next',
  PrevSecondStep: '[Second Step] Prev',
  PrevPageSecondStep: '[Second Step] Prev Page',
  ResetSecondStep: '[Second Step] Reset',
  SetActiveSecondStep: '[Second Step] Active Step',
  ToggleHiddenNavSecondStep: '[Second Step] Toggle nav',
  SetSkipPageSecondStep: '[Second Step] Skip Page',
  PropSelectSecondStep: '[Second Step] Select Prop',
};

const initialState = {
  active: 0,
  clickedOrientation: 'left',
  loading: false,
  hidden: false,
  disabledSwipe: true,
  propSelected: { value: '', index: '' },
  roomFurnitureList: [],
  skipSteps: { assembleForm: true, disassembleForm: true, storageForm: true, storageBoxesForm: true },
  disabled: false,
  selected: {
    nextPage: 'boxesForm',
    prevPage: '',
    validate: false,
    canGoForward: true,
    formType: 'furnitureForm',
    completed: true,
    categoryType: 1,
  },
  items: [
    {
      nextPage: 'boxesForm',
      prevPage: '',
      validate: false,
      canGoForward: true,
      formType: 'furnitureForm',
      completed: true,
      showDot: true,
      categoryType: 1,
    },
    {
      nextPage: 'disassembleForm',
      prevPage: 'furnitureForm',
      formType: 'boxesForm',
      validate: true,
      canGoForward: false,
      completed: false,
      showDot: true,
      categoryType: 2,
    },
    {
      nextPage: 'assembleForm',
      prevPage: 'boxesForm',
      formType: 'disassembleForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: true,
      categoryType: 3,
    },
    {
      nextPage: 'storageForm',
      prevPage: 'disassembleForm',
      formType: 'assembleForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: false,
      categoryType: 3,
    },
    {
      nextPage: 'storageBoxesForm',
      prevPage: 'assembleForm',
      formType: 'storageForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: false,
      categoryType: 3,
    },
    {
      nextPage: 'photoForm',
      prevPage: 'storageForm',
      formType: 'storageBoxesForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: false,
      categoryType: 3,
    },
    {
      nextPage: '',
      prevPage: 'storageForm',
      formType: 'photoForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: true,
      categoryType: 4,
    },
  ],
};

export const reducer = persistReducer(
  { storage: localforage, key: 'second-step', whitelist: ['skipSteps'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetActiveSecondStep: {
        return {
          ...state,
          ...action.payload,
        };
      }
      case actionTypes.NextPageSecondStep: {
        const copyItems = [...state.items];
        const page = action.payload.active >= copyItems.length - 1 ? action.payload.active : action.payload.active + 1;
        let index = copyItems.findIndex(item => item.formType === action.payload.selected.nextPage);
        let nextItem = { ...copyItems[index] };
        if (!nextItem.validate) {
          nextItem.completed = true;
        }

        copyItems[index] = nextItem;

        while (!nextItem || state.skipSteps[nextItem.formType]) {
          // eslint-disable-next-line no-loop-func
          index = copyItems.findIndex(item => item.formType === nextItem.nextPage);
          nextItem = { ...copyItems[index] };

          if (!nextItem.validate) {
            nextItem.completed = true;
          }

          copyItems[index] = nextItem;
        }

        return {
          ...state,
          active: page,
          clickedOrientation: 'left',
          loading: false,
          selected: nextItem,
          items: copyItems,
        };
      }
      case actionTypes.PrevPageSecondStep: {
        let prevItem = state.items.find(item => item.formType === action.payload.selected.prevPage);

        while (!prevItem || state.skipSteps[prevItem.formType]) {
          // eslint-disable-next-line no-loop-func
          prevItem = state.items.find(item => item.formType === prevItem.prevPage);
        }

        return {
          ...state,
          ...action.payload,
          clickedOrientation: 'right',
          loading: false,
          selected: prevItem,
        };
      }
      case actionTypes.LoadingPageSecondStep: {
        return {
          ...state,
          loading: false,
          disabled: action.payload,
        };
      }
      case actionTypes.ToggleHiddenNavSecondStep: {
        return {
          ...state,
          hidden: action.payload,
        };
      }
      case actionTypes.PropSelectSecondStep: {
        return {
          ...initialState,
          ...action.payload,
          skipSteps: state.skipSteps,
        };
      }
      case actionTypes.FilledFormSecondStep: {
        const copyItems = [...state.items];

        const index = copyItems.findIndex(item => item.formType === state.selected.formType);
        const itemSelectedInArray = { ...copyItems[index] };
        itemSelectedInArray.canGoForward = action.payload;
        itemSelectedInArray.completed = action.payload;
        copyItems[index] = itemSelectedInArray;

        return {
          ...state,
          selected: {
            ...state.selected,
            canGoForward: action.payload,
            completed: action.payload,
          },
          items: copyItems,
        };
      }
      case actionTypes.ResetSecondStep: {
        return {
          ...initialState,
          skipSteps: state.skipSteps,
        };
      }
      case actionTypes.SetSkipPageSecondStep: {
        const skipStepsCopy = { ...state.skipSteps };
        skipStepsCopy[action.payload.formType] = action.payload.skip;

        return {
          ...state,
          skipSteps: skipStepsCopy,
        };
      }
      default:
        return { ...state };
    }
  },
);

export const actions = {
  toggleNav: data => ({
    type: actionTypes.ToggleHiddenNavSecondStep,
    payload: data,
  }),
  setNav: data => ({ type: actionTypes.SetActiveSecondStep, payload: data }),
  setLoading: data => ({ type: actionTypes.LoadingPageSecondStep, payload: data }),
  nextPage: (data, selected) => ({
    type: actionTypes.NextPageSecondStep,
    payload: { active: data, selected },
  }),
  prevPage: (data, selected) => ({
    type: actionTypes.PrevPageSecondStep,
    payload: { active: data.selected, selected },
  }),
  filledForm: data => ({ type: actionTypes.FilledFormSecondStep, payload: data }),
  reset: () => ({ type: actionTypes.ResetSecondStep }),
  setCustomerStep: () => ({ type: actionTypes.SetCustomerStep }),
  next: (data, selected) => ({ type: actionTypes.NextSecondStep, payload: { data, selected } }),
  prev: (data, selected) => ({ type: actionTypes.PrevSecondStep, payload: { data, selected } }),
  setSkip: (formType, skip) => ({
    type: actionTypes.SetSkipPageSecondStep,
    payload: { formType, skip },
  }),
  setPropSelected: (data, roomFurnitureList) => ({
    type: actionTypes.PropSelectSecondStep,
    payload: { propSelected: data, roomFurnitureList },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.NextSecondStep, function* nextPageSaga(action) {
    yield put(actions.setLoading());
    yield put(actions.nextPage(action.payload.data, action.payload.selected));
  });
  yield takeLatest(actionTypes.PrevSecondStep, function* prevPageSaga(action) {
    const page = action.payload.data === 0 ? action.payload.data : action.payload.data - 1;
    yield put(actions.setLoading());
    yield put(actions.prevPage(page, action.payload.selected));
  });
}
