import { normalizeErrors } from './dataAccess';

const handleBackendErrors = async callable => {
  try {
    return await callable();
  } catch (e) {
    if (e.response && [400, 422].includes(e.response.status)) {
      return normalizeErrors(e.response.data);
    }

    throw e;
  }
};

export default { handleBackendErrors };
