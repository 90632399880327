import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MapComponent from 'components/MapComponent';

const useStyles = makeStyles({
  paddingBottom: {
    height: 'calc(100%)',
    paddingBottom: '80px',
  },
});

const WizardComponent = ({ mapper, reducer, formKey, backFunction, nextFunction, loadingFunction, style }) => {
  const classes = useStyles();

  const [show, setShow] = useState();
  const { selected, clickedOrientation, loading, active, disabledSwipe } = useSelector(reducer);
  const [direction, setDirection] = useState(0);
  const [directionToGo, setDirectionToGo] = useState(0);
  const [display, setDisplay] = useState(false);

  const handleTouchStart = e => {
    setDirection(e.targetTouches[0].clientX);
    setDirectionToGo(e.targetTouches[0].clientX);
  };

  const handleMove = e => {
    setDirectionToGo(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!disabledSwipe) {
      if (directionToGo - direction < -200 && selected.canGoForward && selected.nextPage) {
        loadingFunction(true);
        nextFunction(active, selected);
      }
      if (directionToGo - direction > 200 && selected.prevPage) {
        loadingFunction(true);
        backFunction(active, selected);
      }
    }
  };

  useEffect(() => {
    loadingFunction(true);
    setShow(false);
    setTimeout(() => {
      loadingFunction(false);
    }, 1000);
    setTimeout(() => {
      setShow(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.formType]);

  useEffect(() => {
    if (show) {
      setDisplay(true);
    }
  }, [show]);

  let content = <div>De gegevens zijn nog niet geladen, probeer het nogmaals</div>;
  if (formKey)
    content = (
      <Grid container className={classes.paddingBottom}>
        {display && (
          <Grid item xs={12}>
            <MapComponent selected={selected} mapper={mapper} formKey={formKey} />
          </Grid>
        )}
      </Grid>
    );

  return (
    <>
      {!loading && (
        <Slide
          style={style}
          direction={clickedOrientation}
          in={show}
          mountOnEnter
          timeout={{ appear: 600, enter: 600, exit: 0 }}
          unmountOnExit
          onTouchStart={e => handleTouchStart(e)}
          onTouchMove={e => handleMove(e)}
          onTouchEnd={e => handleTouchEnd(e)}
        >
          {content}
        </Slide>
      )}
    </>
  );
};

WizardComponent.propTypes = {
  mapper: PropTypes.object.isRequired,
  formKey: PropTypes.string.isRequired,
  reducer: PropTypes.func.isRequired,
  style: PropTypes.object,
};

WizardComponent.defaultProps = {
  style: {},
};

export default WizardComponent;
