import { useCallback } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import CountryService from 'i18n-iso-countries';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  label: {
    marginBottom: '7px',
  },
  whiteLabel: {
    color: '#ffffff',
    marginBottom: '7px',
  },
  whiteHelperText: {
    background: 'transparent',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  inputWhite: {
    color: '#ffffff',
  },
  whiteUnderline: {
    '&:before': {
      borderBottom: '1px solid #ffffff',
    },
  },
}));

const CountryAutocomplete = props => {
  const {
    whiteLabel,
    form: { setFieldValue },
    field: { name, value },
  } = props;
  const classes = useStyles();

  const language = useSelector(state => state.AppReducer.language);

  const onChange = useCallback(
    (event, newValue) => {
      setFieldValue(name, newValue || '');
    },
    [setFieldValue, name],
  );

  return (
    <Autocomplete
      {...props}
      style={{ width: '100%' }}
      onChange={onChange}
      defaultValue={value}
      value={value}
      getOptionLabel={option => CountryService.getName(option, language) ?? ''}
      options={Object.keys(CountryService.getAlpha2Codes())}
      renderInput={params => (
        <TextField
          {...fieldToTextField(props)}
          {...params}
          onChange={() => {}}
          fullWidth
          className={whiteLabel ? classes.whiteHelperText : classes.helperText}
          InputLabelProps={{
            className: clsx([classes.root, whiteLabel ? classes.whiteHelperText : classes.helperText]),
          }}
          FormHelperTextProps={{
            className: whiteLabel ? classes.whiteHelperText : classes.helperText,
          }}
          inputProps={{
            ...params.inputProps,
            className: clsx([classes.root, whiteLabel ? classes.inputWhite : classes.helperText]),
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};

export default CountryAutocomplete;
