import { all, spawn, call } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as App from 'Domain/App/Ducks/App.duck';
import * as Integrations from 'Domain/App/Ducks/Integrations.duck';
import * as AutomatedEmails from 'Domain/App/Ducks/AutomatedEmails.duck';
import * as AutomatedEmail from 'Domain/App/Ducks/AutomatedEmail.duck';
import * as Packages from 'Domain/App/Ducks/Packages.duck';
import * as Package from 'Domain/App/Ducks/Package.duck';
import * as FinancialDocumentTemplates from 'Domain/App/Ducks/FinancialDocumentTemplates.duck';
import * as FinancialDocumentTemplate from 'Domain/App/Ducks/FinancialDocumentTemplate.duck';
import * as EstimationSettings from 'Domain/App/Ducks/EstimationSettings.duck';
import * as ServiceTypes from 'Domain/App/Ducks/ServiceTypes.duck';
import * as MovingJobEstimate from 'Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import * as MovingJobEstimateDesktop from 'Domain/MovingJobEstimate/Ducks/MovingJobDesktopEstimate.duck';
import * as FirstStep from 'Domain/FirstStep/Ducks/FirstStep.duck';
import * as SecondStep from 'Domain/SecondStep/Ducks/SecondStep.duck';
import * as ThirdStep from 'Domain/ThirdStep/Ducks/ThirdStep.duck';
import * as FailedEstimates from 'Domain/FailedEstimates/Ducks/FailedEstimates.duck';
import * as Employees from 'Domain/Resource/Ducks/Employees.duck';
import * as Employee from 'Domain/Resource/Ducks/Employee.duck';
import * as Vehicles from 'Domain/Resource/Ducks/Vehicles.duck';
import * as Vehicle from 'Domain/Resource/Ducks/Vehicle.duck';
import * as Customers from 'Domain/Customer/Ducks/Customers.duck';
import * as Customer from 'Domain/Customer/Ducks/Customer.duck';
import * as CustomerQuotes from 'Domain/Customer/Ducks/Quotes.duck';
import * as CustomerInvoices from 'Domain/Customer/Ducks/Invoices.duck';
import * as CustomerStorages from 'Domain/Customer/Ducks/Storages.duck';
import * as CustomerCommunications from 'Domain/Customer/Ducks/CustomerCommunications.duck';
import * as Quotes from 'Domain/Financial/Ducks/Quotes.duck';
import * as Quote from 'Domain/Financial/Ducks/Quote.duck';
import * as Invoices from 'Domain/Financial/Ducks/Invoices.duck';
import * as Invoice from 'Domain/Financial/Ducks/Invoice.duck';
import * as Projects from 'Domain/Project/Ducks/Projects.duck';
import * as Project from 'Domain/Project/Ducks/Project.duck';
import * as Estimates from 'Domain/Project/Ducks/Estimates.duck';
import * as ProjectEvents from 'Domain/Project/Ducks/ProjectEvents.duck';
import * as ProjectStorages from 'Domain/Project/Ducks/ProjectStorages.duck';
import * as MovingJob from 'Domain/Project/Ducks/MovingJob.duck';
import * as Planning from 'Domain/Planning/Ducks/Planning.duck';
import * as PlanningAttentions from 'Domain/Planning/Ducks/PlanningAttentions.duck';
import * as UserChat from 'Domain/Communication/Ducks/UserChat.duck';
import * as Conversations from 'Domain/Communication/Ducks/Conversations.duck';
import * as News from 'Domain/Communication/Ducks/News.duck';
import * as NewsItem from 'Domain/Communication/Ducks/NewsItem.duck';
import * as BatchData from 'Domain/App/Ducks/Integrations/BatchData.duck';
import * as Storage from 'Domain/Storage/Ducks/Storage.duck';
import * as Storages from 'Domain/Storage/Ducks/Storages.duck';
import * as Inventory from 'Domain/Inventory/Ducks/Inventory.duck';
import * as InventoryItem from 'Domain/Inventory/Ducks/InventoryItem.duck';
import * as OutStanding from 'Domain/Inventory/Ducks/OutStanding.duck';
import * as OrderList from 'Domain/Inventory/Ducks/OrderList.duck';
import * as DashboardInvoices from 'Domain/App/Ducks/DashboardInvoices.duck';
import * as DashboardProjects from 'Domain/App/Ducks/DashboardProjects.duck';
import * as DashboardQuotes from 'Domain/App/Ducks/DashboardQuotes.duck';
import * as DashboardQuotesAgreement from 'Domain/App/Ducks/DashboardQuotesAgreement.duck';
import * as Dashboard from 'Domain/App/Ducks/Dashboard.duck';
import * as EventsWithoutHoursEntry from 'Domain/MobileApp/Ducks/EventsWithoutHoursEntry.duck';
import * as ApproveHours from 'Domain/Resource/Ducks/ApproveHours.duck';

const appReducer = combineReducers({
  AppReducer: App.reducer,
  InventoryReducer: Inventory.reducer,
  InventoryItemReducer: InventoryItem.reducer,
  OutStandingReducer: OutStanding.reducer,
  OrderListReducer: OrderList.reducer,
  AutomatedEmailsReducer: AutomatedEmails.reducer,
  AutomatedEmailReducer: AutomatedEmail.reducer,
  PackagesReducer: Packages.reducer,
  PackageReducer: Package.reducer,
  FinancialDocumentTemplatesReducer: FinancialDocumentTemplates.reducer,
  FinancialDocumentTemplateReducer: FinancialDocumentTemplate.reducer,
  EstimationSettingsReducer: EstimationSettings.reducer,
  ServiceTypesReducer: ServiceTypes.reducer,
  MovingJobEstimateReducer: MovingJobEstimate.reducer,
  MovingJobEstimateDesktopReducer: MovingJobEstimateDesktop.reducer,
  FirstStepReducer: FirstStep.reducer,
  SecondStepReducer: SecondStep.reducer,
  ThirdStepReducer: ThirdStep.reducer,
  EmployeesReducer: Employees.reducer,
  EmployeeReducer: Employee.reducer,
  VehiclesReducer: Vehicles.reducer,
  VehicleReducer: Vehicle.reducer,
  CustomerReducer: Customer.reducer,
  CustomerQuotesReducer: CustomerQuotes.reducer,
  CustomerCommunicationsReducer: CustomerCommunications.reducer,
  CustomerInvoicesReducer: CustomerInvoices.reducer,
  CustomerStoragesReducer: CustomerStorages.reducer,
  CustomersReducer: Customers.reducer,
  ProjectReducer: Project.reducer,
  ProjectsReducer: Projects.reducer,
  QuoteReducer: Quote.reducer,
  QuotesReducer: Quotes.reducer,
  InvoiceReducer: Invoice.reducer,
  InvoicesReducer: Invoices.reducer,
  FailedEstimatesReducer: FailedEstimates.reducer,
  EstimatesReducer: Estimates.reducer,
  ProjectEventsReducer: ProjectEvents.reducer,
  ProjectStoragesReducer: ProjectStorages.reducer,
  MovingJobReducer: MovingJob.reducer,
  PlanningReducer: Planning.reducer,
  UserChatReducer: UserChat.reducer,
  StorageReducer: Storage.reducer,
  StoragesReducer: Storages.reducer,
  ConversationsReducer: Conversations.reducer,
  NewsReducer: News.reducer,
  NewsItemReducer: NewsItem.reducer,
  IntegrationsReducer: Integrations.reducer,
  BatchDataReducer: BatchData.reducer,
  DashboardInvoicesReducer: DashboardInvoices.reducer,
  DashboardProjectsReducer: DashboardProjects.reducer,
  DashboardQuotesReducer: DashboardQuotes.reducer,
  DashboardQuotesAgreementReducer: DashboardQuotesAgreement.reducer,
  EventsWithoutHoursEntryReducer: EventsWithoutHoursEntry.reducer,
  PlanningAttentionsReducer: PlanningAttentions.reducer,
  DashboardReducer: Dashboard.reducer,
  ApproveHoursReducer: ApproveHours.reducer,
});

export const rootReducer = (state, action) => appReducer(state, action);

export function* rootSaga() {
  const sagas = [
    App.saga,
    Inventory.saga,
    InventoryItem.saga,
    OutStanding.saga,
    OrderList.saga,
    AutomatedEmails.saga,
    AutomatedEmail.saga,
    Packages.saga,
    Package.saga,
    FinancialDocumentTemplates.saga,
    FinancialDocumentTemplate.saga,
    EstimationSettings.saga,
    ServiceTypes.saga,
    MovingJobEstimate.saga,
    MovingJobEstimateDesktop.saga,
    FirstStep.saga,
    ProjectEvents.saga,
    ProjectStorages.saga,
    SecondStep.saga,
    ThirdStep.saga,
    Employees.saga,
    Employee.saga,
    Vehicles.saga,
    Vehicle.saga,
    Customers.saga,
    Customer.saga,
    Quotes.saga,
    Quote.saga,
    Invoices.saga,
    Invoice.saga,
    Projects.saga,
    Project.saga,
    Estimates.saga,
    MovingJob.saga,
    Planning.saga,
    Conversations.saga,
    UserChat.saga,
    Storage.saga,
    Storages.saga,
    Integrations.saga,
    News.saga,
    NewsItem.saga,
    CustomerQuotes.saga,
    CustomerInvoices.saga,
    CustomerStorages.saga,
    CustomerCommunications.saga,
    BatchData.saga,
    DashboardInvoices.saga,
    DashboardProjects.saga,
    DashboardQuotes.saga,
    DashboardQuotesAgreement.saga,
    EventsWithoutHoursEntry.saga,
    PlanningAttentions.saga,
    Dashboard.saga,
    ApproveHours.saga,
  ];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
