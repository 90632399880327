import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import complete from 'icons/complete.svg';
import unchecked from 'icons/unchecked.svg';
import def from 'icons/default.svg';

const useStyles = makeStyles({
  icon: {
    color: '#BCC1D9',
    marginRight: '9.5px',
  },
  active: {
    color: '#3A78F2',
    marginRight: '9.5px',
  },
  textColor: {
    color: '#BCC1D9',
  },
});

// ---- Should be stateless ---
const PaginationFormComponent = ({ reducer, title }) => {
  const classes = useStyles();
  const navOptions = useSelector(reducer);

  const renderIcons = item => {
    const { selected } = navOptions;
    if (selected.formType === item.formType) {
      return <img src={def} alt="def" />;

      // return <FontAwesomeIcon icon="circle" key={item.formType} color="#3A78F2" size="lg" className={classes.active} />;
    }

    if (selected.categoryType === item.categoryType) {
      return <img src={def} alt="def" />;
      // <FontAwesomeIcon icon="circle" key={item.formType} color="#3A78F2" size="lg" className={classes.active} />;
    }

    if (item.completed) {
      return (
        <>
          <img src={complete} alt="complete" />
          {/* <FontAwesomeIcon icon="check-circle" key={item.formType} color="#BCC1D9" size="lg" className={classes.icon} /> */}
        </>
      );
    }

    return <img src={unchecked} alt="unchecked" />;
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.textColor}>
        {title}
      </Grid>
      <Grid item xs={12}>
        {navOptions.items.map(item => (
          <Fragment key={item.formType}>{item.showDot && renderIcons(item)}</Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default PaginationFormComponent;
