import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useFormatMessage from 'utils/useFormatMessage';
import _ from 'lodash';
import { getProp } from 'utils';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DoneIcon from '@material-ui/icons/Done';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import formatHours from '../../../../utils/formatHours';
import { FormatCurrencyComponent } from '../../../../components';
import './style.css';
import EstimateItemTitleComponent from '../../Components/EstimateItemTitleComponent';
import parseHoursToFloat from '../../../../utils/parseHoursToFloat';
import WizardContext from '../../../EmployeeApp/Utils/WizardContext';
import { overwriteProjectEstimateValue } from '../../../Project/Services/ProjectService';
import { actions as ServiceTypesActions } from '../../../App/Ducks/ServiceTypes.duck';
import { actions as MovingJobEstimateActions } from '../../Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(theme => ({
  typo: {
    borderBottom: '1px solid #DADCE4',
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
  },
  firstBlock: {
    // marginTop: '20px',
    marginBottom: '20px',
  },
  specifications: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  mb20: {
    marginBottom: '20px',
  },
  mt10: {
    marginTop: '10px',
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  title: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    paddingBottom: '21px',
    color: '#2C2E30',
  },
  toggleRoot: {
    width: '100%',
    '& .MuiToggleButton-root.Mui-selected': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.1), -2px 2px 8px rgba(0, 0, 0, 0.15)',
      '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        borderColor: 'transparent',
      },
    },
  },
  percentageField: {
    width: '100%',
    // background: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '5px',
    boxSizing: 'border-box',
    height: '48px',
  },
  textCenter: {
    textAlign: 'center',
    padding: '14px 0',
  },
  whiteButton: {
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderColor: 'transparent',
    fontWeight: 'bold',
    minHeight: '48px',
    borderRadius: '5px',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      borderColor: 'transparent',
    },
  },
  textArea: {
    marginTop: '0',
    background: 'white',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3px',
    marginTop: '3px',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  default: {
    color: '#A2A1AE',
  },
  itemSummary: {
    padding: 0,
  },
  itemDetails: {
    padding: 0,
  },
  listItem: {
    paddingTop: 7,
    paddingBottom: 7,
    width: '100%',
    boxShadow: 'none',
    backgroundColor: '#F2F3F8',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    marginRight: '24px !important',
    marginLeft: '24px !important',
  },
  centerItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnPrice: {
    height: '44px',
    border: '1px solid #DADCE4',
    boxSizing: 'border-box',
    borderRadius: '5px',
    background: '#FFFFFF',
    color: '#000',
    overflow: 'overlay',
  },
  editableRow: {
    zIndex: 15,
    display: 'flex',
    width: '100%',
  },
  above: {
    zIndex: 15,
  },
  currencyInput: {
    background: '#fff',
    '& fieldset': {
      border: 0,
    },
  },
  currencyInputField: {
    textAlign: 'right',
    padding: '11.5px 14px',
  },
  growMore: {
    flexGrow: 2,
    border: '1px solid #DADCE4',
    boxSizing: 'border-box',
    borderRadius: '3px',
    fontSize: '24px',
    fontWeight: 'bold',
    float: 'left',
    width: '42%',
    height: '44px',
    textAlign: 'right',
    paddingRight: '4px',
    letterSpacing: '4px',
  },
  backDrop: {
    position: 'fixed',
    zIndex: 10,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.3,
  },
  btnPriceConfirm: {
    height: '44px',
    border: '1px solid transparent',
    backgroundColor: '#3A78F2',
    color: '#FFFFFF',
    borderRadius: '3px',
    marginLeft: '3px',
    boxSizing: 'border-box',
    float: 'left',
    minWidth: '10%',
  },
  btnPriceMinus: {
    height: '44px',
    border: '1px solid transparent',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    marginRight: '3px',
    boxSizing: 'border-box',
    float: 'left',
    minWidth: '10%',
  },
  btnPricePlus: {
    height: '44px',
    marginLeft: '3px',
    border: '1px solid transparent',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    boxSizing: 'border-box',
    float: 'left',
    minWidth: '10%',
  },
  spaceBetween: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
  },
  amount: {},
  currency: {
    position: 'absolute',
    padding: '0 10px 0 20px',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  comma: {
    position: 'absolute',
    right: '22%',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center!important',
  },
}));

const MovingJobEstimateResultPerHourPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { quoteResult: quote, projectId } = useSelector(state => state.MovingJobEstimateReducer);
  const tenantId = useSelector(state => state.AppReducer.tenant.tenantId);
  const { items: services } = useSelector(state => state.ServiceTypesReducer);

  const [errors, setErrors] = useState(null);
  const [editable, setEditable] = useState({ open: false });
  const [collapses, setCollapses] = useState(null);
  const [total, setTotal] = useState((quote && quote.total && quote.total.amount) || 0);
  const [data, setData] = useState(null);
  const [reason, setReason] = useState('');
  const formatMessage = useFormatMessage();
  const { setNextStepFunction } = useContext(WizardContext);

  useEffect(() => {
    if (!services || services.length === 0) {
      dispatch(ServiceTypesActions.requestData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setNextStepFunction(() => async () => {
      const overwriteItems = [];
      Object.keys(data)
        .filter(value => value !== 'valid')
        .forEach(group => {
          data[group].items.forEach(item => {
            const originalQuantity = item.overwritten ? item.overwritten.quantity : item.calculated.quantity;
            const originalPrice = item.overwritten
              ? item.overwritten.pricePerUnit?.amount
              : item.calculated.pricePerUnit?.amount;
            if (item.price.quantity !== originalQuantity || item.price.pricePerUnit.amount !== originalPrice) {
              overwriteItems.push({
                lineNumber: item.lineNumber,
                quantity: item.price.quantity,
                pricePerUnit:
                  typeof item.price.pricePerUnit.amount !== 'undefined' && item.price.pricePerUnit.amount !== ''
                    ? { ...item.price.pricePerUnit, amount: item.price.pricePerUnit.amount.toString() }
                    : null,
              });
            }
          });
        });

      try {
        await overwriteProjectEstimateValue(tenantId, projectId, {
          reason,
          overwriteItems,
        });

        const newItems = [
          ...quote.items.map(item => {
            const overwritten = overwriteItems.find(({ lineNumber }) => lineNumber === item.lineNumber);
            if (!overwritten) {
              return item;
            }

            return {
              ...item,
              overwritten: { ...overwritten },
            };
          }),
        ];

        const newQuoteResult = {
          ...quote,
          items: newItems,
        };

        await dispatch(MovingJobEstimateActions.save({ quoteResult: newQuoteResult }));

        return true;
      } catch (error) {
        if (error && error.response && error.response.data['hydra:description']) {
          setErrors({ message: error.response.data['hydra:description'] });
        } else {
          setErrors(error);
        }
        return false;
      }
    });
  }, [data, setNextStepFunction, reason, projectId, tenantId, dispatch, history, quote]);

  const getProperties = (key, obj) => {
    if (key === 'price.quantity') {
      if (obj.itemType === 'hours') {
        return {
          type: 'time',
          value: getProp(editable.key, editable.el),
        };
      }
      if (obj.itemType === 'staff') {
        return {
          type: 'number',
          value: getProp(editable.key, editable.el),
        };
      }
    }

    if (key === 'price.pricePerUnit.amount') {
      return {
        value: getProp(editable.key, editable.el),
        maxLength: 6,
        type: 'text',
      };
    }

    return {
      value: getProp(editable.key, editable.el),
      // maxLength: 5,
      // type: 'number',
    };
  };

  const confirm = () => {
    let completeValue = 0;
    const newData = { ...data };
    Object.keys(data)
      .filter(value => value !== 'valid')
      .forEach(it => {
        let value = 0;
        // eslint-disable-next-line no-unused-expressions
        newData[it].items.forEach(item => {
          value += +item.price?.pricePerUnit?.amount * item.price.quantity || 0;
        });
        completeValue += value;

        newData[it] = {
          items: [...newData[it].items],
          price: {
            amount: value,
            currency: 'EUR',
          },
        };
      });

    (async () => {
      await setData(newData);
      setEditable({ open: false });
      setTotal(completeValue.toFixed(0));
    })();
  };

  useEffect(() => {
    const resultData =
      quote &&
      quote.items.map(it => ({
        ...it,
        price: {
          itemTotalPrice: {
            amount: it.overwritten ? it.overwritten.itemTotalPrice?.amount : it.calculated.itemTotalPrice?.amount,
            currency: it.overwritten ? it.overwritten.itemTotalPrice?.currency : it.calculated.itemTotalPrice?.currency,
          },
          pricePerUnit: {
            amount: it.overwritten ? it.overwritten.pricePerUnit?.amount : it.calculated.pricePerUnit?.amount,
            currency: it.overwritten ? it.overwritten.pricePerUnit?.currency : it.calculated.pricePerUnit?.currency,
          },
          quantity: it.overwritten ? it.overwritten.quantity : it.calculated.quantity,
          hours: formatHours(it.overwritten ? it.overwritten.quantity : it.calculated.quantity),
        },
      }));

    const groupedData = _.groupBy(resultData, 'groupType');

    Object.keys(groupedData).forEach(it => {
      let value = 0;
      // eslint-disable-next-line no-unused-expressions
      groupedData[it].forEach(item => {
        value +=
          +(item.overwritten ? item.overwritten.itemTotalPrice?.amount : item.calculated.itemTotalPrice?.amount) || 0;
      });

      groupedData[it] = {
        items: [...groupedData[it]],
        price: {
          amount: value,
          currency: 'EUR',
        },
      };
    });

    setData({ ...groupedData, valid: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && editable.open === false) {
      let itemsOnCollapse = {};
      Object.keys(data)
        .filter(value => value !== 'valid')
        .forEach(item => {
          itemsOnCollapse = { ...itemsOnCollapse, [item]: false };
        });
      setCollapses(itemsOnCollapse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Grid container spacing={2} className={classes.firstBlock}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h3" align="center" className={classes.headerTitle}>
            <FormattedMessage id="quote.result.offerte" />
          </Typography>
          <Typography variant="h3" component="h3" align="center">
            <FormattedMessage id="quote.result.title" />
          </Typography>
        </Grid>
      </Grid>
      {errors && (
        <Grid item xs={12} className={classes.mb20}>
          <Alert severity="error">{errors.message}</Alert>
        </Grid>
      )}
      <Grid container className={classes.mb20}>
        <Grid item xs={12} className={classes.title}>
          <Grid item xs={6}>
            <Typography variant="h6" component="h6">
              <FormattedMessage id="quote.result.total" />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.alignRight}>
            <FormatCurrencyComponent amount={total || 0} currency={(quote && quote.total.currency) || 'EUR'} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {data &&
            collapses &&
            Object.keys(data)
              .filter(value => value !== 'valid')
              .map(item => (
                <Accordion
                  key={item}
                  expanded={collapses[item] || false}
                  onChange={() => setCollapses({ ...collapses, [item]: !collapses[item] })}
                  className={clsx(classes.listItem, classes.typo)}
                >
                  <AccordionSummary
                    className={classes.itemSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box className={classes.spaceBetween}>
                      <Typography variant="subtitle2">
                        <FormattedMessage id={`quote.result.group.${item}`} />
                      </Typography>
                      <FormatCurrencyComponent amount={data[item].price.amount} currency={data[item].price.currency} />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.itemDetails}>
                    <Grid container>
                      {data[item].items.map((el, idx) => {
                        if (editable.idx !== idx) {
                          return (
                            <Grid item xs={12} key={idx}>
                              <Box className={classes.listItem}>
                                <Grid container spacing={1}>
                                  <Grid item xs={5} className={classes.centerItem}>
                                    <Box className={classes.typo}>
                                      <Typography variant="subtitle2">
                                        <EstimateItemTitleComponent services={services} estimateItem={el} />
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3} className={classes.centerItem}>
                                    {['travel_time', 'hours'].includes(el.itemType) && (
                                      <Button
                                        className={classes.btnPrice}
                                        type="text"
                                        color="primary"
                                        onClick={() =>
                                          setEditable({ open: true, idx, el, key: 'price.hours', group: item })
                                        }
                                        fullWidth
                                      >
                                        {formatHours(el.price.quantity)}
                                      </Button>
                                    )}
                                    {el.itemType === 'staff' && (
                                      <Button
                                        className={classes.btnPrice}
                                        type="text"
                                        color="primary"
                                        onClick={() =>
                                          setEditable({ open: true, idx, el, key: 'price.quantity', group: item })
                                        }
                                        fullWidth
                                      >
                                        {el.price.quantity}
                                      </Button>
                                    )}
                                    {['boxes', 'inventory-item'].includes(el.itemType) && (
                                      <Button
                                        className={classes.btnPrice}
                                        type="text"
                                        color="primary"
                                        fullWidth
                                        disabled
                                      >
                                        {parseFloat(el.price.quantity).toFixed(2)}
                                      </Button>
                                    )}
                                    {!['hours', 'staff', 'travel_time', 'boxes', 'inventory-item'].includes(
                                      el.itemType,
                                    ) && (
                                      <Button
                                        className={classes.btnPrice}
                                        type="text"
                                        color="primary"
                                        onClick={() =>
                                          setEditable({ open: true, idx, el, key: 'price.quantity', group: item })
                                        }
                                        fullWidth
                                      >
                                        {parseFloat(el.price.quantity).toFixed(2)}
                                      </Button>
                                    )}
                                  </Grid>
                                  <Grid item xs={4} className={classes.centerItem}>
                                    {el.price.pricePerUnit?.amount && el.price.pricePerUnit?.currency && (
                                      <Button
                                        className={classes.btnPrice}
                                        type="text"
                                        color="primary"
                                        onClick={() =>
                                          setEditable({
                                            open: true,
                                            idx,
                                            el,
                                            key: 'price.pricePerUnit.amount',
                                            group: item,
                                          })
                                        }
                                        fullWidth
                                      >
                                        <FormatCurrencyComponent
                                          amount={el.price.pricePerUnit?.amount}
                                          currency={el.price.pricePerUnit?.currency}
                                        />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          );
                        }

                        if (editable && editable.idx === idx && editable.group === item && editable.open) {
                          return (
                            <Grid item xs={12} className={classes.above} key={idx}>
                              <Box className={classes.editableRow}>
                                <Button
                                  className={classes.btnPriceMinus}
                                  disabled={!data.valid}
                                  onClick={() => {
                                    const newData = { ...data };
                                    if (editable.key === 'price.pricePerUnit.amount') {
                                      newData[item].items[idx].price.pricePerUnit = {
                                        ...newData[item].items[idx].price.pricePerUnit,
                                        amount: `${+newData[item].items[idx].price.pricePerUnit.amount - 100}`,
                                      };
                                    }
                                    if (editable.key === 'price.quantity') {
                                      newData[item].items[idx].price = {
                                        ...newData[item].items[idx].price,
                                        quantity: +newData[item].items[idx].price.quantity - 1,
                                      };
                                    }
                                    if (editable.key === 'price.hours') {
                                      newData[item].items[idx].price = {
                                        ...newData[item].items[idx].price,
                                        quantity: +newData[item].items[idx].price.quantity - 1,
                                        hours: formatHours(newData[item].items[idx].price.quantity - 1),
                                      };
                                    }
                                    setData(newData);
                                  }}
                                >
                                  <RemoveIcon fontSize="large" />
                                </Button>

                                {editable.key === 'price.pricePerUnit.amount' && (
                                  <div className={classes.flexer}>
                                    <CurrencyTextField
                                      value={getProp(editable.key, editable.el) / 100}
                                      className={clsx(classes.growMore, classes.currencyInput)}
                                      currencySymbol="€"
                                      decimalCharacter=","
                                      digitGroupSeparator="."
                                      variant="outlined"
                                      maximumValue="99999"
                                      inputProps={{ className: classes.currencyInputField }}
                                      onChange={(e, newValue) => {
                                        const newData = { ...data };
                                        newData[item].items[idx].price.pricePerUnit = {
                                          ...newData[item].items[idx].price.pricePerUnit,
                                          amount: (newValue * 100).toString(),
                                        };
                                      }}
                                    />
                                  </div>
                                )}
                                {editable.key !== 'price.pricePerUnit.amount' &&
                                  ['hours', 'travel_time'].includes(editable.el.itemType) && (
                                    <IMaskInput
                                      mask={[
                                        {
                                          mask: '0{:}`MM',
                                          lazy: false,
                                          blocks: {
                                            MM: {
                                              mask: IMask.MaskedRange,
                                              from: 0,
                                              to: 59,
                                              maxLength: 2,
                                            },
                                          },
                                        },
                                        {
                                          mask: '00{:}`MM',
                                          lazy: false,
                                          blocks: {
                                            MM: {
                                              mask: IMask.MaskedRange,
                                              from: 0,
                                              to: 59,
                                              maxLength: 2,
                                            },
                                          },
                                        },
                                        {
                                          mask: '000{:}`MM',
                                          lazy: false,
                                          blocks: {
                                            MM: {
                                              mask: IMask.MaskedRange,
                                              from: 0,
                                              to: 59,
                                              maxLength: 2,
                                            },
                                          },
                                        },
                                      ]}
                                      onAccept={value => {
                                        let { quantity } = data[item].items[idx].price;
                                        let valid = false;
                                        if (!value.includes('_')) {
                                          quantity = parseHoursToFloat(value);
                                          valid = true;
                                        }
                                        const newData = { ...data, valid };
                                        newData[item].items[idx].price = {
                                          ...newData[item].items[idx].price,
                                          quantity,
                                          hours: value,
                                        };
                                        setData(newData);
                                      }}
                                      className={clsx(classes.growMore, classes.center)}
                                      value={getProp(editable.key, editable.el)}
                                    />
                                  )}
                                {editable.key !== 'price.pricePerUnit.amount' &&
                                  !['hours', 'travel_time'].includes(editable.el.itemType) && (
                                    <input
                                      className={clsx(classes.growMore, classes.center)}
                                      onChange={e => {
                                        const newData = { ...data };
                                        if (editable.key === 'price.pricePerUnit.amount') {
                                          newData[item].items[idx].price.pricePerUnit = {
                                            ...newData[item].items[idx].price.pricePerUnit,
                                            amount: e.target.value,
                                          };
                                        }
                                        if (editable.key === 'price.quantity') {
                                          let value = parseFloat(e.target.value);
                                          if (Number.isNaN(value)) {
                                            value = 0;
                                          }

                                          newData[item].items[idx].price = {
                                            ...newData[item].items[idx].price,
                                            quantity: value,
                                          };
                                        }
                                        setData(newData);
                                      }}
                                      {...getProperties(editable.key, editable.el)}
                                    />
                                  )}

                                <Button
                                  className={classes.btnPricePlus}
                                  disabled={!data.valid}
                                  onClick={() => {
                                    const newData = { ...data };
                                    if (editable.key === 'price.pricePerUnit.amount') {
                                      newData[item].items[idx].price.pricePerUnit = {
                                        ...newData[item].items[idx].price.pricePerUnit,
                                        amount: +newData[item].items[idx].price.pricePerUnit.amount + 100,
                                      };
                                    }
                                    if (editable.key === 'price.quantity') {
                                      newData[item].items[idx].price = {
                                        ...newData[item].items[idx].price,
                                        quantity: +newData[item].items[idx].price.quantity + 1,
                                      };
                                    }
                                    if (editable.key === 'price.hours') {
                                      newData[item].items[idx].price = {
                                        ...newData[item].items[idx].price,
                                        quantity: +newData[item].items[idx].price.quantity + 1,
                                        hours: formatHours(newData[item].items[idx].price.quantity + 1),
                                      };
                                    }
                                    setData(newData);
                                  }}
                                >
                                  <AddIcon fontSize="large" />
                                </Button>
                                <Button
                                  className={classes.btnPriceConfirm}
                                  onClick={() => confirm()}
                                  disabled={!data.valid}
                                >
                                  <DoneIcon fontSize="large" />
                                </Button>
                              </Box>
                            </Grid>
                          );
                        }

                        return null;
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}

          <Grid container className={classes.specifications} spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="left">
                <FormattedMessage id="quote.result.explain_price" />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.mt10} spacing={4}>
            <Grid item xs={12}>
              <TextField
                required
                color="secondary"
                InputProps={{ className: classes.textArea }}
                variant="outlined"
                name="discountReason"
                multiline
                rows={6}
                onChange={event => setReason(event.target.value)}
                placeholder={formatMessage({ id: 'quote.result.discount.explain' })}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {editable.open && <Box className={classes.backDrop} />}
    </>
  );
};

export default MovingJobEstimateResultPerHourPage;
