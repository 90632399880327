import { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';
import { InputComponent } from '../../components';
import { actions as FirstStepActions } from '../../Domain/FirstStep/Ducks/FirstStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import PhoneNumberField from '../../components/PhoneNumberField';
import { GenderField, SalutationField } from '../../components/Form/Fields';
import WizardContext from '../../Domain/EmployeeApp/Utils/WizardContext';

const useStyles = makeStyles(() => ({
  hiddenButton: {
    marginLeft: '-105vw',
    visibility: 'hidden',
  },
}));

const validationSchema = Yup.object({
  lastName: Yup.string().required(),
  gender: Yup.string().oneOf(['', 'male', 'female']),
  email: Yup.string().required(),
  phoneNumber: Yup.string()
    .phoneNumber()
    .nullable()
    .when('mobileNumber', (mobileNumber, schema) => {
      if (
        typeof mobileNumber === 'undefined' ||
        mobileNumber === null ||
        mobileNumber === '' ||
        ['+32', '+31', '+'].includes(mobileNumber)
      ) {
        return schema.required();
      }

      return schema.nullable();
    }),
  mobileNumber: Yup.string().phoneNumber().nullable(),
  companyName: Yup.string().when('relationType', (relationType, schema) => {
    if (!relationType || relationType === 'private') {
      return schema.nullable();
    }

    return schema.required();
  }),
});

const CustomerForm = () => {
  const formRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const address = useSelector(state => state.AppReducer.tenant.address);
  const customer = useSelector(state => state.MovingJobEstimateReducer.customerForm);
  const error = useSelector(state => state.MovingJobEstimateReducer.error);
  const { active, selected } = useSelector(state => state.FirstStepReducer);

  const validateState = values => {
    const touched = {};
    if (customer && values) {
      Object.keys(values).forEach(key => {
        touched[key] = customer[key] !== values[key];
      });
    }

    const errors = {};
    if (error && error.props && error.props.data) {
      const customerErrors = error.props.data.violations.filter(item => item.propertyPath.includes('customer'));
      customerErrors.forEach(item => {
        const query = item.propertyPath.replace('customer.', '');
        if (typeof touched[query] !== 'boolean' || !touched[query]) {
          errors[query] = item.message || <FormattedMessage id="error.required" />;
        }
      });
    }

    return errors;
  };

  const { setValidateFunction } = useContext(WizardContext);

  const [initialValues] = useState(customer);
  const [initialErrors] = useState(validateState());
  const [initialTouched] = useState(validateState());
  Object.keys(initialTouched).forEach(errorKey => {
    initialTouched[errorKey] = true;
  });

  const validate = async values => {
    let errors = {};

    errors = validateState(values);
    const newValues = { ...values };
    const keys = ['mobileNumber', 'phoneNumber'];
    keys.forEach(key => {
      const value = newValues[key];
      if (typeof value === 'undefined' || value === null || value === '' || ['+32', '+31', '+'].includes(value)) {
        newValues[key] = null;
      }
    });

    dispatch(MovingJobEstimateActions.saveForm({ customerForm: newValues }));

    let valid = false;
    try {
      await validationSchema.validate(values);
      valid = true;
    } catch (e) {
      valid = false;
    }

    if (Object.keys(errors).length === 0 && valid) {
      dispatch(FirstStepActions.filledForm(true));
    } else {
      dispatch(FirstStepActions.filledForm(false));
    }

    return errors;
  };

  useEffect(() => {
    if (formRef.current) {
      setValidateFunction(() => async () => {
        if (!formRef.current) {
          return true;
        }

        await formRef.current.validateForm();
        if (!formRef.current.isValid) {
          await formRef.current.submitForm();

          return false;
        }

        return formRef.current.isValid;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef]);

  if (!customer || Object.keys(customer).length === 0) {
    return '';
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="moving.customer_data" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          innerRef={formRef}
          onSubmit={() => dispatch(FirstStepActions.next(active, selected))}
          initialValues={initialValues}
          validate={values => validate(values)}
          initialErrors={initialErrors}
          initialTouched={initialTouched}
          validationSchema={validationSchema}
          validateOnMount
        >
          {() => (
            <Form>
              <Grid container spacing={3}>
                {customer.relationType === 'business' && (
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      color="secondary"
                      component={InputComponent}
                      name="companyName"
                      label={<FormattedMessage id="moving.customer_data.label.company_name" />}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={GenderField}
                    name="gender"
                    label={<FormattedMessage id="customers.gender" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={SalutationField}
                    name="salutation"
                    label={<FormattedMessage id="customers.salutation" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={InputComponent}
                    name="firstName"
                    label={<FormattedMessage id="moving.customer_data.label.first_name" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={InputComponent}
                    name="middleName"
                    label={<FormattedMessage id="moving.customer_data.label.middle_name" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={InputComponent}
                    name="lastName"
                    label={<FormattedMessage id="moving.customer_data.label.last_name" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={InputComponent}
                    name="email"
                    label={<FormattedMessage id="moving.customer_data.label.email_address" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={PhoneNumberField}
                    name="phoneNumber"
                    label={<FormattedMessage id="moving.customer_data.label.phone_number" />}
                    country={address.country}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    component={PhoneNumberField}
                    name="mobileNumber"
                    label={<FormattedMessage id="moving.customer_data.label.mobile_number" />}
                    country={address.country}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button type="submit" fullWidth className={classes.hiddenButton}>
                <FormattedMessage id="address.add" />
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
