import { useState } from 'react';
import * as Sentry from '@sentry/browser';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { faCarCrash } from '@fortawesome/pro-regular-svg-icons/faCarCrash';
import { CenteredLayout } from '../../../../layouts';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: '0px',
    paddingTop: theme.spacing(10),
    height: '100%',
    color: '#fff',
  },
  headerTitle: {
    borderBottom: '1px solid #1249B7',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(5),
    color: '#F2F3F8',
  },
  whiteText: {
    color: '#ffffff',
  },
  fullWidth: {
    width: '100%',
  },
  paddingBottom: {
    paddingBottom: '10vh',
  },
}));

const ErrorPage = ({ resetError, eventId }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user: appUser, language } = useSelector(state => state.AppReducer);
  const [reported, setReported] = useState(false);
  let user = null;
  if (appUser && appUser.userId) {
    user = {
      id: appUser.userId,
      name: appUser.personName.fullName,
      username: appUser.emailAddress,
      email: appUser.emailAddress,
    };
  }

  const handleHomepage = () => {
    history.push('/');
    resetError();
  };

  return (
    <CenteredLayout centered={false}>
      <Container maxWidth="xs" className={classes.mainContainer}>
        <Grid container spacing={3} style={{ marginTop: '10%' }}>
          <Grid item xs={12}>
            <Typography component="div" variant="h1" className={classes.whiteText} align="center">
              <FontAwesomeIcon size="2x" icon={faCarCrash} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="h3" className={classes.whiteText} align="center">
              <FormattedMessage id="error.page.sub.title" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="h6" className={classes.whiteText}>
              {!reported && <FormattedMessage id="error.page.description" />}
              {reported && <FormattedMessage id="error.page.reported" />}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              src={
                reported
                  ? 'https://media-vault.bas.app/08cf7c0a-3ade-4e09-8eec-c3881e6eb5ab/c85dc539-8f35-4e3c-8a30-799138c70936/602c40662f5a8_robgelost.png'
                  : 'https://media.giphy.com/media/14pUUmCj9ysy6A/source.gif'
              }
              alt=""
              className={classes.fullWidth}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '5%' }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setReported(true);
                  Sentry.showReportDialog({
                    user,
                    lang: language,
                    eventId,
                    title: 'Het lijkt erop dat we problemen hebben.',
                    subtitle: 'Zou je willen beschrijven wat je aan het doen was zodat we de fout kunnen reproduceren?',
                    subtitle2: '',
                    labelName: 'Naam',
                    labelEmail: 'Email',
                    labelComments: 'Wat is er gebeurd?',
                    labelClose: 'Sluiten',
                    labelSubmit: 'Verstuur',
                  });
                }}
              >
                <FormattedMessage id="error.page.button.support" />
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button variant="contained" fullWidth onClick={() => resetError()}>
                <FormattedMessage id="error.page.button.backReset" />
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" fullWidth onClick={handleHomepage}>
                <FormattedMessage id="error.page.button.homepage" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </CenteredLayout>
  );
};

export default ErrorPage;
