import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    background: theme.palette.background.secondary,
    color: theme.palette.common.white,
    height: '100%',
    padding: '32px 20px',
    overflow: 'scroll',
  },
}));

const CenteredLayout = props => {
  const { children, color, centered } = props;
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        className={classes.mainLayout}
        style={{ backgroundColor: color }}
        alignContent={centered && 'center'}
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

CenteredLayout.propTypes = {
  children: PropTypes.object,
  centered: PropTypes.bool,
  color: PropTypes.string,
};

CenteredLayout.defaultProps = {
  children: {},
  color: '',
  centered: true,
};

export default CenteredLayout;
