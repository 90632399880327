import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import RandomWidthSkeleton from '../RandomWidthSkeleton';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    // minHeight: '158px',
    paddingTop: '50%',
    paddingBottom: '50%',
    height: 0,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '30px 10px',
  },
  bgWhite: {
    background: '#fff',
  },
  center: {
    margin: '0 auto',
  },
  blackText: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    color: '#000000',
  },
}));

const LoadingIconCardComponent = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Avatar align="center" className={classes.center} variant="rounded">
              <RandomWidthSkeleton variant="rect" />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.blackText}>
              <RandomWidthSkeleton className={classes.center} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LoadingIconCardComponent;
