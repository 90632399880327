import axios from 'axios';

let tokenSource;
const autocompleteAddress = async (country, zipCode, houseNumber, houseNumberAddition) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const { data } = await axios.post(
      `api/address/autocomplete/dutch`,
      {
        zipCode,
        houseNumber,
        houseNumberAddition,
      },
      {
        cancelToken: tokenSource.token,
      },
    );

    return { result: data };
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }
    return [err];
  }
};

// eslint-disable-next-line import/prefer-default-export
export { autocompleteAddress };
