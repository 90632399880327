import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'Domain/App/Ducks/Table.duck';
import RelationService from '../Services/RelationService';

export const actionTypes = {
  RequestData: '[Customers Quote Tab] Request Quotes',
  FulfilledTable: '[Customers Quote Tab] FulfilledCustomers quotes',
  SearchTable: '[Customers Quote Tab] Search customers quotes',
  ChangePage: '[Customers Quote Tab] change page quotes',
  SetPageSize: '[Customers Quote Tab] set page size',
  SortTable: '[Customers Quote Tab] Sort customers quotes',
  ApplyFilter: '[Customers Quote Tab] Apply filter',
  RemoveFilter: '[Customers Quote Tab] Remove filter',
  ResetFilters: '[Customers Quote Tab] Reset filter',
};

const initialState = {
  ...tableInitialState,
  customerId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);
  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    const customerId = yield select(state => state.CustomerQuotesReducer.customerId);

    yield put(actions.requestData({ customerId }));
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* customerQuotesSaga({ data: { customerId } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.CustomerQuotesReducer);
    const response = yield RelationService.fetchAllQuotes(
      currentTenantId,
      customerId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
    );
    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
