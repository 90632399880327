import { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { actions as AppActions } from 'Domain/App/Ducks/App.duck';
import { LoginForm, TwoFactorForm } from 'forms';
import { CenteredLayout } from 'layouts';
import { AuthService } from 'services';
import Alert from '@material-ui/lab/Alert';
import adminDomains from 'data/adminDomains';
import { getEmployee } from '../../../../services/EmployeeService';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: '0px',
    height: '100%',
  },
  headerTitle: {
    borderBottom: '1px solid #1249B7',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(5),
    color: '#F2F3F8',
  },
  paddingBottom: {
    paddingBottom: '10vh',
  },
}));

const adminApplication = adminDomains.includes(window.location.hostname);
const LoginPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tenantId = useSelector(state => state.AppReducer.tenant.tenantId);
  const initialPath = useSelector(state => state.AppReducer.initialPath);
  const [showTwoFactorForm, setShowTwoFactorForm] = useState(false);
  const [authToken, setAuthToken] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleCallback = async event => {
      if (
        !['https://mrburns.local:3000', 'https://mrburns.staging.bas.app', 'https://mrburns.bas.app'].includes(
          event.origin,
        )
      ) {
        return;
      }

      if (event.data.message === 'loadAuthentication') {
        const { user, token, mercureToken, intercomHash, employee } = event.data;

        Sentry.setUser({
          id: user.userId,
          username: user.emailAddress,
          email: user.emailAddress,
        });

        await dispatch(AppActions.login(token, mercureToken, user, employee, intercomHash));
        history.push(initialPath);
      }
    };

    window.addEventListener('message', handleCallback, false);
    return () => {
      window.removeEventListener('message', handleCallback);
    };
  }, []);

  const handleLogin = async (token, mercureToken, userId, employeeId, intercomHash) => {
    let employee = {};
    const user = await AuthService.getUser(token, userId);

    if (!adminApplication) {
      if (
        !user.roles.includes('ROLE_SUPER_ADMIN') &&
        user.accessToTenants.filter(access => access.tenant.tenantId === tenantId).length === 0
      ) {
        setError(<FormattedMessage id="login.access_denied" />);

        return;
      }

      if (employeeId) {
        const response = await getEmployee(tenantId, employeeId, token);
        employee = response.data;
      }
    } else if (!user.roles.includes('ROLE_SUPER_ADMIN')) {
      setError(<FormattedMessage id="login.access_denied" />);

      return;
    }

    Sentry.setUser({
      id: user.userId,
      username: user.emailAddress,
      email: user.emailAddress,
    });

    await dispatch(AppActions.login(token, mercureToken, user, employee, intercomHash));
    history.push(initialPath);
  };

  const handleAuthenticate = async (values, form) => {
    try {
      const {
        cancelPrevQuery,
        token,
        mercureToken,
        intercomHash,
        userId,
        employeeId,
        twoFactorNeeded,
      } = await AuthService.authenticate(tenantId, values.username, values.password);

      if (cancelPrevQuery) return;

      if (adminApplication && !twoFactorNeeded) {
        setError(<FormattedMessage id="login.twoFactorRequired" />);

        return;
      }

      if (twoFactorNeeded) {
        setShowTwoFactorForm(true);
        setAuthToken(token);

        return;
      }

      await handleLogin(token, mercureToken, userId, employeeId, intercomHash);
    } catch (networkError) {
      form.setSubmitting(false);
      if (networkError.response && networkError.response.status === 401) {
        setError(<FormattedMessage id="login.incorrect_details" />);
      } else {
        throw networkError;
      }
    }
  };

  const handleTwoFactorCheck = async (values, form) => {
    try {
      const { cancelPrevQuery, token, mercureToken, userId, employeeId, intercomHash } = await AuthService.verifyToken(
        tenantId,
        authToken,
        values.code,
      );

      if (cancelPrevQuery) return;

      await handleLogin(token, mercureToken, userId, employeeId, intercomHash);
    } catch (networkError) {
      form.setSubmitting(false);
      if (networkError.response && networkError.response.status === 401) {
        setError(<FormattedMessage id="login.invalid_code" />);
      } else {
        throw networkError;
      }
    }
  };

  const submit = (values, form) => {
    setError(false);
    handleAuthenticate(values, form);
  };

  const checkTwoFactorCode = (values, form) => {
    setError(false);
    handleTwoFactorCheck(values, form);
  };

  if (window.opener) {
    window.opener.postMessage(
      {
        message: 'loadedLoginPage',
      },
      '*',
    );
  }

  return (
    <CenteredLayout>
      <Container maxWidth="xs" className={classes.mainContainer}>
        <Grid container className={classes.paddingBottom}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2" component="h2" align="center" className={classes.headerTitle}>
                  <FormattedMessage id="login.welcome_message" />
                </Typography>
              </Grid>

              {error && (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}

              {!showTwoFactorForm && <LoginForm handleSubmit={submit} />}
              {showTwoFactorForm && <TwoFactorForm handleSubmit={checkTwoFactorCode} />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </CenteredLayout>
  );
};

export default LoginPage;
