import { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { PropTypes } from 'prop-types';
import { getPackages } from '../../services/TenantService';

const PackageSelectField = ({ tenantId, identityId, projectType, onSelectPackage, pricingType, ...params }) => {
  const [packages, setPackages] = useState([]);
  const field = useField(params.field);

  const intl = useIntl();

  useEffect(() => {
    (async () => {
      const response = await getPackages(tenantId, identityId, projectType, pricingType);
      setPackages(response.data['hydra:member']);
    })();
  }, [tenantId, identityId, projectType, pricingType]);

  const handleSelectPackage = event => {
    event.preventDefault();
    field[2].setValue(event.target.value);
    if (typeof onSelectPackage === 'function') {
      onSelectPackage(packages.find(({ packageId }) => packageId === event.target.value));
    }
  };

  return (
    <TextField {...params} onChange={handleSelectPackage}>
      {packages.map(p => (
        <MenuItem value={p.packageId} key={p.packageId}>
          {p.packageName}
          {!pricingType && ` (${intl.formatMessage({ id: `project.pricingType.${p.pricingType}` })})`}
        </MenuItem>
      ))}
    </TextField>
  );
};

PackageSelectField.defaultProps = {
  pricingType: null,
  onSelectPackage: null,
};

PackageSelectField.propTypes = {
  tenantId: PropTypes.string.isRequired,
  identityId: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  pricingType: PropTypes.string,
  onSelectPackage: PropTypes.func,
};

export default PackageSelectField;
