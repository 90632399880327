import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVirusSlash } from '@fortawesome/pro-regular-svg-icons/faVirusSlash';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';
import { actions as FailedEstimatesActions } from '../../Domain/FailedEstimates/Ducks/FailedEstimates.duck';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  direction: {
    flexDirection: 'column',
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  whiteButton: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.primary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  spacing: {
    margin: '8px',
  },
}));

const ErrorHandlerComponent = props => {
  const { children } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector(state => state.MovingJobEstimateReducer.error);
  const quote = useSelector(state => state.MovingJobEstimateReducer);
  const appUser = useSelector(state => state.AppReducer.user);
  let user = null;
  if (appUser && appUser.userId) {
    user = {
      id: appUser.userId,
      name: appUser.personName.fullName,
      username: appUser.emailAddress,
      email: appUser.emailAddress,
    };
  }

  const homepageClick = () => {
    dispatch(MovingJobEstimateActions.reset());
    history.push('/app');
  };

  const backClick = () => {
    dispatch(MovingJobEstimateActions.save({ error: null }));
  };

  const saveClick = () => {
    dispatch(FailedEstimatesActions.save({ ...quote }));
    homepageClick();
  };

  return (
    <>
      {error && !error.type && (
        <Box className={classes.content}>
          <Box className={classes.direction}>
            <Typography component="div" variant="h3" align="center" className={classes.spacing}>
              <FontAwesomeIcon size="2x" icon={faVirusSlash} />
              {Object.keys(error).length > 0 && error.props && `${error.props.status} ${error.props.statusText}`}
              {Object.keys(error).length > 0 && !error.props && `Onbekende fout`}
            </Typography>

            <Typography component="div" variant="h6" align="center" className={classes.spacing}>
              <FormattedMessage id="error.page.sub.title" />
            </Typography>

            <Typography component="div" variant="h6" align="center" className={classes.spacing}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Button
                    className={classes.blueButton}
                    variant="outlined"
                    fullWidth
                    onClick={() =>
                      Sentry.showReportDialog({
                        user,
                        eventId: Sentry.captureEvent({
                          message: error.props
                            ? `Unknown error: ${error.props.status} ${error.props.statusText}`
                            : null,
                          extra: error.props,
                          user,
                        }),
                      })
                    }
                  >
                    <FormattedMessage id="error.page.button.support" />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button className={classes.blueButton} fullWidth variant="outlined" onClick={() => saveClick()}>
                    <FormattedMessage id="error.page.button.save" />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" fullWidth className={classes.whiteButton} onClick={() => backClick()}>
                    <FormattedMessage id="error.page.button.back" />
                  </Button>
                </Grid>
              </Grid>
            </Typography>
          </Box>
        </Box>
      )}
      {error && error.type && error.type === 'CUSTOMER_VALIDATION_ERROR' && (
        <>
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </>
      )}
      {!error && children}
    </>
  );
};

ErrorHandlerComponent.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ErrorHandlerComponent;
