import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from './Table.duck';
import { getAllPackages } from '../../../services/TenantService';
import { newMercureMessage } from './App.duck';

export const actionTypes = {
  RequestData: '[Packages] RequestEmails',
  FulfilledTable: '[Packages] FulfilledEmails',
  SearchTable: '[Packages] Search customers',
  ChangePage: '[Packages] change page',
  SetPageSize: '[Packages] set page size',
  SortTable: '[Packages] Sort customers',
  ApplyFilter: '[Packages] Apply filter',
  RemoveFilter: '[Packages] Remove filter',
  ResetFilters: '[Packages] Reset filter',
};

const initialState = {
  ...tableInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEmailsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.PackagesReducer);

    const response = yield getAllPackages(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Package') {
      yield put(actions.requestData());
    }
  });
}
