import { put, select, takeLatest } from 'redux-saga/effects';
import { getInventoryItem } from '../Services/InventoryService';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';

export const actionTypes = {
  ResetData: '[InventoryItem] Reset InventoryItem',
  RequestData: '[InventoryItem] Request InventoryItem',
  FulfilledData: '[InventoryItem] Fulfilled InventoryItem',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestInventoryItemSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getInventoryItem(currentTenantId, action.itemId);
    yield put(actions.fulfilledData(response));
  });
}
