import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import { actions as SecondStepActions } from '../../Domain/SecondStep/Ducks/SecondStep.duck';
import { actions as ThirdStepActions } from '../../Domain/ThirdStep/Ducks/ThirdStep.duck';

import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

import { actions as MovingJobEstimateDesktopActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobDesktopEstimate.duck';
import InputComponent from '../../components/InputComponent';

const useStyles = makeStyles(theme => ({
  whiteText: {
    color: '#ffffff',
  },
  boxTypeText: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const mapReducers = {
  mobile: {
    stepReducer: 'SecondStepReducer',
    actions: MovingJobEstimateActions,
    dataReducer: 'MovingJobEstimateReducer',
    actionsStep: SecondStepActions,
  },
  desktop: {
    stepReducer: 'ThirdStepReducer',
    actions: MovingJobEstimateDesktopActions,
    dataReducer: 'MovingJobEstimateDesktopReducer',
    actionsStep: ThirdStepActions,
  },
};

const BoxesForm = ({ tag, storage }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = useRef();
  const tenantId = useSelector(state => state.AppReducer.tenant.tenantId);
  const movingBoxes = useSelector(state => state.MovingJobEstimateReducer.loadedInformation.movingBoxTypes);
  const selectedRoom = useSelector(state => state[mapReducers[tag].stepReducer].propSelected);
  const roomKey = `${selectedRoom.value}:${selectedRoom.index}`;
  const inventoryItems = useSelector(state => state[mapReducers[tag].dataReducer].inventoryItems);
  const { active, selected } = useSelector(state => state[mapReducers[tag].stepReducer]);

  useEffect(() => {
    if (typeof inventoryItems[roomKey] === 'object' && Object.keys(inventoryItems[roomKey]).length >= 0) {
      dispatch(mapReducers[tag].actionsStep.filledForm(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryItems]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center" className={classes.whiteText}>
          <FormattedMessage id={storage ? 'room.boxes.storage.title' : 'room.how_many_boxes_are_needed'} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          innerRef={formRef}
          onSubmit={() => {
            dispatch(mapReducers[tag].actionsStep.next(active, selected));
          }}
          initialValues={{ inventoryItems: inventoryItems[roomKey] || {} }}
          validate={values => {
            const errors = {};

            Object.keys(values.inventoryItems).forEach(key => {
              if (values.inventoryItems[key] < 0) {
                errors.inventoryItems[key] = <FormattedMessage id="error.boxes_cant_be_negative" />;
              }
            });

            if (Object.keys(errors).length === 0) {
              dispatch(
                mapReducers[tag].actions.save({
                  ...values,
                  inventoryItems: { ...inventoryItems, [roomKey]: values.inventoryItems },
                }),
              );
              dispatch(mapReducers[tag].actionsStep.filledForm(true));
            } else {
              dispatch(mapReducers[tag].actionsStep.filledForm(false));
            }

            return errors;
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2} className={classes.padding}>
                {movingBoxes
                  .filter(
                    ({ inventoryItemId }) =>
                      !storage ||
                      (typeof inventoryItems[roomKey][inventoryItemId] !== 'undefined' &&
                        typeof inventoryItems[roomKey][inventoryItemId].quantity !== 'undefined' &&
                        inventoryItems[roomKey][inventoryItemId].quantity > 0),
                  )
                  .map(movingBox => (
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      alignContent="center"
                      key={movingBox.inventoryItemId}
                    >
                      <Grid item xs={9}>
                        <Typography className={classes.boxTypeText}>{movingBox.name}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          type="number"
                          component={InputComponent}
                          name={`inventoryItems[${movingBox.inventoryItemId}].${
                            storage ? 'storageQuantity' : 'quantity'
                          }`}
                          variant="outlined"
                          inputProps={{
                            min: 0,
                          }}
                          placeholder={
                            storage ? inventoryItems[roomKey][movingBox.inventoryItemId].quantity.toString() || '' : ''
                          }
                          whiteLabel
                          whiteInput
                          noLabel
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Button type="submit" style={{ opacity: 0 }}>
                &nbsp;
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BoxesForm;
