import 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormattedMessage } from 'react-intl';
import { actions as FirstStepActions } from '../../Domain/FirstStep/Ducks/FirstStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(() => ({
  picker: {
    marginTop: '0',
    background: 'white',
  },
  label: {
    marginBottom: '7px',
  },
  textArea: {
    marginTop: '0',
    background: 'white',
  },
}));

const DateForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { movingDate, note: noteReducer } = useSelector(state => state.MovingJobEstimateReducer);

  const [selectedDate, setSelectedDate] = useState(movingDate || null);
  const [note, setNote] = useState(noteReducer);

  useEffect(() => {
    dispatch(MovingJobEstimateActions.save({ movingDate: selectedDate, note }));
    if (selectedDate) dispatch(FirstStepActions.filledForm(true));
  }, [selectedDate, note, dispatch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="moving.when_do_you_want_to_be_moved" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="nl">
              <Typography variant="h6" className={classes.label}>
                <FormattedMessage id="date.label.preferredMovingDate" />
              </Typography>
              <KeyboardDatePicker
                disableToolbar
                disablePast
                fullWidth
                className={classes.picker}
                color="secondary"
                variant="inline"
                format="L"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                rifmFormatter={val =>
                  // eslint-disable-next-line no-useless-escape
                  val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, '')
                }
                onChange={date => setSelectedDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {selectedDate && (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.label}>
                <FormattedMessage id="moving.notes" />
              </Typography>
              <TextField
                variant="outlined"
                className={classes.textArea}
                value={note}
                onChange={e => setNote(e.target.value)}
                fullWidth
                color="secondary"
                multiline
                rows={6}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DateForm;
