import { Grid, makeStyles, Avatar, Typography, Box } from '@material-ui/core';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  chatBoxRight: {
    background: '#FFFFFF',
    borderRadius: '8px 8px 0px 8px',
    margin: '6px',
    padding: '12px',
    overflowWrap: 'anywhere',
  },
  chatContainerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    maxWidth: '80%',
  },
  chatContainerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '80%',
  },
  chatBoxLeft: {
    background: '#FFFFFF',
    borderRadius: '8px 8px 8px 0px',
    margin: '6px',
    padding: '12px',
    overflowWrap: 'anywhere',
  },
  avatar: {
    backgroundColor: '#3A78F2',
  },
  centerY: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  end: {
    justifyContent: 'flex-end',
  },
  sm: {
    width: '10px !important',
    height: '10px !important',
    marginLeft: '5px ',
  },
  avatarItem: {
    padding: 15,
  },
  centerX: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
  currentUser: {
    background: '#464457',
  },
  active: {
    color: '#29B6F6',
  },
  blackChatBox: {
    background: '#464457',
    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
  },
  blueTime: {
    color: '#ACC6F9',
  },
}));

const BASChatCardComponent = ({ isCurrentUser, message, isDesktop }) => {
  const classes = useStyles();
  const splittedName = message.sender.name.split(' ');
  // const participantMetadata = message.participantMessageMetadata.find(
  //   metadata => metadata.participant.userId === participantId,
  // );
  // let isReceived = false;
  // let isDelivered = false;
  // let isRead = false;
  // if (participantMetadata) {
  //   isReceived = !participantMetadata.receivedAt;
  //   isDelivered = !participantMetadata.deliveredAt;
  //   isRead = !participantMetadata.readAt;
  // }

  /** @TODO @Miguel can you add the checks? */
  return (
    <>
      {isCurrentUser && (
        <Grid container className={classes.end}>
          <Grid item className={classes.chatContainerRight}>
            <Box className={clsx(classes.chatBoxRight, { [classes.blackChatBox]: isDesktop })}>
              <Typography variant="body1" component="p">
                {message.content}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                align="right"
                className={clsx(classes.centerX, classes.alignLeft)}
              >
                <Moment format="LT" className={clsx({ [classes.blueTime]: isDesktop })}>
                  {message.createdAt}
                </Moment>
                <FontAwesomeIcon size="2x" icon={['fal', 'check']} className={clsx(classes.sm, classes.active)} />
              </Typography>
            </Box>
          </Grid>
          {isDesktop && (
            <Grid item className={clsx(classes.centerY, classes.avatarItem)}>
              <Avatar
                align="center"
                variant="circular"
                className={classes.avatar}
                alt={message.sender.name}
                src={message.sender.profileImageUrl}
              >
                {!message.sender.profileImageUrl && splittedName[0][0]}
                {!message.sender.profileImageUrl && splittedName.length > 1 && splittedName[1][0]}
              </Avatar>
            </Grid>
          )}
        </Grid>
      )}
      {!isCurrentUser && (
        <Grid container>
          <Grid item className={clsx(classes.centerY, classes.avatarItem)}>
            <Avatar
              align="center"
              variant="circular"
              className={classes.avatar}
              alt={message.sender.name}
              src={message.sender.profileImageUrl}
            >
              {!message.sender.profileImageUrl && splittedName[0][0]}
              {!message.sender.profileImageUrl && splittedName.length > 1 && splittedName[1][0]}
            </Avatar>
          </Grid>
          <Grid item className={classes.chatContainerLeft}>
            <Box className={clsx(classes.chatBoxLeft, { [classes.currentUser]: isCurrentUser })}>
              <Typography variant="body1" component="p">
                {message.content}
              </Typography>
              <Typography variant="caption" component="p" align="right" className={classes.centerX}>
                <Moment format="LT" className={clsx({ [classes.blueTime]: isDesktop })}>
                  {message.createdAt}
                </Moment>
                {/* <FontAwesomeIcon size="2x" icon={['fal', 'check']} className={clsx(classes.sm, classes.active)} /> */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BASChatCardComponent;
