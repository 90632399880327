import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from '../Table.duck';
import { getBatchData } from '../../Services/IntegrationService';

export const actionTypes = {
  RequestData: '[BatchData] Request',
  FulfilledTable: '[BatchData] Fulfilled',
  SearchTable: '[BatchData] Search',
  ChangePage: '[BatchData] change page',
  SetPageSize: '[BatchData] set page size',
  SortTable: '[BatchData] Sort',
  ApplyFilter: '[BatchData] Apply filter',
  RemoveFilter: '[BatchData] Remove filter',
  ResetFilters: '[BatchData] Reset filter',
};

const initialState = {
  integrationId: null,
  pageSize: 100,
  ...tableInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentState = yield select(state => state.BatchDataReducer);

    const response = yield getBatchData(
      currentTenantId,
      currentState.integrationId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
