import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { ExtraErrorData, Dedupe as DedupeIntegration } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

import './index.css';
import App from './app/app';

import '@fontsource/dm-sans';
import '@fontsource/dm-sans/400-italic.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/500-italic.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-sans/700-italic.css';
import '@fontsource/dm-sans/latin.css';
import '@fontsource/dm-sans/latin-400.css';
import '@fontsource/dm-sans/latin-400-italic.css';
import '@fontsource/dm-sans/latin-500.css';
import '@fontsource/dm-sans/latin-500-italic.css';
import '@fontsource/dm-sans/latin-700.css';
import '@fontsource/dm-sans/latin-700-italic.css';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line import/prefer-default-export
export const appHistory = createBrowserHistory();

Sentry.init({
  dsn: 'https://004626514cd04906843e4c6b9e5a2be0@sentry.easy-cloud.eu/3',
  environment: process.env.NODE_ENV === 'development' ? process.env.NODE_ENV : window.location.hostname.split('.')[0],
  release: process.env.REACT_APP_RELEASE,
  integrations: [
    new ExtraErrorData({ depth: 5 }),
    new TracingIntegrations.BrowserTracing(),
    new SentryRRWeb(),
    // Sentry.reactRouterV5Instrumentation(appHistory),
    new DedupeIntegration(),
  ],
  tracesSampleRate: 1.0, // Be sure to lower this in production
});

if (process.env.NODE_ENV !== 'production') {
  document.title = `Local ${document.title}`;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;
//     waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//
//     window.location.reload();
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
