import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Route, useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isAdminApplication from 'utils/isAdminApplication';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';
import isMobile from '../../utils/isMobile';

const SentryRoute = Sentry.withSentryRouting(Route);

const SecureRoute = props => {
  const {
    loggedIn,
    employee,
    user,
    tenant: { tenantId },
  } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(AppActions.setInitialPath(pathname));
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  if (!loggedIn) {
    return null;
  }

  if (!pathname) {
    return null;
  }
  const alreadyInApp = pathname.includes('/app');

  const hasBackendRights = () => {
    if (isAdminApplication) {
      return user.roles.includes('ROLE_SUPER_ADMIN');
    }

    const tenantRights = user.accessToTenants.find(access => access.tenant.tenantId === tenantId);

    if (
      employee &&
      employee.employeeSkills &&
      employee.employeeSkills.indexOf('planner', 'administrative-assistant') !== -1
    ) {
      return true;
    }

    return user.roles.includes('ROLE_SUPER_ADMIN') || tenantRights.roles.indexOf('ROLE_ADMIN') !== -1;
  };

  return (
    <>
      {(isMobile || !hasBackendRights()) && !alreadyInApp && <Redirect to={{ pathname: '/app' }} />}

      <SentryRoute {...props} />
    </>
  );
};

export default SecureRoute;
