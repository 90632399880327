import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { QuoteService } from 'services';
import { tableAction, tableActions, tableInitialState } from './Table.duck';
import { newMercureMessage } from './App.duck';

export const actionTypes = {
  RequestData: '[DashboardQuotes] RequestDashboardQuotes',
  FulfilledTable: '[DashboardQuotes] FulfilledDashboardQuotes',
  SearchTable: '[DashboardQuotes] Search DashboardQuotes',
  ChangePage: '[DashboardQuotes] change page',
  SetPageSize: '[DashboardQuotes] set page size',
  SortTable: '[DashboardQuotes] Sort DashboardQuotes',
  ApplyFilter: '[DashboardQuotes] Apply filter',
  RemoveFilter: '[DashboardQuotes] Remove filter',
  ResetFilters: '[DashboardQuotes] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestDashboardQuotesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.DashboardQuotesReducer);

    const response = yield QuoteService.getQuotes(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      { quotationNumber: 'desc' },
      { quoteStatus: ['sent', 'expired', 'declined'] },
      null,
    );

    const quotesDeclined = response.data['hydra:member'].filter(q => q.quoteStatus === 'declined');

    yield put(actions.fulfilled(quotesDeclined, quotesDeclined.length));
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Quote1') {
      yield put(actions.requestData());
    }
  });
}
