import { isValidElement } from 'react';
import { Button, Typography } from '@material-ui/core';
import useFormatMessage from 'utils/useFormatMessage';
import { FormattedMessage } from 'react-intl';

const ResultTypoComponent = ({ total, searchField, filters, variant, resetFilters, filterValueTransformer }) => {
  const formatMessage = useFormatMessage();
  const buildSearchString = () => {
    if (!searchField) return '';
    return ` ${formatMessage({ id: `filters.type.search.field` })} "${searchField}"`;
  };

  const seperator = (index, length) => {
    if (index === 0) {
      return '';
    }

    if (index === length - 1) {
      return ` ${formatMessage({ id: 'filters.type.en' })} `;
    }

    return ', ';
  };

  const buildFilterResult = () => {
    let hasValues = false;
    Object.entries(filters).forEach(([key, value]) => {
      if (key && typeof value === 'object' && value.length !== 0) {
        hasValues = true;
        return;
      }
      if (typeof value !== 'object' && value) {
        hasValues = true;
      }
    });
    if (!hasValues) return '';

    const result = [];
    Object.entries(filters).forEach(([key, value], i) => {
      const displayValue = filterValueTransformer ? filterValueTransformer(key, value) : value;
      if (isValidElement(displayValue)) {
        result.push(
          <span key={i} style={{ textTransform: 'lowercase' }}>
            {`${seperator(i, Object.keys(filters).length)} ${formatMessage({
              id: `filters.type.${key}`,
            })}`}
            {' "'}
            {displayValue}
            {'"'}
          </span>,
        );
      } else if (typeof displayValue !== 'object') {
        result.push(
          <span key={i} style={{ textTransform: 'lowercase' }}>
            {`${seperator(i, Object.keys(filters).length)} ${formatMessage({
              id: `filters.type.${key}`,
            })}`}
            {' "'}
            {displayValue}
            {'"'}
          </span>,
        );
      } else if (typeof displayValue === 'object' && Array.isArray(displayValue) && displayValue.length !== 0) {
        result.push(
          <span key={i} style={{ textTransform: 'lowercase' }}>
            {`${seperator(i, Object.keys(filters).length)} ${formatMessage({
              id: `filters.type.${key}`,
            })}`}
            {' "'}
            {displayValue.map((row, index) => (
              <span key={index}>
                {row}
                {index !== displayValue.length - 1 ? ', ' : ''}
              </span>
            ))}
            {'"'}
          </span>,
        );
      } else if (typeof displayValue === 'object' && !Array.isArray(displayValue) && displayValue.length !== 0) {
        const arrayValues = Object.values(displayValue);
        result.push(
          <span key={i} style={{ textTransform: 'lowercase' }}>
            {`${seperator(i, Object.keys(filters).length)} ${formatMessage({
              id: `filters.type.${key}`,
            })}`}
            {' "'}
            {Object.values(arrayValues).map((row, index) => (
              <span key={index}>
                {row}
                {index !== arrayValues.length - 1 ? ', ' : ''}
              </span>
            ))}
            {'"'}
          </span>,
        );
      }
    });

    return result;
  };

  const searchString = buildSearchString();
  const filterResult = buildFilterResult();

  return (
    <Typography variant={variant}>
      {`${total} ${formatMessage({
        id: `filters.type.search.results`,
      })} ${
        searchString || filterResult.length > 0 ? formatMessage({ id: 'filters.type.basedOn' }) : ''
      } ${searchString}${searchString && filterResult.length > 0 ? seperator(1, Object.keys(filters).length + 1) : ''}`}
      {filterResult}
      {resetFilters && (searchString || filterResult.length > 0) && (
        <Button variant="text" onClick={() => resetFilters()}>
          <FormattedMessage id="filters.type.reset" />
        </Button>
      )}
    </Typography>
  );
};

export default ResultTypoComponent;
