import { useState } from 'react';
import { Card, CardActions, CardContent, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { ButtonComponent } from '../index';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  active: {
    position: 'relative',
    padding: theme.spacing(2),
    margin: -theme.spacing(2),
    background: '#FAFAFB',
    borderRadius: '5px',
    border: 'none',
    width: '100%',
    height: '100%',
    '&:hover': {
      border: 'none',
    },
  },
  root: {
    position: 'relative',
    padding: 0,
    width: '100%',
    height: '100%',
    '&:hover': {
      border: 'none',
    },
    backgroundColor: 'transparent',
  },
  editable: {
    position: 'relative',
    lineHeight: '26px',
    overflow: 'visible',
    '&:hover': {
      margin: -theme.spacing(2),
      padding: theme.spacing(2),
      background: '#FAFAFB',
      borderRadius: '5px',
    },
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  cardActions: {
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardContent: {
    padding: 0,
    '& .MuiTypography-root': {
      lineHeight: '26px',
    },
  },
}));

const EditableCardComponent = ({
  loading,
  title,
  titleVariant,
  onSave,
  onDelete,
  hideAddButton,
  hideEditButton,
  showDeleteButton,
  showChildrenOnAdd,
  showChildrenOnEdit,
  handleShowEdit,
  handleShowAdd,
  oneLiner,
  children,
  addContent,
  editContent,
  noHover,
  saveButtonText,
  editFlag,
  addFlag,
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [add, setAdd] = useState(addFlag);
  const [edit, setEdit] = useState(editFlag);
  const [saving, setSaving] = useState(false);
  const showActions = hover && !edit && !add;
  const showSave = edit || add;
  const editable = !hideAddButton || !hideEditButton;

  // useEffect(() => {
  //   if (edit === true) {
  //     setEdit(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading, children]);

  const actions = (
    <CardActions className={classes.cardActions}>
      {showDeleteButton && (
        <Tooltip title="Delete">
          <IconButton onClick={onDelete} size="small" color="primary">
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
          </IconButton>
        </Tooltip>
      )}
      {!hideAddButton && (
        <Tooltip title="Add">
          <IconButton
            onClick={() => {
              const result = handleShowAdd(true);
              if (!result) {
                setAdd(true);
              }
            }}
            size="small"
            color="primary"
          >
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </IconButton>
        </Tooltip>
      )}
      {!edit && !hideEditButton && (
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              const result = handleShowEdit(true);
              if (!result) {
                setEdit(true);
              }
            }}
            size="small"
            color="primary"
          >
            <FontAwesomeIcon icon={['fal', 'pencil']} />
          </IconButton>
        </Tooltip>
      )}
    </CardActions>
  );

  const handleSave = async () => {
    setSaving(true);
    await onSave({
      add,
      edit,
      setEdit,
      setAdd,
      setSaving,
      finish: () => {
        setEdit(false);
        setAdd(false);
        handleShowAdd(false);
        handleShowEdit(false);
        setSaving(false);
      },
    });

    setSaving(false);
  };

  const handleHover = async hovering => {
    if (!loading) {
      setHover(hovering);
    }
  };

  const saveActions = (
    <CardActions className={classes.cardActions}>
      <ButtonComponent onClick={handleSave} variant="contained" color="primary" loading={saving}>
        {!saveButtonText ? <FormattedMessage id="button.save" /> : saveButtonText}
        {saving && <FontAwesomeIcon icon={faSpinnerThird} spin />}
      </ButtonComponent>
    </CardActions>
  );

  const showChildren = !(edit && !showChildrenOnEdit) && !(add && !showChildrenOnAdd);
  return (
    <Card
      onMouseEnter={() => !noHover && handleHover(true)}
      onMouseLeave={() => !noHover && handleHover(false)}
      elevation={0}
      className={clsx(
        { [classes.active]: edit || add },
        { [classes.root]: !edit && !add },
        { [classes.editable]: editable },
      )}
      variant={hover || edit ? 'outlined' : 'elevation'}
    >
      <CardContent className={classes.cardContent}>
        {title && (
          <>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <>
                <Grid item className={classes.actionsHeight}>
                  <Typography
                    variant={titleVariant}
                    color={hover && editable ? 'primary' : 'initial'}
                    className={classes.upperCase}
                  >
                    {title}
                  </Typography>
                </Grid>
                {showActions && actions}
                {showSave && saveActions}
              </>
            </Grid>
            <Grid item xs={12}>
              {showChildren && children}
              {edit && (typeof editContent === 'function' ? editContent({ handleSave }) : editContent)}
              {add && (typeof addContent === 'function' ? addContent({ handleSave }) : addContent)}
            </Grid>
          </>
        )}
        {!title && (
          <>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <>
                <Grid item className={classes.actionsHeight}>
                  {oneLiner && edit && (typeof editContent === 'function' ? editContent({ handleSave }) : editContent)}
                  {oneLiner && add && (typeof addContent === 'function' ? addContent({ handleSave }) : addContent)}
                  {oneLiner && showChildren && children}
                </Grid>
                <Grid item>{showActions && editable && actions}</Grid>
              </>
            </Grid>
            <Grid item xs={12}>
              {!oneLiner && showChildren && children}
              {!oneLiner && edit && (typeof editContent === 'function' ? editContent({ handleSave }) : editContent)}
              {!oneLiner && add && (typeof addContent === 'function' ? addContent({ handleSave }) : addContent)}
              {showSave && editable && saveActions}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

EditableCardComponent.propTypes = {
  title: PropTypes.node,
  titleVariant: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  handleShowEdit: PropTypes.func,
  handleShowAdd: PropTypes.func,
  hideAddButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  oneLiner: PropTypes.bool,
  loading: PropTypes.bool,
  showChildrenOnAdd: PropTypes.bool,
  showChildrenOnEdit: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  addContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  editContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  noHover: PropTypes.bool,
  saveButtonText: PropTypes.node,
};

EditableCardComponent.defaultProps = {
  titleVariant: 'subtitle1',
  title: null,
  onSave: () => {},
  onDelete: () => {},
  handleShowEdit: () => {},
  handleShowAdd: () => {},
  hideAddButton: false,
  hideEditButton: false,
  showDeleteButton: false,
  noHover: false,
  oneLiner: false,
  loading: false,
  showChildrenOnAdd: false,
  showChildrenOnEdit: false,
  children: null,
  addContent: null,
  editContent: null,
  saveButtonText: null,
};

export default EditableCardComponent;
