import { Button, IconButton, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

const loaderMap = {
  outlined: {
    loader: <CircularProgress thickness={4} size={20} />,
    icon: '',
  },
  iconButton: {
    loader: <CircularProgress thickness={4} size={20} />,
  },
  contained: {
    loader: <CircularProgress color="secondary" thickness={4} size={20} />,
    icon: '',
  },
  thirdForm: {
    loader: '',
    icon: <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '4px' }} />,
  },
};

const ButtonComponent = ({ loading, iconButton, ...props }) => {
  const { children, variant } = props;

  if (iconButton) {
    return (
      <IconButton {...props}>
        {!loading && children}
        {loading && loaderMap.iconButton.loader}
        {loading && !loaderMap.iconButton.loader && children}
      </IconButton>
    );
  }

  return (
    <Button {...props}>
      {!loading && children}
      {!loading && loaderMap[variant].icon}
      {loading && loaderMap[variant].loader}
      {loading && !loaderMap[variant].loader && children}
      {loading && !loaderMap[variant].loader && loaderMap[variant].icon}
    </Button>
  );
};

export default ButtonComponent;
