import { Box, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apartment } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import CountryService from 'i18n-iso-countries';
import { faBuilding, faHome, faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#A3A1AE',
  },
  icon: {
    fontSize: '18px',
  },
  cardContainer: {
    marginTop: '7px',
    borderRadius: '5px',
    background: 'white',
  },
  cardContent: {
    padding: '17px',
    borderBottom: '0.4px solid #F2F3F8',
  },
}));

const ShowAddressComponent = ({ id, address, title, showRemove, handleRemove }) => {
  const classes = useStyles();
  const language = useSelector(state => state.AppReducer.language);

  return (
    <Grid item xs={12} id={id}>
      {title && (
        <Typography variant="h6">
          {title}
          {':'}
        </Typography>
      )}
      <Grid container className={classes.cardContainer}>
        <Grid container className={classes.cardContent}>
          <Grid item xs={2} className={classes.flex}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.icon} />
          </Grid>
          <Grid item xs={8}>
            {!address.unknownAddress && (
              <Typography component="div" variant="body1">
                <Box id="streetName">
                  {`${address.streetName}, ${address.houseNumber}${
                    address.houseNumberAddition ? ` ${address.houseNumberAddition}` : ''
                  }`}
                </Box>
                <Box id="zipCode">{`${address.zipCode}, ${address.city}`}</Box>
                <Box id="country">{CountryService.getName(address.country, language)}</Box>
              </Typography>
            )}
            {address.unknownAddress && (
              <Typography component="div" variant="body1">
                <Box id="streetName">
                  <FormattedMessage id="movingJob.address.unknownAddress" />
                </Box>
              </Typography>
            )}
          </Grid>
          {showRemove && (
            <Grid item xs={2} className={classes.flex}>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} onClick={handleRemove} className={classes.icon} />
            </Grid>
          )}
        </Grid>
        {!address.unknownAddress && address.type && (
          <>
            <Grid container className={classes.cardContent}>
              <Grid item xs={2} className={classes.flex}>
                <FontAwesomeIcon icon={address.type === 'apartment' ? faBuilding : faHome} className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <Typography component="div" variant="body1">
                  <Box>
                    <FormattedMessage id={`address.property_type.${address.type.toLowerCase()}`} />
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            {['apartment', 'office'].includes(address.type) && (
              <Grid container className={classes.cardContent}>
                <Grid item xs={2} className={classes.flex}>
                  <Apartment />
                </Grid>
                <Grid item xs={10}>
                  <Typography component="div" variant="body1">
                    <Box>
                      <FormattedMessage
                        id={address.hasElevator ? 'address.confirm.with_elevator' : 'address.confirm.no_elevator'}
                      />
                    </Box>
                    {address.floor > 0 && (
                      <Box>
                        <FormattedMessage id="address.confirm.number_of_floors" values={{ floors: address.floor }} />
                      </Box>
                    )}
                    {address.distanceFromCar > 0 && (
                      <Box>
                        <FormattedMessage
                          id="address.confirm.distance_from_car"
                          values={{ distance: address.distanceFromCar }}
                        />
                      </Box>
                    )}
                    {address.distanceToApartment > 0 && (
                      <Box>
                        <FormattedMessage
                          id="address.confirm.distance_to_apartment"
                          values={{ distance: address.distanceToApartment }}
                        />
                      </Box>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

ShowAddressComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  address: PropTypes.object.isRequired,
};

ShowAddressComponent.defaultProps = {
  title: '',
};

export default ShowAddressComponent;
