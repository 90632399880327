import {
  ConfirmAddressPage,
  ConfirmCustomerPage,
  RoomSelectionPage,
  SecondaryAddressesPage,
  MovingJobEstimateResultPerHourPage,
  MovingJobPricingPage,
} from 'pages';

import { FormattedMessage } from 'react-intl';
import {
  AddressForm,
  BoxesForm,
  CustomerForm,
  FurnitureForm,
  PhotoForm,
  RoomServicesForm,
  RoomsForm,
  ServicesQuestionForm,
  NotesForm,
} from '../forms';
import SendQuotePage from '../Domain/MovingJobEstimate/Pages/SendQuotePage';

export default {
  forms: {
    firstStepForm: {
      addressFromForm: (
        <AddressForm title={<FormattedMessage id="address.from_which_address" />} formKey="addressFrom" />
      ),
      addressToForm: <AddressForm title={<FormattedMessage id="address.to_which_address" />} formKey="addressTo" />,
      confirmAddressForm: <ConfirmAddressPage />,
      servicesQuestionForm: <ServicesQuestionForm />,
      roomsForm: <RoomsForm />,
      roomsSelectionPage: <RoomSelectionPage />,
      // dateForm: <DateForm />,
      customerForm: <CustomerForm />,
      notesForm: <NotesForm />,
      confirmCustomerPage: <ConfirmCustomerPage />,
      secondaryAddressPage: <SecondaryAddressesPage />,
      quotePricing: <MovingJobPricingPage />,
      quoteResult: <MovingJobEstimateResultPerHourPage />,
      sendQuotePage: <SendQuotePage />,
    },
    secondStepForm: {
      furnitureForm: <FurnitureForm />,
      disassembleForm: <RoomServicesForm service="disassemble" tag="mobile" />,
      assembleForm: <RoomServicesForm service="assemble" tag="mobile" />,
      storageForm: <RoomServicesForm service="storage" tag="mobile" />,
      storageBoxesForm: <BoxesForm tag="mobile" storage />,
      boxesForm: <BoxesForm tag="mobile" />,
      photoForm: <PhotoForm tag="mobile" />,
    },
    thirdStepForm: {
      furnitureForm: <FurnitureForm tag="desktop" />,
      disassembleForm: <RoomServicesForm service="disassemble" tag="desktop" />,
      assembleForm: <RoomServicesForm service="assemble" tag="desktop" />,
      storageForm: <RoomServicesForm service="storage" tag="desktop" />,
      boxesForm: <BoxesForm tag="desktop" />,
    },
  },
};
