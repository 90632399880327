import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  enterHours,
  getEmployeesWithHoursToBeApproved,
  getHourEntriesToApproveByEmployeeIdAndPeriod,
  removeHours,
} from '../../MobileApp/Services/HoursEntryService';
import { newMercureMessage } from '../../App/Ducks/App.duck';

dayjs.extend(utc);

export const actionTypes = {
  RequestData: '[ApproveHours] Request Employees',
  FulfilledEmployees: '[ApproveHours] FulfilledEmployees',
  SelectEmployee: '[ApproveHours] SelectEmployee',
  FulfilledHourEntriesToApprove: '[ApproveHours] FulfilledHourEntriesToApprove',
  RetrievedHourEntry: '[ApproveHours] RetrievedHourEntry',
  ChangePeriod: '[ApproveHours] ChangePeriod',
  ApproveHourEntry: '[ApproveHours] ApproveHourEntry',
  RemoveHourEntry: '[ApproveHours] RemoveHourEntry',
};

const initialState = {
  employees: [],
  loading: true,
  loaded: false,
  currentEmployeeId: null,
  hourEntries: [],
  loadingHourEntries: true,
  loadedHourEntries: false,
  hourEntriesStartDate: null,
  hourEntriesEndDate: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.FulfilledEmployees: {
      return {
        ...state,
        employees: action.payload,
        loading: false,
      };
    }

    case actionTypes.ChangePeriod: {
      return {
        ...state,
        hourEntries: [],
        hourEntriesStartDate: action.payload.startDate,
        hourEntriesEndDate: action.payload.endDate,
      };
    }

    case actionTypes.SelectEmployee: {
      return {
        ...state,
        hourEntries: [],
        currentEmployeeId: action.payload.employeeId,
        loadingHourEntries: true,
      };
    }

    case actionTypes.FulfilledHourEntriesToApprove: {
      return {
        ...state,
        hourEntries: action.payload,
        loadingHourEntries: false,
        loadHourEntries: true,
      };
    }

    case actionTypes.RetrievedHourEntry: {
      const hourEntries = [...state.hourEntries.filter(({ entryId }) => entryId !== action.payload.entryId)];
      hourEntries.push(action.payload);

      return {
        ...state,
        hourEntries,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  requestData: () => ({
    type: actionTypes.RequestData,
  }),
  changePeriod: (startDate, endDate) => ({
    type: actionTypes.ChangePeriod,
    payload: {
      startDate,
      endDate,
    },
  }),
  fulfilledData: employees => ({
    type: actionTypes.FulfilledEmployees,
    payload: employees,
  }),
  selectEmployee: employeeId => ({
    type: actionTypes.SelectEmployee,
    payload: {
      employeeId,
    },
  }),
  approveHourEntry: (startDate, endDate, breakTime, approvedHoursEntryId) => ({
    type: actionTypes.ApproveHourEntry,
    payload: {
      startDate,
      endDate,
      breakTime,
      approvedHoursEntryId,
    },
  }),
  removeHourEntry: approvedHoursEntryId => ({
    type: actionTypes.RemoveHourEntry,
    payload: {
      approvedHoursEntryId,
    },
  }),
  fulfilledHourEntries: hourEntries => ({
    type: actionTypes.FulfilledHourEntriesToApprove,
    payload: hourEntries,
  }),
  retrievedHourEntry: hourEntry => ({
    type: actionTypes.RetrievedHourEntry,
    payload: hourEntry,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.ApproveHourEntry,
    function* invoke({ payload: { startDate, endDate, breakTime, approvedHoursEntryId } }) {
      const {
        tenant: { tenantId: currentTenantId },
        employee: { employeeId: currentEmployeeId },
      } = yield select(state => state.AppReducer);
      const employeeId = yield select(state => state.ApproveHoursReducer.currentEmployeeId);

      yield enterHours(currentTenantId, employeeId, {
        employeeId,
        startDate: dayjs(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        breakTime,
        entryData: {
          entryType: 'approved',
          approvedHoursEntryId,
          approvedById: currentEmployeeId,
        },
      });
    },
  );
  yield takeLatest(actionTypes.RemoveHourEntry, function* invoke({ payload: { approvedHoursEntryId } }) {
    const {
      tenant: { tenantId: currentTenantId },
    } = yield select(state => state.AppReducer);

    yield removeHours(currentTenantId, approvedHoursEntryId);
  });

  yield takeLatest(actionTypes.RequestData, function* requestEmployeeSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const response = yield getEmployeesWithHoursToBeApproved(currentTenantId);

    yield put(actions.fulfilledData(response.data['hydra:member']));
  });

  yield takeLatest(actionTypes.SelectEmployee, function* invoke({ payload: { employeeId, startDate, endDate } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const { hourEntriesStartDate, hourEntriesEndDate } = yield select(state => state.ApproveHoursReducer);

    if (employeeId) {
      const response = yield getHourEntriesToApproveByEmployeeIdAndPeriod(
        currentTenantId,
        employeeId,
        dayjs(startDate || hourEntriesStartDate).format('YYYY-MM-DD'),
        dayjs(endDate || hourEntriesEndDate)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      );

      yield put(actions.fulfilledHourEntries(response.data['hydra:member']));
    }
  });

  yield takeLatest(actionTypes.ChangePeriod, function* invoke({ payload: { startDate, endDate } }) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const { currentEmployeeId: employeeId } = yield select(state => state.ApproveHoursReducer);

    if (employeeId) {
      const response = yield getHourEntriesToApproveByEmployeeIdAndPeriod(
        currentTenantId,
        employeeId,
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
      );

      yield put(actions.fulfilledHourEntries(response.data['hydra:member']));
    }
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (
      typeof action.data['@type'] === 'string' &&
      [
        'ApprovedHoursEntry',
        'HolidayHoursEntry',
        'HoursEntry',
        'OfficeWorkHoursEntry',
        'ProjectHoursEntry',
        'SickHoursEntry',
        'WarehouseWorkHoursEntry',
      ].includes(action.data['@type'])
    ) {
      const { currentEmployeeId, hourEntriesStartDate, hourEntriesEndDate } = yield select(
        state => state.ApproveHoursReducer,
      );

      if (
        currentEmployeeId === action.data.employeeId &&
        dayjs(action.data.startDate).isBetween(
          dayjs(hourEntriesStartDate).subtract(1, 'day'),
          dayjs(hourEntriesEndDate).add(1, 'day'),
          'day',
        )
      ) {
        if (action.data['@type'] === 'ApprovedHoursEntry') {
          yield put(actions.requestData());
        } else {
          yield put(actions.retrievedHourEntry(action.data));
        }
      }
    }
  });
}
