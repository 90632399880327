import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { FormattedMessage } from 'react-intl';
import { ErrorHandlerComponent, QuoteItemComponent } from 'components';
import { MobileLayout } from 'layouts';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '0px',
    height: '100%',
  },
  paddingBottom: {
    paddingBottom: '10vh',
  },
}));

const FailedQuotesPage = () => {
  const classes = useStyles();
  const { failedQuotes } = useSelector(state => state.FailedEstimatesReducer);

  return (
    <MobileLayout>
      <Container maxWidth="sm">
        <ErrorHandlerComponent>
          <Grid container className={classes.paddingBottom}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h3" align="center">
                    <FormattedMessage id="quotes.failed.page.title" />
                  </Typography>
                  <Typography variant="h5" component="h5" align="center">
                    <FormattedMessage id="quotes.failed.page.results" values={{ count: failedQuotes.length }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {failedQuotes.map(quote => (
                      <QuoteItemComponent key={quote.projectId} quote={quote} />
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ErrorHandlerComponent>
      </Container>
    </MobileLayout>
  );
};

export default FailedQuotesPage;
