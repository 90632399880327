import axios from 'axios';
import Qs from 'qs';

let automatedEmailToken;
const getAutomatedEmails = (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof automatedEmailToken !== typeof undefined) {
    automatedEmailToken.cancel('Operation canceled due to new request.');
  }
  automatedEmailToken = axios.CancelToken.source();
  try {
    return axios.get(`api/communication/automated-emails`, {
      params: {
        ...filters,
        tenantId,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: automatedEmailToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

const getAutomatedEmail = (tenantId, automatedEmailId) =>
  axios.get(`api/${tenantId}/communication/automated-emails/${automatedEmailId}`);

const updateAutomatedEmail = async (tenantId, automatedEmailId, values) =>
  axios.put(`api/${tenantId}/communication/automated-emails/${automatedEmailId}`, {
    ...values,
    automatedEmailId,
    tenantId,
  });

const updateBccEmailAddresses = async (tenantId, automatedEmailId, values) =>
  axios.put(`api/${tenantId}/communication/automated-emails/${automatedEmailId}/update-bcc-email-addresses`, {
    ...values,
    automatedEmailId,
    tenantId,
  });

const updateReplyEmailAddress = async (tenantId, automatedEmailId, values) =>
  axios.put(`api/${tenantId}/communication/automated-emails/${automatedEmailId}/update-reply-email-address`, {
    ...values,
    automatedEmailId,
    tenantId,
  });

const enableAutomatedEmail = async (tenantId, automatedEmailId) =>
  axios.put(`api/${tenantId}/communication/automated-emails/${automatedEmailId}/enable`, {
    automatedEmailId,
    tenantId,
  });
const disableAutomatedEmail = async (tenantId, automatedEmailId) =>
  axios.put(`api/${tenantId}/communication/automated-emails/${automatedEmailId}/disable`, {
    automatedEmailId,
    tenantId,
  });

const getEmail = (tenantId, emailId) => axios.get(`api/communication/emails/${emailId}`);
const resendEmail = (tenantId, emailId) =>
  axios.put(`api/communication/emails/${emailId}/resend`, { emailId, tenantId });

let emailsToken;
const getEmails = (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof emailsToken !== typeof undefined) {
    emailsToken.cancel('Operation canceled due to new request.');
  }
  emailsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/communication/emails`, {
      params: {
        ...filters,
        tenantId,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: emailsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export default {
  getAutomatedEmail,
  getAutomatedEmails,
  updateAutomatedEmail,
  updateBccEmailAddresses,
  enableAutomatedEmail,
  disableAutomatedEmail,
  updateReplyEmailAddress,
  getEmail,
  getEmails,
  resendEmail,
};
