import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';
import validateVatNumberWithApi from './validateVat';
import { EmployeeService } from '../services';

const registerCustomYupTypes = () => {
  function validatePhoneNumber() {
    return this.test(value => {
      if (typeof value === 'undefined' || value === null || value === '' || ['+32', '+31', '+'].includes(value)) {
        return true;
      }
      try {
        const parsedPhoneNumber = parsePhoneNumberFromString(value);

        if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
          return false;
        }
      } catch (e) {
        return false;
      }

      return true;
    });
  }

  function validateVatNumber() {
    return this.test(async value => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }

      return validateVatNumberWithApi(value);
    });
  }

  function validateUniqueEmployeeEmailAddress() {
    const validEmailAddresses = [];
    return this.test({
      message: 'Het email adres moet uniek zijn.',
      test: async (value, context) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          return true;
        }

        if (validEmailAddresses.includes(value)) {
          return true;
        }

        try {
          const response = await EmployeeService.getEmployeeByEmailAddress(context.parent.tenantId, value, true);

          if (response.cancelPrevQuery) {
            return true;
          }

          const result = response.data['hydra:totalItems'] <= 0;
          if (result) {
            validEmailAddresses.push(value);
          }

          if (
            context.parent.employeeId &&
            response.data['hydra:member'].find(({ employeeId }) => employeeId === context.parent.employeeId)
          ) {
            return true;
          }

          return result;
        } catch (error) {
          if (axios.isCancel(error)) {
            return true;
          }

          throw error;
        }
      },
    });
  }

  // `addMethod` doesn't do anything special; it's
  // equivalent to: yup.date.prototype.format = parseDateFromFormats
  Yup.addMethod(Yup.string, 'phoneNumber', validatePhoneNumber);
  Yup.addMethod(Yup.string, 'vatNumber', validateVatNumber);
  Yup.addMethod(Yup.string, 'uniqueEmployeeEmailAddress', validateUniqueEmployeeEmailAddress);
};

export default registerCustomYupTypes;
