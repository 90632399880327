import axios from 'axios';
import Qs from 'qs';

let automatedEmailToken;
export const getAutomatedEmails = (page, perPage, order, filters = {}, globalFilter) => {
  if (typeof automatedEmailToken !== typeof undefined) {
    automatedEmailToken.cancel('Operation canceled due to new request.');
  }
  automatedEmailToken = axios.CancelToken.source();
  try {
    return axios.get(`api/communication/automated-emails`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: automatedEmailToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const getAutomatedEmail = automatedEmailId =>
  axios.get(`api/communication/automated-emails/${automatedEmailId}`);

export const updateAutomatedEmail = async (automatedEmailId, values) =>
  axios.put(`api/communication/automated-emails/${automatedEmailId}`, {
    ...values,
    automatedEmailId,
  });

export const updateBccEmailAddresses = async (automatedEmailId, values) =>
  axios.put(`api/communication/automated-emails/${automatedEmailId}/update-bcc-email-addresses`, {
    ...values,
    automatedEmailId,
  });

export const updateReplyEmailAddress = async (automatedEmailId, values) =>
  axios.put(`api/communication/automated-emails/${automatedEmailId}/update-reply-email-address`, {
    ...values,
    automatedEmailId,
  });
