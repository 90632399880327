import { put, select, takeLatest } from 'redux-saga/effects';
import { getOutStandingList } from '../Services/InventoryService';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';

export const actionTypes = {
  RequestData: '[OutStanding] RequestOutStanding',
  FulfilledTable: '[OutStanding] FulfilledOutStanding',
  SearchTable: '[OutStanding] Search OutStanding',
  ChangePage: '[OutStanding] change page',
  SetPageSize: '[OutStanding] set page size',
  SortTable: '[OutStanding] Sort OutStanding',
  ApplyFilter: '[OutStanding] Apply filter',
  RemoveFilter: '[OutStanding] Remove filter',
  ResetFilters: '[OutStanding] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestOutStandingSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.OutStandingReducer);
    const itemId = yield select(state => state.InventoryItemReducer.item.inventoryItemId);

    const response = yield getOutStandingList(
      currentTenantId,
      itemId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      currentState.filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.items, response.total));
  });
}
