import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    margin: '17px 21px',
    padding: '26px 20px',
    background: '#FFFFFF',
    border: '1px solid #E6E7EE',
    boxSizing: 'border-box',
    borderRadius: '2px',
    borderTop: '10px solid #3A78F2',
    display: 'flex',
  },
  thirdOfSpace: {
    flexGrow: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  oneThirdOfSpace: {
    flexGrow: 1,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BASCardComponent = ({ icon, content }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <div container className={classes.root}>
          <div className={classes.oneThirdOfSpace}>{icon}</div>
          <div className={classes.thirdOfSpace}>{content}</div>
        </div>
      </Grid>
    </Grid>
  );
};

export default BASCardComponent;
