import axios from 'axios';
import Qs from 'qs';

let tokenSource;
const mapField = field => {
  switch (field) {
    case 'name':
      return ['personName.firstName', 'personName.lastName'];
    case 'city':
      return ['address.city'];
    case 'zipCode':
      return ['address.zipCode'];
    default:
      return [field];
  }
};

export function getEmployees(tenantId, page, perPage, order, filters = {}, globalFilter) {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }
    tokenSource = axios.CancelToken.source();

    const appliedFilters = {};
    Object.keys(filters).forEach(filter => {
      mapField(filter).forEach(field => {
        appliedFilters[field] = filters[filter];
      });
    });

    const appliedOrder = {};
    Object.keys(order).forEach(key => {
      mapField(key).forEach(field => {
        appliedOrder[field] = order[key];
      });
    });

    return axios.get(`api/${tenantId}/hrm/employees`, {
      params: {
        ...appliedFilters,
        page,
        perPage,
        order: appliedOrder,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: tokenSource.token,
    });
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
}

export const getEmployee = (tenantId, employeeId, token) =>
  axios.get(
    `api/${tenantId}/hrm/employees/${employeeId}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {},
  );

export function createEmployee(tenantId, values) {
  return axios.post(`api/${tenantId}/hrm/employees`, {
    ...values,
    availability: {
      monday: {
        startTime: '08:00:00',
        endTime: '16:00:00',
      },
      tuesday: { startTime: '08:00:00', endTime: '16:00:00' },
      wednesday: { startTime: '08:00:00', endTime: '16:00:00' },
      thursday: { startTime: '08:00:00', endTime: '16:00:00' },
      friday: { startTime: '08:00:00', endTime: '16:00:00' },
      saturday: { startTime: '08:00:00', endTime: '16:00:00' },
      sunday: { startTime: '08:00:00', endTime: '16:00:00' },
    },
    tenantId,
  });
}

const updateEmployee = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}`, { ...values, tenantId, employeeId });

let updateAvailabilityToken;
const updateEmployeeAvailability = (tenantId, employeeId, values) => {
  if (typeof updateAvailabilityToken !== typeof undefined) {
    updateAvailabilityToken.cancel('Operation canceled due to new request.');
  }
  updateAvailabilityToken = axios.CancelToken.source();
  try {
    return axios.put(
      `api/${tenantId}/hrm/employees/${employeeId}/update-availability`,
      { ...values },
      {
        cancelToken: updateAvailabilityToken.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const updateAddress = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/moved`, { ...values, tenantId, employeeId });
const updateEmployment = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/update-employment`, {
    ...values,
    tenantId,
    employeeId,
  });
const updateSkills = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/update-skills`, { ...values, tenantId, employeeId });
const updateCertificates = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/update-certificates`, {
    ...values,
    tenantId,
    employeeId,
  });
const updateDriversLicense = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/update-drivers-license`, {
    ...values,
    tenantId,
    employeeId,
  });
const changeProfilePicture = (tenantId, employeeId, mediaObjectId) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/change-profile-picture`, {
    tenantId,
    employeeId,
    mediaObjectId,
  });
const addCertificate = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/add-certificate`, {
    ...values,
    tenantId,
    employeeId,
  });
const addContract = (tenantId, employeeId, values) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/add-contract`, {
    ...values,
    tenantId,
    employeeId,
  });
const removeContract = (tenantId, employeeId, contractId) =>
  axios.put(`api/${tenantId}/hrm/employees/${employeeId}/remove-contract`, {
    tenantId,
    employeeId,
    contractId,
  });

let emailToken;
const getEmployeeByEmailAddress = async (tenantId, emailAddress, cancel = false) => {
  if (cancel) {
    if (typeof emailToken !== typeof undefined) {
      emailToken.cancel('Operation canceled due to new request.');
    }
    emailToken = axios.CancelToken.source();
  }

  try {
    return axios.get(`api/${tenantId}/hrm/employees`, {
      params: {
        emailAddress,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: cancel !== false ? emailToken.token : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export default {
  getEmployee,
  getEmployees,
  createEmployee,
  updateEmployeeAvailability,
  updateEmployee,
  updateAddress,
  updateEmployment,
  updateSkills,
  updateCertificates,
  updateDriversLicense,
  changeProfilePicture,
  addCertificate,
  addContract,
  removeContract,
  getEmployeeByEmailAddress,
};
