import axios from 'axios';
import Qs from 'qs';

export const getEventsWithoutHoursEntryByEmployeeId = async (tenantId, employeeId) =>
  axios.get(`api/${tenantId}/hrm/employees/${employeeId}/event-without-hours-entries`);

export const getEventsWithoutHoursEntryByTenantId = async tenantId =>
  axios.get(`api/${tenantId}/hrm/employees/event-without-hours-entries`);

export const getEmployeesWithHoursToBeApproved = async tenantId =>
  axios.get(`api/${tenantId}/hrm/employees/hour-entries/employee-with-hours-to-be-approveds`);

export const enterHours = async (tenantId, employeeId, values) =>
  axios.post(`api/${tenantId}/hrm/employees/${employeeId}/enter-hours`, { tenantId, employeeId, ...values });

export const removeHours = async (tenantId, entryId) =>
  axios.post(`api/${tenantId}/hours-entries/${entryId}/remove`, { tenantId, entryId });

export const getHourEntriesByEmployeeIdAndPeriod = async (tenantId, employeeId, startDate, endDate) =>
  axios.get(`api/${tenantId}/hrm/employees/${employeeId}/hours-entries`, {
    params: {
      perPage: 99999,
      endDate: {
        after: startDate,
      },
      startDate: {
        before: endDate,
      },
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

export const getHourEntriesToApproveByEmployeeIdAndPeriod = async (tenantId, employeeId, startDate, endDate) =>
  axios.get(`api/${tenantId}/hrm/employees/${employeeId}/hours-entries/to-be-approved`, {
    params: {
      perPage: 99999,
      startDate: {
        after: startDate,
        before: endDate,
      },
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

export const getHourEntriesByProjectId = async (tenantId, projectId) =>
  axios.get(`api/${tenantId}/projects/${projectId}/hours-entries`, {
    params: {
      perPage: 99999,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
