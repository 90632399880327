import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getProjectEstimates } from 'Domain/Project/Services/ProjectService';
import { newMercureMessage } from 'Domain/App/Ducks/App.duck';

export const actionTypes = {
  requestProjectsEstimates: '[Project Quote] Fetch estimate',
  storeEstimates: '[Project Quote] Store estimate',
};

const initialState = {
  loading: true,
  projectId: null,
  items: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.requestProjectsEstimates: {
      return {
        ...state,
        items: [],
        projectId: action.projectId,
        loading: true,
      };
    }
    case actionTypes.storeEstimates: {
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  requestProjectsEstimates: projectId => ({
    type: actionTypes.requestProjectsEstimates,
    projectId,
  }),
  storeEstimates: payload => ({
    type: actionTypes.storeEstimates,
    payload,
  }),
};

export function* saga() {
  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && ['MovingJobEstimate'].includes(action.data['@type'])) {
      const { projectId, items } = yield select(state => state.EstimatesReducer);

      if (action.data.project.includes(projectId)) {
        const newItems = [...items].filter(
          ({ projectEstimateId }) => projectEstimateId !== action.data.projectEstimateId,
        );
        yield put(actions.storeEstimates([...newItems, action.data]));
      }
    }
  });

  yield takeLatest(actionTypes.requestProjectsEstimates, function* getProjectEstimatesSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getProjectEstimates(currentTenantId, action.projectId, 1, 2000);

    yield put(actions.storeEstimates(response.data['hydra:member']));
  });
}
