import { isValidElement, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(() => ({}));

const BASCheckboxComponent = ({ checked, checkboxProps, align, label, intl, ...props }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  let realLabel = label;
  if (typeof label === 'object' && !isValidElement(label)) {
    realLabel = intl.formatMessage(label);
  }
  return (
    <Grid container alignItems={align || 'center'}>
      <Grid item>
        <FormControlLabel
          style={{ alignItems: align || 'center' }}
          control={
            <Checkbox
              style={{ alignItems: align || 'center', paddingBottom: 0 }}
              className={hover ? classes.hover : ''}
              inputProps={{
                onMouseEnter: () => {
                  if (!checked) setHover(true);
                },
                onMouseLeave: () => {
                  setHover(false);
                },
              }}
              {...props}
              {...checkboxProps}
              checked={checked || hover}
              disableRipple
              label={realLabel}
            />
          }
          {...props}
          label={realLabel}
        />
      </Grid>
    </Grid>
  );
};

export default injectIntl(BASCheckboxComponent);
