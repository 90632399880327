import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import { EventSourcePolyfill } from 'event-source-polyfill';

export function mercureSubscribe(mercureToken, tenantId, topics) {
  const url = new URL(process.env.REACT_APP_MERCURE_URL);

  topics.forEach(topic =>
    url.searchParams.append('topic', `${process.env.REACT_APP_API_URL}${topic.replace('{tenantId}', tenantId)}`),
  );

  return new EventSourcePolyfill(url, {
    headers: {
      Authorization: `Bearer ${mercureToken}`,
    },
  });
}

export function normalizeErrors(json) {
  const error = json['hydra:description'] || json['hydra:title'] || 'An error occurred.';
  if (!json.violations) return error;

  const errors = { _error: error };
  json.violations.forEach(violation => {
    if (errors[violation.propertyPath]) {
      errors[violation.propertyPath] += `\n ${violation.message}`;
    } else {
      errors[violation.propertyPath] = violation.message;
    }
  });

  return errors;
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, value => (Array.isArray(value) ? value.map(v => get(v, '@id', v)) : get(value, '@id', value)));
}
