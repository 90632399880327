import { Container, Dialog, DialogContent, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  dialog: {
    background: '#464457',
    color: '#FFFFFF',
  },
  dialogContent: {
    background: '#464457',
    color: '#FFFFFF',
    height: '100%',
    boxSizing: 'border-box',
  },
  whiteText: {
    background: '#464457',
    paddingTop: '20px',
    color: '#FFFFFF',
  },
  closeIcon: {
    position: 'relative',
    background: '#464457',
    color: '#FFFFFF',
    top: '10px',
    float: 'right',
    fontSize: '22px',
  },
  lightDialog: {
    background: '#ffffff',
    color: '#BCC1D9',
  },
  lightDialogContent: {
    background: '#ffffff',
    color: '#464457',
    height: '100%',
  },
  lightText: {
    background: '#ffffff',
    paddingTop: '20px',
    color: '#464457',
  },
  lightCloseIcon: {
    position: 'relative',
    background: '#ffffff',
    color: '#BCC1D9',
    top: '10px',
    float: 'right',
    fontSize: '22px',
  },
}));

const DialogComponent = props => {
  const { light, open, handleClose, title, children, maxWidth, hideCloseButton } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: light ? classes.lightDialog : classes.dialog }}
      className={light ? classes.lightDialog : classes.dialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      scroll="body"
    >
      <DialogContent className={light ? classes.lightDialogContent : classes.dialogContent}>
        <Container maxWidth={maxWidth || 'sm'} className={light ? classes.lightMainContainer : classes.mainContainer}>
          {!hideCloseButton && (
            <FontAwesomeIcon
              size="lg"
              icon={['fal', 'times']}
              onClick={handleClose}
              className={light ? classes.lightCloseIcon : classes.closeIcon}
            />
          )}
          <Grid spacing={4} container>
            {title && (
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  align="center"
                  className={light ? classes.lightText : classes.whiteText}
                >
                  {title}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DialogComponent;
