import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { ButtonComponent } from '../../../../components';

const useStyles = makeStyles(() => ({
  white: {
    '& .MuiFormLabel-root': {
      color: '#ffffff',
    },
    '& .MuiTextField-root': {
      color: '#ffffff',
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInput-underline:before': {
      borderColor: '#ffffff',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#ffffff',
    },
  },
}));

const LoginForm = ({ handleSubmit }) => {
  const classes = useStyles();
  const validate = values => {
    const errors = {};

    if (!values.username) {
      errors.username = <FormattedMessage id="error.required" />;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
      errors.username = <FormattedMessage id="error.invalid.email" />;
    }

    if (!values.password) {
      errors.password = <FormattedMessage id="error.required" />;
    }

    return errors;
  };

  return (
    <Grid item xs={12}>
      <Formik initialValues={{ username: '', password: '' }} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  required
                  type="email"
                  component={TextField}
                  className={classes.white}
                  name="username"
                  label={<FormattedMessage id="login.label.username" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  type="password"
                  component={TextField}
                  className={classes.white}
                  label={<FormattedMessage id="login.label.password" />}
                  name="password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} container justify="space-between">
                <Grid item>
                  <Button component={Link} to="/forgot-password" color="secondary" variant="text">
                    <FormattedMessage id="login.forgot_password" />
                    &nbsp;
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Button>
                </Grid>
                <Grid item>
                  <ButtonComponent type="submit" fullWidth variant="contained" color="primary" loading={isSubmitting}>
                    <FormattedMessage id="login.submit_button" />
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default LoginForm;
