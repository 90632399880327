import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { InvoiceService } from 'services';
import { tableAction, tableActions, tableInitialState } from './Table.duck';
import { newMercureMessage } from './App.duck';

export const actionTypes = {
  RequestData: '[DashboardInvoices] RequestDashboardInvoices',
  FulfilledTable: '[DashboardInvoices] FulfilledDashboardInvoices',
  SearchTable: '[DashboardInvoices] Search DashboardInvoices',
  ChangePage: '[DashboardInvoices] change page',
  SetPageSize: '[DashboardInvoices] set page size',
  SortTable: '[DashboardInvoices] Sort DashboardInvoices',
  ApplyFilter: '[DashboardInvoices] Apply filter',
  RemoveFilter: '[DashboardInvoices] Remove filter',
  ResetFilters: '[DashboardInvoices] Reset filter',
  AddItem: '[DashboardInvoices] Add invoice',
  RemoveItem: '[DashboardInvoices] remove invoice',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.RemoveItem: {
      return {
        ...state,
        items: [...state.items].filter(({ invoiceId }) => invoiceId !== action.item.invoiceId),
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestDashboardInvoicesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.DashboardInvoicesReducer);

    const response = yield InvoiceService.getInvoices(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      { invoiceNumber: 'desc' },
      { invoiceStatus: ['concept'] },
      null,
    );

    if (!response.cancelPrevQuery && response.data['hydra:member']) {
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    }
  });

  yield takeEvery(newMercureMessage, function* execute(action) {
    if (typeof action.data['@type'] === 'string' && action.data['@type'] === 'Invoice1') {
      yield put(actions.requestData());
    }
  });
}
