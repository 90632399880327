import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';

import { FormattedMessage } from 'react-intl';
import { PhotoService } from 'services';
import FormHelperText from '@material-ui/core/FormHelperText';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: '#ffffff',
  },
  input: {
    display: 'none',
  },
  box: {
    marginTop: '3%',
    border: '2px solid #FFF',
    position: 'relative',
  },
  close: {
    position: 'relative',
    top: '-12px',
    left: '-13px',
  },
  buttonRow: {
    marginBottom: '3%',
  },
  helperText: {
    background: 'transparent',
    paddingTop: '7px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

const PhotoForm = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const selectedRoom = useSelector(state => state.SecondStepReducer.propSelected);
  const roomKey = `${selectedRoom.value}:${selectedRoom.index}`;
  const tenantId = useSelector(state => state.AppReducer.tenant.tenantId);
  const { authToken } = useSelector(state => state.AppReducer);
  const imagesSelected = useSelector(state => state.MovingJobEstimateReducer.images[roomKey] || []);
  const [progress, setProgress] = useState(null);
  const classes = useStyles();

  const handleChange = e => {
    const input = e.target;

    if (!input.files[0]) {
      return;
    }

    const fileSize = input.files[0].size / 1024 / 1024; // in MB
    if (fileSize > 5) {
      setError('room.photo.file_to_big');

      return;
    }

    setError(null);

    (async () => {
      try {
        const formData = new FormData();
        formData.append('file', input.files[0]);
        setProgress(Math.ceil(Math.random() * 100));
        const result = await PhotoService.upload(tenantId, authToken, formData, progressEvent =>
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
        );

        const copyArray = [...imagesSelected];
        copyArray.push(result);

        await dispatch(MovingJobEstimateActions.save({ images: { [roomKey]: copyArray } }));
        setProgress(null);
      } catch (err) {
        dispatch(MovingJobEstimateActions.setError({ error: { ...err } }));
      }
    })();
  };

  const deleteItem = item => {
    const copyArray = [...imagesSelected];
    copyArray.splice(item, 1);
    dispatch(MovingJobEstimateActions.save({ images: { [roomKey]: copyArray } }));
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" align="center" className={classes.whiteText}>
            <FormattedMessage id="room.add_a_photo" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.buttonRow}>
          <label htmlFor="contained-button-file">
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={e => handleChange(e)}
            />
            {error && (
              <FormHelperText error className={classes.helperText}>
                <FormattedMessage id={error} />
              </FormHelperText>
            )}
            <Button fullWidth variant="contained" color="secondary" component="span">
              <FormattedMessage id="room.make_picture" />
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {progress && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress color="secondary" variant="determinate" value={progress} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body1" className={classes.whiteText}>{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
          </Grid>
        )}
        {imagesSelected.map((item, index) => (
          <Grid item xs={4} key={index}>
            <Box className={classes.box}>
              <Cancel className={classes.close} onClick={() => deleteItem(index)} />

              <img src={item.contentUrl} width="100%" alt="user" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PhotoForm;
