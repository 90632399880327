import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';
import { actions as SecondStepActions } from '../../Domain/SecondStep/Ducks/SecondStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const useStyles = makeStyles(() => ({
  listItem: {
    height: '64px',
    boxSizing: 'border-box',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '19px',
  },
}));

const ServicesQuestionForm = () => {
  const dispatch = useDispatch();
  const {
    services,
    loadedInformation: { serviceTypes },
  } = useSelector(state => state.MovingJobEstimateReducer);
  const classes = useStyles();

  useEffect(() => {
    if (serviceTypes.length === 0) {
      dispatch(MovingJobEstimateActions.loadInformation());
    }
  }, [serviceTypes.length]);

  const handleToggle = (service, value, internalType) => () => {
    if (internalType === 'assemble') {
      dispatch(SecondStepActions.setSkip('assembleForm', !value));
    } else if (internalType === 'disassemble') {
      dispatch(SecondStepActions.setSkip('disassembleForm', !value));
    } else if (internalType === 'storage') {
      dispatch(SecondStepActions.setSkip('storageForm', !value));
      dispatch(SecondStepActions.setSkip('storageBoxesForm', !value));
    }

    dispatch(MovingJobEstimateActions.save({ services: { [service]: value } }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center" className={classes.headerTitle}>
          <FormattedMessage id="services.which_services_should_be_performed" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List className={classes.root}>
          {serviceTypes.map(service => (
            <ListItem
              key={service.id}
              className={classes.listItem}
              role={undefined}
              dense
              button
              onClick={handleToggle(service.serviceTypeId, !services[service.serviceTypeId], service.internalType)}
            >
              <ListItemIcon className={classes.icon}>
                <Checkbox edge="start" checked={services[service.serviceTypeId] === true} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                id={service.id}
                primary={service.label}
                primaryTypographyProps={{ className: classes.title }}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ServicesQuestionForm;
