import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import dayjs from 'dayjs';
import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';
import { actions as SecondStepActions } from '../../../SecondStep/Ducks/SecondStep.duck';
import { actions as MovingJobEstimateActions } from '../../Ducks/MovingJobEstimate.duck';
import WizardContext from '../../../EmployeeApp/Utils/WizardContext';
import { generateQuote, sendQuote, createQuote } from '../../../Project/Services/MovingJobService';
import EditingFinancialDocumentTable from '../../../Financial/Forms/EditingFinancialDocumentTable';
import { normalizeErrors } from '../../../../utils/dataAccess';
import FinancialDocumentTemplateSelectTextField from '../../../../components/FinancialDocumentTemplateSelectTextField';
import FinancialDocumentFreeTextFieldsForm from '../../../Financial/Forms/FinancialDocumentFreeTextFieldsForm';
import { TenantService } from '../../../../services';
import { finishEvent, getEventById } from '../../../Planning/Services/PlanningService';
import { enterHours } from '../../../MobileApp/Services/HoursEntryService';

const useStyles = makeStyles(theme => ({
  whiteButton: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  padding: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
}));

const templateTypes = ['default', 'manual', 'moving-job'];

const SendQuotePage = () => {
  const formRef = useRef();
  const { setNextStepFunction, setFinishStepFunction } = useContext(WizardContext);
  const dispatch = useDispatch();
  const history = useHistory();
  setNextStepFunction(null);
  const [shouldSendQuote, setShouldSendQuote] = useState(false);
  const employeeId = useSelector(state => state.AppReducer.employee.employeeId);
  const [defaultQuote, setDefaultQuote] = useState(null);
  const {
    projectId,
    eventId,
    startedAt,
    services,
    customerForm: { language },
  } = useSelector(state => state.MovingJobEstimateReducer);
  const { items: serviceItems } = useSelector(state => state.ServiceTypesReducer);
  const {
    tenant: {
      tenantId,
      address: { country },
    },
  } = useSelector(state => state.AppReducer);

  const finishUp = async () => {
    if (eventId) {
      const { data: event } = await getEventById(tenantId, eventId);
      if (event && !event.finished && startedAt) {
        const endDate = dayjs(new Date(Math.ceil(new Date().getTime() / 900000) * 900000));
        const startDate = dayjs(new Date(Math.floor(dayjs(startedAt).toDate().getTime() / 900000) * 900000));
        const endDateString = endDate.utc().format('YYYY-MM-DD HH:mm:ss');
        await finishEvent(tenantId, eventId, endDateString);

        await enterHours(tenantId, employeeId, {
          startDate: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
          endDate: endDateString,
          breakTime: 0,
          reason: null,
          entryData: {
            entryType: projectId ? 'project' : 'event',
            projectId: projectId || undefined,
            eventId,
          },
        });
      }
    }

    await dispatch(FirstStepActions.reset());
    await dispatch(SecondStepActions.reset());
    await dispatch(MovingJobEstimateActions.reset());
    history.push('/app');
  };

  const startCreatingQuote = async (andSend = false) => {
    const response = await generateQuote(tenantId, projectId);
    setDefaultQuote(response.data);
    setShouldSendQuote(andSend);
  };

  const handleCreateQuote = async (values, form) => {
    const request = {
      lines: values.lines.map(line => ({
        ...line,
        vatCodeId: line.vatCode.vatCodeId,
        lineType: line.hourlyRate ? 'hourly-rate' : 'normal',
      })),
      freeText: values.freeText,
      includingVat: values.includingVat,
      templateId: values.templateId,
      expiresAt: moment(values.expiresAt).format('YYYY-MM-DD'),
    };
    try {
      if (shouldSendQuote) {
        await sendQuote(tenantId, projectId, request);
      } else {
        await createQuote(tenantId, projectId, request);
      }
    } catch (e) {
      if (e.response && [400, 422].includes(e.response.status)) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        } else {
          form.setErrors(errors);
        }

        return;
      }

      throw e;
    }

    await finishUp();
  };

  useEffect(() => {
    setFinishStepFunction(() => () => {
      if (formRef.current) {
        formRef.current.submitForm();
      }
    });

    return () => {
      setFinishStepFunction(null);
    };
  }, [setFinishStepFunction, formRef]);

  const storageService = serviceItems.find(({ internalType }) => internalType === 'storage');
  if (storageService && services[storageService.serviceTypeId]) {
    templateTypes.push('moving-job-with-storage');
  }

  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="quotationTool.sendQuote.title" />
        </Typography>
      </Grid>
      {!defaultQuote && (
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={classes.blueButton}
                fullWidth
                onClick={() => startCreatingQuote(true)}
              >
                <FormattedMessage id="quotationTool.sendQuote.createAndSend" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={classes.blueButton}
                fullWidth
                onClick={() => startCreatingQuote(false)}
              >
                <FormattedMessage id="quotationTool.sendQuote.createWithoutSending" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" className={classes.whiteButton} fullWidth onClick={finishUp}>
                <FormattedMessage id="quotationTool.sendQuote.finishUp" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
      {defaultQuote && (
        <Formik
          innerRef={formRef}
          initialValues={{
            templateId: defaultQuote.templateId,
            lines: [...defaultQuote.lines],
            expiresAt: defaultQuote.expiresAt,
            includingVat: defaultQuote.includingVat,
            freeText: {},
          }}
          validationSchema={Yup.object({
            expiresAt: Yup.date()
              .nullable()
              .required()
              .min(new Date())
              .transform((value, originalValue) => (originalValue ? moment(originalValue).toDate() : null)),
            lines: Yup.array()
              .min(1)
              .of(
                Yup.object({
                  description: Yup.string().required(),
                  quantity: Yup.number().required(),
                  vatCode: Yup.object({
                    vatCodeId: Yup.string().required(),
                  }),
                  pricePerUnit: Yup.object({
                    amount: Yup.number().required(),
                    currency: Yup.string().required(),
                  }),
                }),
              ),
          })}
          onSubmit={handleCreateQuote}
        >
          {({ setFieldValue, values }) => (
            <Grid container spacing={4} className={classes.padding}>
              <Grid item xs={12} md={4}>
                <Field
                  color="secondary"
                  component={FinancialDocumentTemplateSelectTextField}
                  name="templateId"
                  identityId={defaultQuote.identityId}
                  documentType="quote"
                  templateType={templateTypes}
                  label={<FormattedMessage id="financial.quote.template" />}
                  fullWidth
                  onSelectedItem={async newTemplate => {
                    console.log(newTemplate);
                    const freeText = { ...values.freeText };
                    if (newTemplate) {
                      const translation = newTemplate.translations.find(tr => tr.language === language);
                      Object.keys(freeText)
                        .filter(key => !freeText[key])
                        .forEach(key => {
                          delete freeText[key];
                        });
                      if (translation) {
                        translation.freeTextFields.forEach(key => {
                          if (typeof freeText[key] === 'undefined') {
                            freeText[key] = '';
                          }
                        });
                      }
                      setFieldValue('freeText', freeText);
                      const response = await TenantService.calculateExpiresAt(
                        newTemplate.tenantId,
                        newTemplate.financialDocumentTemplateId,
                      );
                      setFieldValue('expiresAt', response.data.calculatedExpiresAt);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} hidden={!shouldSendQuote}>
                <Field
                  color="secondary"
                  component={KeyboardDatePicker}
                  format="L"
                  ampm={false}
                  rifmFormatter={val =>
                    // eslint-disable-next-line no-useless-escape
                    val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, '')
                  }
                  // eslint-disable-next-line no-useless-escape
                  refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
                  name="expiresAt"
                  label={<FormattedMessage id="financial.quote.expiresAt" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ToggleButtonGroup
                  value={values.includingVat}
                  exclusive
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setFieldValue('includingVat', newValue);
                      values.lines.forEach((line, index) => {
                        const percentage = 1 + line.vatPercentage.percentage / 100;
                        if (newValue) {
                          setFieldValue(
                            `lines.${index}.pricePerUnit.amount`,
                            Math.round(line.pricePerUnit.amount * percentage).toString(),
                          );
                          setFieldValue(
                            `lines.${index}.totalPrice.amount`,
                            Math.round(line.totalPrice.amount * percentage).toString(),
                          );
                        } else {
                          setFieldValue(
                            `lines.${index}.pricePerUnit.amount`,
                            Math.round(line.pricePerUnit.amount / percentage).toString(),
                          );
                          setFieldValue(
                            `lines.${index}.totalPrice.amount`,
                            Math.round(line.totalPrice.amount / percentage).toString(),
                          );
                        }
                      });
                    }
                  }}
                >
                  <ToggleButton value={false}>
                    <FormattedMessage id="financial.excludingVat" />
                  </ToggleButton>
                  <ToggleButton value>
                    <FormattedMessage id="financial.includingVat" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <FinancialDocumentFreeTextFieldsForm />
              </Grid>
              <Grid item xs={12}>
                <EditingFinancialDocumentTable showHourlyRate showOptional document={defaultQuote} country={country} />
              </Grid>
            </Grid>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default SendQuotePage;
