import { put, select, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { getNewsItemById } from '../Services/NewsService';

export const actionTypes = {
  ResetData: '[NewsItem] Reset NewsItem',
  RequestData: '[NewsItem] Request NewsItem',
  FulfilledData: '[NewsItem] Fulfilled NewsItem',
};

const initialState = {
  ...singleObjectInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  fulFilledQuotes: quotes => ({
    type: actionTypes.FulfilledQuotes,
    quotes,
  }),
  fulFilledInvoices: invoices => ({
    type: actionTypes.FulfilledInvoices,
    invoices,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestNewsItemSaga(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const response = yield getNewsItemById(currentTenantId, action.itemId);

    yield put(actions.fulfilledData(response.data));
  });
}
