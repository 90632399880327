import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import DialogComponent from 'components/DialogComponent';
import IconCardComponent from 'components/IconCardComponent';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { DragToCloseModalComponent } from 'components';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: '#ffffff',
  },
  searchInput: {
    background: '#ffffff',
    color: '#495057',
    borderRadius: '5px',
  },
}));

const AddFurnitureComponent = ({ open, handleClose, furniture, handleAddItem }) => {
  const classes = useStyles();
  const [searchFilter, setSearchFilter] = useState();

  if (!furniture) {
    return '';
  }

  let filteredFurnitureList = [];
  if (furniture.length > 0 && searchFilter) {
    const regex = new RegExp(searchFilter, 'i');
    filteredFurnitureList = furniture.filter(furnitureType => regex.test(furnitureType.label));
  }

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      title={<FormattedMessage id="room.furniture.choose_the_furniture_to_add" />}
    >
      <DragToCloseModalComponent closeFunction={() => handleClose()} />

      <Grid spacing={4} container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.whiteText}>
            <FormattedMessage id="room.furniture.search" />
          </Typography>
          <TextField
            inputProps={{ className: classes.searchInput }}
            onChange={event => setSearchFilter(event.target.value)}
            variant="outlined"
            color="secondary"
            name="search"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.whiteText} align="center">
                <FormattedMessage
                  id="room.furniture.results"
                  values={{
                    numberOfResults: searchFilter ? filteredFurnitureList.length : furniture.length,
                  }}
                />
              </Typography>
            </Grid>
            {!searchFilter &&
              furniture.length > 0 &&
              furniture.map(item => (
                <Grid item xs={6} sm={4} key={item.id ?? item.furnitureTypeId}>
                  <IconCardComponent
                    item={item}
                    clickFunction={insItem => handleAddItem(insItem)}
                    variantsFunction={(insItem, changes) => handleAddItem(insItem, changes)}
                  />
                </Grid>
              ))}
            {searchFilter &&
              filteredFurnitureList.map(item => (
                <Grid item xs={6} sm={4} key={item.id ?? item.furnitureTypeId}>
                  <IconCardComponent
                    item={item}
                    clickFunction={insItem => handleAddItem(insItem)}
                    variantsFunction={(insItem, changes) => handleAddItem(insItem, changes)}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default AddFurnitureComponent;
