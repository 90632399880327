import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as App from 'Domain/App/Ducks/App.duck';
import * as Tenants from 'Domain/Admin/Ducks/Tenants.duck';
import * as Tenant from 'Domain/Admin/Ducks/Tenant.duck';
import * as AutomatedEmails from 'Domain/Admin/Ducks/AutomatedEmails.duck';
import * as AutomatedEmail from 'Domain/Admin/Ducks/AutomatedEmail.duck';
import * as Users from 'Domain/Admin/Ducks/Users.duck';
import * as User from 'Domain/Admin/Ducks/User.duck';
import * as Rooms from 'Domain/Admin/Ducks/Rooms.duck';
import * as Room from 'Domain/Admin/Ducks/Room.duck';
import * as Furnitures from 'Domain/Admin/Ducks/Furnitures.duck';
import * as Furniture from 'Domain/Admin/Ducks/Furniture.duck';

const appReducer = combineReducers({
  AppReducer: App.reducer,
  TenantsReducer: Tenants.reducer,
  TenantReducer: Tenant.reducer,
  AutomatedEmailsReducer: AutomatedEmails.reducer,
  AutomatedEmailReducer: AutomatedEmail.reducer,
  UsersReducer: Users.reducer,
  UserReducer: User.reducer,
  RoomsReducer: Rooms.reducer,
  RoomReducer: Room.reducer,
  FurnituresReducer: Furnitures.reducer,
  FurnitureReducer: Furniture.reducer,
});

export const adminReducer = (state, action) => appReducer(state, action);

export function* adminSaga() {
  yield all([
    App.saga(),
    Tenants.saga(),
    Tenant.saga(),
    AutomatedEmails.saga(),
    AutomatedEmail.saga(),
    Users.saga(),
    User.saga(),
    Rooms.saga(),
    Room.saga(),
    Furnitures.saga(),
    Furniture.saga(),
  ]);
}
