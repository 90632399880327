import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

export const actionTypes = {
  SavedFailedQuote: '[Failed Quotes] Save Failed MovingJobEstimate',
  RemoveFailedQuote: '[Failed Quotes] Remove Failed MovingJobEstimate',
};

const initialState = {
  failedQuotes: [],
};

export const reducer = persistReducer(
  { storage: localforage, key: 'failed-estimates' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SavedFailedQuote: {
        return {
          failedQuotes: [...state.failedQuotes, { ...action.payload }],
        };
      }
      case actionTypes.RemoveFailedQuote: {
        const copyState = [...state.failedQuotes];
        const indexToRemove = copyState.findIndex(item => item.projectId === action.payload);
        copyState.splice(indexToRemove, 1);
        return {
          ...state,
          failedQuotes: copyState,
        };
      }
      default:
        return { ...state };
    }
  },
);

export const actions = {
  save: data => {
    const { error, ...saveData } = data;
    return {
      type: actionTypes.SavedFailedQuote,
      payload: { ...saveData, status: 'failed' },
    };
  },
  remove: index => ({ type: actionTypes.RemoveFailedQuote, payload: index }),
};
