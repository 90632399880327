import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import EditableCardComponent from '../EditableCardComponent';
import { normalizeErrors } from '../../utils/dataAccess';

const FormikEditableCardComponent = ({
  loading,
  onAdd,
  onEdit,
  editInitialValues,
  addInitialValues,
  validate,
  editValidationSchema,
  addValidationSchema,
  enableReinitialize,
  editContent,
  title,
  handleShowEdit,
  ...props
}) => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(false);
  }, [loading, setEdit]);

  if (loading) {
    return (
      <EditableCardComponent loading={loading} {...props} title={title}>
        {props.children}
      </EditableCardComponent>
    );
  }

  if (!add && !edit) {
    return (
      <EditableCardComponent
        title={title}
        handleShowEdit={value => {
          if (handleShowEdit) {
            handleShowEdit(true);
          }

          setEdit(value);
        }}
        handleShowAdd={value => setAdd(value)}
        {...props}
      />
    );
  }

  let values = edit ? editInitialValues : addInitialValues;
  if (typeof values === 'function') {
    values = values();
  }
  let validationSchema = edit ? editValidationSchema : addValidationSchema;
  if (typeof validationSchema === 'function') {
    validationSchema = validationSchema();
  }
  const { innerRef } = props;
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      validate={validate}
      innerRef={innerRef}
      onSubmit={() => {}}
    >
      {form => (
        <EditableCardComponent
          handleShowEdit={value => {
            if (handleShowEdit) {
              handleShowEdit(true);
            }

            setEdit(value);
          }}
          title={title}
          editFlag={edit}
          addFlag={add}
          handleShowAdd={value => setAdd(value)}
          editContent={({ handleSave }) => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleSave();
              }}
            >
              {typeof editContent !== 'function' && editContent}
              {typeof editContent === 'function' && editContent(form)}
              <Button type="submit" style={{ opacity: 0 }}>
                &nbsp;
              </Button>
            </Form>
          )}
          onSave={async ({ finish }) => {
            if (!form.dirty) {
              return finish();
            }

            await form.validateForm();
            await form.submitForm();

            if (form.isValid) {
              let result = false;

              try {
                if (add) {
                  result = await onAdd(form.values);
                } else {
                  result = await onEdit(form.values);
                }
              } catch (e) {
                if (e.response && [400, 422].includes(e.response.status)) {
                  const errors = normalizeErrors(e.response.data);
                  form.setErrors(errors);
                  form.setSubmitting(false);

                  return false;
                }

                throw e;
              }

              if (result === true) {
                return finish();
              }

              if (typeof result === 'string') {
                form.setStatus(result);
              }

              form.setErrors(result);
              form.setSubmitting(false);

              return false;
            }

            return false;
          }}
          {...props}
        />
      )}
    </Formik>
  );
};

export default FormikEditableCardComponent;
