import { FormattedMessage } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  modal: {
    padding: theme.spacing(5),
    overflow: 'hidden',
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5),
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  paddingTop: {
    paddingTop: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  paddingAround: {
    padding: '48px',
  },
  bold: {
    fontWeight: theme.bold,
  },
  dark: {
    background: '#464457',
    color: '#FFFFFF',
  },
  modalTitle: {
    flex: '0 0 auto',
    margin: 0,
    // paddingTop: '48px',
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    // marginBottom: '33px',
  },
  pointer: {
    cursor: 'pointer',
  },
  notScrollable: {
    overflow: 'hidden',
    maxHeight: '100%',
  },
  cross: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

const DefaultModal = ({
  show,
  title,
  onHide,
  maxWidth,
  children,
  showActions,
  actions,
  dark,
  onSave,
  disableSave,
  noScroll,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={show}
      onExit={onHide}
      maxWidth={maxWidth}
      fullWidth
      classes={{ paper: noScroll ? classes.notScrollable : null }}
      className={classes.modal}
    >
      <Box className={classes.paddingAround}>
        <Grid container>
          <Grid item xs={10}>
            <div className={clsx({ [classes.dark]: dark }, classes.modalTitle)}>
              <Typography variant="h5" color="primary" className={classes.bold}>
                {title}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={clsx({ [classes.dark]: dark }, classes.modalTitle, classes.cross)}>
              <FontAwesomeIcon size="lg" icon={['fal', 'times']} className={classes.pointer} onClick={() => onHide()} />
            </div>
          </Grid>
        </Grid>

        <DialogContent
          style={{ overflow: noScroll ? 'hidden' : 'auto', boxSizing: 'border-box', paddingTop: '48px' }}
          className={clsx({ [classes.dark]: dark })}
        >
          {children}
        </DialogContent>
        {showActions &&
          (actions || (
            <DialogActions className={clsx({ [classes.dark]: dark })}>
              <Button variant="outlined" color="primary" onClick={onHide}>
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button variant="contained" color="primary" onClick={onSave} disabled={disableSave}>
                <FormattedMessage id="button.save" />
              </Button>
            </DialogActions>
          ))}
      </Box>
    </Dialog>
  );
};

export default DefaultModal;
