import axios from 'axios';
import Qs from 'qs';

let integrationsToken;
export const getIntegrations = (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof integrationsToken !== typeof undefined) {
    integrationsToken.cancel('Operation canceled due to new request.');
  }
  integrationsToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/integrations`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: integrationsToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

let batchDataToken;
export const getBatchData = (tenantId, integrationId, page, perPage, order, filters = {}, globalFilter) => {
  if (typeof batchDataToken !== typeof undefined) {
    batchDataToken.cancel('Operation canceled due to new request.');
  }
  batchDataToken = axios.CancelToken.source();
  try {
    return axios.get(`api/${tenantId}/integrations/${integrationId}/batch-datas`, {
      params: {
        ...filters,
        page,
        perPage,
        order,
        globalFilter: globalFilter !== '' ? globalFilter : undefined,
      },
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
      cancelToken: batchDataToken.token,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelPrevQuery: true };
    }

    throw error;
  }
};

export const getIntegration = (tenantId, integrationId) => axios.get(`api/${tenantId}/integrations/${integrationId}`);

export const addIntegration = async (tenantId, values) =>
  axios.post(`api/${tenantId}/integrations`, { ...values, tenantId });

export const updateIntegrationSettings = async (tenantId, integrationId, values) =>
  axios.put(`api/${tenantId}/integrations/${integrationId}`, { ...values, integrationId, tenantId });

export const processBatchData = async (tenantId, integrationId, batchId) =>
  axios.put(`api/${tenantId}/integrations/${integrationId}/batches/${batchId}/process`, {
    integrationId,
    batchId,
    tenantId,
  });

export const approveBatchData = async (tenantId, integrationId, batchId, values) =>
  axios.put(`api/${tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/approve`, {
    ...values,
    integrationId,
    batchId,
    tenantId,
  });

export const disapproveBatchData = async (tenantId, integrationId, batchId, values) =>
  axios.put(`api/${tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/disapprove`, {
    ...values,
    integrationId,
    batchId,
    tenantId,
  });

export const finalizedSchoutenZekerheidBatchData = async (tenantId, integrationId, batchId, dataId) =>
  axios.put(
    `api/${tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/${dataId}/schouten-zekerheid-finalized`,
    {
      integrationId,
      batchId,
      tenantId,
      dataId,
    },
  );
