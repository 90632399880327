import ApolloClient from 'apollo-boost';

const BasClient = (tenantId, authToken) =>
  new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}api/graphql/${tenantId}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

export default BasClient;
