import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    padding: '32px 20px',
    background: theme.palette.background.default,
    paddingBottom: '100px',
  },
  maxHeight: {
    padding: '32px 20px',
    background: theme.palette.background.default,
    minHeight: '-webkit-fill-available',
  },
}));

const MobileLayout = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.maxHeight, 'customPadding')}>
      <Grid item xs={12} className={clsx(classes.maxHeight, 'customPadding')}>
        {children}
      </Grid>
    </Grid>
  );
};

MobileLayout.propTypes = {
  children: PropTypes.object,
};

MobileLayout.defaultProps = {
  children: {},
};

export default MobileLayout;
