import { actions as AppActions } from 'Domain/App/Ducks/App.duck';
import { mercureSubscribe } from '../utils/dataAccess';

export default store => {
  let eventSource = null;
  return next => action => {
    if (!eventSource) {
      const {
        mercureToken,
        tenant: { tenantId },
      } = store.getState().AppReducer;
      if (mercureToken) {
        eventSource = mercureSubscribe(mercureToken, tenantId, ['api/{tenantId}/{+anything}']);
        eventSource.onmessage = message => {
          const data = JSON.parse(message.data);
          store.dispatch(AppActions.newMercureMessage({ ...message, data: '' }, data));
        };
      }
    }
    next(action);
  };
};
