const parseHoursToFloat = time => {
  const hoursMinutes = time.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  if (Number.isNaN(minutes)) {
    return hours;
  }

  return hours + minutes / 60;
};

export default parseHoursToFloat;
