import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  FilledFormThirdStep: '[Third Step] Fill form',
  LoadingPageThirdStep: '[Third Step] Loading Page',
  NextPageThirdStep: '[Third Step] Next Page',
  NextThirdStep: '[Third Step] Next',
  PrevThirdStep: '[Third Step] Prev',
  PrevPageThirdStep: '[Third Step] Prev Page',
  ResetThirdStep: '[Third Step] Reset',
  SetActiveThirdStep: '[Third Step] Active Step',
  ToggleHiddenNavThirdStep: '[Third Step] Toggle nav',
  SetSkipPageThirdStep: '[Third Step] Skip Page',
  PropSelectThirdStep: '[Third Step] Select Prop',
};

const initialState = {
  active: 0,
  clickedOrientation: 'left',
  loading: false,
  hidden: false,
  disabledSwipe: true,
  propSelected: { value: '', index: '' },
  roomFurnitureList: [],
  skipSteps: {},
  disabled: false,
  selected: {
    nextPage: 'boxesForm',
    prevPage: '',
    validate: false,
    canGoForward: true,
    formType: 'furnitureForm',
    completed: true,
    categoryType: 1,
  },
  items: [
    {
      nextPage: 'boxesForm',
      prevPage: '',
      validate: false,
      canGoForward: true,
      formType: 'furnitureForm',
      completed: true,
      showDot: true,
      categoryType: 1,
    },
    {
      nextPage: 'disassembleForm',
      prevPage: 'furnitureForm',
      formType: 'boxesForm',
      validate: true,
      canGoForward: false,
      completed: false,
      showDot: true,
      categoryType: 2,
    },
    {
      nextPage: 'assembleForm',
      prevPage: 'boxesForm',
      formType: 'disassembleForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: true,
      categoryType: 3,
    },
    {
      nextPage: '',
      prevPage: 'disassembleForm',
      formType: 'assembleForm',
      validate: false,
      canGoForward: true,
      completed: false,
      showDot: false,
      categoryType: 3,
    },
  ],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetActiveThirdStep: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.NextPageThirdStep: {
      const copyItems = [...state.items];
      const page = action.payload.active >= copyItems.length - 1 ? action.payload.active : action.payload.active + 1;
      let index = copyItems.findIndex(item => item.formType === action.payload.selected.nextPage);
      let nextItem = { ...copyItems[index] };
      if (!nextItem.validate) {
        nextItem.completed = true;
      }

      copyItems[index] = nextItem;

      while (!nextItem || state.skipSteps[nextItem.formType]) {
        // eslint-disable-next-line no-loop-func
        index = copyItems.findIndex(item => item.formType === nextItem.nextPage);
        nextItem = { ...copyItems[index] };

        if (!nextItem.validate) {
          nextItem.completed = true;
        }

        copyItems[index] = nextItem;
      }

      return {
        ...state,
        active: page,
        clickedOrientation: 'left',
        loading: false,
        selected: nextItem,
        items: copyItems,
      };
    }
    case actionTypes.PrevPageThirdStep: {
      let prevItem = state.items.find(item => item.formType === action.payload.selected.prevPage);

      while (!prevItem || state.skipSteps[prevItem.formType]) {
        // eslint-disable-next-line no-loop-func
        prevItem = state.items.find(item => item.formType === prevItem.prevPage);
      }

      return {
        ...state,
        ...action.payload,
        clickedOrientation: 'right',
        loading: false,
        selected: prevItem,
      };
    }
    case actionTypes.LoadingPageThirdStep: {
      return {
        ...state,
        loading: false,
        disabled: action.payload,
      };
    }
    case actionTypes.ToggleHiddenNavThirdStep: {
      return {
        ...state,
        hidden: action.payload,
      };
    }
    case actionTypes.PropSelectThirdStep: {
      return {
        ...initialState,
        ...action.payload,
        skipSteps: state.skipSteps,
      };
    }
    case actionTypes.FilledFormThirdStep: {
      const copyItems = [...state.items];

      const index = copyItems.findIndex(item => item.formType === state.selected.formType);
      const itemSelectedInArray = { ...copyItems[index] };
      itemSelectedInArray.canGoForward = action.payload;
      itemSelectedInArray.completed = action.payload;
      copyItems[index] = itemSelectedInArray;

      return {
        ...state,
        selected: {
          ...state.selected,
          canGoForward: action.payload,
          completed: action.payload,
        },
        items: copyItems,
      };
    }
    case actionTypes.ResetThirdStep: {
      return {
        ...initialState,
        skipSteps: state.skipSteps,
      };
    }
    case actionTypes.SetSkipPageThirdStep: {
      const skipStepsCopy = { ...state.skipSteps };
      skipStepsCopy[action.payload.formType] = action.payload.skip;

      return {
        ...state,
        skipSteps: skipStepsCopy,
      };
    }
    default:
      return { ...state };
  }
};

export const actions = {
  toggleNav: data => ({
    type: actionTypes.ToggleHiddenNavThirdStep,
    payload: data,
  }),
  setNav: data => ({ type: actionTypes.SetActiveThirdStep, payload: data }),
  setLoading: data => ({ type: actionTypes.LoadingPageThirdStep, payload: data }),
  nextPage: (data, selected) => ({
    type: actionTypes.NextPageThirdStep,
    payload: { active: data, selected },
  }),
  prevPage: (data, selected) => ({
    type: actionTypes.PrevPageThirdStep,
    payload: { active: data.selected, selected },
  }),
  filledForm: data => ({ type: actionTypes.FilledFormThirdStep, payload: data }),
  reset: () => ({ type: actionTypes.ResetThirdStep }),
  setCustomerStep: () => ({ type: actionTypes.SetCustomerStep }),
  next: (data, selected) => ({ type: actionTypes.NextThirdStep, payload: { data, selected } }),
  prev: (data, selected) => ({ type: actionTypes.PrevThirdStep, payload: { data, selected } }),
  setSkip: (formType, skip) => ({
    type: actionTypes.SetSkipPageThirdStep,
    payload: { formType, skip },
  }),
  setPropSelected: (data, roomFurnitureList) => ({
    type: actionTypes.PropSelectThirdStep,
    payload: { propSelected: data, roomFurnitureList },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.NextThirdStep, function* nextPageSaga(action) {
    yield put(actions.setLoading());
    yield put(actions.nextPage(action.payload.data, action.payload.selected));
  });
  yield takeLatest(actionTypes.PrevThirdStep, function* prevPageSaga(action) {
    const page = action.payload.data === 0 ? action.payload.data : action.payload.data - 1;
    yield put(actions.setLoading());
    yield put(actions.prevPage(page, action.payload.selected));
  });
}
