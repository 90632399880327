import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

const useStyles = makeStyles(() => ({
  card: {
    fontWeight: 'bold',
    width: '100%',
    height: '55px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: '1px dashed #C6C6C6',
    background: 'transparent',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
  },
  whiteText: {
    color: '#ffffff',
    marginLeft: '5px',
  },
  blackText: {
    color: '#525252',
    marginLeft: '5px',
  },
  button: {
    fontSize: '16px',
    lineHeight: '21px',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5%',
  },
}));

const AddAddressComponent = props => {
  const { label, clickFunction, inverted } = props;
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card} onClick={clickFunction}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={12} className={classes.flexContainer}>
              <FontAwesomeIcon color="#525252" icon={faPlus} />
              <Typography
                component="div"
                variant="p"
                className={`${inverted ? classes.whiteText : classes.blackText} button`}
              >
                {label}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

AddAddressComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inverted: PropTypes.bool,
  clickFunction: PropTypes.func,
};

AddAddressComponent.defaultProps = {
  label: '',
  inverted: false,
  clickFunction: null,
};

export default AddAddressComponent;
