import { useState } from 'react';
import { Grid, Slider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import './schedule.css';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

const mapperValues = {
  0: '20:00:00',
  5: '19:30:00',
  10: '19:00:00',
  15: '18:30:00',
  20: '18:00:00',
  25: '17:30:00',
  30: '17:00:00',
  35: '16:30:00',
  40: '16:00:00',
  45: '15:30:00',
  50: '15:00:00',
  55: '14:30:00',
  60: '14:00:00',
  65: '13:30:00',
  70: '13:00:00',
  75: '12:30:00',
  80: '12:00:00',
  85: '11:30:00',
  90: '11:00:00',
  95: '10:30:00',
  100: '10:00:00',
  105: '09:30:00',
  110: '09:00:00',
  115: '08:30:00',
  120: '08:00:00',
  125: '07:30:00',
  130: '07:00:00',
  135: '06:30:00',
  140: '06:00:00',
};

const mapperValuesSwap = {
  '20:00:00': 0,
  '19:30:00': 5,
  '19:00:00': 10,
  '18:30:00': 15,
  '18:00:00': 20,
  '17:30:00': 25,
  '17:00:00': 30,
  '16:30:00': 35,
  '16:00:00': 40,
  '15:30:00': 45,
  '15:00:00': 50,
  '14:30:00': 55,
  '14:00:00': 60,
  '13:30:00': 65,
  '13:00:00': 70,
  '12:30:00': 75,
  '12:00:00': 80,
  '11:30:00': 85,
  '11:00:00': 90,
  '10:30:00': 95,
  '10:00:00': 100,
  '09:30:00': 105,
  '09:00:00': 110,
  '08:30:00': 115,
  '08:00:00': 120,
  '07:30:00': 125,
  '07:00:00': 130,
  '06:30:00': 135,
  '06:00:00': 140,
};

const marks = [
  {
    value: '0',
  },
  {
    value: '5',
  },
  {
    value: '10',
  },
  {
    value: '15',
  },
  {
    value: '20',
  },
  {
    value: '25',
  },
  {
    value: '30',
  },
  {
    value: '35',
  },
  {
    value: '40',
  },
  {
    value: '45',
  },
  {
    value: '50',
  },
  {
    value: '55',
  },
  {
    value: '60',
  },
  {
    value: '65',
  },
  {
    value: '70',
  },
  {
    value: '75',
  },
  {
    value: '80',
  },
  {
    value: '85',
  },
  {
    value: '90',
  },
  {
    value: '95',
  },
  {
    value: '100',
  },
  {
    value: '105',
  },
  {
    value: '110',
  },
  {
    value: '115',
  },
  {
    value: '120',
  },
  {
    value: '125',
  },
  {
    value: '130',
  },
  {
    value: '135',
  },
  {
    value: '140',
  },
];

const componentBuilder = {
  headers: {
    leftHalf: ['', 'monday', 'tuesday', 'wednesday'],
    rightHalf: ['thursday', 'friday', 'saturday', 'sunday'],
  },
};

const useStyles = makeStyles(() => ({
  dFlex: {
    display: 'flex',
    justifyContent: 'center',
  },
  dayOfWeek: {
    fontWeight: 'bold',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  grayHover: {
    background: '#F2F3F8',
    borderRadius: '3px',
    padding: '10px',
    cursor: 'pointer',
  },
  centered: {
    alignItems: 'center',
    height: '78px',
    display: 'flex',
    fontWeight: 'bold',
  },
  colorBlue: { color: '#3A78F2' },
  h100: {
    height: '100%',
  },
}));

const CustomSlider = withStyles({
  root: {
    marginTop: '21px',
    width: '70% !important',
    color: '#3A78F2',
    height: '90%!important',
    padding: '13px 0',
    '&.Mui-disabled': {
      color: '#3A78F2',
      borderRadius: '1%',
      opacity: '0.3',
    },
  },
  thumb: {
    height: '8px',
    width: '20px',
    marginLeft: '22%!important',
    borderRadius: '0',
    marginBottom: '4px!important',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      width: '20px',
      height: '8px',
      background: 'none!important',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: '#3A78F2',
      // marginLeft: 1,
      // marginRight: 1,
    },
  },
  active: {},
  track: {
    width: '70% !important',
    borderRadius: '2%',
  },
  mark: {
    width: '70%',
    height: '1px',
    color: '#DADCE4',
  },
  rail: {
    color: 'white',
    opacity: 1,
    width: '70% !important',
  },
  markActive: {
    backgroundColor: '#3A78F2',
  },
})(Slider);

const mapVal = val => mapperValuesSwap[val];

const ScheduleComponent = ({ onChange, currentSchedule }) => {
  const mappedData = Object.entries(currentSchedule).map(([key, val]) => ({
    [key]: [mapVal(val.startTime) || mapperValuesSwap['08:00:00'], mapVal(val.endTime) || mapperValuesSwap['20:00:00']],
  }));

  const dataObject = {};
  mappedData.forEach(item => Object.assign(dataObject, item));

  const classes = useStyles();
  const [activeDay, setActiveDay] = useState({
    monday: currentSchedule.monday.startTime !== null,
    tuesday: currentSchedule.tuesday.startTime !== null,
    wednesday: currentSchedule.wednesday.startTime !== null,
    thursday: currentSchedule.thursday.startTime !== null,
    friday: currentSchedule.friday.startTime !== null,
    saturday: currentSchedule.saturday.startTime !== null,
    sunday: currentSchedule.sunday.startTime !== null,
  });

  const [activeHover, setActiveHover] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [data, setData] = useState({ ...dataObject });

  const handleChange = (e, newValue, day) => {
    setData({ ...data, [day]: [newValue[0], newValue[1]] });
  };

  const parseDate = (day, newActiveDay) => {
    if (newActiveDay && !newActiveDay[day]) {
      return { startTime: null, endTime: null };
    }

    if (!newActiveDay && !activeDay[day]) {
      return { startTime: null, endTime: null };
    }

    return { startTime: mapperValues[data[day][0]] || null, endTime: mapperValues[data[day][1]] || null };
  };

  const handleChangeCommit = (e, newValue, day) => {
    const endTime = moment(newValue[1]).isBefore(moment(newValue[0])) ? newValue[1] : newValue[0];
    const startTime = moment(newValue[1]).isAfter(moment(newValue[0])) ? newValue[1] : newValue[0];
    setData({ ...data, [day]: [startTime, endTime] });

    const sendObject = {
      monday: parseDate('monday'),
      tuesday: parseDate('tuesday'),
      wednesday: parseDate('wednesday'),
      thursday: parseDate('thursday'),
      friday: parseDate('friday'),
      saturday: parseDate('saturday'),
      sunday: parseDate('sunday'),
      [day]: {
        startTime: mapperValues[startTime],
        endTime: mapperValues[endTime],
      },
    };

    onChange(sendObject);
  };

  const handleChangeDay = day => {
    const newActiveDay = { ...activeDay, [day]: !activeDay[day] };
    setActiveDay(newActiveDay);

    const sendObject = {
      monday: parseDate('monday', newActiveDay),
      tuesday: parseDate('tuesday', newActiveDay),
      wednesday: parseDate('wednesday', newActiveDay),
      thursday: parseDate('thursday', newActiveDay),
      friday: parseDate('friday', newActiveDay),
      saturday: parseDate('saturday', newActiveDay),
      sunday: parseDate('sunday', newActiveDay),
    };

    onChange(sendObject);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container className={classes.centered}>
              {componentBuilder.headers.leftHalf.map((item, index) => (
                <Grid
                  key={item}
                  item
                  xs={3}
                  className={(classes.dFlex, classes.h100, classes.centered)}
                  onClick={() => {
                    if (item) {
                      handleChangeDay(item);
                    }
                  }}
                  onMouseEnter={() => {
                    if (item) {
                      setActiveHover({
                        ...activeHover,
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false,
                        [item]: true,
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (item) {
                      setActiveHover({ ...activeHover, [item]: false });
                    }
                  }}
                >
                  <Grid container className={clsx({ [classes.grayHover]: activeHover[item] })}>
                    {item && (
                      <Grid item xs={12} className={classes.dFlex}>
                        <CheckIcon
                          className={clsx({
                            [classes.colorBlue]: activeDay[item] === true && activeHover[item] === false,
                          })}
                          style={{
                            visibility: activeDay[item] === true || activeHover[item] === true ? '' : 'hidden',
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      className={classes.dFlex}
                      style={{ opacity: activeDay[item] === false && activeHover[item] === false ? '0.3' : '' }}
                    >
                      <Typography className={classes.dayOfWeek} variant="body1">
                        {index > 0 && moment.weekdaysShort(index).replace('.', '')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              {componentBuilder.headers.rightHalf.map((item, index) => (
                <Grid
                  key={item}
                  item
                  xs={3}
                  className={(classes.dFlex, classes.h100, classes.centered)}
                  onClick={() => {
                    handleChangeDay(item);
                  }}
                  onMouseEnter={() => {
                    setActiveHover({
                      ...activeHover,
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false,
                      [item]: true,
                    });
                  }}
                  onMouseLeave={() => {
                    setActiveHover({ ...activeHover, [item]: false });
                  }}
                >
                  <Grid container className={clsx({ [classes.grayHover]: activeHover[item] })}>
                    <Grid item xs={12} className={classes.dFlex}>
                      <CheckIcon
                        className={clsx({
                          [classes.colorBlue]: activeDay[item] === true && activeHover[item] === false,
                        })}
                        style={{
                          visibility: activeDay[item] === true || activeHover[item] === true ? '' : 'hidden',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.dFlex}
                      style={{ opacity: activeDay[item] === false && activeHover[item] === false ? '0.3' : '' }}
                    >
                      <Typography className={classes.dayOfWeek} variant="body1">
                        {moment.weekdaysShort(index + componentBuilder.headers.leftHalf.length).replace('.', '')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    06:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    08:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    10:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    12:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    14:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    16:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    18:00
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#464457',
                    }}
                  >
                    20:00
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    disabled={!activeDay.monday}
                    orientation="vertical"
                    value={activeDay.monday ? data.monday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'monday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'monday')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    disabled={!activeDay.tuesday}
                    orientation="vertical"
                    value={activeDay.tuesday ? data.tuesday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'tuesday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'tuesday')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    orientation="vertical"
                    disabled={!activeDay.wednesday}
                    value={activeDay.wednesday ? data.wednesday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'wednesday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'wednesday')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container style={{ height: '100%' }}>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    disabled={!activeDay.thursday}
                    orientation="vertical"
                    value={activeDay.thursday ? data.thursday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'thursday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'thursday')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    orientation="vertical"
                    disabled={!activeDay.friday}
                    value={activeDay.friday ? data.friday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'friday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'friday')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    orientation="vertical"
                    disabled={!activeDay.saturday}
                    value={activeDay.saturday ? data.saturday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'saturday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'saturday')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSlider
                    className={classes.root}
                    orientation="vertical"
                    disabled={!activeDay.sunday}
                    value={activeDay.sunday ? data.sunday : [0, 0]}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={5}
                    max={140}
                    onChange={(...args) => handleChange(...args, 'sunday')}
                    onChangeCommitted={(...args) => handleChangeCommit(...args, 'sunday')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleComponent;
