import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 } from 'uuid';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { BASCheckboxComponent, FormatCurrencyComponent } from '../../../../components';
import CurrencyTextFieldComponent from '../../../../components/CurrencyTextFieldComponent';
import VatCodeSelectFieldComponent from '../../../../components/VatCodeSelectFieldComponent';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
    padding: theme.spacing(1),
  },
  small: {
    width: '25px',
  },
}));

const isNumeric = n => !Number.isNaN(Number.parseFloat(n)) && Number.isFinite(Number.parseFloat(n));

export const validateLines = async values => {
  const errorMessages = {};
  await values.lines.forEach((line, index) => {
    const lineErrors = {};
    if (!line.description) {
      lineErrors.description = <FormattedMessage id="error.required" />;
    }

    if (line.quantity === '' || line.quantity === null) {
      lineErrors.quantity = <FormattedMessage id="error.required" />;
    } else if (!isNumeric(line.quantity)) {
      lineErrors.quantity = <FormattedMessage id="error.invalid.number" />;
    }

    if (!line.vatCode || !line.vatCode.vatCodeId) {
      lineErrors.vatCode = <FormattedMessage id="error.required" />;
    }

    if (line.pricePerUnit.amount === '' || line.pricePerUnit.amount === null) {
      lineErrors.pricePerUnit = { amount: <FormattedMessage id="error.required" /> };
    } else if (!isNumeric(line.pricePerUnit.amount)) {
      lineErrors.pricePerUnit = { amount: <FormattedMessage id="error.invalid.number" /> };
    }

    if (Object.keys(lineErrors).length > 0) {
      if (!errorMessages.lines) {
        errorMessages.lines = {};
      }

      errorMessages.lines[index] = lineErrors;
    }
  });

  return errorMessages;
};

const move = (data, from, to) => data.splice(to, 0, data.splice(from, 1)[0]);

const EditingFinancialDocumentTable = ({ allowVatSwitching, document, showOptional, showHourlyRate, country }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  if (!values) {
    return '';
  }

  let totalAmount = 0;
  const lines = values.lines.map((line, index) => {
    const lineTotal = line.quantity * line.pricePerUnit.amount;
    totalAmount += lineTotal;

    return (
      <TableRow key={index}>
        <TableCell>
          <Field
            inputProps={{ step: 'any' }}
            type="number"
            color="secondary"
            component={TextField}
            name={`lines.${index}.quantity`}
            fullWidth
          />
        </TableCell>
        <TableCell>
          <Field color="secondary" component={TextField} fullWidth multiline name={`lines.${index}.description`} />
        </TableCell>
        <TableCell>
          <Field
            color="secondary"
            component={VatCodeSelectFieldComponent}
            name={`lines.${index}.vatCode.vatCodeId`}
            fullWidth
            country={country}
          />
        </TableCell>
        <TableCell>
          <Field
            color="secondary"
            component={CurrencyTextFieldComponent}
            decimalPlaces={2}
            name={`lines.${index}.pricePerUnit.amount`}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </TableCell>
        <TableCell>
          <FormatCurrencyComponent currency={line.totalPrice.currency} amount={lineTotal} />
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    disabled={index === 0 && values.lines.length === 1}
                    style={{ minWidth: 'auto', padding: '6px 0' }}
                    onClick={() => {
                      const newLines = [...values.lines];

                      newLines.splice(index, 1);

                      setFieldValue('lines', newLines);
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'trash']} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={index === 0}
                    style={{ minWidth: 'auto', padding: '6px 0' }}
                    onClick={() => {
                      const newLines = [...values.lines];
                      console.log(move(newLines, index, index - 1));
                      setFieldValue('lines', newLines);
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'arrow-up']} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={index === values.lines.length - 1}
                    style={{ minWidth: 'auto', padding: '6px 0' }}
                    onClick={() => {
                      const newLines = [...values.lines];
                      console.log(move(newLines, index, index + 1));
                      setFieldValue('lines', newLines);
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'arrow-down']} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {showOptional && (
              <Grid item xs={12}>
                <Grid container alignContent="center" alignItems="center">
                  <Grid item>
                    <BASCheckboxComponent
                      name="optional"
                      checked={values.lines[index].optional}
                      onClick={() => setFieldValue(`lines.${index}.optional`, !values.lines[index].optional)}
                      label={{ id: 'financial.optional' }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={<FormattedMessage id="financial.optional.explained" />}>
                      <span>
                        <FontAwesomeIcon icon={['fal', 'info-circle']} />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {showHourlyRate && (
              <Grid item xs={12}>
                <Grid container alignContent="center" alignItems="center">
                  <Grid item>
                    <BASCheckboxComponent
                      name="hourlyRate"
                      checked={values.lines[index].hourlyRate}
                      onClick={() => setFieldValue(`lines.${index}.hourlyRate`, !values.lines[index].hourlyRate)}
                      label={{ id: 'financial.hourlyRate' }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={<FormattedMessage id="financial.hourlyRate.explained" />}>
                      <span>
                        <FontAwesomeIcon icon={['fal', 'info-circle']} />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    );
  });

  const total = (
    <TableRow>
      <TableCell variant="head" colSpan={4}>
        <FormattedMessage id="financial.document.total" />
      </TableCell>
      <TableCell variant="head">
        <FormatCurrencyComponent currency={document.totalIncludingVat.currency} amount={totalAmount} />
      </TableCell>
    </TableRow>
  );

  return (
    <Grid container spacing={3}>
      {allowVatSwitching && (
        <Grid item xs={8}>
          <ToggleButtonGroup
            value={values.includingVat}
            exclusive
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setFieldValue('includingVat', newValue);
                values.lines.forEach((line, index) => {
                  const percentage = 1 + line.vatPercentage.percentage / 100;
                  if (newValue) {
                    setFieldValue(
                      `lines.${index}.pricePerUnit.amount`,
                      Math.round(line.pricePerUnit.amount * percentage).toString(),
                    );
                    setFieldValue(
                      `lines.${index}.totalPrice.amount`,
                      Math.round(line.totalPrice.amount * percentage).toString(),
                    );
                  } else {
                    setFieldValue(
                      `lines.${index}.pricePerUnit.amount`,
                      Math.round(line.pricePerUnit.amount / percentage).toString(),
                    );
                    setFieldValue(
                      `lines.${index}.totalPrice.amount`,
                      Math.round(line.totalPrice.amount / percentage).toString(),
                    );
                  }
                });
              }
            }}
          >
            <ToggleButton value={false}>
              <FormattedMessage id="financial.excludingVat" />
            </ToggleButton>
            <ToggleButton value>
              <FormattedMessage id="financial.includingVat" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer className={classes.fullWidth}>
          <Table className={classes.fullWidth}>
            <TableHead>
              <TableRow>
                <TableCell width="60px">
                  <FormattedMessage id="financial.document.quantity" />
                </TableCell>
                <TableCell width="33%" style={{ minWidth: 250 }}>
                  <FormattedMessage id="financial.document.description" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="financial.document.vat" />
                </TableCell>
                <TableCell width="150px" style={{ minWidth: 150 }}>
                  <FormattedMessage id="financial.document.pricePerUnit" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="financial.document.totalPrice" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {lines}
              <TableRow>
                <TableCell colSpan={5}>
                  <Button
                    onClick={() => {
                      const lastLine = values.lines[values.lines.length - 1];
                      const newLine = {
                        lineId: v4(),
                        quantity: 1,
                        description: '',
                        pricePerUnit: { amount: '0', currency: lastLine.pricePerUnit.currency },
                        totalPrice: { amount: '0', currency: lastLine.totalPrice.currency },
                        vatCode: { ...lastLine.vatCode },
                      };

                      setFieldValue('lines', [...values.lines, newLine]);
                    }}
                  >
                    <FormattedMessage id="financial.document.addLine" />
                  </Button>
                </TableCell>
              </TableRow>
              {total}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

EditingFinancialDocumentTable.propTypes = {
  document: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  showOptional: PropTypes.bool,
  showHourlyRate: PropTypes.bool,
};

EditingFinancialDocumentTable.defaultProps = {
  showOptional: false,
  showHourlyRate: false,
};

export default EditingFinancialDocumentTable;
