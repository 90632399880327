import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Toolbar } from '@material-ui/core';

import PaginationFormComponent from 'components/PaginationFormComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import isMobile from '../../utils/isMobile';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    alignItems: 'center',
    bottom: 0,
  },
  desktop: {
    position: 'relative',
  },
  disabled: {
    background: 'red',
  },
  backButton: {
    background: '#FFF',
    height: '64px',
    borderRadius: 0,
    '&::after': {
      content: "''",
      borderLeft: '1px solid #A3A1AE',
      opacity: 0.2,
      position: 'absolute',
      height: '50%',
      right: '0',
      top: '25%',
    },
  },
  nextButton: {
    background: '#FFF',
    height: '64px',
    borderRadius: 0,
    '&::before': {
      content: "''",
      borderLeft: '1px solid #A3A1AE',
      opacity: 0.2,
      position: 'absolute',
      height: '50%',
      left: '0',
      top: '25%',
    },
  },
  toolbar: {
    padding: 0,
    alignItems: 'center',
    maxWidth: '1366px',
    width: '100%',
  },
}));

const FooterComponent = ({
  nextButtonId,
  prevButtonId,
  reducer,
  nextFunction,
  backFunction,
  validate,
  finishFunction,
  clickPagination,
  loadingFunction,
  disableDesktop,
  color,
  title,
}) => {
  const classes = useStyles();
  const { active, hidden, selected, disabled } = useSelector(reducer);

  return (
    <>
      {!hidden && (
        <AppBar
          position="absolute"
          color="primary"
          className={clsx(classes.appBar, { [classes.desktop]: !isMobile && !disableDesktop })}
          style={{
            background: color,
            bottom: 0,
          }}
        >
          <Toolbar className={classes.toolbar}>
            {selected.prevPage && (
              <Button
                id={prevButtonId}
                className={classes.backButton}
                onClick={() => {
                  loadingFunction(true);
                  backFunction(active, selected);
                }}
                style={{ background: color }}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faArrowLeft} color="#BCC1D9" size="lg" />
              </Button>
            )}
            {!selected.prevPage && (
              <Button className={classes.backButton} disabled style={{ background: color }}>
                {` `}
              </Button>
            )}
            <Button fullWidth onClick={clickPagination} className={classes.backButton} style={{ background: color }}>
              <PaginationFormComponent title={title} reducer={reducer} />
            </Button>
            {selected.nextPage && (
              <Button
                id={nextButtonId}
                disabled={!validate && (!selected.canGoForward || disabled)}
                className={classes.nextButton}
                onClick={() => {
                  loadingFunction(true);
                  nextFunction(active, selected);
                }}
                style={{ background: color }}
              >
                <FontAwesomeIcon icon={faArrowRight} color="#BCC1D9" size="lg" />
              </Button>
            )}
            {!selected.nextPage && (
              <Button
                id={`${nextButtonId}Finish`}
                color="secondary"
                className={classes.nextButton}
                onClick={() => finishFunction()}
                style={{ background: color }}
              >
                <FontAwesomeIcon icon={faArrowRight} color="#BCC1D9" size="lg" />
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default FooterComponent;
