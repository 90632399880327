import axios from 'axios';
import { v4 } from 'uuid';

const mapAddressToBackend = (address, addressType, primary) => {
  if (address.unknownAddress) {
    return null;
  }

  return {
    primary: !!primary,
    addressId: address.addressId ?? v4(),
    addressType,
    buildingType: address.type,
    floor: address.floor === '' ? null : address.floor,
    distanceFromCar: address.distanceFromCar === '' ? null : address.distanceFromCar,
    distanceToApartment: address.distanceToApartment === '' ? null : address.distanceToApartment,
    hasElevator: address.hasElevator === '' ? null : address.hasElevator,
    notes: null,
    trafficSigns: address.trafficSigns,
    streetName: address.streetName,
    houseNumber: address.houseNumber,
    houseNumberAddition: address.houseNumberAddition ?? null,
    zipCode: address.zipCode,
    city: address.city,
    country: address.country,
  };
};

const mapCustomFurnitureToBackend = (furniture, furnitureServices) => ({
  furnitureId: furniture.furnitureId ?? null,
  furnitureType: 'custom',
  label: furniture.label ?? null,
  cubicMeter: furniture.cubicMeter ?? null,
  pricePerVariant: furniture.pricePerVariant ?? null,
  servicesPossible: furniture.servicesPossible ?? null,
  services: Object.keys(furnitureServices)
    .filter(serviceTypeId => furnitureServices[serviceTypeId])
    .map(serviceTypeId => ({
      serviceTypeId,
    })),
});

const mapFurnitureToBackend = (furniture, services) => {
  const furnitureServices = services[`${furniture.value}:${furniture.index}`] ?? {};
  if (!furniture.furnitureTypeId) {
    return mapCustomFurnitureToBackend(furniture, furnitureServices);
  }

  return {
    furnitureId: furniture.furnitureId ?? null,
    furnitureType: 'furniture',
    furnitureTypeId: furniture.furnitureTypeId,
    furnitureVariantId: furniture.selectedVariant?.variantTypeId ?? null,
    services: Object.keys(furnitureServices)
      .filter(serviceTypeId => furnitureServices[serviceTypeId])
      .map(serviceTypeId => ({
        serviceTypeId,
      })),
  };
};
const mapPhoneNumber = number => {
  if (typeof number === 'undefined' || number === null || number === '' || ['+32', '+31', '+'].includes(number)) {
    return null;
  }

  return number;
};

const mapRoomsToBackend = quoteData =>
  quoteData.rooms
    .map((room, index) => ({ ...room, index }))
    .filter(room => room.selected)
    .map(room => {
      const roomKey = `${room.value}:${room.index}`;
      const roomFurniture = quoteData.furnitureByRoom[roomKey] ?? [];
      const inventoryItems = [];
      if (quoteData.inventoryItems[roomKey]) {
        Object.keys(quoteData.inventoryItems[roomKey]).forEach(inventoryItemId => {
          const data = { inventoryItemId, ...quoteData.inventoryItems[roomKey][inventoryItemId] };
          if (data.quantity > 0) {
            inventoryItems.push({
              ...data,
              quantity: data.quantity && data.quantity !== '' ? data.quantity : 0,
              storageQuantity: data.storageQuantity && data.storageQuantity !== '' ? data.storageQuantity : 0,
            });
          }
        });
      }

      return {
        roomId: room.roomId ?? null,
        roomTypeId: room.roomTypeId,
        inventoryItems,
        notes: null,
        photos: quoteData.images[roomKey] || [],
        furniture: roomFurniture
          .map((furniture, index) => ({ ...furniture, index }))
          .filter(furniture => furniture.selected)
          .map(furniture => mapFurnitureToBackend(furniture, quoteData.servicesByRoom[roomKey] ?? {})),
      };
    });

const mapToBackend = (quoteData, tenantId) => ({
  projectId: quoteData.projectId ? quoteData.projectId : v4(),
  identityId: quoteData.identityId !== '' ? quoteData.identityId : null,
  packageId: quoteData.packageId,
  billingMoments: quoteData.billingMoments,
  pricingType: quoteData.pricingType,
  tenantId,
  customer: {
    customerId: quoteData.customerId ? quoteData.customerId : v4(),
    relationType: quoteData.customerForm.companyName ? 'business' : 'private',
    gender: quoteData.customerForm.gender ?? null,
    salutation: quoteData.customerForm.salutation ?? null,
    firstName: quoteData.customerForm.firstName ?? null,
    middleName: quoteData.customerForm.middleName ?? null,
    lastName: quoteData.customerForm.lastName ?? null,
    companyName: quoteData.customerForm.companyName ?? null,
    emailAddress: quoteData.customerForm.email ?? null,
    phoneNumber: mapPhoneNumber(quoteData.customerForm.phoneNumber ?? null),
    mobileNumber: mapPhoneNumber(quoteData.customerForm.mobileNumber ?? null),
    vatNumber: quoteData.customerForm.vatNumber ?? null,
  },
  addresses: [
    mapAddressToBackend(quoteData.addressFrom, 'from', true),
    mapAddressToBackend(quoteData.addressTo, 'to', true),
    ...quoteData.secondaryAddressFrom.map(address => mapAddressToBackend(address, 'from')),
    ...quoteData.secondaryAddressTo.map(address => mapAddressToBackend(address, 'to')),
  ].filter(address => address !== null),
  rooms: mapRoomsToBackend(quoteData),
  services: Object.keys(quoteData.services)
    .filter(service => quoteData.services[service] === true)
    .map(serviceTypeId => ({
      serviceTypeId,
    })),
  notes: quoteData.notes || null,
  storageNotes: quoteData.storageNotes || null,
});

let tokenSource;
const sendQuoteRequest = async (token, tenantId, projectId, quoteData) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();
    const requestData = mapToBackend(quoteData, tenantId);

    let data = null;
    if (!projectId) {
      data = await axios
        .post(
          `${process.env.REACT_APP_API_URL}api/${tenantId}/moving-jobs/request-estimate`,
          requestData,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          {
            cancelToken: tokenSource.token,
          },
        )
        .then(result => ({
          ...result.data,
          customerId: requestData.customer.customerId,
        }));
    } else {
      data = await axios
        .put(
          `${process.env.REACT_APP_API_URL}api/${tenantId}/moving-jobs/${projectId}/request-estimate`,
          requestData,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
          {
            cancelToken: tokenSource.token,
          },
        )
        .then(result => result.data);
    }

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const addSurcharge = async (token, tenantId, projectId, percentage, reason) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();
    const requestData = {
      projectId,
      tenantId,
      percentage,
      reason,
    };

    return axios.put(
      `${process.env.REACT_APP_API_URL}api/${tenantId}/projects/${projectId}/add-surcharge`,
      requestData,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        cancelToken: tokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const giveDiscount = async (token, tenantId, projectId, percentage, reason) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();
    const requestData = {
      projectId,
      tenantId,
      percentage,
      reason,
    };

    return axios.put(
      `${process.env.REACT_APP_API_URL}api/${tenantId}/projects/${projectId}/give-discount`,
      requestData,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        cancelToken: tokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};
const removeSurchargeOrDiscount = async (token, tenantId, projectId) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();
    const requestData = {
      projectId,
      tenantId,
    };

    return axios.put(
      `${process.env.REACT_APP_API_URL}api/${tenantId}/projects/${projectId}/remove-surcharge-or-discount`,
      requestData,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        cancelToken: tokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

export default {
  sendQuoteRequest,
  mapToBackend,
  addSurcharge,
  giveDiscount,
  removeSurchargeOrDiscount,
  mapRoomsToBackend,
};
