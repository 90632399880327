import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';

const SalutationField = props => (
  <TextField {...props} select>
    <MenuItem value="">
      <FormattedMessage id="customers.salutation.unknown" />
    </MenuItem>
    <MenuItem value="heer">heer</MenuItem>
    <MenuItem value="mevrouw">mevrouw</MenuItem>
    <MenuItem value="familie">familie</MenuItem>
    <MenuItem value="Dhr.">Dhr.</MenuItem>
    <MenuItem value="Mevr.">Mevr.</MenuItem>
    <MenuItem value="Fam.">Fam.</MenuItem>
    <MenuItem value="Mr">Mr</MenuItem>
    <MenuItem value="Ms">Ms</MenuItem>
    <MenuItem value="family">family</MenuItem>
  </TextField>
);

export default SalutationField;
