import { Tab, Tabs } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(6),
    flexGrow: 1,
    // height: `calc(100vh - ${theme.spacing(6)}px)`,
    // overflow: 'hidden',
  },
  tabs: {
    marginLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  tabContent: {
    marginLeft: theme.spacing(6),
  },
}));

const TabLayout = ({ tabs, children, noPad }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  let value = tabs[0].page;

  const activeTab = tabs.findIndex(tab =>
    matchPath(pathname, { path: tab.page, strict: tab.strict, exact: tab.exact }),
  );

  if (activeTab !== -1) {
    value = tabs[activeTab].page;
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        className={classes.tabs}
        style={{ paddingLeft: noPad && '0' }}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map(tab => (
          <Tab
            value={tab.page}
            key={tab.translate}
            onClick={() => history.push(tab.page)}
            label={<FormattedMessage id={tab.translate} />}
          />
        ))}
      </Tabs>
      <div className={classes.tabContent} style={{ paddingLeft: noPad && '0' }}>
        {children}
      </div>
    </div>
  );
};

export default TabLayout;
