import { put, select, takeLatest } from 'redux-saga/effects';
import { getMessages } from '../Services/ConversationService';

export const actionTypes = {
  SetConversation: '[UserChat] Set Conversation',
  SetMessages: '[UserChat] Set messages',
  RequestUserChat: '[UserChat] request user chat',
  AddMessage: '[UserChat] add message',
  AddMoreMessages: '[UserChat] add more messages',
  LoadMoreMessages: '[UserChat] load more messages',
};

const initialState = {
  conversationId: {},
  totalMessagesCount: 0,
  page: 1,
  messages: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetMessages: {
      return {
        ...state,
        ...action.payload,
        page: 1,
      };
    }

    case actionTypes.AddMoreMessages: {
      const messages = [...state.messages, ...action.payload.messages];
      return {
        ...state,
        messages,
        totalMessagesCount: action.payload.totalMessagesCount,
        page: state.page + 1,
      };
    }

    case actionTypes.AddMessage: {
      const messages = [action.payload, ...state.messages];
      return {
        ...state,
        messages,
        totalMessagesCount: state.totalMessagesCount++,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setMessages: (messages, totalMessagesCount) => ({
    type: actionTypes.SetMessages,
    payload: { messages, totalMessagesCount },
  }),
  addMoreMessages: (messages, totalMessagesCount) => ({
    type: actionTypes.AddMoreMessages,
    payload: { messages, totalMessagesCount },
  }),
  requestUserChat: conversationId => ({
    type: actionTypes.RequestUserChat,
    payload: conversationId,
  }),
  loadMoreMessages: conversationId => ({
    type: actionTypes.LoadMoreMessages,
    payload: conversationId,
  }),
  addMessage: message => ({
    type: actionTypes.AddMessage,
    payload: message,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestUserChat, function* requestUserChat(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentEmployeeId = yield select(state => state.AppReducer.employee.employeeId);

    try {
      const messagesResponse = yield getMessages(currentTenantId, action.payload || currentEmployeeId, 1, 15);
      yield put(actions.setMessages(messagesResponse.data['hydra:member'], messagesResponse.data['hydra:totalItems']));
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.LoadMoreMessages, function* execute(action) {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);

    const currentEmployeeId = yield select(state => state.AppReducer.employee.employeeId);

    const page = yield select(state => state.UserChatReducer.page);

    try {
      const messagesResponse = yield getMessages(currentTenantId, action.payload || currentEmployeeId, page + 1, 15);
      yield put(
        actions.addMoreMessages(messagesResponse.data['hydra:member'], messagesResponse.data['hydra:totalItems']),
      );
    } catch (error) {
      console.log(error);
    }
  });
}
