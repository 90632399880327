import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import DialogComponent from 'components/DialogComponent';
import { makeStyles } from '@material-ui/core/styles';

import { AddressForm } from 'forms';
import { FormattedMessage } from 'react-intl';
import { AddAddressComponent, ShowAddressComponent } from 'components';
import { actions as MovingJobEstimateActions } from '../../Ducks/MovingJobEstimate.duck';
import { actions as FirstStepActions } from '../../../FirstStep/Ducks/FirstStep.duck';

const useStyles = makeStyles(theme => ({
  whiteButton: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  addressButton: {
    border: '1px dashed black',
    background: theme.palette.primary.main,
  },
  content: {
    paddingBottom: '30%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#A3A1AE',
  },
  card: {
    background: 'white',
    padding: '15px',
    border: '1px solid #F2F3F8',
  },
}));

const SecondaryAddressesPage = () => {
  const dispatch = useDispatch();
  const { active, selected } = useSelector(state => state.FirstStepReducer);
  const [openDialogOptions, setOpenDialogOptions] = useState({ open: false, renderComponent: {} });
  const { addressTo, addressFrom, secondaryAddressTo, secondaryAddressFrom } = useSelector(
    state => state.MovingJobEstimateReducer,
  );

  const [multipleAddresses, setMultipleAddresses] = useState(
    () => secondaryAddressTo.length > 0 || secondaryAddressFrom.length > 0,
  );

  const classes = useStyles();

  useEffect(() => {
    dispatch(FirstStepActions.filledForm(multipleAddresses));
  }, [multipleAddresses]);

  const showMultipleAddresses = () => {
    setMultipleAddresses(true);
    dispatch(FirstStepActions.filledForm(true));
  };

  return (
    <Grid container spacing={4} className={classes.content}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="address.secondary_address.does_everything_need_to_go_to_the_same_address" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {!multipleAddresses && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={classes.blueButton}
                fullWidth
                onClick={() => {
                  dispatch(FirstStepActions.next(active, selected));
                }}
              >
                <FormattedMessage id="address.secondary_address.correct" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" className={classes.whiteButton} fullWidth onClick={showMultipleAddresses}>
                <FormattedMessage id="address.secondary_address.no_several" />
              </Button>
            </Grid>
          </Grid>
        )}

        {multipleAddresses && (
          <Grid container spacing={4}>
            <ShowAddressComponent address={addressFrom} title={<FormattedMessage id="address.confirm.from" />} />

            {secondaryAddressFrom.map((item, index) => (
              <ShowAddressComponent
                key={`${item.zipCode}-${item.houseNumber}-${index}`}
                address={item}
                showRemove
                handleRemove={() => {
                  dispatch(
                    MovingJobEstimateActions.removeSecondaryAddressesFrom({
                      index,
                    }),
                  );
                }}
              />
            ))}

            <Grid item xs={12}>
              <AddAddressComponent
                label={<FormattedMessage id="address.secondary_address.add_address" />}
                clickFunction={() =>
                  setOpenDialogOptions({
                    open: true,
                    renderComponent: (
                      <AddressForm
                        title={<FormattedMessage id="address.from_which_address" />}
                        formKey="secondaryAddressFrom"
                        index={secondaryAddressFrom.length}
                        showSubmit
                        inverted
                        isSecondary
                        handleSubmit={values => {
                          dispatch(
                            MovingJobEstimateActions.saveSecondaryAddressesFrom({
                              value: values,
                              index: secondaryAddressFrom.length,
                            }),
                          );

                          setOpenDialogOptions({ open: false, renderComponent: <></> });
                        }}
                      />
                    ),
                  })
                }
              />
            </Grid>

            <ShowAddressComponent address={addressTo} title={<FormattedMessage id="address.confirm.to" />} />

            {secondaryAddressTo.map((item, index) => (
              <ShowAddressComponent
                key={`${item.zipCode}-${item.houseNumber}-${index}`}
                address={item}
                showRemove
                handleRemove={() => {
                  dispatch(
                    MovingJobEstimateActions.removeSecondaryAddressesTo({
                      index,
                    }),
                  );
                }}
              />
            ))}

            <Grid item xs={12}>
              {!addressTo.unknownAddress && (
                <AddAddressComponent
                  label={<FormattedMessage id="address.secondary_address.add_address" />}
                  clickFunction={() =>
                    setOpenDialogOptions({
                      open: true,
                      renderComponent: (
                        <AddressForm
                          title={<FormattedMessage id="address.to_which_address" />}
                          formKey="secondaryAddressTo"
                          index={secondaryAddressTo.length}
                          showSubmit
                          isSecondary
                          inverted
                          handleSubmit={values => {
                            dispatch(
                              MovingJobEstimateActions.saveSecondaryAddressesTo({
                                value: values,
                                index: secondaryAddressTo.length,
                              }),
                            );

                            setOpenDialogOptions({ open: false, renderComponent: <></> });
                          }}
                        />
                      ),
                    })
                  }
                />
              )}
            </Grid>

            <DialogComponent
              open={openDialogOptions.open}
              handleClose={() => setOpenDialogOptions({ open: false, renderComponent: <></> })}
            >
              {openDialogOptions.renderComponent}
            </DialogComponent>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SecondaryAddressesPage;
