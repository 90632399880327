import { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button, FormControlLabel, Grid, MenuItem, Typography } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import { InputComponent } from 'components';
import objectUtils from 'utils/object';
import { Checkbox, TextField } from 'formik-material-ui';
import { actions as FirstStepActions } from '../../Domain/FirstStep/Ducks/FirstStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

import useStyles from './style';
import { autocompleteAddress } from '../../Domain/Address/Services';
import CountryAutocomplete from '../../components/CountryAutocomplete';
import WizardContext from '../../Domain/EmployeeApp/Utils/WizardContext';

const defaultAddress = {
  zipCode: '',
  streetName: '',
  houseNumber: '',
  addition: '',
  city: '',
  country: 'NL',
  type: 'house',
  floor: null,
  distanceFromCar: null,
  distanceToApartment: null,
  hasElevator: false,
};

const AddressForm = ({ formKey, index, showSubmit, handleSubmit, title, inverted, isSecondary }) => {
  const country = useSelector(state => state.AppReducer.tenant.address.country);
  defaultAddress.country = country;
  const { setValidateFunction } = useContext(WizardContext);

  const address = useSelector(state => {
    if (!index) {
      return state.MovingJobEstimateReducer[formKey] ?? false;
    }

    return state.MovingJobEstimateReducer[formKey][index] ?? false;
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const { active, selected } = useSelector(state => state.FirstStepReducer);

  const [error, setError] = useState(false);
  const [expandedForm, setExpandedForm] = useState(address ? address.expandedForm : false);
  const [autoCompleted, setAutoCompleted] = useState({});

  const fetchAddress = async (values, callback) => {
    if (!values.country || values.unknownAddress) {
      return;
    }

    if (values.country.toLowerCase() !== 'nl') {
      setExpandedForm(true);

      return;
    }

    if (!values.zipCode || !values.houseNumber) {
      return;
    }

    const { cancelPrevQuery, result } = await autocompleteAddress(
      values.country,
      values.zipCode,
      values.houseNumber,
      values.houseNumberAddition,
    );

    if (cancelPrevQuery) return;

    if (!result || !result.country) {
      setError(true);
      setExpandedForm(true);

      return;
    }

    setAutoCompleted(result);
    setError(false);
    setExpandedForm(false);
    if (callback) {
      callback(result);
    }
  };

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      setValidateFunction(() => async () => {
        if (!formRef.current) {
          return true;
        }

        await formRef.current.validateForm();
        if (!formRef.current.isValid) {
          await formRef.current.submitForm();

          return false;
        }

        return formRef.current.isValid;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef]);

  const validate = values => {
    const errors = {};

    if (values.unknownAddress) {
      if (!index && index !== 0) {
        dispatch(
          MovingJobEstimateActions.save({
            [formKey]: { unknownAddress: true, expandedForm },
          }),
        );

        dispatch(FirstStepActions.filledForm(true));
      } else {
        dispatch(FirstStepActions.filledForm(true));
      }

      return errors;
    }

    if (!values.zipCode) {
      errors.zipCode = <FormattedMessage id="error.required" />;
    }
    if (!values.houseNumber) {
      errors.houseNumber = <FormattedMessage id="error.required" />;
    }

    if (Object.keys(errors).length === 0) {
      if (!index && index !== 0) {
        fetchAddress(values, result => {
          dispatch(
            MovingJobEstimateActions.save({
              [formKey]: {
                ...result,
                addition: values.addition,
                type: values.type,
                distanceFromCar: values.distanceFromCar,
                floor: values.floor,
                distanceToApartment: values.distanceToApartment,
                hasElevator: values.hasElevator,
                trafficSigns: values.trafficSigns,
              },
            }),
          );
        });
      } else {
        fetchAddress(values);
      }
    } else if (!isSecondary) {
      if (!index && index !== 0) {
        fetchAddress(values, result => {
          dispatch(
            MovingJobEstimateActions.save({
              [formKey]: {
                ...result,
                addition: values.addition,
                type: values.type,
                distanceFromCar: values.distanceFromCar,
                floor: values.floor,
                distanceToApartment: values.distanceToApartment,
                hasElevator: values.hasElevator,
                trafficSigns: values.trafficSigns,
              },
            }),
          );
        });
      }
      dispatch(FirstStepActions.filledForm(false));
    }

    if (['apartment', 'office'].includes(values.type)) {
      if (values.floor === null || values.floor < null) {
        errors.floor = <FormattedMessage id="error.required" />;
      }
      if (values.hasElevator === null) {
        errors.hasElevator = <FormattedMessage id="error.required" />;
      }
      if (values.distanceFromCar < 0) {
        errors.distanceFromCar = <FormattedMessage id="error.required" />;
      }
      if (values.distanceToApartment < 0) {
        errors.distanceToApartment = <FormattedMessage id="error.required" />;
      }
    }

    if (!autoCompleted.streetName && !values.streetName) {
      errors.streetName = <FormattedMessage id="error.required" />;
    }
    if (!autoCompleted.country && !values.country) {
      errors.country = <FormattedMessage id="error.required" />;
    }
    if (!autoCompleted.city && !values.city) {
      errors.city = <FormattedMessage id="error.required" />;
    }

    if (!autoCompleted.type && !values.type) {
      errors.type = <FormattedMessage id="error.required" />;
    }

    if (Object.keys(errors).length === 0) {
      if (!index && index !== 0) {
        dispatch(
          MovingJobEstimateActions.save({
            [formKey]: { ...values, expandedForm },
          }),
        );

        dispatch(FirstStepActions.filledForm(true));
      }
    } else if (!isSecondary) {
      dispatch(FirstStepActions.filledForm(false));
    } else {
      dispatch(FirstStepActions.filledForm(true));
    }

    return errors;
  };

  useEffect(() => {
    if (address.unknownAddress || (address.fromBackend && address.addressId && address.type)) {
      dispatch(FirstStepActions.filledForm(true));
    } else if (!isSecondary) {
      dispatch(FirstStepActions.filledForm(false));
    } else {
      dispatch(FirstStepActions.filledForm(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.addressId, address.fromBackend]);

  const submit = values => {
    if (!index && index !== 0) {
      dispatch(FirstStepActions.next(active, selected));
    }
    if (handleSubmit) {
      const cleanObject = objectUtils(values);
      handleSubmit({
        ...autoCompleted,
        ...cleanObject,
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="h3" align="center" className={classes.headerTitle}>
          <FormattedMessage id="address.title" />
        </Typography>
        <Typography variant="h3" component="h3" align="center" className={classes.headerTitle}>
          {title}
        </Typography>
      </Grid>

      {error && (
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" align="center" className={classes.error}>
            <FormattedMessage id="address.not_found" />
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Formik
          innerRef={formRef}
          initialValues={{
            ...(Object.keys(address).length > 0 ? address : defaultAddress),
            type: (Object.keys(address).length > 0 ? address.type : defaultAddress.type) || '',
          }}
          validate={values => validate(values)}
          onSubmit={submit}
        >
          {form => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    required={!form.values.unknownAddress}
                    type="text"
                    color="secondary"
                    component={InputComponent}
                    whiteLabel={inverted}
                    name="zipCode"
                    label={<FormattedMessage id="address.label.zipCode" />}
                    fullWidth
                  />
                </Grid>
                {expandedForm && (
                  <Grid item xs={12}>
                    <Field
                      required={!form.values.unknownAddress}
                      type="text"
                      color="secondary"
                      component={InputComponent}
                      whiteLabel={inverted}
                      name="streetName"
                      label={<FormattedMessage id="address.label.streetName" />}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={expandedForm ? 6 : 12}>
                  <Field
                    required={!form.values.unknownAddress}
                    component={InputComponent}
                    whiteLabel={inverted}
                    label={<FormattedMessage id="address.label.houseNumber" />}
                    name="houseNumber"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={expandedForm ? 6 : 12}>
                  <Field
                    component={InputComponent}
                    whiteLabel={inverted}
                    label={<FormattedMessage id="address.label.addition" />}
                    color="secondary"
                    name="addition"
                    fullWidth
                  />
                </Grid>
                {expandedForm && (
                  <>
                    <Grid item xs={12}>
                      <Field
                        required={!form.values.unknownAddress}
                        type="text"
                        color="secondary"
                        component={InputComponent}
                        whiteLabel={inverted}
                        name="city"
                        label={<FormattedMessage id="address.label.city" />}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Field
                    required={!form.values.unknownAddress}
                    type="text"
                    color="secondary"
                    component={CountryAutocomplete}
                    whiteLabel={inverted}
                    name="country"
                    label={<FormattedMessage id="address.label.country" />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    select
                    name="type"
                    label={<FormattedMessage id="address.label.property_type" />}
                    type="Addition"
                    whiteLabel={inverted}
                    fullWidth
                    required={!form.values.unknownAddress}
                  >
                    <MenuItem value="house">
                      <FormattedMessage id="address.property_type.house" />
                    </MenuItem>
                    <MenuItem value="bungalow">
                      <FormattedMessage id="address.property_type.bungalow" />
                    </MenuItem>
                    <MenuItem value="apartment">
                      <FormattedMessage id="address.property_type.apartment" />
                    </MenuItem>
                    <MenuItem value="office">
                      <FormattedMessage id="address.property_type.office" />
                    </MenuItem>
                    <MenuItem value="storage">
                      <FormattedMessage id="address.property_type.storage" />
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.marginLeft}
                    label={<FormattedMessage id="movingJob.address.trafficSigns" />}
                    control={
                      <Field
                        type="checkbox"
                        className={classes.removePadding}
                        component={Checkbox}
                        name="trafficSigns"
                      />
                    }
                  />
                </Grid>
                {['apartment', 'office'].includes(form.values.type) && (
                  <>
                    <Grid item xs={12}>
                      <Field
                        type="number"
                        component={InputComponent}
                        whiteLabel={inverted}
                        label={<FormattedMessage id="address.label.floor" />}
                        color="secondary"
                        name="floor"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={inverted ? classes.whiteLabel : classes.label}>
                        <FormattedMessage id="address.is_there_a_elevator" />
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button
                            onClick={() => {
                              form.setFieldValue('hasElevator', true);
                            }}
                            fullWidth
                            className={
                              form.values.hasElevator === true ? classes.activeElevatorButton : classes.elevatorButton
                            }
                          >
                            <FormattedMessage id="address.hasElevator.yes" />
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            onClick={() => {
                              form.setFieldValue('hasElevator', false);
                            }}
                            fullWidth
                            className={
                              form.values.hasElevator === false ? classes.activeElevatorButton : classes.elevatorButton
                            }
                          >
                            <FormattedMessage id="address.hasElevator.no" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="number"
                        component={InputComponent}
                        whiteLabel={inverted}
                        label={<FormattedMessage id="address.label.distance_to_car" />}
                        color="secondary"
                        name="distanceFromCar"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="number"
                        component={InputComponent}
                        whiteLabel={inverted}
                        label={<FormattedMessage id="address.label.distance_to_apartment" />}
                        color="secondary"
                        name="distanceToApartment"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {formKey === 'addressTo' && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={classes.marginLeft}
                      label={<FormattedMessage id="movingJob.address.unknownAddress" />}
                      control={
                        <Field
                          type="checkbox"
                          className={classes.removePadding}
                          component={Checkbox}
                          name="unknownAddress"
                        />
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button type="submit" fullWidth className={showSubmit ? classes.popButton : classes.hiddenButton}>
                    <FormattedMessage id="address.add" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddressForm;
