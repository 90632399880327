import { useState, useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { TextField } from 'formik-material-ui';
import { useField } from 'formik';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { TenantService } from '../../services';

const FinancialDocumentTemplateSelectTextField = ({
  identityId,
  documentType,
  onSelectedItem,
  InputProps,
  templateType,
  ...params
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const { tenantId } = useSelector(state => state.AppReducer.tenant);
  const field = useField(params.field);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await TenantService.getFinancialDocumentTemplates(
        tenantId,
        1,
        99999,
        { name: 'asc' },
        {
          identityId: identityId || undefined,
          'documentType.value': documentType || undefined,
          'templateType.value': templateType || undefined,
        },
      );
      const financialDocumentTemplates = await response.data['hydra:member'];

      setDocuments(financialDocumentTemplates);
      setOptions(
        financialDocumentTemplates.map(financialDocumentTemplate => ({
          financialDocumentTemplateId: financialDocumentTemplate.financialDocumentTemplateId,
          translations: financialDocumentTemplate.translations,
          label: financialDocumentTemplate.name,
        })),
      );

      onSelectedItem(
        financialDocumentTemplates.find(
          ({ financialDocumentTemplateId }) => financialDocumentTemplateId === field[1].value,
        ),
      );
      setLoading(false);
    })();
  }, [tenantId, identityId, documentType, templateType]);

  const handleSelectItem = event => {
    event.preventDefault();
    field[2].setValue(event.target.value);
    if (typeof onSelectedItem === 'function') {
      onSelectedItem(
        documents.find(({ financialDocumentTemplateId }) => financialDocumentTemplateId === event.target.value),
      );
    }
  };

  return (
    <TextField
      fullWidth
      select
      InputProps={{
        ...InputProps,
        endAdornment: <>{loading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : null}</>,
      }}
      {...params}
      onChange={handleSelectItem}
    >
      {options.map(({ financialDocumentTemplateId, label }) => (
        <MenuItem key={financialDocumentTemplateId} value={financialDocumentTemplateId}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

FinancialDocumentTemplateSelectTextField.defaultProps = {
  documentType: null,
  templateType: null,
  onSelectedItem: null,
};

FinancialDocumentTemplateSelectTextField.propTypes = {
  identityId: PropTypes.string.isRequired,
  documentType: PropTypes.string,
  templateType: PropTypes.array,
  onSelectedItem: PropTypes.func,
};

export default FinancialDocumentTemplateSelectTextField;
