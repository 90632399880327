import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { fieldToTextField } from 'formik-material-ui';
import { TextField } from '@material-ui/core';
import { RandomWidthSkeleton } from '../index';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';

const VatCodeSelectFieldComponent = props => {
  const {
    form: { setFieldValue },
    field: { name, value },
    country,
  } = props;

  const vatCodesList = useSelector(state => state.AppReducer.vatCodes);
  const vatCodes = country
    ? vatCodesList.filter(item => item.country.toLowerCase() === country.toLowerCase())
    : vatCodesList;
  const dispatch = useDispatch();

  const onChange = useCallback(
    event => {
      const newValue = vatCodes.find(vat => vat.vatCodeId === event.target.value);
      setFieldValue(name, newValue.vatCodeId || null);
    },
    [setFieldValue, name, vatCodes],
  );

  useEffect(() => {
    if (!vatCodes || vatCodes.length === 0) {
      dispatch(AppActions.requestVatCodes());
    }
  }, [dispatch]);

  if (vatCodes.length === 0) {
    return <RandomWidthSkeleton />;
  }

  return (
    <TextField {...fieldToTextField(props)} value={value} onChange={onChange} select>
      {vatCodes.map(vatCode => (
        <MenuItem value={vatCode.vatCodeId} key={vatCode.vatCodeId}>
          {`${vatCode.percentage.percentage}%`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default VatCodeSelectFieldComponent;
