import axios from 'axios';
import Qs from 'qs';

export function getQuotes(tenantId, page, perPage, order, appliedFilters, globalFilter) {
  return axios.get(`api/${tenantId}/quotes`, {
    params: {
      ...appliedFilters,
      page,
      perPage,
      order,
      globalFilter,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export function getQuote(tenantId, id) {
  return axios.get(`api/${tenantId}/quotes/${id}`);
}

export function getQuoteForAcceptance(tenantId, quoteId, relationId, code) {
  return axios.get(`api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}`);
}

export function getQuoteForAcceptanceOpened(tenantId, quoteId, relationId, code, values) {
  return axios.put(`api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}/opened`, {
    tenantId,
    quoteId,
    relationId,
    code,
    ...values,
  });
}

export function calculateTotalsForAcceptanceQuote(tenantId, quoteId, relationId, code, values) {
  return axios.put(`api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}/calculate-totals`, {
    tenantId,
    quoteId,
    relationId,
    code,
    ...values,
  });
}

export function acceptQuoteForAcceptance(tenantId, quoteId, relationId, code, values) {
  return axios.put(`api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}/accept`, {
    tenantId,
    quoteId,
    relationId,
    code,
    ...values,
  });
}

export function declineQuoteForAcceptance(tenantId, quoteId, relationId, code) {
  return axios.put(`api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}/decline`, {
    tenantId,
    quoteId,
    relationId,
    code,
  });
}

export function previewQuote(tenantId, quoteId) {
  return axios.get(`api/${tenantId}/quotes/${quoteId}/preview-pdf`, {
    responseType: 'blob',
  });
}

export function downloadQuote(tenantId, quoteId) {
  return axios.get(`api/${tenantId}/quotes/${quoteId}/download-pdf`, {
    responseType: 'blob',
  });
}

export function acceptQuote(tenantId, id, values) {
  return axios.put(`api/${tenantId}/quotes/${id}/accept`, values);
}

export function cancelQuote(tenantId, id, values) {
  return axios.put(`api/${tenantId}/quotes/${id}/cancel`, values);
}

export function declineQuote(tenantId, id, values) {
  return axios.put(`api/${tenantId}/quotes/${id}/decline`, values);
}
