import PropTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '5px',
  },
  label: {
    marginBottom: '7px',
  },
  whiteLabel: {
    color: '#ffffff',
    marginBottom: '7px',
  },
  helperText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  whiteHelperText: {
    background: 'transparent',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  inputWhite: {
    color: '#ffffff',
  },
  whiteInput: {
    background: '#ffffff',
    color: '#464457',
  },
  whiteUnderline: {
    '&:before': {
      borderBottom: '1px solid #ffffff',
    },
  },
}));

const InputComponent = props => {
  const { label, whiteInput, whiteLabel, noLabel, inputProps, ...rest } = props;
  const classes = useStyles();

  return (
    <>
      <TextField
        {...rest}
        label={!noLabel && label}
        className={whiteLabel ? classes.whiteHelperText : classes.helperText}
        inputProps={{
          ...inputProps,
          className: clsx([
            classes.root,
            whiteLabel ? classes.inputWhite : classes.helperText,
            { [classes.whiteInput]: whiteInput },
          ]),
        }}
        InputLabelProps={{
          className: clsx([classes.root, whiteLabel ? classes.whiteHelperText : classes.helperText]),
        }}
        FormHelperTextProps={{
          className: whiteLabel ? classes.whiteHelperText : classes.helperText,
        }}
      />
    </>
  );
};

InputComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

InputComponent.defaultProps = {
  label: '',
};

export default InputComponent;
