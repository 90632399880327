import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import { MobileLayout } from '../../../../layouts';
import { ErrorHandlerComponent } from '../../../../components';

import { actions as AppActions } from '../../../App/Ducks/App.duck';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: '0px',
    height: 'calc(100%)',
  },
  blueButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  failedButton: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.primary.main,
    fontWeight: 'bold',
    minHeight: '56px',
    borderRadius: '5px',
  },
  error: {
    color: 'red',
  },
  user: {},
}));

const EmployeeHomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, tenant } = useSelector(state => state.AppReducer);
  const { failedQuotes: failed } = useSelector(state => state.FailedEstimatesReducer);

  const showProjects = () => history.push('/employee/projects');
  const failedQuotes = () => history.push('/employee/failed-quotes');

  // const startNewProject = async () => {
  //   await dispatch(FirstStepActions.reset());
  //   await dispatch(SecondStepActions.reset());
  //   await dispatch(MovingJobEstimateActions.reset());

  //   history.push('/employee/wizard');
  // };

  const logout = () => dispatch(AppActions.logout());

  return (
    <MobileLayout>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <ErrorHandlerComponent>
          <Grid container className={`${classes.paddingBottom} ${classes.mainContainer}`}>
            <Grid item xs={12}>
              <Grid container spacing={4} className={classes.mainContainer} justify="flex-end">
                <Grid item xs={3} className={classes.user} onClick={logout}>
                  <Avatar alt={user.personName.fullName}>
                    {user.personName.firstName[0]}
                    {user.personName.lastName[0]}
                  </Avatar>
                  {user.personName.firstName ? user.personName.firstName : user.personName.lastName}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h3" component="h3" align="center" className={classes.headerTitle}>
                    {tenant.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {failed.length > 0 && (
                  <Grid item xs={12}>
                    <Button variant="outlined" className={classes.failedButton} fullWidth onClick={failedQuotes}>
                      <FormattedMessage id="homepage.failed_quotes" />
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    id="projectListButton"
                    variant="outlined"
                    className={classes.blueButton}
                    fullWidth
                    onClick={showProjects}
                  >
                    <FormattedMessage id="homepage.select_project" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ErrorHandlerComponent>
      </Container>
    </MobileLayout>
  );
};

export default EmployeeHomePage;
