import axios from 'axios';
import Qs from 'qs';
import { normalizeErrors } from '../../utils/dataAccess';

let tokenSource;
let tenantsTokenSource;
let financialDocumentsSource;
let packagesSource;
const getServices = async (tenantId, page, perPage) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/public/${tenantId}/settings/service-types`,
        { params: { page, perPage } },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => ({
        items: result.data['hydra:member'].map(item => ({
          id: item['@id'],
          serviceTypeId: item.serviceTypeId,
          name: item.serviceTypeName,
          value: item.serviceTypeName,
          label: item.serviceTypeName,
          internalType: item.internalType,
        })),
        total: result.data['hydra:totalItems'],
      }));

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

const getFinancialDocumentTemplates = async (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  try {
    if (typeof financialDocumentsSource !== typeof undefined) {
      financialDocumentsSource.cancel('Operation canceled due to new request.');
    }

    financialDocumentsSource = axios.CancelToken.source();

    return axios.get(
      `/api/${tenantId}/financial-document-templates`,
      {
        params: {
          ...filters,
          tenantId,
          page,
          perPage,
          order,
          globalFilter: globalFilter !== '' ? globalFilter : undefined,
        },
      },
      {
        cancelToken: financialDocumentsSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };

    throw err;
  }
};

export const getAllPackages = async (tenantId, page, perPage, order, filters = {}, globalFilter) => {
  try {
    if (typeof packagesSource !== typeof undefined) {
      packagesSource.cancel('Operation canceled due to new request.');
    }

    packagesSource = axios.CancelToken.source();

    return axios.get(
      `/api/${tenantId}/packages`,
      {
        params: {
          ...filters,
          tenantId,
          page,
          perPage,
          order,
          globalFilter: globalFilter !== '' ? globalFilter : undefined,
        },
      },
      {
        cancelToken: packagesSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };

    throw err;
  }
};

export const getPackages = async (tenantId, identityId, projectType, pricingType) => {
  const filters = {};

  if (projectType) {
    filters['projectType.value'] = projectType;
  }

  if (pricingType) {
    filters['pricingType.value'] = pricingType;
  }

  return axios.get(`/api/${tenantId}/packages`, {
    params: {
      tenantId,
      identityId: identityId || undefined,
      ...filters,
      perPage: 100000,
    },
  });
};

export const addNewPackage = async (tenantId, values) =>
  axios.post(`api/${tenantId}/packages`, {
    ...values,
    tenantId,
  });

export const changeNameOfPackage = async (tenantId, packageId, values) =>
  axios.put(`api/${tenantId}/packages/${packageId}/change-name`, {
    ...values,
    packageId,
    tenantId,
  });

export const changeBillingMomentsOfPackage = async (tenantId, packageId, values) =>
  axios.put(`api/${tenantId}/packages/${packageId}/change-billing-moments`, {
    ...values,
    packageId,
    tenantId,
  });

export const getPackage = async (tenantId, packageId) => axios.get(`/api/${tenantId}/packages/${packageId}`);

const getFinancialDocumentTemplate = async (tenantId, financialDocumentTemplateId) =>
  axios.get(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}`);

const calculateExpiresAt = (tenantId, financialDocumentTemplateId) =>
  axios.get(`api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/calculate-expires-at`);

const renameFinancialDocumentTemplate = async (tenantId, financialDocumentTemplateId, values) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/rename`, {
    ...values,
    financialDocumentTemplateId,
    tenantId,
  });

const deleteFinancialDocumentTemplate = async (tenantId, financialDocumentTemplateId) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/delete`, {
    financialDocumentTemplateId,
    tenantId,
  });

const addAttachmentToFinancialDocumentTemplate = async (tenantId, financialDocumentTemplateId, values) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/add-attachment`, {
    ...values,
    financialDocumentTemplateId,
    tenantId,
  });

const removeAttachmentFromFinancialDocumentTemplate = async (tenantId, financialDocumentTemplateId, values) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/remove-attachment`, {
    ...values,
    financialDocumentTemplateId,
    tenantId,
  });

const addFinancialDocumentTemplate = async (tenantId, values) =>
  axios.post(`/api/${tenantId}/financial-document-templates`, {
    ...values,
    tenantId,
  });

const updateFinancialDocumentTemplateTranslation = async (tenantId, financialDocumentTemplateId, values) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/update-translation`, {
    ...values,
    financialDocumentTemplateId,
    tenantId,
  });

const updateFinancialDocumentTemplateSettings = async (tenantId, financialDocumentTemplateId, values) =>
  axios.put(`/api/${tenantId}/financial-document-templates/${financialDocumentTemplateId}/update-settings`, {
    ...values,
    financialDocumentTemplateId,
    tenantId,
  });

const getTenants = async (page, perPage, order, filters = {}, globalFilter) => {
  try {
    if (typeof tenantsTokenSource !== typeof undefined) {
      tenantsTokenSource.cancel('Operation canceled due to new request.');
    }

    tenantsTokenSource = axios.CancelToken.source();

    return await axios.get(
      `/api/tenants`,
      {
        params: {
          ...filters,
          page,
          perPage,
          order,
          globalFilter,
        },

        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
      },
      {
        cancelToken: tenantsTokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

const getProjects = async (token, tenantId, page, perPage, projectStatus, order) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/${tenantId}/moving-jobs`,
        {
          params: { page, perPage, projectStatus, ...order },
          headers: { Authorization: `Bearer ${token}` },
        },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => ({
        projects: result.data['hydra:member'],
        total: result.data['hydra:totalItems'],
      }));

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

const getTenantIdByHostname = async hostname => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/public/tenants/${hostname}`,
        { skipAuthorization: true },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => result.data);

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    return [err];
  }
};

const getIdentitiesByTenantId = async tenantId =>
  axios.get(`/api/${tenantId}/identities`, {
    params: {
      page: 1,
      perPage: 100,
    },
  });

const getTenant = async tenantId => axios.get(`/api/tenants/${tenantId}`).then(result => result.data);

const createTenant = async values =>
  axios.post(`/api/tenants`, {
    ...values,
  });

const updateTenant = async (tenantId, values) =>
  axios.put(`/api/tenants/${tenantId}`, {
    ...values,
    tenantId,
  });

const updateSignerInformation = async (tenantId, values) =>
  axios.put(`/api/tenants/${tenantId}/update-signer-information`, {
    ...values,
    tenantId,
  });

const addIdentity = async (tenantId, values) =>
  axios.post(`/api/tenants/${tenantId}/identities`, {
    ...values,
    tenantId,
    logoId: null,
  });

const startUsingIdentities = async (tenantId, values) =>
  axios.put(`/api/tenants/${tenantId}/start-using-identities`, {
    ...values,
    tenantId,
  });
const updateIdentity = async (tenantId, identityId, values) =>
  axios.put(`/api/tenants/${tenantId}/identities/${identityId}`, {
    ...values,
    identityId,
    tenantId,
  });

const getServiceTypes = async tenantId => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    return axios.get(
      `/api/${tenantId}/settings/service-types`,
      {},
      {
        cancelToken: tokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const changePriceOfServiceType = async (tenantId, serviceTypeId, values) => {
  const result = await axios.put(`/api/${tenantId}/settings/service-types/${serviceTypeId}/change-price`, {
    ...values,
    serviceTypeId,
    tenantId,
  });

  return result;
};

const changePriceOfServiceTypes = async (tenantId, values) => {
  try {
    await Promise.all(
      values.map(async serviceType => {
        await changePriceOfServiceType(tenantId, serviceType.serviceTypeId, serviceType);
      }),
    );
  } catch (e) {
    if (e.response && [400, 422].includes(e.response.status)) {
      return normalizeErrors(e.response.data);
    }

    throw e;
  }

  return true;
};

const getEstimationSettings = async tenantId => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    return axios.get(
      `/api/${tenantId}/estimation-settings`,
      {},
      {
        cancelToken: tokenSource.token,
      },
    );
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const updateMovingJobEstimationSettings = async (tenantId, estimationSettingsId, values) =>
  axios.put(`/api/${tenantId}/estimation-settings/${estimationSettingsId}/moving-job`, {
    ...values,
    estimationSettingsId,
    tenantId,
  });

const getFinancialSettingsForIdentity = async (tenantId, identityId) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    return axios
      .get(
        `/api/${tenantId}/financial-settings`,
        {
          params: {
            identityId,
          },
        },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => result.data);
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const getFinancialSettings = async tenantId => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    tokenSource = axios.CancelToken.source();

    const data = await axios
      .get(
        `/api/${tenantId}/financial-settings`,
        {
          params: {
            perPage: 1000,
          },
        },
        {
          cancelToken: tokenSource.token,
        },
      )
      .then(result => result.data);

    return data;
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const updateFinancialSettings = async (tenantId, financialSettingsId, values) =>
  axios.put(`/api/${tenantId}/financial-settings/${financialSettingsId}`, {
    ...values,
    financialSettingsId,
    tenantId,
  });

const updateFinancialDocumentTranslation = async (tenantId, values) =>
  axios.put(`/api/${tenantId}/financial-settings/update-translation`, {
    ...values,
    tenantId,
  });

const getVatCodes = async tenantId => axios.get(`/api/${tenantId}/financials/vat-codes`, {});

const getWorkingSchedules = async tenantId => axios.get(`/api/${tenantId}/working-schedules`, {});
const getStatistics = async tenantId => axios.get(`/api/${tenantId}/dashboard-statistics`, {});

const updateWorkingSchedule = async (tenantId, workingScheduleId, values) =>
  axios.put(`/api/${tenantId}/working-schedules/${workingScheduleId}`, {
    ...values,
    tenantId,
    workingScheduleId,
  });

export default {
  getServices,
  getProjects,
  getTenantIdByHostname,
  getStatistics,
  getTenant,
  getTenants,
  createTenant,
  updateTenant,
  updateSignerInformation,
  startUsingIdentities,
  updateIdentity,
  addIdentity,
  getFinancialDocumentTemplates,
  getFinancialDocumentTemplate,
  calculateExpiresAt,
  renameFinancialDocumentTemplate,
  deleteFinancialDocumentTemplate,
  addAttachmentToFinancialDocumentTemplate,
  removeAttachmentFromFinancialDocumentTemplate,
  addFinancialDocumentTemplate,
  updateFinancialDocumentTemplateTranslation,
  updateFinancialDocumentTemplateSettings,
  getFinancialSettings,
  getFinancialSettingsForIdentity,
  updateFinancialSettings,
  updateFinancialDocumentTranslation,
  getServiceTypes,
  getEstimationSettings,
  updateMovingJobEstimationSettings,
  changePriceOfServiceTypes,
  changePriceOfServiceType,
  getVatCodes,
  getWorkingSchedules,
  updateWorkingSchedule,
  getIdentitiesByTenantId,
};
