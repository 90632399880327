import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';
import { getMovingJobs } from '../Services/MovingJobService';

export const actionTypes = {
  RequestData: '[Projects] RequestProjects',
  FulfilledTable: '[Projects] FulfilledProjects',
  SearchTable: '[Projects] Search customers',
  ChangePage: '[Projects] change page',
  SetPageSize: '[Projects] set page size',
  SortTable: '[Projects] Sort customers',
  ApplyFilter: '[Projects] Apply filter',
  RemoveFilter: '[Projects] Remove filter',
  ResetFilters: '[Projects] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: {
    nextActionDate: 'asc',
  },
  filters: { projectStatus: [] },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  function* goToFirstPage() {
    yield put(actions.changePage(1));
  }

  yield takeLatest(actionTypes.SearchTable, goToFirstPage);
  yield takeLatest(actionTypes.ApplyFilter, goToFirstPage);
  yield takeLatest(actionTypes.RemoveFilter, goToFirstPage);

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestProjectsSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.ProjectsReducer);
    const filters = { ...currentState.filters };

    if ((!filters.projectStatus || filters.projectStatus.length === 0) && !currentState.searchQuery) {
      filters.projectStatus = [
        'first-contact',
        'intake',
        'quotation',
        'quote-sent',
        'quote-declined',
        'tuning',
        'quote-accepted',
        'awaiting-deposit',
        'received-deposit',
        'planned',
        'deliver-boxes',
        'in-storage',
        'carry-out',
        'aftercare',
        'pick-up-boxes',
      ];
    }

    let response = null;

    response = yield getMovingJobs(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      filters,
      currentState.searchQuery,
      true,
      true,
    );

    if (response.cancelPrevQuery) {
      return;
    }

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
