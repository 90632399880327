import { put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { EmployeeService } from '../../../services';
import { tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';

export const actionTypes = {
  RequestData: '[Employees] RequestEmployees',
  FulfilledTable: '[Employees] FulfilledEmployees',
  SearchTable: '[Employees] Search customers',
  ChangePage: '[Employees] change page',
  SetPageSize: '[Employees] set page size',
  SortTable: '[Employees] Sort customers',
  ApplyFilter: '[Employees] Apply filter',
  RemoveFilter: '[Employees] Remove filter',
  ResetFilters: '[Employees] Reset filter',
};

const initialState = {
  ...tableInitialState,
  sortBy: { name: 'asc' },
  filters: { skills: [] },
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEmployeesSaga() {
    const currentTenantId = yield select(state => state.AppReducer.tenant.tenantId);
    const currentState = yield select(state => state.EmployeesReducer);
    const filters = { ...currentState.filters };

    if (
      (!filters.employmentEndDate ||
        !filters.employmentEndDate.after ||
        filters.employmentEndDate?.after?.length === 0) &&
      !currentState.searchQuery
    ) {
      filters.employmentEndDate = { after: dayjs().format('YYYY-MM-DD') };
    }

    const response = yield EmployeeService.getEmployees(
      currentTenantId,
      currentState.page,
      currentState.pageSize,
      currentState.sortBy,
      filters,
      currentState.searchQuery,
    );

    yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
  });
}
