import Kitchen from '@material-ui/icons/KitchenOutlined';

const mockRooms = [
  {
    value: 'woonkamer',
    key: 'woonkamer',
    label: 'Woonkamer',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'keuken',
    key: 'keuken',
    label: 'Keuken',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'gang',
    key: 'gang',
    label: 'Gang',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'bijkeuken',
    key: 'bijkeuken',
    label: 'Bijkeuken',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'slaapkamer',
    key: 'slaapkamer',
    label: 'Slaapkamer',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'kantoor_hobbykamer',
    key: 'kantoor_hobbykamer',
    label: 'Kantoor hobbykamer',
    name: 'rooms',
    icon: <Kitchen fontSize="large" />,
  },
];

const mockFurniture = [
  {
    value: 'woonkamer',
    key: 'woonkamer',
    label: 'Woonkamer',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'keuken',
    key: 'keuken',
    label: 'Keuken',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'gang',
    key: 'gang',
    label: 'Gang',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'bijkeuken',
    key: 'bijkeuken',
    label: 'Bijkeuken',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'slaapkamer',
    key: 'slaapkamer',
    label: 'Slaapkamer',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
  {
    value: 'kantoor_hobbykamer',
    key: 'kantoor_hobbykamer',
    label: 'Kantoor hobbykamer',
    name: 'woonkamer',
    icon: <Kitchen fontSize="large" />,
  },
];

const mockDisassemble = [
  {
    name: 'slaapkamer',
    id: 'key',
  },
  {
    name: 'slaapkamer',
    id: 'key2',
  },
];

export { mockRooms, mockFurniture, mockDisassemble };
