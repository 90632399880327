import 'date-fns';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { InputComponent } from '../../components';
import { actions as FirstStepActions } from '../../Domain/FirstStep/Ducks/FirstStep.duck';
import { actions as MovingJobEstimateActions } from '../../Domain/MovingJobEstimate/Ducks/MovingJobEstimate.duck';

const NotesForm = ({ disableFillForm }) => {
  const dispatch = useDispatch();
  const { notes, storageNotes } = useSelector(state => state.MovingJobEstimateReducer);
  const { active, selected } = useSelector(state => state.FirstStepReducer);
  const skipSteps = useSelector(state => state.SecondStepReducer.skipSteps);
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = values => {
    dispatch(MovingJobEstimateActions.saveForm({ notes: values.notes, storageNotes: values.storageNotes }));
    if (!disableFillForm) {
      dispatch(FirstStepActions.filledForm(true));
    }
  };
  console.log(skipSteps);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" align="center">
          <FormattedMessage id="moving.notes" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          innerRef={formRef}
          validate={values => validate(values)}
          onSubmit={() => {
            if (!disableFillForm) {
              dispatch(FirstStepActions.next(active, selected));
            }
          }}
          initialValues={{ notes, storageNotes }}
        >
          {() => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    color="secondary"
                    name="notes"
                    multiline
                    component={InputComponent}
                    label={<FormattedMessage id="moving.notes" />}
                    fullWidth
                    rows={6}
                  />
                </Grid>
                {(typeof skipSteps.storageForm === 'undefined' || skipSteps.storageForm === false) && (
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      color="secondary"
                      name="storageNotes"
                      multiline
                      component={InputComponent}
                      label={<FormattedMessage id="moving.storageNotes" />}
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default NotesForm;
