export const mapField = (key, fieldMappings) => fieldMappings[key] || key;

export const mapFields = (object, fieldMappings) => {
  const result = {};
  if (Array.isArray(object)) {
    return object;
  }

  Object.keys(object).forEach(key => {
    result[mapField(key, fieldMappings)] = object[key];
  });

  return result;
};
